import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import React from 'react';
import { Form } from 'react-bootstrap';
import type { ColumnDef } from '@tanstack/react-table';
import { MinusIcon, PlusIcon } from '../../shared/icons/Svgs';
import CategoryAutosuggest from '../../shared/category-autosuggest/CategoryAutosuggest';
import type { ILocationData } from '../../../interfaces/ILocationData';
import type { ICategoryName } from '../../../interfaces/ICategoryName';
import type { ILocationMapping } from '../../../interfaces/ILocationMapping';

import './style.css';

export interface ILocationMappingsTableProps {
  show: boolean;
  locationData: ILocationData | undefined;
  setSelectedLocationMappings: React.Dispatch<React.SetStateAction<ILocationData | undefined>>;
  categoriesNames: ICategoryName[];
  updateLocationMappings: (value: ILocationData) => void;
}

export interface IEditableCellProps {
  value: any;
  row: any;
  column: any;
  updateLocation: (rowIndex: number, columnId: string, value: any) => void;
  categoriesNames: ICategoryName[];
}
function LocationMappingsTable(props: ILocationMappingsTableProps) {
  const { setSelectedLocationMappings } = props;

  const columns = React.useMemo<ColumnDef<ILocationMapping>[]>(
    () => [
      {
        accessorFn: (row) => row.mappingId,
        id: 'actionButton',
        header: () => (
          <div
            onClick={() => {
              setSelectedLocationMappings((prevState) => {
                if (prevState === undefined || prevState.location === undefined) {
                  return prevState;
                }
                return {
                  ...prevState,
                  location: {
                    ...prevState.location,
                    mappings: prevState?.location?.mappings?.concat([
                      {
                        mappingId: -1,
                        from: '',
                        to: '',
                      },
                    ]),
                  },
                };
              });
            }}
          >
            <PlusIcon style={{ cursor: 'pointer', width: '30px', height: '30px', fill: 'var(--app-main-color)' }} />
          </div>
        ),
        cell: (info) => (
          <div
            onClick={() => {
              setSelectedLocationMappings((prevState) => {
                if (prevState === undefined || prevState.location === undefined) {
                  return prevState;
                }
                const selectedValues = [info.row.original];
                return {
                  ...prevState,
                  location: {
                    ...prevState.location,
                    mappings: prevState?.location?.mappings.filter((item) => !selectedValues.includes(item)),
                  },
                };
              });
            }}
          >
            <MinusIcon style={{ cursor: 'pointer', width: '30px', height: '30px', fill: '#dc3545' }} />
          </div>
        ),
      },
      {
        accessorFn: (row) => row.from,
        id: 'from',
        header: () => <span style={{ userSelect: 'none' }}>Wartość źródłowa</span>,
      },
      {
        accessorFn: (row) => row.to,
        id: 'to',
        header: () => <span style={{ userSelect: 'none' }}>Wartość docelowa</span>,
      },
      {
        accessorFn: (row) => row.category,
        id: 'category',
        header: () => <span style={{ userSelect: 'none' }}>Kategoria</span>,
      },
    ],
    [setSelectedLocationMappings]
  );

  const defaultColumn: Partial<ColumnDef<ILocationMapping>> = {
    cell: function Cell({ getValue, row: { index }, column: { id }, table }) {
      const initialValue = getValue();
      const [value, setValue] = React.useState(initialValue);

      const onBlur = () => {
        table.options.meta?.updateData?.(index, id, value);
      };

      const onCategoryChange = (value: string) => {
        let obj = JSON.parse(value);
        setValue(obj);
      };

      React.useEffect(() => {
        setValue(initialValue);
      }, [initialValue]);

      switch (id) {
        case 'category':
          return (
            <CategoryAutosuggest
              selectedValue={value as ICategoryName}
              onChange={onCategoryChange}
              onBlur={onBlur}
              categories={table.options.meta?.categoriesNames ?? []}
            />
          );
        default:
          return (
            <Form.Control
              size='sm'
              type='text'
              onChange={(e) => setValue(e.target.value)}
              onBlur={onBlur}
              value={value as string}
            />
          );
      }
    },
  };

  var items = props.locationData?.location?.mappings ?? [];

  const table = useReactTable({
    data: items,
    columns,
    defaultColumn,
    getCoreRowModel: getCoreRowModel(),
    meta: {
      updateData: (rowIndex, columnId, value) => {
        props.setSelectedLocationMappings((prevState) => {
          if (prevState === undefined) {
            return prevState;
          }
          const updatedValue = prevState?.location?.mappings;
          if (updatedValue !== undefined) {
            updatedValue[rowIndex] = {
              ...prevState.location!.mappings[rowIndex],
              [columnId]: value,
            };

            const obj = {
              ...prevState,
              mappings: updatedValue,
            };

            return obj;
          }
          return prevState;
        });
      },
      categoriesNames: props.categoriesNames,
    },
  });

  return (
    <>
      <table id={'location-mappings-table'} className='table table-bordered table-sm mt-2' aria-labelledby='tabelLabel'>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder ? null : (
                    <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}

export default LocationMappingsTable;
