import { models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import React, { useCallback, useEffect, useState } from 'react';
import Common from '../shared/Common';
import type { Report, Embed, service } from 'powerbi-client';
import type { IPowerBiReportPage } from '../../interfaces/IPowerBiReportPage';
import 'powerbi-report-authoring';
import { Container, Nav, Navbar, Offcanvas } from 'react-bootstrap';
import { useSearchParams, useLocation } from 'react-router-dom';

import './style.css';

const Reports = () => {
  const [report, setReport] = useState<Report>();
  const [reportLoaded, setReportLoaded] = useState<boolean>(false);
  const [visibleReportPages, setVisibleReportPages] = useState<IPowerBiReportPage[]>([]);
  const [currentSelectedPageIndex, setCurrentSelectedPageIndex] = useState(0);
  const [bookmarkActive, setBookmarkActive] = React.useState(false);
  const [reportConfig, setReportConfig] = useState<models.IReportEmbedConfiguration | undefined>();
  const [searchParams, setSearchParams] = useSearchParams();
  const fetchIdRef = React.useRef(0);

  const location = useLocation();

  const query = location.search;
  React.useEffect(() => {
    if (query && query.includes('show-menu=1')) {
      setBookmarkActive(true);
    } else {
      setBookmarkActive(false);
    }
  }, [query]);

  const setPowerBiReportDefaultPage = useCallback(
    async (defaultPage: IPowerBiReportPage) => {
      const pages = await report?.getPages();
      const ind = pages?.findIndex((p) => p.displayName === defaultPage.pageName) ?? -1;
      if (ind > -1 && pages !== undefined) {
        setReportConfig((prevReportConfig) => ({
          ...prevReportConfig,
          pageName: pages[ind].name,
        }));
      }
    },
    [setReportConfig, report]
  );

  useEffect(() => {
    if (report !== undefined && visibleReportPages.length > 0 && reportLoaded) {
      setPowerBiReportDefaultPage(visibleReportPages[0]);
    }
  }, [report, visibleReportPages, reportLoaded, setPowerBiReportDefaultPage]);

  // Map of event handlers to be applied to the embedding report
  const eventHandlersMap = new Map([
    [
      'loaded',
      function () {
        setReportLoaded(true);
      },
    ],
    ['rendered', function () {}],
    [
      'error',
      function (event?: service.ICustomEvent<any>) {
        if (event) {
          console.error(event.detail);
        }
      },
    ],
  ]);

  const fetchPowerBiReport = useCallback(async () => {
    const fetchId = ++fetchIdRef.current;
    const response = await Common.authorizedFetch('api/reports/GetPowerBiEmbedInfo');
    const data = await response.json();
    if (data.success) {
      if (fetchId === fetchIdRef.current) {
        const embedInfo = data.result.powerBiEmbedInfo;
        setReportConfig((prevReportConfig) => ({
          ...prevReportConfig,
          type: 'report',
          tokenType: models.TokenType.Embed,
          settings: {
            panes: {
              pageNavigation: {
                visible: false,
              },
              filters: {
                expanded: false,
                visible: false,
              },
            },
          },
          embedUrl: embedInfo.embedReport[0].embedUrl,
          accessToken: embedInfo.embedToken.token,
        }));
        setVisibleReportPages(data.result.visibleReportPages);
      }
    }
  }, []);

  useEffect(() => {
    fetchPowerBiReport();
  }, [fetchPowerBiReport]);

  const setReportPage = async (pageName: string) => {
    const pages = await report?.getPages();
    const ind = pages?.findIndex((p) => p.displayName === pageName) ?? -1;
    if (ind > -1 && pages !== undefined) {
      await report?.setPage(pages[ind].name);
    } else {
      await report?.setPage('ReportSection');
    }
  };

  const closeBookmark = () => {
    if (searchParams.has('show-menu')) {
      searchParams.delete('show-menu');
      setSearchParams(searchParams);
    }
  };

  const generateUserVisiblePages = (visiblePages: IPowerBiReportPage[]) => {
    let userVisiblePages = [];
    for (let ind = 0; ind < visiblePages.length; ind++) {
      userVisiblePages.push(
        <Nav.Item>
          <Nav.Link
            className='px-2'
            active={ind === currentSelectedPageIndex}
            onClick={() => {
              setReportPage(visiblePages[ind].pageName);
              setCurrentSelectedPageIndex(ind);
            }}
          >
            {visiblePages[ind].name}
          </Nav.Link>
        </Nav.Item>
      );
    }
    return (
      <>
        <Offcanvas show={bookmarkActive} onHide={closeBookmark}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Wybierz raport</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            {userVisiblePages.length > 0 && (
              <Nav className='flex-column' variant='pills'>
                {userVisiblePages.map((elem, ind) => (
                  <div key={'offcanvas-' + ind} onClick={closeBookmark}>
                    {elem}
                  </div>
                ))}
              </Nav>
            )}
          </Offcanvas.Body>
        </Offcanvas>
        <Navbar className='reports-submenu-hide-on-small-devices' bg='white'>
          <Container className='justify-content-center'>
            {userVisiblePages.length > 0 ? (
              <Nav variant='pills'>
                {userVisiblePages.map((elem, ind) => (
                  <div key={'nav-' + ind}>{elem}</div>
                ))}
              </Nav>
            ) : (
              <Nav key='temporary' variant='pills'>
                <Nav.Item>
                  <Nav.Link className='px-2'>&nbsp;</Nav.Link>
                </Nav.Item>
              </Nav>
            )}
          </Container>
        </Navbar>
      </>
    );
  };

  return (
    <div>
      {generateUserVisiblePages(visibleReportPages)}
      {reportConfig === undefined ? (
        <PowerBIEmbed
          key='temporary'
          embedConfig={{
            type: 'report',
            tokenType: models.TokenType.Embed,
          }}
          cssClassName={'report-style-class'}
        />
      ) : (
        <PowerBIEmbed
          embedConfig={reportConfig}
          eventHandlers={eventHandlersMap}
          cssClassName={'report-style-class'}
          getEmbeddedComponent={async (embedObject: Embed) => {
            console.log(`Embedded object of type "${embedObject.embedtype}" received`);
            const report = embedObject as Report;
            setReport(report);
          }}
        />
      )}
    </div>
  );
};

export default Reports;
