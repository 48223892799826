import { useState } from 'react';
import Autosuggest from 'react-autosuggest';
import type { IGroupName } from '../../../interfaces/IGroupName';
import '../custom-react-autosuggest.css';

export interface IGroupAutosuggestProps {
  selectedValue?: IGroupName;
  groups: IGroupName[];
  onChange: (value: string) => void;
  onBlur?: () => void;
  triggerOnChangeWhenEmpty?: boolean;
}

function GroupAutosuggest({
  selectedValue,
  onChange,
  onBlur,
  triggerOnChangeWhenEmpty,
  groups,
}: IGroupAutosuggestProps) {
  const [value, setValue] = useState(selectedValue?.groupFullName ?? '');
  const [suggestions, setSuggestions] = useState<string[]>([]);

  function getSuggestions(value: string): string[] {
    const filteredGroups = groups.filter((group) =>
      group.groupFullName.toLowerCase().includes(value.trim().toLowerCase())
    );
    return filteredGroups.map((group) => JSON.stringify(group));
  }

  const theme = {
    container: 'autosuggest',
    input: 'form-control',
    suggestionsList: `dropdown-menu ${suggestions.length ? 'show' : ''}`,
    suggestion: 'dropdown-item',
    suggestionHighlighted: 'active',
  };

  return (
    <div>
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsClearRequested={() => setSuggestions([])}
        onSuggestionsFetchRequested={({ value }) => {
          setValue(value);
          setSuggestions(getSuggestions(value));
        }}
        onSuggestionSelected={(_, { suggestionValue }) => {
          setValue(JSON.parse(suggestionValue).groupFullName);
          onChange(suggestionValue);
        }}
        getSuggestionValue={(suggestion) => suggestion}
        renderSuggestion={(suggestion) => <span>{JSON.parse(suggestion).groupFullName}</span>}
        inputProps={{
          value: value,
          onChange: (_, { newValue, method }) => {
            setValue(newValue);
            if (triggerOnChangeWhenEmpty === true && newValue === '') {
              onChange(newValue);
            }
          },
          onBlur: () => {
            if (onBlur !== undefined) {
              onBlur();
            }
          },
        }}
        theme={theme}
        highlightFirstSuggestion={true}
      />
    </div>
  );
}

export default GroupAutosuggest;
