import Skeleton from 'react-loading-skeleton';
import authService from '../api-authorization/AuthorizeService';
import 'react-loading-skeleton/dist/skeleton.css';
import { Spinner } from './icons/AnimationSvgs';

export class Common {
  static authorizedFetch = async (
    input: RequestInfo,
    init?: RequestInit,
    redirect: boolean = true
  ): Promise<Response> => {
    const token = await authService.getAccessToken();
    let headers = {
      ...init?.headers,
    };
    if (token) {
      headers = {
        ...headers,
        Authorization: `Bearer ${token}`,
      };
    }
    init = {
      ...init,
      headers: headers,
    };

    const response = await fetch(input, init);
    if (response.redirected && response.url.toLowerCase().includes('account')) {
      // nastąpiło przekierowanie do strony logowania, czyli aktualnie nie ma zalogowanego użytkownia
      authService.updateState(undefined);
      window.location.replace(response.url);
    }

    if (response.redirected && response.url.toLowerCase().includes('access')) {
      window.location.replace(response.url);
    }

    if (redirect && response.status === 302) {
      window.location.replace(response.url);
    }

    if (redirect && response.status === 401) {
      const redirectUrl = `${window.location.origin}/authentication/login`;
      window.location.replace(redirectUrl);
    }

    if (redirect && response.status === 403) {
      const redirectUrl = `${window.location.origin}/account/access-denied`;
      window.location.replace(redirectUrl);
    }

    return response;
  };

  static Ui = class {
    static getSkeletonTableRows = (rows: number, columns: number, rowHeight: number) => {
      let skeletonTable = [];
      for (let ind = 0; ind < rows; ind++) {
        let children = [];
        for (let ind2 = 0; ind2 < columns; ind2++) {
          children.push(
            <td key={ind2}>
              <Skeleton style={{ zIndex: 0 }} height={rowHeight} />
            </td>
          );
        }
        skeletonTable.push(<tr key={ind}>{children}</tr>);
      }
      return skeletonTable;
    };

    static getSkeletonListElements = (rows: number, elementHeight: number) => {
      const skeletonList = [];
      for (let ind = 0; ind < rows; ind++) {
        skeletonList.push(
          <li key={ind} className='list-group-item'>
            <Skeleton height={elementHeight} />
          </li>
        );
      }
      return <ul className='list-group mb-4'>{skeletonList}</ul>;
    };

    static showLoadingSpinnerFixed = (showSpinner: boolean) =>
      showSpinner && (
        <Spinner
          style={{
            position: 'fixed',
            top: '40%',
            left: '50%',
            width: '100px',
            height: '100px',
            marginLeft: '-50px',
            zIndex: 10000,
          }}
        />
      );

    static showLoadingSpinnerAbsolute = (showSpinner: boolean) =>
      showSpinner && (
        <Spinner
          style={{
            position: 'absolute',
            top: '40%',
            left: '50%',
            zIndex: 10000,
            marginLeft: '-50px',
            width: '100px',
            height: '100px',
          }}
        />
      );
  };

  static Utils = class {
    static getComponentTimestampFromSeconds = (seconds: number) => {
      if (seconds < 60) {
        return `${seconds}s`;
      }
      const minutes = Math.floor(seconds / 60);
      if (minutes < 60) {
        return `${minutes}m${seconds % 60}s`;
      }
      const hours = Math.floor(minutes / 60);
      return `${hours}g${minutes % 60}m${seconds % 60}s`;
    };

    static cloneObject = (objectToClone: any) => {
      if ('structuredClone' in window) {
        return structuredClone(objectToClone);
      } else {
        return JSON.parse(JSON.stringify(objectToClone));
      }
    };

    static getNumberWithSpaces = (value: string | number) => {
      const nonBreakableSpace = String.fromCharCode(160);
      var parts = value.toString().split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, nonBreakableSpace);
      return parts.join('.');
    };

    static getCurrencyString = (value: string | number) => {
      const nonBreakableSpace = String.fromCharCode(160);
      if (typeof value === 'number') {
        value = value.toFixed(2);
      }
      var parts = value.toString().split('.');
      if (parts.length === 1) {
        parts.push('00');
      }
      // sprawdzanie czy nie ucęto części jednostek
      if (parts[1].length === 1) {
        parts[1] += '0';
      }
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, nonBreakableSpace);
      var currencyString = parts.join('.') + nonBreakableSpace + 'zł';
      return currencyString;
    };

    static getDatetimeWithoutZoneConverter = (inputDate: string) => {
      if (!inputDate) {
        return '';
      }
      var date = new Date(inputDate);

      const year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();

      const yyyy = year.toString();
      var mm = month.toString();
      var dd = day.toString();

      if (day < 10) {
        dd = '0' + dd.toString();
      }
      if (month < 10) {
        mm = '0' + mm.toString();
      }
      const formattedToday = `${dd}.${mm}.${yyyy}`;
      return formattedToday;
    };

    static getDateWithTimeWithoutZoneConverter = (inputDate?: Date) => {
      if (!inputDate) {
        return '';
      }
      var date = new Date(inputDate);

      const year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();

      var hours = date.getHours();
      var minutes = date.getMinutes();

      const yyyy = year.toString();
      var mm = month.toString();
      var dd = day.toString();
      var h = hours.toString();
      var m = minutes.toString();

      if (day < 10) {
        dd = '0' + dd.toString();
      }
      if (month < 10) {
        mm = '0' + mm.toString();
      }
      if (hours < 10) {
        h = '0' + h.toString();
      }
      if (minutes < 10) {
        m = '0' + m.toString();
      }
      const formattedToday = `${dd}.${mm}.${yyyy} ${h}:${m}`;
      return formattedToday;
    };

    static groupBy = <T, K extends keyof any>(arr: T[], key: (i: T) => K) =>
      arr.reduce((groups, item) => {
        (groups[key(item)] ||= []).push(item);
        return groups;
      }, {} as Record<K, T[]>);

    static camelCaseToTitleCase = (camelCase: string): string => {
      const words = camelCase.replace(/([a-z])([A-Z])/g, '$1 $2').split(' ');
      const titleCaseWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
      return titleCaseWords.join(' ');
    };

    static titleCaseToCamelCase = (titleCase: string): string => {
      const words = titleCase.split(' ');
      const camelCaseWords = words.map((word, index) =>
        index === 0 ? word.toLowerCase() : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      );

      return camelCaseWords.join('');
    };
  };

  static Images = class {
    static toBase64 = (file: File) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });

    static isPictureVertical = (pictureBase64: string): Promise<boolean> =>
      new Promise((resolve, reject) => {
        var img = new Image();
        img.src = pictureBase64;
        img.onload = () => {
          const result = img.width < img.height;
          resolve(result);
        };
        img.onerror = reject;
      });

    static compresUploadedImage = (file: File): Promise<string> =>
      new Promise(async (resolve, reject) => {
        const filetType = file.type;
        const billPictureBase64 = (await this.toBase64(file)) as string;
        const offScreenCanvas = document.createElement('canvas');
        const img = new Image();
        img.src = billPictureBase64;
        img.onload = () => {
          const MAX_WIDTH = 1200;
          const MAX_HEIGHT = 1200;
          var width = img.width ? img.width : MAX_WIDTH;
          var height = img.height ? img.height : MAX_HEIGHT;

          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }
          }
          offScreenCanvas.height = height;
          offScreenCanvas.width = width;
          const offScreenCanvasCtx = offScreenCanvas.getContext('2d');
          if (!!offScreenCanvasCtx) {
            offScreenCanvasCtx.drawImage(img, 0, 0, width, height);
            const compressedPictureBase64 = offScreenCanvas.toDataURL(filetType, 1);
            resolve(compressedPictureBase64);
          } else {
            reject();
          }
        };
        img.onerror = reject;
      });

    static rotatePicture = (pictureBase64: string): Promise<string> =>
      new Promise(async (resolve, reject) => {
        const offScreenCanvas = document.createElement('canvas');
        const offScreenCanvasCtx = offScreenCanvas.getContext('2d');

        var type = 'image/png';
        const arr = pictureBase64.split(';')[0].split('/');
        if (arr.length > 1) {
          type = 'image/' + arr[1];
        }

        const img = new Image();
        img.src = pictureBase64;
        img.onload = () => {
          offScreenCanvas.height = img.width;
          offScreenCanvas.width = img.height;
          if (!!offScreenCanvasCtx) {
            offScreenCanvasCtx.rotate((90 * Math.PI) / 180);
            offScreenCanvasCtx.translate(0, -offScreenCanvas.width);
            offScreenCanvasCtx.drawImage(img, 0, 0);
            const rotatedImageBase64 = offScreenCanvas.toDataURL(type);
            resolve(rotatedImageBase64);
          } else {
            reject();
          }
        };
        img.onerror = reject;
      });
  };
}

export default Common;
