import { Formik } from 'formik';
import { useCallback, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import * as yup from 'yup';

import Common from '../../shared/Common';

const ResendActivationEmail = () => {
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

  const ResendActivationEmailForm = useCallback(() => {
    const validationSchema = yup.object().shape({
      email: yup.string().required('Pole z adresem e-mail jest wymagane').email('Podano nieprawidłowy adres e-mail'),
    });

    return (
      <Row>
        <Col md={4}>
          <Formik
            initialValues={{
              email: '',
            }}
            validationSchema={validationSchema}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true);
              setFormLoading(true);
              const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                  email: values.email,
                }),
              };
              const response = await Common.authorizedFetch('idsrv/account/resendActivationEmail', requestOptions);
              const data = await response.json();
              setFormLoading(false);
              setSubmitting(false);
              if (data.success) {
                setShowConfirmation(true);
              }
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
              <>
                <Form onSubmit={handleSubmit}>
                  <Form.Group>
                    <Form.Label>Adres e-mail</Form.Label>
                    <Form.Control
                      autoComplete='on'
                      type='text'
                      name='email'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      className={touched.email && errors.email ? 'error' : undefined}
                      placeholder='Twój adres e-mail'
                    />
                    {touched.email && errors.email ? <div className='error-message'>{errors.email}</div> : null}
                  </Form.Group>
                  <Button className='mt-2' variant='primary' type='submit' disabled={isSubmitting}>
                    Wyślij ponownie
                  </Button>
                </Form>
              </>
            )}
          </Formik>
        </Col>
      </Row>
    );
  }, []);

  return (
    <>
      {Common.Ui.showLoadingSpinnerFixed(formLoading)}
      {showConfirmation ? (
        <>
          <h1>Ponowne wysłanie e-maila aktywacyjnego</h1>
          <p>E-mail weryfikacyjny wysłany. Proszę sprawdź swoją pocztę.</p>
        </>
      ) : (
        <>
          <h1>Ponowne wysłanie e-maila aktywacyjnego</h1>
          <ResendActivationEmailForm />
        </>
      )}
    </>
  );
};

export default ResendActivationEmail;
