import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import React, { useCallback, useRef, useState, useEffect } from 'react';
import { Button, ButtonGroup, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { Gallery, Item } from 'react-photoswipe-gallery';
import Common from '../../../shared/Common';
import WarningModal from '../../../shared/shared-modals/WarningModal';
import type { IBillPicture } from '../../../../interfaces/IBillPicture';
import {
  SortUpIcon,
  SortDownIcon,
  CheckIcon,
  CircleNotchSpinIcon,
  BanIcon,
  MinusIcon,
  PlusIcon,
  Gallery2Icon,
} from '../../../shared/icons/Svgs';
import type { ColumnDef, SortingState } from '@tanstack/react-table';

import './style.css';
import type { UIElementData } from 'photoswipe';
import type PhotoSwipe from 'photoswipe';
import ReactDOMServer from 'react-dom/server';

export interface ILoadBillCompatibilityTest {
  id: number;
  testName: string;
  result?: ILoadBillCompatibilityTestResult;
  configuration: ILoadBillCompatibilityTestConfiguration;
}

export interface ILoadBillCompatibilityTestResult {
  locationResult: boolean;
  dateResult: boolean;
  namesResult: boolean;
  quantitiesResult: boolean;
  pricesResult: boolean;
  resultInformations: string[];
}

export interface ILoadBillCompatibilityTestConfiguration {
  billPicture?: IBillPicture;
  visionInputText: string;
  expectedLocation: string;
  expectedDate: string;
  expectedBillItems: ILoadBillCompatibilityTestBillItem[];
}

export interface ILoadBillCompatibilityTestBillItem {
  billItemId: number;
  description: string;
  cost: number;
  quantity: number;
  quantityType: string;
}

export interface ITableProps {
  columns: ColumnDef<ILoadBillCompatibilityTest>[];
  data: ILoadBillCompatibilityTest[];
  loading: boolean;
  fetchData: () => Promise<void>;
  fetchResults: () => Promise<void>;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  fetchTrigger: number;
  fetchResultsTrigger: number;
  setFetchTrigger: React.Dispatch<React.SetStateAction<number>>;
  setFetchResultsTrigger: React.Dispatch<React.SetStateAction<number>>;
  updateCompatibilityTest: (config: ILoadBillCompatibilityTest) => void;
  addCompatibilityTest: () => void;
}

export interface IExpectedBillItemsTableProps {
  show: boolean;
  loadBillCompatibilityTest: ILoadBillCompatibilityTest;
  setLoadBillCompatibilityTest: React.Dispatch<React.SetStateAction<ILoadBillCompatibilityTest>>;
  onHide: () => void;
  onSave: () => void;
  editMode: boolean;
}

export interface ILoadBillCompatibilityTestConfigurationModalProps {
  show: boolean;
  loadBillCompatibilityTest: ILoadBillCompatibilityTest;
  setLoadBillCompatibilityTest: React.Dispatch<React.SetStateAction<ILoadBillCompatibilityTest>>;
  onHide: () => void;
  onSave: () => void;
}

export interface IEditableCellProps {
  value: any;
  row: any;
  column: any;
  editMode: boolean;
}

function CompatibilityTestTable(props: ITableProps) {
  const [sorting, setSorting] = React.useState<SortingState>([]);

  const table = useReactTable({
    data: props.data,
    columns: props.columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const { fetchData, fetchResults, fetchTrigger, fetchResultsTrigger, setFetchResultsTrigger } = props;

  useEffect(() => {
    fetchData();
  }, [fetchData, fetchTrigger]);

  useEffect(() => {
    fetchResults();
  }, [fetchResults, fetchResultsTrigger]);

  return (
    <Container>
      <Row>
        <h4>Test poprawności wczytywania paragonu</h4>
      </Row>
      <Row className='mt-2'>
        <ButtonGroup aria-label='Zarządzanie konfiguracją'>
          <Button variant='outline-dark' onClick={() => props.addCompatibilityTest()}>
            Dodaj test
          </Button>
          <Button
            variant='outline-dark'
            onClick={() => {
              setFetchResultsTrigger(fetchResultsTrigger + 1);
            }}
          >
            Odśwież wyniki
          </Button>
        </ButtonGroup>
      </Row>
      <Row className='mt-3 table-responsive-sm'>
        <table id='all-compatibility-test-table' className='table table-sm table-bordered table-striped table-hover'>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          style: header.column.getCanSort() ? { cursor: 'pointer' } : {},
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: <SortUpIcon style={{ marginLeft: '5px' }} />,
                          desc: <SortDownIcon style={{ marginLeft: '5px' }} />,
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {props.loading ? (
              Common.Ui.getSkeletonTableRows(6, 6, 35)
            ) : (
              <>
                <tr>
                  <td>
                    <b>Wynik</b>
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    <b>
                      {((100 * props.data.filter((e) => e.result?.locationResult).length) / props.data.length).toFixed(
                        0
                      )}
                      %
                    </b>
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    <b>
                      {((100 * props.data.filter((e) => e.result?.dateResult).length) / props.data.length).toFixed(0)}%
                    </b>
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    <b>
                      {((100 * props.data.filter((e) => e.result?.namesResult).length) / props.data.length).toFixed(0)}%
                    </b>
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    <b>
                      {(
                        (100 * props.data.filter((e) => e.result?.quantitiesResult).length) /
                        props.data.length
                      ).toFixed(0)}
                      %
                    </b>
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    <b>
                      {((100 * props.data.filter((e) => e.result?.pricesResult).length) / props.data.length).toFixed(0)}
                      %
                    </b>
                  </td>
                </tr>
                {table.getRowModel().rows.map((row) => (
                  <tr
                    key={row.id}
                    onClick={() =>
                      props.updateCompatibilityTest({
                        id: row.original.id,
                        testName: row.original.testName,
                        result: row.original.result,
                        configuration: row.original.configuration,
                      })
                    }
                  >
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                    ))}
                  </tr>
                ))}
              </>
            )}
          </tbody>
        </table>
      </Row>
    </Container>
  );
}

function ExpectedBillItemsTable(props: IExpectedBillItemsTableProps) {
  const { setLoadBillCompatibilityTest } = props;

  const columns = React.useMemo<ColumnDef<ILoadBillCompatibilityTestBillItem>[]>(
    () => [
      {
        id: 'actionButton',
        header: () => (
          <div
            title='Dodaj element paragonu'
            onClick={() => {
              setLoadBillCompatibilityTest((prevState) => ({
                ...prevState,
                configuration: {
                  ...prevState.configuration,
                  expectedBillItems: prevState.configuration.expectedBillItems.concat([
                    {
                      billItemId: 0,
                      quantityType: 'Sztuk',
                      description: 'opis',
                      cost: 0,
                      quantity: 1,
                    },
                  ]),
                },
              }));
            }}
          >
            <PlusIcon style={{ cursor: 'pointer', width: '28px', height: '28px', fill: 'var(--app-main-color)' }} />
          </div>
        ),
        cell: (info) => (
          <div
            title='Usuń element paragonu'
            onClick={() => {
              setLoadBillCompatibilityTest((prevState) => {
                const selectedValues = [info.row.original];
                return {
                  ...prevState,
                  configuration: {
                    ...prevState.configuration,
                    expectedBillItems: prevState.configuration.expectedBillItems.filter(
                      (item) => !selectedValues.includes(item)
                    ),
                  },
                };
              });
            }}
          >
            <MinusIcon style={{ cursor: 'pointer', width: '28px', height: '28px', fill: '#dc3545' }} />
          </div>
        ),
      },
      {
        header: () => <span style={{ userSelect: 'none' }}>Opis</span>,
        id: 'description',
        accessorFn: (row) => row.description,
      },
      {
        header: () => <span style={{ userSelect: 'none' }}>Koszt</span>,
        id: 'cost',
        accessorFn: (row) => row.cost,
      },
      {
        header: () => <span style={{ userSelect: 'none' }}>Ile</span>,
        id: 'quantity',
        accessorFn: (row) => row.quantity,
      },
      {
        header: () => <span style={{ userSelect: 'none' }}>Jednostka</span>,
        id: 'quantityType',
        accessorFn: (row) => row.quantityType,
      },
    ],
    [setLoadBillCompatibilityTest]
  );

  const defaultColumn: Partial<ColumnDef<ILoadBillCompatibilityTestBillItem>> = {
    cell: function Cell({ getValue, row: { index }, column: { id }, table }) {
      const initialValue = getValue();
      const [value, setValue] = React.useState(initialValue);

      const onBlur = () => {
        table.options.meta?.updateData?.(index, id, value);
      };

      React.useEffect(() => {
        setValue(initialValue);
      }, [initialValue]);

      if (table.options.meta?.editMode) {
        return (
          <Form.Control
            size='sm'
            type='text'
            onChange={(e) => setValue(e.target.value)}
            onBlur={onBlur}
            value={value as string}
          />
        );
      } else {
        return <>{value}</>;
      }
    },
  };

  var items = props.loadBillCompatibilityTest?.configuration?.expectedBillItems ?? [];

  const table = useReactTable({
    data: items,
    columns,
    state: {
      columnVisibility: {
        actionButton: props.editMode,
      },
    },
    defaultColumn,
    getCoreRowModel: getCoreRowModel(),
    meta: {
      updateData: (rowIndex, columnId, value) => {
        props.setLoadBillCompatibilityTest((prevState) => {
          const updatedValue = prevState.configuration.expectedBillItems;
          if (updatedValue !== undefined) {
            updatedValue[rowIndex] = {
              ...prevState.configuration.expectedBillItems[rowIndex],
              [columnId]: value,
            };
          }

          return {
            ...prevState,
            value: updatedValue,
          };
        });
      },
      editMode: props.editMode,
    },
  });

  return (
    <div className='table-responsive-sm'>
      <table
        id={'edited-load-bill-compatibility-table'}
        className='table table-striped table-sm mt-2'
        aria-labelledby='tabelLabel'
      >
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder ? null : (
                    <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

function LoadBillCompatibilityTestConfigurationModal(props: ILoadBillCompatibilityTestConfigurationModalProps) {
  type BillImageSize = { width: number; height: number };

  const [loading, setLoading] = useState(false);
  const [visionTextloading, setVisionTextloading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [showVisionTextModal, setShowVisionTextModal] = useState(false);
  const [picturesSize, setPictureSize] = useState<BillImageSize | undefined>(undefined);

  const inputRef = useRef<HTMLInputElement>(null);
  const fetchIdRef = React.useRef(0);

  useEffect(() => {
    if (props.loadBillCompatibilityTest.configuration.billPicture?.pictureBase64String) {
      const img = new Image();
      img.onload = function () {
        const size: BillImageSize = {
          width: img.width,
          height: img.height,
        };
        setPictureSize(size);
      };
      img.src = props.loadBillCompatibilityTest.configuration.billPicture?.pictureBase64String;
    }
  }, [props.loadBillCompatibilityTest.configuration.billPicture?.pictureBase64String]);

  const handleUploadBillImage = async (event: any) => {
    if (!!event.target.files[0]) {
      const billPictureBase64 = (await Common.Images.toBase64(event.target.files[0])) as string;
      if (props.loadBillCompatibilityTest.configuration?.billPicture?.pictureBase64String !== billPictureBase64) {
        props.setLoadBillCompatibilityTest((prevState) => ({
          ...prevState,
          configuration: {
            ...prevState.configuration,
            billPicture: {
              billPictureId: 0,
              pictureBase64String: billPictureBase64,
              pictureMd5Hash: '',
            },
          },
        }));
      }
      event.target.value = null;
    }
  };

  const handleUpload = () => {
    inputRef.current?.click();
  };

  const handleVisionTextModalClose = () => setShowVisionTextModal(false);
  const handleVisionTextModalShow = () => setShowVisionTextModal(true);

  const saveTest = async () => {
    setLoading(true);
    const method =
      props.loadBillCompatibilityTest.id === 0 ? 'addLoadBillCompatibilityTest' : 'saveLoadBillCompatibilityTest';
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ test: props.loadBillCompatibilityTest }),
    };
    const response = await Common.authorizedFetch('api/applicationLogicConfiguration/' + method, requestOptions);
    const data = await response.json();
    if (!data.success) {
      alert(data.errors);
    }
    setLoading(false);
    props.onSave();
    props.onHide();
  };

  const removeTest = async () => {
    setLoading(true);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        testId: props.loadBillCompatibilityTest.id,
      }),
    };
    const response = await Common.authorizedFetch(
      'api/applicationLogicConfiguration/removeLoadBillCompatibilityTest',
      requestOptions
    );
    const data = await response.json();
    if (!data.success) {
      alert(data.errors);
    }
    setShowWarningModal(false);
    setLoading(false);
    props.onHide();
    props.onSave();
  };

  const getTextFromVision = async () => {
    setVisionTextloading(true);
    const fetchId = ++fetchIdRef.current;
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        pictureBase64: props.loadBillCompatibilityTest.configuration.billPicture?.pictureBase64String,
      }),
    };
    const response = await Common.authorizedFetch(
      'api/applicationLogicConfiguration/getTextFromPicture',
      requestOptions
    );
    const data = await response.json();
    if (data.success) {
      if (fetchId === fetchIdRef.current) {
        props.setLoadBillCompatibilityTest((prevState) => ({
          ...prevState,
          configuration: {
            ...prevState.configuration,
            visionInputText: data.result.text.join('\r\n'),
          },
        }));
      }
    }
    setVisionTextloading(false);
  };

  const gallerryUiElements: UIElementData[] = [
    {
      name: 'remove-button',
      ariaLabel: 'Remove button',
      title: 'Usuń',
      order: 9,
      isButton: true,
      html: ReactDOMServer.renderToStaticMarkup(
        <MinusIcon style={{ cursor: 'pointer', width: '28x', height: '28px', fill: '#dc3545' }} />
      ),
      appendTo: 'bar',
      onClick: (e, el, pswpInstance: PhotoSwipe) => {
        props.setLoadBillCompatibilityTest((prevState) => ({
          ...prevState,
          configuration: {
            ...prevState.configuration,
            billPicture: undefined,
          },
        }));
        pswpInstance.close();
      },
    },
  ];
  return (
    <>
      <Modal show={props.show} onHide={props.onHide} size='lg' aria-labelledby='contained-modal-title-vcenter'>
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            {props.loadBillCompatibilityTest.id !== 0 ? <>Konfiguruj test</> : <>Dodaj test</>}{' '}
            {props.loadBillCompatibilityTest.testName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {Common.Ui.showLoadingSpinnerAbsolute(loading)}
          <Row>
            <Col sm={3}>Nazwa testu</Col>
            <Col>Lokalizacja</Col>
            <Col>Data</Col>
            <Col>Zawartość</Col>
            <Col />
          </Row>
          <Row>
            <Col sm={3}>
              <Form.Control
                size='sm'
                type='text'
                value={props.loadBillCompatibilityTest.testName}
                onChange={(e) => {
                  props.setLoadBillCompatibilityTest((prevState) => ({
                    ...prevState,
                    testName: e.target.value,
                  }));
                }}
              />
            </Col>
            <Col>
              <Form.Control
                size='sm'
                type='text'
                value={props.loadBillCompatibilityTest.configuration.expectedLocation}
                onChange={(e) => {
                  props.setLoadBillCompatibilityTest((prevState) => ({
                    ...prevState,
                    configuration: {
                      ...prevState.configuration,
                      expectedLocation: e.target.value,
                    },
                  }));
                }}
              />
            </Col>
            <Col>
              <Form.Control
                size='sm'
                type='text'
                value={props.loadBillCompatibilityTest.configuration.expectedDate}
                onChange={(e) => {
                  props.setLoadBillCompatibilityTest((prevState) => ({
                    ...prevState,
                    configuration: {
                      ...prevState.configuration,
                      expectedDate: e.target.value,
                    },
                  }));
                }}
              />
            </Col>
            <Col>
              <Button size='sm' onClick={handleVisionTextModalShow}>
                Pokaż zawartość
              </Button>
            </Col>
            <Col className='load-bill-test-right-alignment'>
              <Form.Check
                type='switch'
                id='load-bill-test-edit-switch'
                label='Tryb edycji'
                style={{ userSelect: 'none' }}
                defaultChecked={editMode}
                onChange={() => {
                  setEditMode(!editMode);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <ExpectedBillItemsTable
                show={props.show}
                loadBillCompatibilityTest={props.loadBillCompatibilityTest}
                setLoadBillCompatibilityTest={props.setLoadBillCompatibilityTest}
                onHide={() => {
                  props.onHide();
                }}
                onSave={() => {
                  props.onSave();
                }}
                editMode={editMode}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <div className='me-auto'>
            {!!props.loadBillCompatibilityTest.configuration.billPicture && (
              <Gallery options={{ zoom: false }} uiElements={gallerryUiElements}>
                <Item
                  original={props.loadBillCompatibilityTest.configuration.billPicture.pictureBase64String}
                  width={picturesSize?.width ?? 600}
                  height={picturesSize?.height ?? 600}
                >
                  {({ ref, open }) => (
                    <Button ref={ref} variant='dark' onClick={open}>
                      <Gallery2Icon
                        style={{
                          fill: 'white',
                          width: '16',
                          height: '16',
                        }}
                      />
                    </Button>
                  )}
                </Item>
              </Gallery>
            )}
            <input
              ref={inputRef}
              onChange={handleUploadBillImage}
              className='d-none'
              type='file'
              accept='image/*'
            />
            <Button variant='dark' className='ms-2' onClick={handleUpload}>
              Wgraj zdjęcie
            </Button>
          </div>
          <Button variant='dark' onClick={props.onHide}>
            Zamknij
          </Button>
          <Button
            variant='danger'
            disabled={loading || !(props.loadBillCompatibilityTest?.id > 0)}
            onClick={() => setShowWarningModal(true)}
          >
            Usuń
          </Button>
          <Button variant='success' disabled={loading} onClick={saveTest}>
            Zapisz
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showVisionTextModal} onHide={handleVisionTextModalClose}>
        <Modal.Body>
          {Common.Ui.showLoadingSpinnerAbsolute(visionTextloading)}
          <Form>
            <Form.Group className='mt-3' controlId='exampleForm.ControlTextarea1'>
              <Form.Label>Zawartość tekstowa paragonu</Form.Label>
              <Form.Control
                as='textarea'
                rows={10}
                value={props.loadBillCompatibilityTest.configuration.visionInputText}
                onChange={(e) => {
                  props.setLoadBillCompatibilityTest((prevState) => ({
                    ...prevState,
                    configuration: {
                      ...prevState.configuration,
                      visionInputText: e.target.value,
                    },
                  }));
                }}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <div className='me-auto'>
            {!!props.loadBillCompatibilityTest.configuration.billPicture && (
              <Button variant='dark' className='ms-2' onClick={getTextFromVision}>
                Pobierz tekst ze zdjęcia
              </Button>
            )}
          </div>
          <Button variant='dark' onClick={handleVisionTextModalClose}>
            Zamknij
          </Button>
        </Modal.Footer>
      </Modal>
      <WarningModal
        show={showWarningModal}
        modalBodyText={'Czy na pewno chcesz usunąć ten test?'}
        onHide={() => {
          setShowWarningModal(false);
        }}
        onConfirmation={() => removeTest()}
      />
    </>
  );
}

const LoadBillConfigurationCompatibility = () => {
  const [compatibilityTests, setCompatibilityTests] = useState<ILoadBillCompatibilityTest[]>([]);
  const [testIds, setTestIds] = useState<number[]>([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [loadBillCompatibilityTest, setLoadBillCompatibilityTest] = useState<ILoadBillCompatibilityTest>({
    id: 0,
    testName: '',
    result: {
      locationResult: false,
      dateResult: false,
      namesResult: false,
      quantitiesResult: false,
      pricesResult: false,
      resultInformations: [],
    },
    configuration: {
      billPicture: undefined,
      visionInputText: '',
      expectedLocation: '',
      expectedDate: '',
      expectedBillItems: [],
    },
  });
  const [fetchTrigger, setFetchTrigger] = useState(0);
  const [fetchResultsTrigger, setFetchResultsTrigger] = useState(0);
  const fetchIdRef = React.useRef(0);

  const updateCompatibilityTest = (loadBillCompatibilityTest: ILoadBillCompatibilityTest) => {
    setLoadBillCompatibilityTest(loadBillCompatibilityTest);
    setShowModal(true);
  };

  const addCompatibilityTest = () => {
    setLoadBillCompatibilityTest({
      id: 0,
      testName: '',
      result: {
        locationResult: false,
        dateResult: false,
        namesResult: false,
        quantitiesResult: false,
        pricesResult: false,
        resultInformations: [],
      },
      configuration: {
        billPicture: undefined,
        visionInputText: '',
        expectedLocation: '',
        expectedDate: '',
        expectedBillItems: [],
      },
    });
    setShowModal(true);
  };

  const getTestResultIcon = (result: boolean | undefined) => {
    switch (result) {
      case true:
        return <CheckIcon style={{ color: 'green' }} />;
      case false:
        return <BanIcon style={{ color: 'red', height: '1em', width: '1em' }} />;
      case undefined:
        return <CircleNotchSpinIcon style={{ color: '#007bff' }} />;
    }
  };

  const fetchTestResults = useCallback(async () => {
    setCompatibilityTests((prevState) => {
      prevState.forEach((elem) => {
        elem.result = undefined;
      });
      const newArray = Object.assign([], prevState);
      return newArray;
    });
    const promises: any[] = [];
    const getResult = async (id: number) => {
      const response = await Common.authorizedFetch(
        'api/applicationLogicConfiguration/getLoadBillCompatibilityResult?testId=' + id
      );
      const data = await response.json();
      if (data.success) {
        if (data.result.resultInformations.length > 0) {
          data.result.resultInformations.forEach((information: string) => {
            console.log(information);
          });
        }
        setCompatibilityTests((prevState) => {
          const index = prevState.findIndex((value: ILoadBillCompatibilityTest) => value.id === id);

          if (index > -1) {
            let elem = prevState[index];
            elem.result = data.result;
            const newArray = Object.assign([], prevState, {
              [index]: elem,
            });
            return newArray;
          } else {
            return prevState;
          }
        });
      }
    };

    testIds.forEach((id: number) => {
      promises.push(getResult(id));
    });
    await Promise.all(promises);
  }, [testIds]);

  const fetchData = useCallback(async () => {
    setLoading(true);
    const fetchId = ++fetchIdRef.current;
    const response = await Common.authorizedFetch('api/applicationLogicConfiguration/getLoadBillCompatibilityTests');
    const data = await response.json();
    if (data.success) {
      if (fetchId === fetchIdRef.current) {
        setCompatibilityTests(data.result.tests);
        setTestIds(data.result.tests.map((t: { id: number }) => t.id));
      }
    }
    setLoading(false);
  }, []);

  const columns = React.useMemo<ColumnDef<ILoadBillCompatibilityTest>[]>(
    () => [
      {
        accessorFn: (row) => row.testName,
        id: 'testName',
        header: () => <span>Nazwa testu</span>,
      },
      {
        accessorFn: (row) => row.result?.locationResult,
        header: () => <div style={{ textAlign: 'center' }}>Lokalizacja</div>,
        cell: (info) => (
          <div style={{ textAlign: 'center' }}>{getTestResultIcon(info.row.original.result?.locationResult)}</div>
        ),
        id: 'locationResult',
      },
      {
        accessorFn: (row) => row.result?.dateResult,
        header: () => <div style={{ textAlign: 'center' }}>Data</div>,
        cell: (info) => (
          <div style={{ textAlign: 'center' }}>{getTestResultIcon(info.row.original.result?.dateResult)}</div>
        ),
        id: 'dateResult',
      },
      {
        accessorFn: (row) => row.result?.namesResult,
        header: () => <div style={{ textAlign: 'center' }}>Nazwy</div>,
        cell: (info) => (
          <div style={{ textAlign: 'center' }}>{getTestResultIcon(info.row.original.result?.namesResult)}</div>
        ),
        id: 'namesResult',
      },
      {
        accessorFn: (row) => row.result?.quantitiesResult,
        header: () => <div style={{ textAlign: 'center' }}>Ilości</div>,
        cell: (info) => (
          <div style={{ textAlign: 'center' }}>{getTestResultIcon(info.row.original.result?.quantitiesResult)}</div>
        ),
        id: 'quantitiesResult',
      },
      {
        accessorFn: (row) => row.result?.pricesResult,
        header: () => <div style={{ textAlign: 'center' }}>Cena</div>,
        cell: (info) => (
          <div style={{ textAlign: 'center' }}>{getTestResultIcon(info.row.original.result?.pricesResult)}</div>
        ),
        id: 'pricesResult',
      },
    ],
    []
  );

  return (
    <Container className='mt-2'>
      <CompatibilityTestTable
        columns={columns}
        data={compatibilityTests}
        loading={loading}
        fetchData={fetchData}
        fetchResults={fetchTestResults}
        setShowModal={setShowModal}
        fetchTrigger={fetchTrigger}
        setFetchTrigger={setFetchTrigger}
        fetchResultsTrigger={fetchResultsTrigger}
        setFetchResultsTrigger={setFetchResultsTrigger}
        updateCompatibilityTest={updateCompatibilityTest}
        addCompatibilityTest={addCompatibilityTest}
      />
      <LoadBillCompatibilityTestConfigurationModal
        show={showModal}
        loadBillCompatibilityTest={loadBillCompatibilityTest}
        setLoadBillCompatibilityTest={setLoadBillCompatibilityTest}
        onHide={() => {
          setShowModal(false);
          setLoadBillCompatibilityTest({
            id: 0,
            testName: '',
            result: {
              locationResult: false,
              dateResult: false,
              namesResult: false,
              quantitiesResult: false,
              pricesResult: false,
              resultInformations: [],
            },
            configuration: {
              billPicture: undefined,
              visionInputText: '',
              expectedLocation: '',
              expectedDate: '',
              expectedBillItems: [],
            },
          });
        }}
        onSave={() => {
          setFetchTrigger(fetchTrigger + 1);
        }}
      />
    </Container>
  );
};

export default LoadBillConfigurationCompatibility;
