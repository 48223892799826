import React from 'react';
import { Button, Modal } from 'react-bootstrap';

export interface IConfirmationModal {
  show: boolean;
  onHide: () => void;
  onConfirmation: () => void;
  modalBodyText: string;
}

function WarningModal(props: IConfirmationModal) {
  return (
    <Modal
      className='delete-modal'
      show={props.show}
      onHide={props.onHide}
      aria-labelledby='contained-modal-title-vcenter'
    >
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>Uwaga</Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.modalBodyText}</Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={props.onHide}>
          Nie
        </Button>
        <Button variant='danger' onClick={props.onConfirmation}>
          Tak
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default WarningModal;
