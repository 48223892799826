import { Formik } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Link, Navigate, useLocation } from 'react-router-dom';
import * as yup from 'yup';
import Common from '../../shared/Common';
import AlertMessages from '../../shared/application-alert/AlertMessages';
import ApplicationAlert from '../../shared/application-alert/ApplicationAlert';
import ExternalLogins from '../../shared/external-logins/ExternalLogins';
import type { IApplicationAlert } from '../../shared/application-alert/ApplicationAlert';
import './style.css';

const Login = () => {
  const [status, setStatus] = useState<IApplicationAlert>();
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [redirect, setRedirect] = useState<boolean>(false);
  const [redirectToLockout, setRedirectToLockout] = useState<boolean>(false);
  const [returnUrl, setReturnUrl] = useState<string>('');
  const [urlToRedirect, setUrlToRedirect] = useState<string>('');
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [show2FaLogin, setShow2FaLogin] = useState<boolean>(false);
  const [loginWith2Fa, setLoginWith2Fa] = useState<boolean>(false);
  const [loginWithRecoveryCode, setLoginWithRecoveryCode] = useState<boolean>(false);
  const location = useLocation();

  const identityServerUrl = import.meta.env.VITE_IDENTITY_SERVER_URL ?? '';

  useEffect(() => {
    const search = location.search;
    const params = new URLSearchParams(search);
    const resultInfo = params.get('result');
    const returnUrl = params.get('ReturnUrl');
    const urlToRedirect = params.get('urlToRedirect');
    if (!!returnUrl) {
      setReturnUrl(returnUrl);
    }
    if (!!urlToRedirect) {
      if (urlToRedirect.includes('http')) {
        const url = new URL(urlToRedirect);
        setUrlToRedirect(url.pathname + url.search);
      } else {
        setUrlToRedirect(urlToRedirect);
      }
    }
    if (!!resultInfo) {
      // udane logowanie
      if (resultInfo === 'ExternalProviderSignInSuccess') {
        setRedirect(true);
      } else {
        const info = AlertMessages.getMessageByCode(resultInfo);
        setStatus({
          variant: info.variant,
          status: info.message,
          messageTime: Date.now(),
        });
      }
    }
  }, [location.search]);

  const clearExternalAuthentication = useCallback(async () => {
    const requestOptions = {
      method: 'POST',
    };
    await fetch('idsrv/account/clearExternalAuthentication', requestOptions);
  }, []);

  useEffect(() => {
    clearExternalAuthentication();
  }, [clearExternalAuthentication]);

  useEffect(() => {
    if (show2FaLogin) {
      setLoginWith2Fa(true);
    }
  }, [show2FaLogin]);

  const Footer = () => (
    <Row className='login-footer overflow-hidden'>
      <ul>
        <li>
          <a href='/privacy'>Prywatność</a>
          <span>
            <span>&nbsp;</span>
            <span aria-hidden='true'> · </span>
          </span>
        </li>
        <li>
          <a href='/privacy#cookies'>Pliki cookie</a>
          <span>
            <span>&nbsp;</span>
            <span aria-hidden='true'> · </span>
            <span>&nbsp;</span>
          </span>
        </li>
      </ul>
      <a className='p-0' target='_blank' rel='noopener noreferrer' href='https://maciejos.pl'>
        Maciej Jakubiak © {new Date().getFullYear()}
      </a>
    </Row>
  );

  const LoginForm = useCallback(() => {
    enum SignInStatus {
      Succeeded = 0,
      RequiresTwoFactor = 1,
      LockedOut = 2,
      WrongPassword = 3,
    }

    const validationSchema = yup.object().shape({
      userName: yup.string().required('Pole z adresem e-mail jest wymagane'),
      password: yup.string().required('Pole z hasłem jest wymagane'),
    });

    return (
      <Formik
        initialValues={{
          userName: '',
          password: '',
          rememberMe: false,
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          setFormLoading(true);
          const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              username: values.userName,
              password: values.password,
              rememberMe: values.rememberMe,
            }),
          };
          const response = await Common.authorizedFetch('idsrv/account/signIn', requestOptions);
          const data = await response.json();
          setRememberMe(values.rememberMe);
          setFormLoading(false);
          if (!data.success) {
            setStatus({
              variant: 'error',
              status: data.errors,
              messageTime: Date.now(),
            });
            return;
          } else {
            const signInStatus = data.result?.status;
            switch (signInStatus) {
              case SignInStatus.Succeeded:
                window.location.replace(`${identityServerUrl}${returnUrl}`);
                return;
              case SignInStatus.RequiresTwoFactor:
                setShow2FaLogin(true);
                return;
              case SignInStatus.LockedOut:
                setRedirectToLockout(true);
                return;
              case SignInStatus.WrongPassword:
                setStatus({
                  variant: 'error',
                  status: 'Wpisano nieprawidłowy login lub hasło',
                  messageTime: Date.now(),
                });
                return;
              default:
                break;
            }
          }
          setSubmitting(false);
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
          <>
            <Form onSubmit={handleSubmit}>
              <Form.Group>
                <Form.Label>E-mail</Form.Label>
                <Form.Control
                  autoComplete='on'
                  type='text'
                  name='userName'
                  placeholder='Twój adres e-mail'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.userName}
                  className={touched.userName && errors.userName ? 'error' : undefined}
                />
                {touched.userName && errors.userName ? <div className='error-message'>{errors.userName}</div> : null}
              </Form.Group>
              <Form.Group className='mt-2'>
                <Form.Label>Hasło</Form.Label>
                <Form.Control
                  autoComplete='on'
                  type='password'
                  name='password'
                  placeholder='Twoje hasło'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  className={touched.password && errors.password ? 'error' : undefined}
                />
                {touched.password && errors.password ? <div className='error-message'>{errors.password}</div> : null}
              </Form.Group>
              <Form.Group className='mt-2'>
                <Form.Check
                  type='checkbox'
                  name='rememberMe'
                  label='Zapamiętaj mnie'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  checked={values.rememberMe}
                />
              </Form.Group>
              <Form.Group className='mt-3'>
                <Button variant='primary' type='submit' disabled={isSubmitting}>
                  Zaloguj
                </Button>
              </Form.Group>
              <Form.Group className='mt-3'>
                <Link to={'/account/register' + window.location.search}>Stwórz nowe konto</Link>
              </Form.Group>
              <Form.Group className='mt-1'>
                <Link to='/account/reset-password'>Przypomnienie hasła</Link>
              </Form.Group>
            </Form>
          </>
        )}
      </Formik>
    );
  }, [returnUrl, identityServerUrl]);

  const LoginWith2FaForm = useCallback(() => {
    enum SignInWith2FaStatus {
      Succeeded = 0,
      LockedOut = 1,
      WrongCode = 2,
    }

    const validationSchema = yup.object().shape({
      code: yup
        .string()
        .required('Pole z kodem jest wymagane')
        .min(6, 'Kod powinien mieć minimum 6 znaków')
        .max(7, 'Kod powinien mieć maksimum 7 znaków'),
    });

    return (
      <Formik
        initialValues={{
          code: '',
          rememberMachine: false,
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          setFormLoading(true);
          const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              authenticatorCode: values.code,
              rememberMe: rememberMe,
              rememberMachine: values.rememberMachine,
            }),
          };
          const response = await Common.authorizedFetch('idsrv/account/signInWith2fa', requestOptions);
          const data = await response.json();
          setFormLoading(false);
          if (!data.success) {
            setStatus({
              variant: 'error',
              status: data.errors,
              messageTime: Date.now(),
            });
            return;
          } else {
            const signInWith2FaStatus = data.result?.status;
            switch (signInWith2FaStatus) {
              case SignInWith2FaStatus.Succeeded:
                window.location.replace(`${identityServerUrl}${returnUrl}`);
                return;
              case SignInWith2FaStatus.LockedOut:
                setRedirectToLockout(true);
                return;
              case SignInWith2FaStatus.WrongCode:
                setStatus({
                  variant: 'error',
                  status: 'Wpisano nieprawidłowy kod uwierzytelnienia',
                  messageTime: Date.now(),
                });
                return;
              default:
                break;
            }
          }
          setSubmitting(false);
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
          <>
            <Form onSubmit={handleSubmit}>
              <Form.Group>
                <Form.Label>Kod do uwierzytelnienia</Form.Label>
                <Form.Control
                  autoComplete='off'
                  type='text'
                  name='code'
                  placeholder='Wpisz kod z aplikacji'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.code}
                  className={touched.code && errors.code ? 'error' : undefined}
                />
                {touched.code && errors.code ? <div className='error-message'>{errors.code}</div> : null}
              </Form.Group>
              <Form.Group className='mt-3'>
                <Form.Check
                  type='checkbox'
                  name='rememberMachine'
                  label='Zapamiętaj te urządzenie'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  checked={values.rememberMachine}
                />
              </Form.Group>
              <Form.Group className='mt-3'>
                <Button variant='primary' type='submit' disabled={isSubmitting}>
                  Zaloguj
                </Button>
              </Form.Group>
            </Form>
          </>
        )}
      </Formik>
    );
  }, [returnUrl, rememberMe, identityServerUrl]);

  const LoginWithRecoveryCodeForm = useCallback(() => {
    enum SignInWithRecoveryCodeStatus {
      Succeeded = 0,
      LockedOut = 1,
      WrongCode = 2,
    }

    const validationSchema = yup.object().shape({
      code: yup.string().required('Pole z kodem jest wymagane'),
    });

    return (
      <Formik
        initialValues={{
          code: '',
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          setFormLoading(true);
          const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              recoveryCode: values.code,
            }),
          };
          const response = await Common.authorizedFetch('idsrv/account/signInWithRecoveryCode', requestOptions);
          const data = await response.json();
          setFormLoading(false);
          if (!data.success) {
            setStatus({
              variant: 'error',
              status: data.errors,
              messageTime: Date.now(),
            });
            return;
          } else {
            const signInWith2FaStatus = data.result?.status;
            switch (signInWith2FaStatus) {
              case SignInWithRecoveryCodeStatus.Succeeded:
                window.location.replace(`${identityServerUrl}${returnUrl}`);
                return;
              case SignInWithRecoveryCodeStatus.LockedOut:
                setRedirectToLockout(true);
                return;
              case SignInWithRecoveryCodeStatus.WrongCode:
                setStatus({
                  variant: 'error',
                  status: 'Wpisano nieprawidłowy kod odzyskania',
                  messageTime: Date.now(),
                });
                return;
              default:
                break;
            }
          }
          setSubmitting(false);
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
          <>
            <Form onSubmit={handleSubmit}>
              <Form.Group>
                <Form.Label>Kod odzyskania</Form.Label>
                <Form.Control
                  autoComplete='off'
                  type='text'
                  name='code'
                  placeholder='Wpisz niewykorzystany kod'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.code}
                  className={touched.code && errors.code ? 'error' : undefined}
                />
                {touched.code && errors.code ? <div className='error-message'>{errors.code}</div> : null}
              </Form.Group>
              <Form.Group className='mt-3'>
                <Button variant='primary' type='submit' disabled={isSubmitting}>
                  Zaloguj
                </Button>
              </Form.Group>
            </Form>
          </>
        )}
      </Formik>
    );
  }, [returnUrl, identityServerUrl]);

  const LoginPanel = () => (
    <Row>
      <Col md={6} className='mt-3 order-lg-2'>
        <h4>Zaloguj się przez aplikację</h4>
        <hr />
        <ExternalLogins
          returnUrl={returnUrl}
          identityServerUrl={identityServerUrl}
          actionType='signIn'
          loginProviders={[
            { name: 'Facebook', displayName: 'Facebook' },
            { name: 'Google', displayName: 'Google' },
          ]}
        />
      </Col>
      <Col md={4} className='mt-3 order-lg-1'>
        <h4>Zaloguj się</h4>
        <hr />
        <LoginForm />
        <Footer />
      </Col>
    </Row>
  );

  const LoginWith2FaPanel = () => (
    <>
      <p>
        Twój login jest zabezpieczony przez aplikację do uwierzytelnienia dwuskładnikowego (2FA). Wprowadź swój kod do
        uwierzytelnienia poniżej.
      </p>
      <Row>
        <Col md={4}>
          <LoginWith2FaForm />
        </Col>
      </Row>
      <p>
        Nie masz dostępu do swojego urządzenia z aplikacją do uwierzytelnienia? Możesz
        <button className='btn btn-link' onClick={() => setLoginWithRecoveryCode(true)}>
          zalogować się za pomocą kodu odzyskiwania.
        </button>
      </p>
    </>
  );

  const LoginWithRecoveryCodePanel = () => (
    <>
      <p>
        Próbujesz zalogować się za pomocą kodu odzyskiwania. Po zalogowaniu nie zostaniesz zapamiętany, dopóki nie
        wprowadzisz kodu uwierzytelnienia z aplikacji do uwierzytelnienia dwuskładnikowego albo wyłączysz
        uwierzytelnienie dwuskładnikowe i zalogujesz się ponownie.
      </p>
      <Row>
        <Col md={4}>
          <LoginWithRecoveryCodeForm />
        </Col>
      </Row>
    </>
  );

  return (
    <>
      {redirect && <Navigate to={urlToRedirect} replace />}
      {redirectToLockout && <Navigate to='lockout' replace />}
      {Common.Ui.showLoadingSpinnerFixed(formLoading)}
      <h1>Logowanie</h1>
      {status && (
        <ApplicationAlert variant={status?.variant} status={status?.status} messageTime={status?.messageTime} />
      )}
      {loginWith2Fa ? loginWithRecoveryCode ? <LoginWithRecoveryCodePanel /> : <LoginWith2FaPanel /> : <LoginPanel />}
    </>
  );
};

export default Login;
