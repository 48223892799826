import { Formik } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import * as yup from 'yup';

import Common from '../../shared/Common';

const SetPassword = () => {
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [code, setCode] = useState<string>('');

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const codeParam = params.get('code');
    const emailParam = params.get('email');
    if (!!codeParam) {
      setCode(codeParam);
    }
    if (!!emailParam) {
      setEmail(emailParam);
    }
  }, []);

  const SetPasswordForm = useCallback(() => {
    const validationSchema = yup.object().shape({
      email: yup.string(),
      password: yup
        .string()
        .required('Nie wprowadzono hasła')
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
          'Musi zawierać minimum jedną wielką literę, jedną małą literę i jedną cyfrę'
        )
        .min(8, 'Hasło jest za krótkie - hasło powinno zawierać minimum 8 znaków'),
      confirmedPassword: yup.string().test('passwords-match', 'Hasła muszą być takie same', function (value) {
        return this.parent.password === value;
      }),
    });

    return (
      <Row>
        <Col md={6}>
          <Formik
            initialValues={{
              password: '',
              confirmedPassword: '',
              email: email ?? '',
            }}
            validationSchema={validationSchema}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true);
              setFormLoading(true);
              const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                  email: values.email,
                  password: values.password,
                  code: code,
                }),
              };
              const response = await Common.authorizedFetch('idsrv/account/setPassword', requestOptions);
              const data = await response.json();
              setFormLoading(false);
              setSubmitting(false);
              if (data.success) {
                setShowConfirmation(true);
              }
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
              <>
                <Form onSubmit={handleSubmit}>
                  <Form.Group>
                    <Form.Label>E-mail</Form.Label>
                    <Form.Control
                      type='text'
                      name='email'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      disabled
                    />
                  </Form.Group>
                  <Form.Group className='mt-2'>
                    <Form.Label>Nowe hasło</Form.Label>
                    <Form.Control
                      autoComplete='off'
                      type='password'
                      name='password'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      className={touched.password && errors.password ? 'error' : undefined}
                      placeholder='Wpisz swoje nowe hasło'
                    />
                    {touched.password && errors.password ? (
                      <div className='error-large-message'>{errors.password}</div>
                    ) : null}
                  </Form.Group>
                  <Form.Group className='mt-2'>
                    <Form.Label>Potwierdź hasło</Form.Label>
                    <Form.Control
                      autoComplete='off'
                      type='password'
                      name='confirmedPassword'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.confirmedPassword}
                      className={touched.confirmedPassword && errors.confirmedPassword ? 'error' : undefined}
                      placeholder='Wpisz nowe hasło ponownie'
                    />
                    {touched.confirmedPassword && errors.confirmedPassword ? (
                      <div className='error-message'>{errors.confirmedPassword}</div>
                    ) : null}
                  </Form.Group>
                  <Button className='mt-3' variant='primary' type='submit' disabled={isSubmitting}>
                    Zatwierdź
                  </Button>
                </Form>
              </>
            )}
          </Formik>
        </Col>
      </Row>
    );
  }, [email, code]);

  return (
    <>
      {Common.Ui.showLoadingSpinnerFixed(formLoading)}
      {showConfirmation ? (
        <>
          <h1>Twoje hasło zostało zmienione</h1>
          <p>
            Twoje hasło zostało zmienione. Kliknij <Link to='/'>tutaj</Link>, aby się zalogować.
          </p>
        </>
      ) : (
        <>
          <h1>Zapomniane hasło</h1>
          <h4 id='external-login-title'>Wprowadź odpowiednie dane w celu zresetowania aktualnego hasła</h4>
          <hr />
          <SetPasswordForm />
        </>
      )}
    </>
  );
};

export default SetPassword;
