import { Formik } from 'formik';
import { useCallback, useState } from 'react';
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import * as yup from 'yup';
import Common from '../../../shared/Common';
import type { IUserSettingsChildProps } from '../UserSettings';

import 'react-loading-skeleton/dist/skeleton.css';

const Email = (props: IUserSettingsChildProps) => {
  const [formLoading, setFormLoading] = useState<boolean>(false);

  const { loading, userData, setStatus } = props;

  const EmailForm = useCallback(() => {
    const sendVerificationEmail = async () => {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({}),
      };
      const response = await Common.authorizedFetch('api/users/sendVerificationEmail', requestOptions);
      const data = await response.json();
      setFormLoading(false);
      if (!data.success) {
        setStatus({
          variant: 'error',
          status: data.errors,
          messageTime: Date.now(),
        });
      } else {
        setStatus({
          variant: 'success',
          status: data.informations,
          messageTime: Date.now(),
        });
      }
    };

    const validationSchema = yup.object().shape({
      userEmail: yup.string(),
      newEmail: yup.string().email('Podano nieprawidłowy adres e-mail'),
    });

    return (
      <Row>
        <Col md={6}>
          <Formik
            initialValues={{
              userEmail: userData?.email ?? '',
              newEmail: userData?.email ?? '',
            }}
            validationSchema={validationSchema}
            onSubmit={async (values, { setFieldError, setSubmitting }) => {
              setSubmitting(true);
              setFormLoading(true);
              const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                  email: values.newEmail,
                }),
              };
              const response = await Common.authorizedFetch('api/users/saveUserEmail', requestOptions);
              const data = await response.json();
              setFormLoading(false);
              if (!data.success) {
                setFieldError('newEmail', data.errors);
              } else {
                setStatus({
                  variant: 'success',
                  status: data.informations,
                  messageTime: Date.now(),
                });
              }
              setSubmitting(false);
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
              <>
                <Form onSubmit={handleSubmit} autoComplete='off'>
                  <Form.Group>
                    <Form.Label>Aktualny adres e-mail</Form.Label>
                    {loading ? (
                      <Skeleton height={35} />
                    ) : (
                      <>
                        <InputGroup>
                          <Form.Control
                            type='text'
                            name='userEmail'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.userEmail}
                            disabled
                          />
                          {userData?.isEmailConfirmed ? (
                            <span className='input-group-text text-success font-weight-bold'>✓</span>
                          ) : (
                            <span className='input-group-text text-danger font-weight-bold'>🗙</span>
                          )}
                        </InputGroup>
                        {!userData?.isEmailConfirmed && (
                          <Button
                            variant='link'
                            disabled={isSubmitting || loading}
                            onClick={async () => await sendVerificationEmail()}
                          >
                            {' '}
                            Wyślij e-mail weryfikacyjny
                          </Button>
                        )}
                      </>
                    )}
                  </Form.Group>
                  <Form.Group className='mt-3'>
                    <Form.Label>Nowy e-mail</Form.Label>
                    {loading ? (
                      <Skeleton height={35} />
                    ) : (
                      <>
                        <Form.Control
                          type='text'
                          name='newEmail'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.newEmail}
                          className={touched.newEmail && errors.newEmail ? 'error' : undefined}
                        />
                        {touched.newEmail && errors.newEmail ? (
                          <div className='error-message'>{errors.newEmail}</div>
                        ) : null}
                      </>
                    )}
                  </Form.Group>
                  <Button className='mt-3' variant='primary' type='submit' disabled={isSubmitting || loading}>
                    Zapisz
                  </Button>
                </Form>
              </>
            )}
          </Formik>
        </Col>
      </Row>
    );
  }, [loading, userData, setStatus]);
  return (
    <>
      <h4>E-mail</h4>
      {Common.Ui.showLoadingSpinnerFixed(formLoading)}
      <EmailForm />
    </>
  );
};

export default Email;
