export const Spinner = ({ style }: { style?: React.CSSProperties }) => (
  <svg xmlns='http://www.w3.org/2000/svg' style={style} viewBox='0 0 24 24'>
    <path
      fill='#00aa5b'
      d='M12,20 C7.6,20 4,16.4 4,12 C4,7.6 7.6,4 12,4 C12.6,4 13,3.6 13,3 C13,2.4 12.6,2 12,2 C6.5,2 2,6.5 2,12 C2,17.5 6.5,22 12,22 C12.6,22 13,21.6 13,21 C13,20.4 12.6,20 12,20'
    >
      <animateTransform
        attributeName='transform'
        values='0 12 12; 360 12 12; 360 12 12'
        type='rotate'
        calcMode='spline'
        keySplines='0.2 0 0.4 1; 0.4 0 0.2 1'
        keyTimes='0; 0.8; 1'
        dur='2000ms'
        repeatCount='indefinite'
      />
    </path>
    <path
      fill='#145638'
      d='M12,6 C11.4,6 11,6.4 11,7 C11,7.5 11.4,8 12,8 C14.2,8 16,9.8 16,12 C16,14.2 14.2,16 12,16 C11.4,16 11,16.4 11,17 C11,17.6 11.4,18 12,18 C15.3,18 18,15.3 18,12 C18,8.7 15.3,6 12,6'
    >
      <animateTransform
        attributeName='transform'
        type='rotate'
        values='0 12 12; -360 12 12; -360 12 12'
        calcMode='spline'
        keySplines='0.4 0 0.2 1; 0.4 0 0.2 1'
        keyTimes='0; 0.8; 1'
        dur='1000ms'
        repeatCount='indefinite'
      />
    </path>
  </svg>
);
