import React, { useState, useRef, useContext } from 'react';
import { Container, Form, Button, Modal, Stack } from 'react-bootstrap';
import { DictionaryContext } from '../../../App';
import type { IDefaultLocationPicture } from '../../../interfaces/IDefaultLocationPicture';
import Common from '../../shared/Common';
import DefaultLocationPictures from '../../shared/default-location-pictures/DefaultLocationPictures';

import './style.css';

export interface IAddLocationModalProps {
  show: boolean;
  onHide: () => void;
  onSave: () => void;
  setShowSpinner: React.Dispatch<React.SetStateAction<boolean>>;
  locationPictures: IDefaultLocationPicture[];
}

function AddLocationModal(props: IAddLocationModalProps) {
  const [locationName, setLocationName] = useState<string>('');
  const [picture, setPicture] = useState<string>('');
  const [defaultPictureId, setDefaultPictureId] = useState<number | undefined>();
  const [onSaving, setOnSaving] = useState<boolean>(false);
  const [showDefaultPictures, setShowDefaultPictures] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { reloadLocationNames } = useContext(DictionaryContext);
  const { locationPictures } = props;

  const handleUploadPicture = async (event: any) => {
    if (!!event.target.files[0]) {
      const billPictureBase64 = (await Common.Images.toBase64(event.target.files[0])) as string;
      setPicture(billPictureBase64);
    }
  };

  const addLocation = async () => {
    props.setShowSpinner(true);
    setOnSaving(true);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ locationName, picture, defaultPictureId }),
    };
    const response = await Common.authorizedFetch('api/locations/addLocation', requestOptions);
    const data = await response.json();
    props.setShowSpinner(false);
    setOnSaving(false);
    if (!data.success) {
      alert(data.errors);
    } else {
      if (reloadLocationNames) {
        reloadLocationNames();
      }
      setLocationName('');
      props.onHide();
      props.onSave();
    }
  };

  const onPictureSelected = (selectedPicture: IDefaultLocationPicture | undefined) => {
    if (selectedPicture) {
      setPicture(selectedPicture.pictureBase64String);
      setDefaultPictureId(selectedPicture.defaultPictureId);
    } else {
      setPicture('');
      setDefaultPictureId(undefined);
    }
  };

  return (
    <>
      <DefaultLocationPictures
        show={showDefaultPictures}
        setShow={setShowDefaultPictures}
        locationPictures={locationPictures}
        onPictureSelected={onPictureSelected}
      />
      <Modal
        centered
        show={props.show}
        onHide={props.onHide}
        aria-labelledby='contained-modal-title-vcenter'
        style={showDefaultPictures ? { zIndex: 1049 } : {}}
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>Dodaj nową lokalizację</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId='formBasicLocationName'>
              <Form.Label>Nazwa lokalizacji</Form.Label>
              <Form.Control
                type='text'
                placeholder='Wpisz nazwę'
                onChange={(e: any) => {
                  setLocationName(e.target.value);
                }}
              />
              <Form.Text className='text-muted'>Nowa nazwa lokalizacji w której są robione zakupy.</Form.Text>
            </Form.Group>
            <Form.Group controlId='formBasicLocationName' className='mt-2'>
              <Form.Label>Obraz</Form.Label>
              <Container>
                <img src={picture === '' ? '/Static/icons/avatar.png' : picture} height={75} alt='avatar' />
                <input
                  name='avatar'
                  className='d-none'
                  ref={inputRef}
                  onChange={(e) => {
                    handleUploadPicture(e);
                  }}
                  type='file'
                  accept='image/*'
                />
                <Stack style={{ width: 150 }}>
                  <Button
                    className='mt-1'
                    size='sm'
                    variant='success'
                    onClick={() => {
                      setShowDefaultPictures((prev) => !prev);
                    }}
                  >
                    Wybierz obraz
                  </Button>
                  <Button
                    className='mt-1'
                    size='sm'
                    variant='dark'
                    onClick={() => {
                      inputRef.current?.click();
                    }}
                  >
                    Wgraj zdjęcie
                  </Button>
                  {picture !== '' && (
                    <Button
                      className='mt-1'
                      size='sm'
                      variant='danger'
                      onClick={(e) => {
                        setPicture('');
                      }}
                    >
                      Usuń obraz
                    </Button>
                  )}
                </Stack>
              </Container>
              <Form.Text className='text-muted'>Obraz symbolizujący daną lokalizację.</Form.Text>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='dark' onClick={props.onHide}>
            Zamknij
          </Button>
          <Button variant='success' onClick={addLocation} disabled={onSaving}>
            Zapisz
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddLocationModal;
