import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Layout from './components/Layout';
import authService from './components/api-authorization/AuthorizeService';
import { User } from './classes/User';
import React from 'react';
import type { ILocation } from './interfaces/ILocation';
import Common from './components/shared/Common';
import type { ICategoryName } from './interfaces/ICategoryName';
import type { IGroupName } from './interfaces/IGroupName';
import type { IQuantityType } from './interfaces/IQuantityType';
import * as tf from '@tensorflow/tfjs';
import { PredictionModel } from './classes/PredictionModel';
import AppRoutes, { DashboardPaths } from './AppRoutes';
import Offline from './components/offline/Offline';

export const UserContext = React.createContext<{
  user?: User;
  setUser?: React.Dispatch<React.SetStateAction<User>>;
  ready: boolean;
  authenticated: boolean;
}>({
  ready: false,
  authenticated: false,
});

export const DictionaryContext = React.createContext<{
  quantityTypes: IQuantityType[];
  categoriesNames: ICategoryName[];
  groupsNames: IGroupName[];
  locationNames: ILocation[];
  predictionModel?: PredictionModel;
  reloadLocationNames?: () => void;
  reloadGroupsNames?: () => void;
  reloadCategoriesNames?: () => void;
}>({
  locationNames: [],
  groupsNames: [],
  categoriesNames: [],
  quantityTypes: [],
  predictionModel: undefined,
  reloadLocationNames: undefined,
  reloadGroupsNames: undefined,
  reloadCategoriesNames: undefined,
});

const App = () => {
  const [ready, setReady] = useState<boolean>(false);
  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState<User>(new User('', []));
  const [categoriesNames, setCategoriesNames] = useState<ICategoryName[]>([]);
  const [locationNames, setLocationNames] = useState<ILocation[]>([]);
  const [groupsNames, setGroupsNames] = useState<IGroupName[]>([]);
  const [quantityTypes, setQuantityTypes] = useState<IQuantityType[]>([]);
  const [predictionModel, setPredictionModel] = useState<PredictionModel | undefined>(undefined);

  const fetchQuantityTypesIdRef = useRef(0);
  const fetchLocationIdRef = useRef(0);
  const fetchCategoriesIdRef = React.useRef(0);
  const fetchGroupsIdRef = React.useRef(0);

  const [predictionModelLoaded, setPredictionModelLoaded] = useState(false);
  const [quantityTypesLoaded, setQuantityTypesLoaded] = useState(false);
  const [locationsLoaded, setLocationsLoaded] = useState(false);
  const [categoriesLoaded, setCategoriesLoaded] = useState(false);
  const [groupsLoaded, setGroupsLoaded] = useState(false);

  const location = useLocation();
  const currentPathname = location.pathname;

  const [offline, setOffline] = useState(false);

  useEffect(() => {
    if (!navigator.onLine) {
      setOffline(true);
    }
  }, []);

  const fetchCategoriesNames = useCallback(async () => {
    if (authenticated && ready) {
      const fetchId = ++fetchCategoriesIdRef.current;
      const response = await Common.authorizedFetch('api/categories/getcategories');
      const data = await response.json();
      if (data.success) {
        if (fetchId === fetchCategoriesIdRef.current) {
          setCategoriesNames(data.result.categories);
          setCategoriesLoaded(true);
        }
      }
    }
  }, [authenticated, ready]);

  const fetchGroupsNames = useCallback(async () => {
    if (authenticated && ready) {
      const fetchId = ++fetchGroupsIdRef.current;
      const response = await Common.authorizedFetch('api/groups/getGroups');
      const data = await response.json();
      if (data.success) {
        if (fetchId === fetchGroupsIdRef.current) {
          setGroupsNames(data.result.groups);
          setGroupsLoaded(true);
        }
      }
    }
  }, [authenticated, ready]);

  const fetchQuantityTypes = useCallback(async () => {
    if (authenticated && ready) {
      const fetchId = ++fetchQuantityTypesIdRef.current;
      const response = await Common.authorizedFetch('api/quantitytypes/getquantitytypes');
      if (fetchId === fetchQuantityTypesIdRef.current) {
        const data = await response.json();
        if (data.success) {
          setQuantityTypes(data.result.quantityTypes);
          setQuantityTypesLoaded(true);
        }
      }
    }
  }, [authenticated, ready]);

  const fetchLocationNames = useCallback(async () => {
    if (authenticated && ready) {
      const fetchId = ++fetchLocationIdRef.current;
      const response = await Common.authorizedFetch('/api/locations/getLocations');
      if (fetchId === fetchLocationIdRef.current) {
        const data = await response.json();
        if (data.success) {
          setLocationNames(data.result.locations);
          setLocationsLoaded(true);
        }
      }
    }
  }, [authenticated, ready]);

  const fetchTensorflowModel = useCallback(async () => {
    if (authenticated && ready) {
      const model = await tf.loadLayersModel('/Static/model.json');
      let dictJson = await fetch('/Static/vocab_dict.json');
      const vocabularyEncoder = await dictJson.json();
      dictJson = await fetch('/Static/label_encoder.json');
      const labelEncoder = await dictJson.json();
      const predictionModel = new PredictionModel(model, labelEncoder, vocabularyEncoder);
      setPredictionModel(predictionModel);
      setPredictionModelLoaded(true);
    }
  }, [authenticated, ready]);

  useEffect(() => {
    if (!DashboardPaths.includes(currentPathname)) {
      return;
    }
    if (predictionModelLoaded) {
      return;
    }
    if (user && user.isUser()) {
      fetchTensorflowModel();
    }
  }, [fetchTensorflowModel, user, currentPathname, predictionModelLoaded]);

  useEffect(() => {
    if (!DashboardPaths.includes(currentPathname)) {
      return;
    }
    if (categoriesLoaded) {
      return;
    }
    if (user && user.isUser()) {
      fetchCategoriesNames();
    }
  }, [fetchCategoriesNames, user, currentPathname, categoriesLoaded]);

  useEffect(() => {
    if (!DashboardPaths.includes(currentPathname)) {
      return;
    }
    if (groupsLoaded) {
      return;
    }
    if (user && user.isUser()) {
      fetchGroupsNames();
    }
  }, [fetchGroupsNames, user, currentPathname, groupsLoaded]);

  useEffect(() => {
    if (!DashboardPaths.includes(currentPathname)) {
      return;
    }
    if (quantityTypesLoaded) {
      return;
    }
    if (user && user.isUser()) {
      fetchQuantityTypes();
    }
  }, [fetchQuantityTypes, user, currentPathname, quantityTypesLoaded]);

  useEffect(() => {
    if (!DashboardPaths.includes(currentPathname)) {
      return;
    }
    if (locationsLoaded) {
      return;
    }
    if (user && user.isUser()) {
      fetchLocationNames();
    }
  }, [fetchLocationNames, user, currentPathname, locationsLoaded]);

  useEffect(() => {
    const populateAuthenticationState = async () => {
      const isAuthenticated = await authService.isAuthenticated();
      const user = await authService.getUser();
      const userName: string = (user && user.name) ?? '';
      const profilePicture: string | undefined = user && user.picture;
      var userRoles: string[] = [];
      if (user && user.role) {
        const roles = user.role.toString().toLowerCase().split(',');
        userRoles = roles;
      }
      setAuthenticated(isAuthenticated);
      setUser(new User(userName, userRoles, profilePicture));
      setReady(true);
    };

    const authenticationChanged = async () => {
      setAuthenticated(false);
      setReady(false);
      setUser(new User('', []));
      await populateAuthenticationState();
    };

    const subscriptionId = authService.subscribe(() => authenticationChanged());
    populateAuthenticationState();
    return () => {
      authService.unsubscribe(subscriptionId);
    };
  }, []);

  return (
    <>
      {offline && (
        <Layout>
          <Offline />
        </Layout>
      )}
      {!offline && (
        <UserContext.Provider value={{ user: user, setUser: setUser, ready: ready, authenticated: authenticated }}>
          <DictionaryContext.Provider
            value={{
              locationNames: locationNames,
              groupsNames: groupsNames,
              categoriesNames: categoriesNames,
              quantityTypes: quantityTypes,
              predictionModel: predictionModel,
              reloadLocationNames: () => {
                setLocationsLoaded(false);
              },
              reloadGroupsNames: () => {
                setGroupsLoaded(false);
              },
              reloadCategoriesNames: () => {
                setCategoriesLoaded(false);
              },
            }}
          >
            <Layout>
              <AppRoutes />
            </Layout>
          </DictionaryContext.Provider>
        </UserContext.Provider>
      )}
    </>
  );
};

export default App;
