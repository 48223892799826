export enum ScheduleStatus {
  Scheduled = 1,
  Running = 2,
  Completed = 3,
  CustomErrorsOccured = 4,
  DataFeedNotFound = 5,
  NoRecordsCollected = 6,
}

export interface IScraperScheduleExecution {
  time: string;
  status: ScheduleStatus;
  processedRecords: number;
}
