import { Formik } from 'formik';
import { useCallback, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import QRCode from 'react-qr-code';
import { Navigate } from 'react-router-dom';
import * as yup from 'yup';
import Common from '../../../shared/Common';
import type { IUserSettingsChildProps } from '../UserSettings';

import './style.css';
import 'react-loading-skeleton/dist/skeleton.css';

const EnableAuthenticator = (props: IUserSettingsChildProps) => {
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [redirect, setRedirect] = useState<boolean>(false);
  const [generatedCodes, setGeneratedCodes] = useState<string[]>([]);
  const { setFetchUserDataTrigger, fetchUserDataTrigger, loading, userData, setStatus } = props;

  const Verify2FaForm = useCallback(() => {
    const validationSchema = yup.object().shape({
      code: yup
        .string()
        .required('Nie wprowadzono kodu weryfikacyjnego')
        .min(6, 'Kod weryfikacyjny musi mieć minimum 6 znaków')
        .max(7, 'Kod weryfikacyjny musi mieć maksimum 7 znaków'),
    });

    return (
      <Row>
        <Col md={6}>
          <Formik
            initialValues={{
              code: '',
            }}
            validationSchema={validationSchema}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true);
              setFormLoading(true);
              const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ code: values.code }),
              };
              const response = await Common.authorizedFetch('api/twoFactorAuthentication/Activate2Fa', requestOptions);
              const data = await response.json();
              setFormLoading(false);
              setSubmitting(false);
              if (!data.success) {
                setStatus({
                  variant: 'error',
                  status: data.errors,
                  messageTime: Date.now(),
                });
              } else {
                var generatedCodes = data.result?.generatedCodes;
                if (generatedCodes?.length > 0) {
                  setGeneratedCodes(generatedCodes);
                } else {
                  setRedirect(true);
                  setStatus({
                    variant: 'success',
                    status: data.informations,
                    messageTime: Date.now(),
                  });
                }
                setFetchUserDataTrigger(fetchUserDataTrigger + 1);
              }
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
              <>
                <Form onSubmit={handleSubmit} autoComplete='off'>
                  <Form.Group>
                    <Form.Label>Kod weryfikacyjny</Form.Label>
                    {loading ? (
                      <Skeleton height={35} />
                    ) : (
                      <>
                        <Form.Control
                          type='text'
                          name='code'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.code}
                          className={touched.code && errors.code ? 'error' : undefined}
                        />
                        {touched.code && errors.code ? <div className='error-message'>{errors.code}</div> : null}
                      </>
                    )}
                  </Form.Group>
                  <Button className='mt-3' variant='primary' type='submit' disabled={isSubmitting || loading}>
                    Weryfikuj
                  </Button>
                </Form>
              </>
            )}
          </Formik>
        </Col>
      </Row>
    );
  }, [setFetchUserDataTrigger, fetchUserDataTrigger, loading, setStatus, setGeneratedCodes]);

  return (
    <>
      {redirect && <Navigate to={'/settings/user/2fa'} replace />}
      {generatedCodes?.length > 0 && (
        <Navigate to={{ pathname: '/settings/user/2fa/show-recovery-codes' }} state={{ generatedCodes }} replace />
      )}
      <h4>Konfiguruj aplikację do uwierzytelnienia dwuskładnikowego (2FA)</h4>
      {Common.Ui.showLoadingSpinnerFixed(formLoading)}
      <p>Aby uruchomić uwierzytelnienie dwuskładnikowe (2FA) wykonaj następujące kroki:</p>
      <ol>
        <li>
          <p>
            Pobierz aplikację mobilną do uwierzytelnienia dwuskładnikowego Microsoft Authenticator dla&nbsp;
            <a href='https://go.microsoft.com/fwlink/?Linkid=825072'>Androida</a> i&nbsp;
            <a href='https://go.microsoft.com/fwlink/?Linkid=825073'>iOS'a</a> lub Google Authenticator dla&nbsp;
            <a href='https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&amp;hl=en'>
              Androida
            </a>{' '}
            i&nbsp;
            <a href='https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8'>iOS'a</a>.
          </p>
        </li>
        <li>
          <p>
            Za pomocą pobranej aplikacji zeskanuj kod QR lub ręcznie wprowadź klucz&nbsp;
            {loading ? <Skeleton height={20} width={320} /> : <kbd>{userData?.sharedKey}</kbd>}. Spacje i wielkość
            znaków nie ma znaczenia.
          </p>
          {loading ? (
            <Skeleton height={100} width={100} />
          ) : (
            userData && <QRCode value={userData.authenticatorUri} size={175} />
          )}
        </li>
        <li>
          <p>
            Po zeskanowaniu kodu QR lub wprowadzeniu powyższego klucza, aplikacja wyświetli Ci unikalny kod. Wprowadź te
            kod w polu tekstowym poniżej.
          </p>
          <Row>
            <Col>
              <Verify2FaForm />
            </Col>
          </Row>
        </li>
      </ol>
    </>
  );
};

export default EnableAuthenticator;
