import { Formik } from 'formik';
import { useState, useCallback, useContext } from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import * as yup from 'yup';
import Common from '../../shared/Common';
import CategoryAutosuggest from '../../shared/category-autosuggest/CategoryAutosuggest';
import LocationsAutosuggest from '../../shared/locations-autosuggest/LocationsAutosuggest';
import WarningModal from '../../shared/shared-modals/WarningModal';
import type { ICategoryName } from '../../../interfaces/ICategoryName';
import type { ILocation } from '../../../interfaces/ILocation';
import type { IRecurringOperation } from '../../../interfaces/IRecurringOperation';
import { DictionaryContext } from '../../../App';

import './style.css';

export interface IAddOrUpdateRecurringOperationModalProps {
  show: boolean;
  onHide: () => void;
  onSave: () => void;
  selectedRecurringOperation: IRecurringOperation | null;
  categoriesNames: ICategoryName[];
  locationsNames: ILocation[];
}

function AddOrUpdateRecurringOperationModal(props: IAddOrUpdateRecurringOperationModalProps) {
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const { reloadLocationNames } = useContext(DictionaryContext);

  const removeRecurringOperation = async () => {
    if (!props.selectedRecurringOperation?.recurringOperationId) {
      return;
    }
    setFormLoading(true);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        recurringOperationId: props.selectedRecurringOperation?.recurringOperationId,
      }),
    };
    const response = await Common.authorizedFetch('api/recurringOperations/removeRecurringOperation', requestOptions);
    const data = await response.json();
    if (!data.success) {
      alert(data.errors);
    }
    setFormLoading(false);
    setShowWarningModal(false);
    props.onHide();
    props.onSave();
  };

  const AddOrUpdateRecurringOperationForm = useCallback(() => {
    const validationSchema = yup.object().shape({
      description: yup.string().required('Pole z nazwą jest wymagane'),
      interval: yup
        .string()
        .matches(
          /^([0-9]+m)?([0-9]+d)?$/,
          'Interwał musi być formatu {x}m{y}d albo {x}d, gdzie x,y to liczby większe od 0'
        )
        .required('Pole z interwałem jest wymagane'),
      cost: yup.number().typeError('Wartość rachunku musi być liczbą').required('Koszt rachunku jest wymagany'),
      nextUpdate: yup.string().required('Pole z następną aktualizacją jest wymagane'),
    });

    let date = new Date();
    if (!!props.selectedRecurringOperation?.nextUpdate) {
      date = new Date(props.selectedRecurringOperation?.nextUpdate ?? '');
    }
    // przez strefę czasową automatycznie ustawia się dzień wstecz przy godzinie 00:00
    date = new Date((date as Date).getTime() - (date as Date).getTimezoneOffset() * 60000);
    const nextUpdate = date.toISOString().split('T')[0];

    return (
      <Formik
        initialValues={{
          description: props.selectedRecurringOperation?.description ?? '',
          interval: props.selectedRecurringOperation?.interval ?? '',
          cost: props.selectedRecurringOperation?.cost ?? 0,
          category: props.selectedRecurringOperation?.category ?? {
            categoryId: undefined,
            categoryFullName: '',
            categoryName: '',
          },
          location: props.selectedRecurringOperation?.location ?? {
            locationId: -1,
            locationName: '',
            picture: '',
            mappings: [],
          },
          nextUpdate: nextUpdate,
          active: props.selectedRecurringOperation?.active ?? true,
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          setFormLoading(true);

          let requestBody = '';
          let apiMethod = '';
          if (props.selectedRecurringOperation === null) {
            requestBody = JSON.stringify({
              description: values.description,
              interval: values.interval,
              cost: values.cost,
              location: values.location,
              category: values.category,
              nextUpdate: new Date(values.nextUpdate ?? ''),
              active: values.active,
            });
            apiMethod = 'api/recurringOperations/addRecurringOperation';
          } else {
            requestBody = JSON.stringify({
              recurringOperationId: props.selectedRecurringOperation?.recurringOperationId,
              description: values.description,
              interval: values.interval,
              cost: values.cost,
              location: values.location,
              category: values.category,
              nextUpdate: new Date(values.nextUpdate ?? ''),
              active: values.active,
            });
            apiMethod = 'api/recurringOperations/updateRecurringOperation';
          }

          const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: requestBody,
          };
          const response = await Common.authorizedFetch(apiMethod, requestOptions);
          const data = await response.json();
          setFormLoading(false);
          setSubmitting(false);
          if (!data.success) {
            alert(data.errors);
          } else {
            if (data.result.newLocationAdded && reloadLocationNames) {
              reloadLocationNames();
            }
            props.onHide();
            props.onSave();
          }
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
          <>
            <Form id='fooId' onSubmit={handleSubmit}>
              <Form.Group>
                <Form.Label>Nazwa operacji cyklicznej</Form.Label>
                <Form.Control
                  autoComplete='on'
                  type='text'
                  name='description'
                  placeholder='Wpisz nazwę operacji'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.description}
                  className={touched.description && errors.description ? 'error' : undefined}
                />
                {touched.description && errors.description ? (
                  <div className='error-message'>{errors.description}</div>
                ) : null}
                <Form.Text className='text-muted'>
                  Nazwa operacji, która będzie wstawiona w opisie cyklicznie generowanego rachunku.
                </Form.Text>
              </Form.Group>
              <Form.Group className='mt-1'>
                <Form.Label>Koszt</Form.Label>
                <Form.Control
                  autoComplete='on'
                  type='text'
                  name='cost'
                  placeholder='Podaj wartość rachunku'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.cost}
                  className={touched.cost && errors.cost ? 'error' : undefined}
                />
                {touched.cost && errors.cost ? <div className='error-message'>{errors.cost}</div> : null}
                <Form.Text className='text-muted'>Wartość generowanego cyklicznie rachunku.</Form.Text>
              </Form.Group>
              <Form.Group className='mt-1'>
                <Form.Label>Interwał uruchamiania</Form.Label>
                <Form.Control
                  autoComplete='on'
                  type='text'
                  name='interval'
                  placeholder='Wpisz interwał'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.interval}
                  className={touched.interval && errors.interval ? 'error' : undefined}
                />
                {touched.interval && errors.interval ? <div className='error-message'>{errors.interval}</div> : null}
                <Form.Text className='text-muted'>
                  Co jaki czas ma zostać wygenerowany rachunek (np. 1m12d oznacza, że rachunek będzie generowany co 1
                  miesiąc i 12 dni).
                </Form.Text>
              </Form.Group>
              <Form.Group className='mt-1'>
                <Form.Label>Kategoria</Form.Label>
                <CategoryAutosuggest
                  selectedValue={values.category}
                  onChange={(categoryJson) => {
                    setFieldValue('category', JSON.parse(categoryJson));
                  }}
                  categories={props.categoriesNames}
                />
                <Form.Text className='text-muted'>
                  Kategoria jaka ma być ustawiona w cyklicznie generowanym rachunku.
                </Form.Text>
              </Form.Group>
              <Form.Group className='mt-1'>
                <Form.Label>Lokalizacja</Form.Label>
                <LocationsAutosuggest
                  selectedValue={values.location}
                  onChange={(location) => {
                    setFieldValue('location', location);
                  }}
                  locations={props.locationsNames}
                  inputClassName='form-control'
                />
                <Form.Text className='text-muted'>
                  Lokalizacja jaka ma być ustawiona w cyklicznie generowanym rachunku.
                </Form.Text>
              </Form.Group>
              <Form.Group className='mt-1'>
                <Form.Label>Następna aktualizacja</Form.Label>
                <Form.Control
                  type='date'
                  name='nextUpdate'
                  placeholder='Wpisz interwał'
                  disabled={!values.active}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.nextUpdate}
                  className={touched.nextUpdate && errors.nextUpdate ? 'error' : undefined}
                />
                {touched.nextUpdate && errors.nextUpdate ? (
                  <div className='error-message'>{errors.nextUpdate}</div>
                ) : null}
                <Form.Text className='text-muted'>Kiedy ma zostać wygenerowany następny rachunek.</Form.Text>
              </Form.Group>
              <Form.Group className='mt-1'>
                <Form.Check
                  type='switch'
                  id='bill-edit-switch'
                  name='active'
                  label='Aktywna'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  checked={values.active}
                  className={touched.active && errors.active ? 'error' : undefined}
                />
                {touched.active && errors.active ? <div className='error-message'>{errors.active}</div> : null}
              </Form.Group>
            </Form>
          </>
        )}
      </Formik>
    );
  }, [props, reloadLocationNames]);

  return (
    <>
      <Modal centered show={props.show} onHide={props.onHide} aria-labelledby='contained-modal-title-vcenter'>
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            {props.selectedRecurringOperation === null
              ? 'Dodaj nową operację cykliczną'
              : 'Aktualizacja operacji cyklicznej'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {Common.Ui.showLoadingSpinnerAbsolute(formLoading)}
          <AddOrUpdateRecurringOperationForm />
        </Modal.Body>
        <Modal.Footer>
          <Button variant='dark' onClick={props.onHide}>
            Zamknij
          </Button>
          {props.selectedRecurringOperation && (
            <Button
              variant='danger'
              onClick={() => {
                setShowWarningModal(true);
              }}
            >
              Usuń
            </Button>
          )}
          <Button variant='success' type='submit' form='fooId'>
            Zapisz
          </Button>
        </Modal.Footer>
      </Modal>
      <WarningModal
        show={showWarningModal}
        modalBodyText={'Czy na pewno chcesz usunąć operację cykliczną?'}
        onHide={() => {
          setShowWarningModal(false);
        }}
        onConfirmation={() => removeRecurringOperation()}
      />
    </>
  );
}

export default AddOrUpdateRecurringOperationModal;
