import { Routes, Route } from 'react-router-dom';
import Account from './components/account/Account';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import Dashboard from './components/dashboard/Dashboard';
import Privacy from './components/privacy/Privacy';
import Reports from './components/reports/Reports';
import ApplicationSettings from './components/settings/application/ApplicationSettings';
import UserSettings from './components/settings/user/UserSettings';
import Summary from './components/summary/Summary';

const AppRoutesPaths = {
  Default: '/',
  Dashboard: '/dashboard',
  Prices: '/prices',
  CompanyPrices: '/prices/:company/:locationId',
  ProductPrice: '/prices/:company/:locationId/:locationProductId',
  Bills: '/bills',
  Statistics: '/statistics',
  Summary: '/summary/:id',
  Summaries: '/summaries',
  ManageSummary: '/summaries/:id',
  RecurringOperations: '/recurring-operations',
  Locations: '/dictionaries/locations',
  Categories: '/dictionaries/categories',
  CategoriesMap: '/dictionaries/categories-map',
  Groups: '/dictionaries/groups',
  GroupsMap: '/dictionaries/groups-map',
  QuantityTypes: '/dictionaries/quantity-types',
  ApplicationSettings: '/settings/application/*',
  UserSettings: '/settings/user/*',
  Reports: '/reports',
  Account: '/account/*',
  Authentication: '/authentication/*',
  Privacy: '/privacy',
};

export const DashboardPaths = [
  AppRoutesPaths.Default,
  AppRoutesPaths.Dashboard,
  AppRoutesPaths.Prices,
  AppRoutesPaths.CompanyPrices,
  AppRoutesPaths.ProductPrice,
  AppRoutesPaths.Bills,
  AppRoutesPaths.Statistics,
  AppRoutesPaths.Summaries,
  AppRoutesPaths.RecurringOperations,
  AppRoutesPaths.Locations,
  AppRoutesPaths.Categories,
  AppRoutesPaths.CategoriesMap,
  AppRoutesPaths.Groups,
  AppRoutesPaths.GroupsMap,
  AppRoutesPaths.QuantityTypes,
];

const AppRoutes = () => (
  <Routes>
    <Route path={AppRoutesPaths.Default} element={<AuthorizeRoute redirectPath='/' component={<Dashboard />} />} />
    <Route
      path={AppRoutesPaths.Dashboard}
      element={<AuthorizeRoute redirectPath='/dashboard' component={<Dashboard />} />}
    />
    <Route path={AppRoutesPaths.Prices} element={<AuthorizeRoute redirectPath='/prices' component={<Dashboard />} />} />
    <Route
      path={AppRoutesPaths.CompanyPrices}
      element={<AuthorizeRoute redirectPath='/prices' component={<Dashboard />} />}
    />
    <Route
      path={AppRoutesPaths.ProductPrice}
      element={<AuthorizeRoute redirectPath='/prices' component={<Dashboard />} />}
    />
    <Route path={AppRoutesPaths.Bills} element={<AuthorizeRoute redirectPath='/bills' component={<Dashboard />} />} />
    <Route
      path={AppRoutesPaths.Statistics}
      element={<AuthorizeRoute redirectPath='/statistics' component={<Dashboard />} />}
    />
    <Route
      path={AppRoutesPaths.Summaries}
      element={<AuthorizeRoute redirectPath='/summaries' component={<Dashboard />} />}
    />
    <Route
      path={AppRoutesPaths.ManageSummary}
      element={<AuthorizeRoute redirectPath='/summaries' component={<Dashboard />} />}
    />
    <Route path={AppRoutesPaths.Summary} element={<Summary />} />
    <Route
      path={AppRoutesPaths.RecurringOperations}
      element={<AuthorizeRoute redirectPath='/recurring-operations' component={<Dashboard />} />}
    />
    <Route
      path={AppRoutesPaths.Locations}
      element={<AuthorizeRoute redirectPath='/dictionaries/locations' component={<Dashboard />} />}
    />
    <Route
      path={AppRoutesPaths.Categories}
      element={<AuthorizeRoute redirectPath='/dictionaries/categories' component={<Dashboard />} />}
    />
    <Route
      path={AppRoutesPaths.CategoriesMap}
      element={<AuthorizeRoute redirectPath='/dictionaries/categories-map' component={<Dashboard />} />}
    />
    <Route
      path={AppRoutesPaths.Groups}
      element={<AuthorizeRoute redirectPath='/dictionaries/groups' component={<Dashboard />} />}
    />
    <Route
      path={AppRoutesPaths.GroupsMap}
      element={<AuthorizeRoute redirectPath='/dictionaries/groups-map' component={<Dashboard />} />}
    />
    <Route
      path={AppRoutesPaths.QuantityTypes}
      element={<AuthorizeRoute redirectPath='/dictionaries/quantity-types' component={<Dashboard />} />}
    />
    <Route
      path={AppRoutesPaths.ApplicationSettings}
      element={<AuthorizeRoute redirectPath='/settings/application/user-roles' component={<ApplicationSettings />} />}
    />
    <Route
      path={AppRoutesPaths.UserSettings}
      element={<AuthorizeRoute redirectPath='/settings/user/profile' component={<UserSettings />} />}
    />
    <Route path={AppRoutesPaths.Reports} element={<AuthorizeRoute redirectPath='/reports' component={<Reports />} />} />
    <Route path={AppRoutesPaths.Account} element={<Account />} />
    <Route path={AppRoutesPaths.Authentication} element={<ApiAuthorizationRoutes />} />
    <Route path={AppRoutesPaths.Privacy} element={<Privacy />} />
  </Routes>
);

export default AppRoutes;
