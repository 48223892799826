export enum ManuallyWorkerType {
  RefillDataForSelgros = 0,
  RefillEanCodeForHaleBanacha = 1,
}

export interface IManuallyWorkerDataFeed {
  id: number;
  workerType: ManuallyWorkerType;
  paramsJson: string;
  currentIteration: number;
  totalIterations: number;
  start: Date;
  finish?: Date;
}
