import './style.css';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { Navigate } from 'react-router-dom';
import Common from '../../../shared/Common';
import type { IUserSettingsChildProps } from '../UserSettings';

const ResetAuthenticator = (props: IUserSettingsChildProps) => {
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [redirect, setRedirect] = useState<boolean>(false);
  const { setStatus, fetchUserDataTrigger, setFetchUserDataTrigger } = props;

  const resetAuthenticator = async () => {
    setFormLoading(true);
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify({}),
      headers: { 'Content-Type': 'application/json' },
    };
    const response = await Common.authorizedFetch('api/twoFactorAuthentication/resetAuthenticator', requestOptions);
    const data = await response.json();
    setFormLoading(false);
    if (!data.success) {
      setStatus({
        variant: 'error',
        status: data.errors,
        messageTime: Date.now(),
      });
    } else {
      setRedirect(true);
      setStatus({
        variant: 'success',
        status: data.informations,
        messageTime: Date.now(),
      });
      setFetchUserDataTrigger(fetchUserDataTrigger + 1);
    }
  };

  return (
    <>
      {redirect && <Navigate to={'/settings/user/2fa/enable-authenticator'} replace />}
      {Common.Ui.showLoadingSpinnerFixed(formLoading)}
      <h4>Zresetuj klucz uwierzytelniający</h4>
      <div className='alert alert-warning' role='alert'>
        <p>
          <strong>
            Jeśli zresetujesz swój klucz uwierzytelniający Twoja aplikacja do uwierzytelnienia 2FA nie będzie działać.
          </strong>
        </p>
        <p>
          Ten proces zdezaktywuje 2FA, dopóki nie zweryfikujesz swojej aplikacji do autentykacji ponownie. Jeśli nie
          dokończysz konfiguracji w swojej aplikacji do autentykacji możesz stracić dostęp do konta.
        </p>
      </div>
      <Button variant='danger' onClick={resetAuthenticator} disabled={formLoading}>
        Zresetuj klucz uwierzytelniający
      </Button>
    </>
  );
};

export default ResetAuthenticator;
