import { useCallback, useEffect, useRef, useState } from 'react';
import type { IPricesView } from '../../../interfaces/IPricesView';
import Skeleton from 'react-loading-skeleton';
import type { ICompanyProductDetails } from '../../../interfaces/ICompanyProductDetails';
import { CompanyProductAvailability } from '../../../interfaces/ICompanyProduct';
import Common from '../../shared/Common';
import type { ICompanyProductPriceHistory } from '../../../interfaces/ICompanyProductPriceHistory';
import type { ICompanyProductAvailabilityHistory } from '../../../interfaces/ICompanyProductAvailabilityHistory';
import ProductDetailsPriceHistory from './ProductDetailsPriceHistory';
import ProductDetailsAvailabilityHistory from './ProductDetailsAvailabilityHistory';
import { Button, Card, Container } from 'react-bootstrap';
import ProductDetailsHistory from './ProductDetailsHistory';

interface IProductDetailsProps {
  currentViewParams: IPricesView;
  setSelectedProductName: React.Dispatch<React.SetStateAction<string | undefined>>;
}

function ProductDetails(props: IProductDetailsProps) {
  const [loading, setLoading] = useState(false);
  const [productNotFound, setProductNotFound] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<ICompanyProductDetails | undefined>(undefined);
  const [priceHistory, setPriceHistory] = useState<ICompanyProductPriceHistory[]>([]);
  const [showProductHistory, setShowProductHistory] = useState(false);
  const [availabilityHistory, setAvailabilityHistory] = useState<ICompanyProductAvailabilityHistory[]>([]);
  const [similarProductsPriceResetTrigger, setSimilarProductsPriceResetTrigger] = useState(0);
  const [similarProductsAvailabilitiesResetTrigger, setSimilarProductsAvailabilitiesResetTrigger] = useState(0);
  const fetchIdRef = useRef(0);

  const { setSelectedProductName, currentViewParams } = props;

  const fetchData = useCallback(async () => {
    if (!currentViewParams.selectedLocationProductId) {
      return;
    }
    setProductNotFound(false);
    setLoading(true);
    setSimilarProductsPriceResetTrigger((prev) => prev + 1);
    setSimilarProductsAvailabilitiesResetTrigger((prev) => prev + 1);
    setSelectedProductName('');
    const fetchId = ++fetchIdRef.current;
    const response = await Common.authorizedFetch(
      'api/prices/getProductDetails?locationProductId=' + currentViewParams.selectedLocationProductId
    );
    const data = await response.json();
    if (data.success) {
      if (fetchId === fetchIdRef.current) {
        if (data.result.productDetails) {
          setSelectedProduct(data.result.productDetails);
          setSelectedProductName(data.result.productDetails.productName);
          setPriceHistory(
            data.result.priceHistory.map((h: any) => ({
              dateValue: new Date(h.date).valueOf(),
              price: h.price,
              company: data.result.productDetails.companyName,
            }))
          );
          setAvailabilityHistory(
            data.result.availabilityHistory.map((h: any) => ({
              dateValue: new Date(h.date).valueOf(),
              availability: h.availability,
              company: data.result.productDetails.companyName,
            }))
          );
        } else {
          setSelectedProduct(undefined);
        }
      }
    } else {
      setProductNotFound(true);
      setSelectedProduct(undefined);
    }
    setLoading(false);
  }, [setSelectedProductName, currentViewParams.selectedLocationProductId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      {Common.Ui.showLoadingSpinnerFixed(loading)}
      {productNotFound && <h1 className='text-danger'>Nie znaleziono produktu</h1>}
      <ProductDetailsHistory
        showHistory={showProductHistory}
        setShowHistory={setShowProductHistory}
        productHistory={selectedProduct?.productHistory ?? []}
      />
      <Card className='mt-3'>
        <Card.Header className='statistics-card-header'>
          Informacje o produkcie {loading ? '' : selectedProduct?.productName}
        </Card.Header>
        <Container>
          <table className='table table-without-border-bottom' style={{ marginBottom: '11px' }}>
            <tbody>
              <tr>
                <td style={{ width: '30%' }}>Nazwa produktu</td>
                <td colSpan={2}>{loading ? <Skeleton /> : selectedProduct?.productName}</td>
              </tr>
              <tr>
                <td>Marka</td>
                <td colSpan={2}>{loading ? <Skeleton /> : selectedProduct?.brand}</td>
              </tr>
              {(selectedProduct?.url ?? '') !== '' ? (
                <tr>
                  <td>Strona produktu</td>
                  <td colSpan={2}>
                    {loading ? (
                      <Skeleton />
                    ) : (
                      <a href={selectedProduct?.url} target='_blank' rel='noreferrer'>
                        Link do {selectedProduct?.companyName} 🔗
                      </a>
                    )}
                  </td>
                </tr>
              ) : (
                <></>
              )}
              {(selectedProduct?.quantity ?? '') !== '' ? (
                <tr>
                  <td>Ilość</td>
                  <td colSpan={2}>{loading ? <Skeleton /> : selectedProduct?.quantity?.toLocaleLowerCase()}</td>
                </tr>
              ) : (
                <></>
              )}
              {(selectedProduct?.weight ?? '') !== '' ? (
                <tr>
                  <td>Waga</td>
                  <td colSpan={2}>{loading ? <Skeleton /> : selectedProduct?.weight?.toLocaleLowerCase()}</td>
                </tr>
              ) : (
                <></>
              )}
              <tr>
                <td>Aktualna cena</td>
                <td colSpan={2}>
                  {loading ? (
                    <Skeleton />
                  ) : selectedProduct?.currentCost ? (
                    Common.Utils.getCurrencyString(selectedProduct?.currentCost)
                  ) : (
                    ''
                  )}
                </td>
              </tr>
              <tr>
                <td>Sklep</td>
                <td colSpan={2}>{loading ? <Skeleton /> : selectedProduct?.companyName}</td>
              </tr>
              {(selectedProduct?.ean ?? '') !== '' ? (
                <tr>
                  <td>Kod EAN</td>
                  <td colSpan={2}>{loading ? <Skeleton /> : selectedProduct?.ean}</td>
                </tr>
              ) : (
                <></>
              )}
              <tr>
                <td>Kategorie produktu</td>
                <td colSpan={2}>{loading ? <Skeleton /> : selectedProduct?.productCategories.join(', ')}</td>
              </tr>
              {!loading && (
                <tr>
                  <td>Dostępność</td>
                  <td>
                    <span>
                      {' '}
                      {((availability: CompanyProductAvailability | undefined) => {
                        switch (availability) {
                          case CompanyProductAvailability.Available:
                            return 'Dostępny';
                          case CompanyProductAvailability.Unavailable:
                            return 'Usunięty';
                          case CompanyProductAvailability.OutOfStock:
                            return 'Brak zapasów';
                        }
                      })(selectedProduct?.availability)}
                    </span>
                  </td>
                  <td>
                    <Button
                      className='float-end'
                      size='sm'
                      onClick={() => {
                        setShowProductHistory(true);
                      }}
                    >
                      Historia zmian ({selectedProduct?.productHistory?.length ?? 0})
                    </Button>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </Container>
      </Card>
      {!loading && (
        <div>
          <Card className='mt-3'>
            <Card.Header className='statistics-card-header'>Historia cen</Card.Header>
            <Card.Body>
              <ProductDetailsPriceHistory
                priceHistory={priceHistory}
                selectedProduct={selectedProduct}
                resetDataTrigger={similarProductsPriceResetTrigger}
              />
            </Card.Body>
          </Card>
          <Card className='mt-3'>
            <Card.Header className='statistics-card-header'>Historia dostępności</Card.Header>
            <Card.Body>
              <ProductDetailsAvailabilityHistory
                availabilityHistory={availabilityHistory}
                selectedProduct={selectedProduct}
                resetDataTrigger={similarProductsAvailabilitiesResetTrigger}
              />
            </Card.Body>
          </Card>
        </div>
      )}
    </>
  );
}

export default ProductDetails;
