import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Common from '../../../shared/Common';
import type { IUserSettingsChildProps } from '../UserSettings';

const TwoFactorAuthentication = (props: IUserSettingsChildProps) => {
  const [formLoading, setFormLoading] = useState<boolean>(false);

  const { loading, userData, setStatus, fetchUserDataTrigger, setFetchUserDataTrigger } = props;

  const forgetTwoFactorClient = async () => {
    setFormLoading(true);
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify({}),
      headers: { 'Content-Type': 'application/json' },
    };
    const response = await Common.authorizedFetch('api/twoFactorAuthentication/forgetTwoFactorClient', requestOptions);
    const data = await response.json();
    setFormLoading(false);
    if (!data.success) {
      setStatus({
        variant: 'error',
        status: data.errors,
        messageTime: Date.now(),
      });
    } else {
      setStatus({
        variant: 'success',
        status: data.informations,
        messageTime: Date.now(),
      });
      setFetchUserDataTrigger(fetchUserDataTrigger + 1);
    }
  };

  const renderGenerateRecoveryCodes = (recoveryCodesLeft: number | undefined) => {
    switch (recoveryCodesLeft) {
      case 0:
        return (
          <div className='alert alert-danger'>
            <strong>Nie posiadasz więcej kodów do odzyskiwania.</strong>
            <p>
              Musisz <Link to='/settings/user/2fa/generate-recovery-codes'>wygenerować nowe kody do odzyskiwania</Link>,
              żeby móc logować się z pomocą kodu odzyskiwania.
            </p>
          </div>
        );
      case 1:
        return (
          <div className='alert alert-danger'>
            <strong>Został Ci 1 kod odzyskiwania.</strong>
            <p>
              Powinieneś{' '}
              <Link to='/settings/user/2fa/generate-recovery-codes'>wygenerować nowe kody do odzyskiwania</Link>.
            </p>
          </div>
        );
      case 2:
      case 3:
        return (
          <div className='alert alert-warning'>
            <strong>Zostały Ci {recoveryCodesLeft} kody odzyskiwania.</strong>
            <p>
              Możesz <Link to='/settings/user/2fa/generate-recovery-codes'>wygenerować nowe kody do odzyskiwania</Link>.
            </p>
          </div>
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      <h4>Uwierzytelnienie dwuskładnikowe (2FA)</h4>
      {Common.Ui.showLoadingSpinnerFixed(loading || formLoading)}
      {userData?.is2faEnabled && (
        <>
          {renderGenerateRecoveryCodes(userData?.recoveryCodesLeft)}
          {userData?.isMachineRemembered && (
            <Button variant='submit' onClick={forgetTwoFactorClient} disabled={formLoading}>
              Zapomnij tę przeglądarkę
            </Button>
          )}
          <Link to='/settings/user/2fa/disable' className='btn btn-default'>
            Wyłącz 2FA
          </Link>
          <Link to='/settings/user/2fa/generate-recovery-codes' className='btn btn-default'>
            Zresetuj kody odzyskiwania
          </Link>
        </>
      )}
      <h5>Aplikacja do uwierzytelnienia</h5>
      {userData?.hasAuthenticator ? (
        <>
          <Link to='/settings/user/2fa/enable-authenticator' className='btn btn-default'>
            Konfiguruj aplikację do uwierzytelnienia
          </Link>
          <Link to='/settings/user/2fa/reset-authenticator' className='btn btn-default'>
            Zresetuj aplikację do uwierzytelnienia
          </Link>
        </>
      ) : (
        <Link to='/settings/user/2fa/enable-authenticator' className='btn btn-default'>
          Dodaj aplikację do uwierzytelnienia
        </Link>
      )}
    </>
  );
};

export default TwoFactorAuthentication;
