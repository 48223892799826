export const SortUpIcon = ({ style }: { style?: React.CSSProperties }) => (
  <svg
    aria-hidden='true'
    focusable='false'
    data-prefix='fas'
    data-icon='sort-up'
    role='img'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 320 512'
    style={{ ...style, height: '1em', verticalAlign: '-0.125em' }}
  >
    <path
      fill='currentColor'
      d='M182.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-9.2 9.2-11.9 22.9-6.9 34.9s16.6 19.8 29.6 19.8H288c12.9 0 24.6-7.8 29.6-19.8s2.2-25.7-6.9-34.9l-128-128z'
    />
  </svg>
);

export const SortDownIcon = ({ style }: { style?: React.CSSProperties }) => (
  <svg
    aria-hidden='true'
    focusable='false'
    data-prefix='fas'
    data-icon='sort-down'
    role='img'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 320 512'
    style={{ ...style, height: '1em', verticalAlign: '-0.125em' }}
  >
    <path
      fill='currentColor'
      d='M182.6 470.6c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-9.2-9.2-11.9-22.9-6.9-34.9s16.6-19.8 29.6-19.8H288c12.9 0 24.6 7.8 29.6 19.8s2.2 25.7-6.9 34.9l-128 128z'
    />
  </svg>
);

export const BanIcon = ({ style }: { style?: React.CSSProperties }) => (
  <svg
    aria-hidden='true'
    focusable='false'
    data-prefix='fas'
    data-icon='ban'
    role='img'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 512 512'
    style={{ ...style, verticalAlign: '-0.125em' }}
  >
    <path
      fill='currentColor'
      d='M367.2 412.5L99.5 144.8C77.1 176.1 64 214.5 64 256c0 106 86 192 192 192c41.5 0 79.9-13.1 111.2-35.5zm45.3-45.3C434.9 335.9 448 297.5 448 256c0-106-86-192-192-192c-41.5 0-79.9 13.1-111.2 35.5L412.5 367.2zM512 256c0 141.4-114.6 256-256 256S0 397.4 0 256S114.6 0 256 0S512 114.6 512 256z'
    />
  </svg>
);

export const CheckIcon = ({ style }: { style?: React.CSSProperties }) => (
  <svg
    aria-hidden='true'
    focusable='false'
    data-prefix='fas'
    data-icon='check'
    role='img'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 512 512'
    style={{ ...style, height: '1em', verticalAlign: '-0.125em' }}
  >
    <path
      fill='currentColor'
      d='M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z'
    />
  </svg>
);

export const ClockIcon = ({ style }: { style?: React.CSSProperties }) => (
  // https://www.svgrepo.com/svg/453407/schedule
  <svg style={style} viewBox='0 0 24 24'>
    <path
      fillOpacity='.9'
      d='M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm.5-13H11v6l5.25 3.15.75-1.23-4.5-2.67z'
    />
  </svg>
);

export const CircleNotchSpinIcon = ({ style }: { style?: React.CSSProperties }) => (
  <svg
    aria-hidden='true'
    focusable='false'
    data-prefix='fas'
    data-icon='circle-notch'
    role='img'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 512 512'
    style={{
      ...style,
      height: '1em',
      verticalAlign: '-0.125em',
      animationName: 'spin',
      animationDelay: '0s',
      animationDirection: 'normal',
      animationDuration: '2s',
      animationIterationCount: 'infinite',
      animationTimingFunction: 'linear',
    }}
  >
    <path
      fill='currentColor'
      d='M222.7 32.1c5 16.9-4.6 34.8-21.5 39.8C121.8 95.6 64 169.1 64 256c0 106 86 192 192 192s192-86 192-192c0-86.9-57.8-160.4-137.1-184.1c-16.9-5-26.6-22.9-21.5-39.8s22.9-26.6 39.8-21.5C434.9 42.1 512 140 512 256c0 141.4-114.6 256-256 256S0 397.4 0 256C0 140 77.1 42.1 182.9 10.6c16.9-5 34.8 4.6 39.8 21.5z'
    />
  </svg>
);

export const GoogleIcon = ({ style }: { style?: React.CSSProperties }) => (
  <svg
    aria-hidden='true'
    focusable='false'
    data-prefix='fab'
    data-icon='google'
    role='img'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 488 512'
    style={{ ...style, height: '1em', verticalAlign: '-0.125em' }}
  >
    <path
      fill='currentColor'
      d='M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z'
    />
  </svg>
);

export const FacebookIcon = ({ style }: { style?: React.CSSProperties }) => (
  <svg
    aria-hidden='true'
    focusable='false'
    data-prefix='fab'
    role='img'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 320 512'
    style={{ ...style, height: '1em', verticalAlign: '-0.125em' }}
  >
    <path
      fill='currentColor'
      d='M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z'
    />
  </svg>
);

export const ActiveHomeIcon = ({ style }: { style?: React.CSSProperties }) => (
  <svg viewBox='0 0 28 28' style={style} fill='currentColor'>
    <path d='M25.825 12.29C25.824 12.289 25.823 12.288 25.821 12.286L15.027 2.937C14.752 2.675 14.392 2.527 13.989 2.521 13.608 2.527 13.248 2.675 13.001 2.912L2.175 12.29C1.756 12.658 1.629 13.245 1.868 13.759 2.079 14.215 2.567 14.479 3.069 14.479L5 14.479 5 23.729C5 24.695 5.784 25.479 6.75 25.479L11 25.479C11.552 25.479 12 25.031 12 24.479L12 18.309C12 18.126 12.148 17.979 12.33 17.979L15.67 17.979C15.852 17.979 16 18.126 16 18.309L16 24.479C16 25.031 16.448 25.479 17 25.479L21.25 25.479C22.217 25.479 23 24.695 23 23.729L23 14.479 24.931 14.479C25.433 14.479 25.921 14.215 26.132 13.759 26.371 13.245 26.244 12.658 25.825 12.29' />
  </svg>
);

export const HomeIcon = ({ style }: { style?: React.CSSProperties }) => (
  <svg viewBox='0 0 28 28' style={style} fill='currentColor'>
    <path d='M17.5 23.979 21.25 23.979C21.386 23.979 21.5 23.864 21.5 23.729L21.5 13.979C21.5 13.427 21.949 12.979 22.5 12.979L24.33 12.979 14.017 4.046 3.672 12.979 5.5 12.979C6.052 12.979 6.5 13.427 6.5 13.979L6.5 23.729C6.5 23.864 6.615 23.979 6.75 23.979L10.5 23.979 10.5 17.729C10.5 17.04 11.061 16.479 11.75 16.479L16.25 16.479C16.939 16.479 17.5 17.04 17.5 17.729L17.5 23.979ZM21.25 25.479 17 25.479C16.448 25.479 16 25.031 16 24.479L16 18.327C16 18.135 15.844 17.979 15.652 17.979L12.348 17.979C12.156 17.979 12 18.135 12 18.327L12 24.479C12 25.031 11.552 25.479 11 25.479L6.75 25.479C5.784 25.479 5 24.695 5 23.729L5 14.479 3.069 14.479C2.567 14.479 2.079 14.215 1.868 13.759 1.63 13.245 1.757 12.658 2.175 12.29L13.001 2.912C13.248 2.675 13.608 2.527 13.989 2.521 14.392 2.527 14.753 2.675 15.027 2.937L25.821 12.286C25.823 12.288 25.824 12.289 25.825 12.29 26.244 12.658 26.371 13.245 26.133 13.759 25.921 14.215 25.434 14.479 24.931 14.479L23 14.479 23 23.729C23 24.695 22.217 25.479 21.25 25.479Z' />
  </svg>
);

export const ActiveReportsIcon = ({ style }: { style?: React.CSSProperties }) => (
  <svg viewBox='0 0 262.727 262.727' fill='currentColor' style={style}>
    <rect
      x='204.927'
      y='37.391'
      style={{
        fill: '#FEDE94',
      }}
      width='30.822'
      height='208.996'
    />
    <rect
      x='126.75'
      y='89.68'
      style={{
        fill: '#AAE1E9',
      }}
      width='30.854'
      height='156.707'
    />
    <rect
      x='46.298'
      y='137.387'
      style={{
        fill: '#F47C6D',
      }}
      width='30.822'
      height='109'
    />
    <path
      style={{
        fill: '#2D213F',
      }}
      d='M252.025,246.418v8.106c0,4.486-3.62,8.138-8.138,8.138h-47.131c-4.486,0-8.138-3.653-8.138-8.138v-8.106L252.025,246.418L252.025,246.418z'
    />
    <path
      style={{
        fill: '#2D213F',
      }}
      d='M204.927,246.386h30.822V37.391h-30.822V246.386z M252.025,246.418h-63.407V29.252c0-4.518,3.653-8.138,8.138-8.138h47.131c4.518,0,8.138,3.621,8.138,8.138V246.418z'
    />
    <path
      style={{
        fill: '#2D213F',
      }}
      d='M262.727,254.557c0,4.518-3.653,8.17-8.138,8.17h-10.701v-0.064c4.518,0,8.138-3.653,8.138-8.138v-8.106h2.563C259.074,246.418,262.727,250.071,262.727,254.557z'
    />
    <path
      style={{
        fill: '#2D213F',
      }}
      d='M173.88,246.418v8.106c0,4.486-3.653,8.138-8.138,8.138h-47.131c-4.486,0-8.138-3.653-8.138-8.138v-8.106L173.88,246.418L173.88,246.418z'
    />
    <path
      style={{
        fill: '#2D213F',
      }}
      d='M173.88,246.418h-63.407V81.541c0-4.486,3.653-8.138,8.138-8.138h47.131c4.486,0,8.138,3.653,8.138,8.138V246.418z M126.75,246.386h30.854V89.68H126.75V246.386z'
    />
    <path
      style={{
        fill: '#2D213F',
      }}
      d='M188.619,254.525c0,4.486,3.653,8.138,8.138,8.138h47.131v0.064h-78.145v-0.064c4.486,0,8.138-3.653,8.138-8.138v-8.106h14.738L188.619,254.525L188.619,254.525z'
    />
    <path
      style={{
        fill: '#2D213F',
      }}
      d='M93.428,246.418v8.106c0,4.486-3.653,8.138-8.138,8.138H38.159c-4.518,0-8.138-3.653-8.138-8.138v-8.106L93.428,246.418L93.428,246.418z'
    />
    <path
      style={{
        fill: '#2D213F',
      }}
      d='M93.428,246.418H30.021v-117.17c0-4.518,3.621-8.138,8.138-8.138H85.29c4.486,0,8.138,3.621,8.138,8.138V246.418z M46.298,246.386H77.12v-109H46.298V246.386z'
    />
    <path
      style={{
        fill: '#2D213F',
      }}
      d='M118.612,262.663h47.131v0.064H85.29v-0.064c4.486,0,8.138-3.653,8.138-8.138v-8.106h17.045v8.106C110.473,259.01,114.126,262.663,118.612,262.663z'
    />
    <path
      style={{
        fill: '#2D213F',
      }}
      d='M38.159,262.663H85.29v0.064H8.138c-4.486,0-8.138-3.653-8.138-8.17V8.138C0,3.621,3.653,0,8.138,0c4.518,0,8.17,3.621,8.17,8.138v238.28h13.713v8.106C30.021,259.01,33.642,262.663,38.159,262.663z'
    />
  </svg>
);

export const ReportsIcon = ({ style }: { style?: React.CSSProperties }) => (
  <svg viewBox='0 0 262.727 262.727' style={style} fill='currentColor'>
    <rect
      x='204.927'
      y='37.391'
      style={{
        fill: 'none',
      }}
      width='30.822'
      height='208.996'
    />
    <rect
      x='126.75'
      y='89.68'
      style={{
        fill: 'none',
      }}
      width='30.854'
      height='156.707'
    />
    <rect
      x='46.298'
      y='137.387'
      style={{
        fill: 'none',
      }}
      width='30.822'
      height='109'
    />
    <path
      style={{
        fill: 'var(--app-secondary-color)',
      }}
      d='M252.025,246.418v8.106c0,4.486-3.62,8.138-8.138,8.138h-47.131c-4.486,0-8.138-3.653-8.138-8.138v-8.106L252.025,246.418L252.025,246.418z'
    />
    <path
      style={{
        fill: 'var(--app-secondary-color)',
      }}
      d='M204.927,246.386h30.822V37.391h-30.822V246.386z M252.025,246.418h-63.407V29.252c0-4.518,3.653-8.138,8.138-8.138h47.131c4.518,0,8.138,3.621,8.138,8.138V246.418z'
    />
    <path
      style={{
        fill: 'var(--app-secondary-color)',
      }}
      d='M262.727,254.557c0,4.518-3.653,8.17-8.138,8.17h-10.701v-0.064c4.518,0,8.138-3.653,8.138-8.138v-8.106h2.563C259.074,246.418,262.727,250.071,262.727,254.557z'
    />
    <path
      style={{
        fill: 'var(--app-secondary-color)',
      }}
      d='M173.88,246.418v8.106c0,4.486-3.653,8.138-8.138,8.138h-47.131c-4.486,0-8.138-3.653-8.138-8.138v-8.106L173.88,246.418L173.88,246.418z'
    />
    <path
      style={{
        fill: 'var(--app-secondary-color)',
      }}
      d='M173.88,246.418h-63.407V81.541c0-4.486,3.653-8.138,8.138-8.138h47.131c4.486,0,8.138,3.653,8.138,8.138V246.418z M126.75,246.386h30.854V89.68H126.75V246.386z'
    />
    <path
      style={{
        fill: 'var(--app-secondary-color)',
      }}
      d='M188.619,254.525c0,4.486,3.653,8.138,8.138,8.138h47.131v0.064h-78.145v-0.064c4.486,0,8.138-3.653,8.138-8.138v-8.106h14.738L188.619,254.525L188.619,254.525z'
    />
    <path
      style={{
        fill: 'var(--app-secondary-color)',
      }}
      d='M93.428,246.418v8.106c0,4.486-3.653,8.138-8.138,8.138H38.159c-4.518,0-8.138-3.653-8.138-8.138v-8.106L93.428,246.418L93.428,246.418z'
    />
    <path
      style={{
        fill: 'var(--app-secondary-color)',
      }}
      d='M93.428,246.418H30.021v-117.17c0-4.518,3.621-8.138,8.138-8.138H85.29c4.486,0,8.138,3.621,8.138,8.138V246.418z M46.298,246.386H77.12v-109H46.298V246.386z'
    />
    <path
      style={{
        fill: 'var(--app-secondary-color)',
      }}
      d='M118.612,262.663h47.131v0.064H85.29v-0.064c4.486,0,8.138-3.653,8.138-8.138v-8.106h17.045v8.106C110.473,259.01,114.126,262.663,118.612,262.663z'
    />
    <path
      style={{
        fill: 'var(--app-secondary-color)',
      }}
      d='M38.159,262.663H85.29v0.064H8.138c-4.486,0-8.138-3.653-8.138-8.17V8.138C0,3.621,3.653,0,8.138,0c4.518,0,8.17,3.621,8.17,8.138v238.28h13.713v8.106C30.021,259.01,33.642,262.663,38.159,262.663z'
    />
  </svg>
);

export const ActiveSettingsIcon = ({ style }: { style?: React.CSSProperties }) => (
  <svg viewBox='0 -12 536 536' style={style} fill='currentColor'>
    <path d='M199 376L112 402 64 318 131 256 64 194 112 110 199 136 220 48 316 48 337 136 424 110 472 194 405 256 472 318 424 402 337 376 316 464 220 464 199 376ZM269 320Q296 320 314 301 331 282 331 254 331 227 313 210 295 192 269 192 241 192 223 210 204 228 204 256 204 283 223 302 241 320 269 320Z' />
  </svg>
);

export const SettingsIcon = ({ style }: { style?: React.CSSProperties }) => (
  <svg viewBox='0 0 15 15' style={style} fill='none'>
    <path
      d='M5.944 0.5L5.858 0.936707L5.52901 2.53467C5.00301 2.73554 4.526 3.02037 4.095 3.35815L2.487 2.8205L2.05501 2.68658L1.83101 3.07233L0.723999 4.9231L0.5 5.3089L0.828003 5.5957L2.07201 6.65399C2.02701 6.93081 1.96901 7.20461 1.96901 7.49542C1.96901 7.78623 2.02701 8.0601 2.07201 8.33691L0.828003 9.3952L0.5 9.68201L0.723999 10.0677L1.83101 11.9186L2.05501 12.3053L2.487 12.1704L4.095 11.6328C4.526 11.9705 5.00301 12.2553 5.52901 12.4562L5.858 14.0541L5.944 14.4909H9.05501L9.142 14.0541L9.47 12.4562C9.996 12.2553 10.473 11.9705 10.904 11.6328L12.512 12.1704L12.944 12.3053L13.169 11.9186L14.275 10.0677L14.5 9.68201L14.171 9.3952L12.927 8.33691C12.973 8.0601 13.03 7.78623 13.03 7.49542C13.03 7.20461 12.973 6.93081 12.927 6.65399L14.171 5.5957L14.5 5.3089L14.275 4.9231L13.169 3.07233L12.944 2.68658L12.512 2.8205L10.904 3.35815C10.473 3.02037 9.996 2.73554 9.47 2.53467L9.142 0.936707L9.05501 0.5H5.944Z'
      strokeLinecap='square'
      strokeLinejoin='round'
    />
    <path
      d='M9.49963 7.49542C9.49963 8.5987 8.60363 9.49414 7.49963 9.49414C6.39563 9.49414 5.49963 8.5987 5.49963 7.49542C5.49963 6.39214 6.39563 5.49677 7.49963 5.49677C8.60363 5.49677 9.49963 6.39214 9.49963 7.49542Z'
      strokeLinecap='square'
      strokeLinejoin='round'
    />
  </svg>
);

export const ActiveBookmarkIcon = ({ style }: { style?: React.CSSProperties }) => (
  <svg viewBox='0 0 28 28' style={style} fill='currentColor'>
    <path d='M23.5 4a1.5 1.5 0 110 3h-19a1.5 1.5 0 110-3h19zm0 18a1.5 1.5 0 110 3h-19a1.5 1.5 0 110-3h19zm0-9a1.5 1.5 0 110 3h-19a1.5 1.5 0 110-3h19z' />
  </svg>
);

export const BookmarkIcon = ({ style }: { style?: React.CSSProperties }) => (
  <svg viewBox='0 0 28 28' style={style} fill='currentColor'>
    <path d='M23.5 4a1.5 1.5 0 110 3h-19a1.5 1.5 0 110-3h19zm0 18a1.5 1.5 0 110 3h-19a1.5 1.5 0 110-3h19zm0-9a1.5 1.5 0 110 3h-19a1.5 1.5 0 110-3h19z' />
  </svg>
);

export const PlusIcon = ({ style }: { style?: React.CSSProperties }) => (
  <svg viewBox='0 0 24 24' style={style} fill='currentColor'>
    <path d='M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM16 12.75H12.75V16C12.75 16.41 12.41 16.75 12 16.75C11.59 16.75 11.25 16.41 11.25 16V12.75H8C7.59 12.75 7.25 12.41 7.25 12C7.25 11.59 7.59 11.25 8 11.25H11.25V8C11.25 7.59 11.59 7.25 12 7.25C12.41 7.25 12.75 7.59 12.75 8V11.25H16C16.41 11.25 16.75 11.59 16.75 12C16.75 12.41 16.41 12.75 16 12.75Z' />
  </svg>
);

export const MinusIcon = ({ style }: { style?: React.CSSProperties }) => (
  <svg viewBox='0 0 24 24' style={style} fill='currentColor'>
    <path d='M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM16 12.75H8C7.59 12.75 7.25 12.41 7.25 12C7.25 11.59 7.59 11.25 8 11.25H16C16.41 11.25 16.75 11.59 16.75 12C16.75 12.41 16.41 12.75 16 12.75Z' />
  </svg>
);

export const MoreIcon = ({ style }: { style?: React.CSSProperties }) => (
  // https://www.svgrepo.com/svg/495525/more-square
  <svg viewBox='0 0 24 24' style={style} fill='currentColor'>
    <path d='M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM8 13C7.44 13 7 12.55 7 12C7 11.45 7.45 11 8 11C8.55 11 9 11.45 9 12C9 12.55 8.56 13 8 13ZM12 13C11.44 13 11 12.55 11 12C11 11.45 11.45 11 12 11C12.55 11 13 11.45 13 12C13 12.55 12.56 13 12 13ZM16 13C15.44 13 15 12.55 15 12C15 11.45 15.45 11 16 11C16.55 11 17 11.45 17 12C17 12.55 16.56 13 16 13Z' />
  </svg>
);

export const CopyIcon = ({ style }: { style?: React.CSSProperties }) => (
  // https://www.svgrepo.com/svg/495196/copy
  <svg viewBox='0 0 24 24' style={style} fill='currentColor'>
    <path d='M16 12.9V17.1C16 20.6 14.6 22 11.1 22H6.9C3.4 22 2 20.6 2 17.1V12.9C2 9.4 3.4 8 6.9 8H11.1C14.6 8 16 9.4 16 12.9Z' />
    <path d='M17.0998 2H12.8998C9.81668 2 8.37074 3.09409 8.06951 5.73901C8.00649 6.29235 8.46476 6.75 9.02167 6.75H11.0998C15.2998 6.75 17.2498 8.7 17.2498 12.9V14.9781C17.2498 15.535 17.7074 15.9933 18.2608 15.9303C20.9057 15.629 21.9998 14.1831 21.9998 11.1V6.9C21.9998 3.4 20.5998 2 17.0998 2Z' />
  </svg>
);

export const GalleryIcon = ({ style }: { style?: React.CSSProperties }) => (
  // https://www.svgrepo.com/svg/495328/gallery
  <svg viewBox='0 0 24 24' style={style} fill='currentColor'>
    <path d='M2.58078 19.0112L2.56078 19.0312C2.29078 18.4413 2.12078 17.7713 2.05078 17.0312C2.12078 17.7613 2.31078 18.4212 2.58078 19.0112Z' />
    <path d='M9.00109 10.3811C10.3155 10.3811 11.3811 9.31553 11.3811 8.00109C11.3811 6.68666 10.3155 5.62109 9.00109 5.62109C7.68666 5.62109 6.62109 6.68666 6.62109 8.00109C6.62109 9.31553 7.68666 10.3811 9.00109 10.3811Z' />
    <path d='M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.19C2 17.28 2.19 18.23 2.56 19.03C3.42 20.93 5.26 22 7.81 22H16.19C19.83 22 22 19.83 22 16.19V13.9V7.81C22 4.17 19.83 2 16.19 2ZM20.37 12.5C19.59 11.83 18.33 11.83 17.55 12.5L13.39 16.07C12.61 16.74 11.35 16.74 10.57 16.07L10.23 15.79C9.52 15.17 8.39 15.11 7.59 15.65L3.85 18.16C3.63 17.6 3.5 16.95 3.5 16.19V7.81C3.5 4.99 4.99 3.5 7.81 3.5H16.19C19.01 3.5 20.5 4.99 20.5 7.81V12.61L20.37 12.5Z' />
  </svg>
);

export const Gallery2Icon = ({ style }: { style?: React.CSSProperties }) => (
  <svg xmlns='http://www.w3.org/2000/svg' style={style} viewBox='0 0 16 16'>
    <path d='M4.502 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z' />
    <path d='M14.002 13a2 2 0 0 1-2 2h-10a2 2 0 0 1-2-2V5A2 2 0 0 1 2 3a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v8a2 2 0 0 1-1.998 2zM14 2H4a1 1 0 0 0-1 1h9.002a2 2 0 0 1 2 2v7A1 1 0 0 0 15 11V3a1 1 0 0 0-1-1zM2.002 4a1 1 0 0 0-1 1v8l2.646-2.354a.5.5 0 0 1 .63-.062l2.66 1.773 3.71-3.71a.5.5 0 0 1 .577-.094l1.777 1.947V5a1 1 0 0 0-1-1h-10z' />
  </svg>
);

export const GalleryAddIcon = ({ style }: { style?: React.CSSProperties }) => (
  // https://www.svgrepo.com/svg/495327/gallery-add
  <svg viewBox='0 0 24 24' style={style} fill='currentColor'>
    <path d='M20.97 1H18.03C17.16 1 16.52 1.36 16.23 2C16.07 2.29 16 2.63 16 3.03V5.97C16 7.24 16.76 8 18.03 8H20.97C21.37 8 21.71 7.93 22 7.77C22.64 7.48 23 6.84 23 5.97V3.03C23 1.76 22.24 1 20.97 1ZM21.91 4.93C21.81 5.03 21.66 5.1 21.5 5.11H20.09V5.62L20.1 6.5C20.09 6.67 20.03 6.81 19.91 6.93C19.81 7.03 19.66 7.1 19.5 7.1C19.17 7.1 18.9 6.83 18.9 6.5V5.1L17.5 5.11C17.17 5.11 16.9 4.83 16.9 4.5C16.9 4.17 17.17 3.9 17.5 3.9L18.38 3.91H18.9V2.51C18.9 2.18 19.17 1.9 19.5 1.9C19.83 1.9 20.1 2.18 20.1 2.51L20.09 3.22V3.9H21.5C21.83 3.9 22.1 4.17 22.1 4.5C22.09 4.67 22.02 4.81 21.91 4.93Z' />
    <path d='M9.00109 10.3811C10.3155 10.3811 11.3811 9.31553 11.3811 8.00109C11.3811 6.68666 10.3155 5.62109 9.00109 5.62109C7.68666 5.62109 6.62109 6.68666 6.62109 8.00109C6.62109 9.31553 7.68666 10.3811 9.00109 10.3811Z' />
    <path d='M20.97 8H20.5V12.61L20.37 12.5C19.59 11.83 18.33 11.83 17.55 12.5L13.39 16.07C12.61 16.74 11.35 16.74 10.57 16.07L10.23 15.79C9.52 15.17 8.39 15.11 7.59 15.65L3.85 18.16C3.63 17.6 3.5 16.95 3.5 16.19V7.81C3.5 4.99 4.99 3.5 7.81 3.5H16V3.03C16 2.63 16.07 2.29 16.23 2H7.81C4.17 2 2 4.17 2 7.81V16.19C2 17.28 2.19 18.23 2.56 19.03C3.42 20.93 5.26 22 7.81 22H16.19C19.83 22 22 19.83 22 16.19V7.77C21.71 7.93 21.37 8 20.97 8Z' />
  </svg>
);

export const GalleryExportIcon = ({ style }: { style?: React.CSSProperties }) => (
  // https://www.svgrepo.com/svg/495330/gallery-export
  <svg viewBox='0 0 24 24' style={style} fill='currentColor'>
    <path d='M20.97 1H18.03C16.76 1 16 1.76 16 3.03V5.97C16 7.24 16.76 8 18.03 8H20.97C22.24 8 23 7.24 23 5.97V3.03C23 1.76 22.24 1 20.97 1ZM21.19 4.31C21.07 4.43 20.91 4.49 20.75 4.49C20.59 4.49 20.43 4.43 20.31 4.31L20.13 4.13V6.37C20.13 6.72 19.85 7 19.5 7C19.15 7 18.87 6.72 18.87 6.37V4.13L18.69 4.31C18.45 4.55 18.05 4.55 17.81 4.31C17.57 4.07 17.57 3.67 17.81 3.43L19.06 2.18C19.11 2.13 19.18 2.09 19.25 2.06C19.27 2.05 19.29 2.05 19.31 2.04C19.36 2.02 19.41 2.01 19.47 2.01C19.49 2.01 19.51 2.01 19.53 2.01C19.6 2.01 19.66 2.02 19.73 2.05C19.74 2.05 19.74 2.05 19.75 2.05C19.82 2.08 19.88 2.12 19.93 2.17C19.94 2.18 19.94 2.18 19.95 2.18L21.2 3.43C21.44 3.67 21.44 4.07 21.19 4.31Z' />
    <path d='M9.00109 10.3811C10.3155 10.3811 11.3811 9.31553 11.3811 8.00109C11.3811 6.68666 10.3155 5.62109 9.00109 5.62109C7.68666 5.62109 6.62109 6.68666 6.62109 8.00109C6.62109 9.31553 7.68666 10.3811 9.00109 10.3811Z' />
    <path d='M20.97 8H20.5V12.61L20.37 12.5C19.59 11.83 18.33 11.83 17.55 12.5L13.39 16.07C12.61 16.74 11.35 16.74 10.57 16.07L10.23 15.79C9.52 15.17 8.39 15.11 7.59 15.65L3.85 18.16C3.63 17.6 3.5 16.95 3.5 16.19V7.81C3.5 4.99 4.99 3.5 7.81 3.5H16V3.03C16 2.63 16.07 2.29 16.23 2H7.81C4.17 2 2 4.17 2 7.81V16.19C2 17.28 2.19 18.23 2.56 19.03C3.42 20.93 5.26 22 7.81 22H16.19C19.83 22 22 19.83 22 16.19V7.77C21.71 7.93 21.37 8 20.97 8Z' />
  </svg>
);

export const GalleryRemoveIcon = ({ style }: { style?: React.CSSProperties }) => (
  // https://www.svgrepo.com/svg/495332/gallery-slash
  <svg viewBox='0 0 24 24' style={style} fill='currentColor'>
    <path d='M21.7689 2.22891C21.4689 1.92891 20.9789 1.92891 20.6789 2.22891L2.22891 20.6889C1.92891 20.9889 1.92891 21.4789 2.22891 21.7789C2.37891 21.9189 2.56891 21.9989 2.76891 21.9989C2.96891 21.9989 3.15891 21.9189 3.30891 21.7689L21.7689 3.30891C22.0789 3.00891 22.0789 2.52891 21.7689 2.22891Z' />
    <path d='M9.00109 10.3811C10.3155 10.3811 11.3811 9.31553 11.3811 8.00109C11.3811 6.68666 10.3155 5.62109 9.00109 5.62109C7.68666 5.62109 6.62109 6.68666 6.62109 8.00109C6.62109 9.31553 7.68666 10.3811 9.00109 10.3811Z' />
    <path d='M21.6084 5.39062L20.3884 6.61063C20.4684 6.98063 20.4984 7.38063 20.4984 7.81063V12.6106L20.3684 12.5006C19.5884 11.8306 18.3284 11.8306 17.5484 12.5006L13.3884 16.0706C12.6784 16.6806 11.5584 16.7406 10.7784 16.2206L5.39844 21.6006C5.43844 21.6206 5.47844 21.6306 5.51844 21.6406C5.78844 21.7306 6.05844 21.8006 6.34844 21.8606C6.44844 21.8806 6.54844 21.9006 6.65844 21.9106C7.02844 21.9606 7.40844 22.0006 7.80844 22.0006H16.1884C19.8284 22.0006 21.9984 19.8306 21.9984 16.1906V7.81063C21.9984 6.91063 21.8684 6.10062 21.6084 5.39062Z' />
    <path d='M21.6106 5.39062L20.3906 6.61063C20.4706 6.98063 20.5006 7.38063 20.5006 7.81063V12.6106L20.3706 12.5006C19.5906 11.8306 18.3306 11.8306 17.5506 12.5006L13.3906 16.0706C12.6806 16.6806 11.5606 16.7406 10.7806 16.2206L5.39062 21.6106C6.10062 21.8706 6.91063 22.0006 7.81063 22.0006H16.1906C19.8306 22.0006 22.0006 19.8306 22.0006 16.1906V7.81063C22.0006 6.91063 21.8706 6.10062 21.6106 5.39062Z' />
    <path d='M20.46 3.54L19.4 4.6C18.68 3.87 17.6 3.5 16.19 3.5H7.81C4.99 3.5 3.5 4.99 3.5 7.81V16.19C3.5 16.95 3.63 17.6 3.85 18.16L7.59 15.65C7.93 15.42 8.32 15.3 8.71 15.29L3.54 20.46C3.14 20.06 2.81 19.58 2.56 19.03C2.19 18.23 2 17.28 2 16.19V7.81C2 4.17 4.17 2 7.81 2H16.19C18.01 2 19.46 2.54 20.46 3.54Z' />
  </svg>
);

export const TaskSquareIcon = ({ style }: { style?: React.CSSProperties }) => (
  // https://www.svgrepo.com/svg/495759/task-square
  <svg viewBox='0 0 24 24' style={style} fill='currentColor'>
    <path d='M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.19C2 19.83 4.17 22 7.81 22H16.19C19.83 22 22 19.83 22 16.19V7.81C22 4.17 19.83 2 16.19 2ZM9.97 14.9L7.72 17.15C7.57 17.3 7.38 17.37 7.19 17.37C7 17.37 6.8 17.3 6.66 17.15L5.91 16.4C5.61 16.11 5.61 15.63 5.91 15.34C6.2 15.05 6.67 15.05 6.97 15.34L7.19 15.56L8.91 13.84C9.2 13.55 9.67 13.55 9.97 13.84C10.26 14.13 10.26 14.61 9.97 14.9ZM9.97 7.9L7.72 10.15C7.57 10.3 7.38 10.37 7.19 10.37C7 10.37 6.8 10.3 6.66 10.15L5.91 9.4C5.61 9.11 5.61 8.63 5.91 8.34C6.2 8.05 6.67 8.05 6.97 8.34L7.19 8.56L8.91 6.84C9.2 6.55 9.67 6.55 9.97 6.84C10.26 7.13 10.26 7.61 9.97 7.9ZM17.56 16.62H12.31C11.9 16.62 11.56 16.28 11.56 15.87C11.56 15.46 11.9 15.12 12.31 15.12H17.56C17.98 15.12 18.31 15.46 18.31 15.87C18.31 16.28 17.98 16.62 17.56 16.62ZM17.56 9.62H12.31C11.9 9.62 11.56 9.28 11.56 8.87C11.56 8.46 11.9 8.12 12.31 8.12H17.56C17.98 8.12 18.31 8.46 18.31 8.87C18.31 9.28 17.98 9.62 17.56 9.62Z' />
  </svg>
);

export const RightRotateIcon = ({ style }: { style?: React.CSSProperties }) => (
  // https://www.svgrepo.com/svg/533711/rotate-cw
  <svg viewBox='0 0 24 24' style={style}>
    <path
      d='M11.5 20.5C6.80558 20.5 3 16.6944 3 12C3 7.30558 6.80558 3.5 11.5 3.5C16.1944 3.5 20 7.30558 20 12C20 13.5433 19.5887 14.9905 18.8698 16.238M22.5 15L18.8698 16.238M17.1747 12.3832L18.5289 16.3542L18.8698 16.238'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export const SpiderIcon = ({ style }: { style?: React.CSSProperties }) => (
  <svg fill='none' opacity='.5'>
    <circle fill='#000' cx='61' cy='20' r='2' />
    <circle fill='#000' cx='53' cy='23' r='2' />
    <ellipse stroke='#000' strokeWidth='2' cx='50' cy='20' rx='19' ry='12' />
    <path stroke='#000' strokeWidth='2' d='M67 15l14-14L90 12' />
    <path stroke='#000' strokeWidth='2' d='M69 20L82 16 98 27M65 0' />
    <path stroke='#000' strokeWidth='2' d='M69 24l13 5L95 50M71 0' />
    <path stroke='#000' strokeWidth='2' d='M62 29l12 4L80 60M31 0' />
    <path stroke='#000' strokeWidth='2' d='M42 31l-16 15V60M62 0' />
    <path stroke='#000' strokeWidth='2' d='M34 27l-14 2L10 50M68 0' />
    <path stroke='#000' strokeWidth='2' d='M32 18L14 15 0 30M35 0' />
    <path stroke='#000' strokeWidth='2' d='M39 10L24 1 3 5' />
  </svg>
);
