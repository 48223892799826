import React, { useState, useCallback } from 'react';
import { Container } from 'react-bootstrap';
import Common from '../../shared/Common';
import type { IQuantityType } from '../../../interfaces/IQuantityType';
import type { ColumnDef } from '@tanstack/react-table';
import AddQuantityTypeModal from './AddQuantityTypeModal';
import QuantityTypesTable from './QuantityTypesTable';
import type { IQuantityTypeData } from '../../../interfaces/IQuantityTypeData';

import './style.css';

const QuantityTypes = () => {
  const [quantityTypes, setQuantityTypes] = useState<IQuantityTypeData[]>([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [removeButtonDisabled, setRemoveButtonDisabled] = useState(true);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const [updatedQuantityTypes, setUpdatedQuantityTypes] = useState<IQuantityType[]>([]);
  const [selectedRowId, setSelectedRowId] = useState(-1);
  const [selectedQuantityType, setSelectedQuantityType] = useState<IQuantityTypeData | null>(null);
  const [fetchTrigger, setFetchTrigger] = useState(0);
  const fetchIdRef = React.useRef(0);

  const fetchData = useCallback(async () => {
    const fetchId = ++fetchIdRef.current;
    setLoading(true);
    const response = await Common.authorizedFetch('api/quantityTypes/getQuantityTypesData');
    const data = await response.json();
    if (data.success) {
      if (fetchId === fetchIdRef.current) {
        setQuantityTypes(data.result.quantityTypes);
        setLoading(false);
      }
      setRemoveButtonDisabled(true);
      setSaveButtonDisabled(true);
      setSelectedQuantityType(null);
      setUpdatedQuantityTypes([]);
      setSelectedRowId(-1);
    }
  }, []);

  const columns = React.useMemo<ColumnDef<IQuantityTypeData>[]>(
    () => [
      {
        header: () => <span>Nazwa</span>,
        accessorFn: (row) => row.quantityType?.name,
        id: 'name',
      },
      {
        header: () => <span>Skrócona nazwa</span>,
        accessorFn: (row) => row.quantityType?.shortName,
        id: 'shortName',
      },
      {
        header: () => <span>Wykorzystano</span>,
        id: 'count',
        cell: (info) => info.row.original.count,
      },
      {
        header: () => <span>Zapłacono</span>,
        id: 'totalCost',
        cell: (info) =>
          Common.Utils.getNumberWithSpaces((Math.round(info.row.original.totalCost * 100) / 100).toFixed(2)),
      },
    ],
    []
  );

  const updateQuantityTypeName = (rowIndex: number, columnId: string, value: any) => {
    setQuantityTypes((prevState) => {
      const updated = prevState;
      if (updated[rowIndex].quantityType.quantityTypeId !== 1 && updated[rowIndex].quantityType.name !== value) {
        const ind = updatedQuantityTypes.findIndex(
          (l) => l.quantityTypeId === updated[rowIndex].quantityType.quantityTypeId
        );
        if (ind === -1) {
          updatedQuantityTypes.push({
            quantityTypeId: updated[rowIndex].quantityType.quantityTypeId,
            name: value,
            shortName: updated[rowIndex].quantityType.shortName,
          });
        } else {
          updatedQuantityTypes[ind].name = value;
        }
        setUpdatedQuantityTypes(updatedQuantityTypes);
      }
      updated[rowIndex] = {
        ...prevState[rowIndex],
        [columnId]: value,
      };

      return updated;
    });
  };

  const updateQuantityTypeShortName = (rowIndex: number, columnId: string, value: any) => {
    setQuantityTypes((prevState) => {
      const updated = prevState;
      if (updated[rowIndex].quantityType.quantityTypeId !== 1 && updated[rowIndex].quantityType.shortName !== value) {
        const ind = updatedQuantityTypes.findIndex(
          (l) => l.quantityTypeId === updated[rowIndex].quantityType.quantityTypeId
        );
        if (ind === -1) {
          updatedQuantityTypes.push({
            quantityTypeId: updated[rowIndex].quantityType.quantityTypeId,
            name: updated[rowIndex].quantityType.name,
            shortName: value,
          });
        } else {
          updatedQuantityTypes[ind].shortName = value;
        }
        setUpdatedQuantityTypes(updatedQuantityTypes);
      }
      updated[rowIndex] = {
        ...prevState[rowIndex],
        [columnId]: value,
      };

      return updated;
    });
  };

  return (
    <Container className='mt-2'>
      <h2 className='mb-3' id='tabelLabel'>
        Jednostki
      </h2>
      {Common.Ui.showLoadingSpinnerFixed(showSpinner)}
      <QuantityTypesTable
        columns={columns}
        data={quantityTypes}
        loading={loading}
        setShowSpinner={setShowSpinner}
        fetchData={fetchData}
        fetchTrigger={fetchTrigger}
        setFetchTrigger={setFetchTrigger}
        updateQuantityTypeName={updateQuantityTypeName}
        updateQuantityTypeShortName={updateQuantityTypeShortName}
        updatedQuantityTypes={updatedQuantityTypes}
        setShowModal={setShowModal}
        saveButtonDisabled={saveButtonDisabled}
        removeButtonDisabled={removeButtonDisabled}
        setSaveButtonDisabled={setSaveButtonDisabled}
        setRemoveButtonDisabled={setRemoveButtonDisabled}
        selectedRowId={selectedRowId}
        setSelectedRowId={setSelectedRowId}
        selectedQuantityType={selectedQuantityType}
        setSelectedQuantityType={setSelectedQuantityType}
      />
      <AddQuantityTypeModal
        show={showModal}
        onHide={() => {
          setShowModal(false);
        }}
        onSave={() => {
          setFetchTrigger(fetchTrigger + 1);
        }}
      />
    </Container>
  );
};

export default QuantityTypes;
