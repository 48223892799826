import { useState } from 'react';
import Autosuggest from 'react-autosuggest';
import type { ILocation } from '../../../interfaces/ILocation';
import '../custom-react-autosuggest.css';
import { Container, Row, Col } from 'react-bootstrap';
import React from 'react';

export interface ILocationsAutosuggestProps {
  selectedValue: ILocation | undefined;
  locations: ILocation[];
  onChange: (value: ILocation) => void;
  inputClassName?: string;
}

function LocationsAutosuggest({ selectedValue, onChange, locations, inputClassName }: ILocationsAutosuggestProps) {
  if (selectedValue === undefined) {
    selectedValue = {
      locationId: -1,
      locationName: '',
      picture: '',
      mappings: [],
    };
  } else {
    selectedValue.locationId = -1;
  }

  const [value, setValue] = useState(selectedValue.locationName);
  const [suggestions, setSuggestions] = useState<string[]>([]);

  function getSuggestions(value: string): string[] {
    const filteredLocations = locations.filter((location) =>
      location.locationName.toLowerCase().includes(value.trim().toLowerCase())
    );
    return filteredLocations.map((location) => JSON.stringify(location));
  }

  const theme = inputClassName
    ? {
        container: 'autosuggest',
        input: inputClassName,
        suggestionsContainer: 'dropdown',
        suggestionsList: `dropdown-menu ${suggestions.length ? 'show' : ''}`,
        suggestion: 'dropdown-item __suggestreact-autosuggestion',
        suggestionHighlighted: 'active',
      }
    : undefined;

  const renderSuggestion = (suggestionJson: string, { query }: any) => {
    const HighlightText = ({ text, query }: any) => {
      if (!query) {
        return text;
      }

      return (
        <React.Fragment>
          {text.split(new RegExp(`(${query})`, 'gi')).map((segment: any, i: any) =>
            segment.toLowerCase() === query ? (
              <span key={i} className='fw-bold autosuggest-highlight autosuggest-active-highlight'>
                {segment}
              </span>
            ) : (
              <span key={i} className='fw-bold'>
                {segment}
              </span>
            )
          )}
        </React.Fragment>
      );
    };
    const userInput = (query as string).trim().toLowerCase();

    const location = JSON.parse(suggestionJson) as ILocation;
    const suggestionUsedCount = location.billCount ?? 0;
    return (
      <Container>
        <Row style={{ width: 'max-content' }}>
          <Col
            style={{
              flex: '0 0',
              padding: '0px',
            }}
          >
            <img
              src={location.picture ? location.picture : '/Static/icons/dashboard-navigation/bills.png'}
              style={{
                height: '24px',
                width: '24px',
              }}
              alt=''
            />
          </Col>
          <Col>
            <HighlightText text={location.locationName} query={userInput} />
            <span> ({suggestionUsedCount})</span>
          </Col>
        </Row>
      </Container>
    );
  };

  return (
    <div>
      <Autosuggest
        suggestions={suggestions}
        theme={theme}
        onSuggestionsClearRequested={() => setSuggestions([])}
        onSuggestionsFetchRequested={({ value }) => {
          setValue(value);
          setSuggestions(getSuggestions(value));
        }}
        onSuggestionSelected={(_, { suggestionValue }) => {
          const location: ILocation = JSON.parse(suggestionValue);
          setValue(location.locationName);
          onChange(location);
        }}
        getSuggestionValue={(suggestion) => suggestion}
        renderSuggestion={renderSuggestion}
        inputProps={{
          placeholder: 'Lokalizacja',
          value: value,
          onChange: (_, { newValue, method }) => {
            setValue(newValue);
            const location: ILocation = {
              locationId: -1,
              locationName: newValue,
              picture: '',
              mappings: [],
            };
            onChange(location);
          },
        }}
      />
    </div>
  );
}

export default LocationsAutosuggest;
