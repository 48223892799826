import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { UserContext } from '../../App';
import { ApplicationPaths, QueryParameterNames } from './ApiAuthorizationConstants';

interface IAuthorizeRouteProps {
  redirectPath: string;
  component: JSX.Element;
}

const AuthorizeRoute = (props: IAuthorizeRouteProps) => {
  const { ready, authenticated } = useContext(UserContext);
  var link = document.createElement('a');
  link.href = props.redirectPath;
  const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
  const returnPath = `${link.pathname}${link.search}${link.hash}`;
  const redirectUrl = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURIComponent(
    returnUrl
  )}&returnPath=${returnPath}`;
  if (!ready) {
    return <div />;
  } else {
    if (authenticated) {
      return props.component;
    } else {
      return <Navigate to={redirectUrl} replace />;
    }
  }
};

export default AuthorizeRoute;
