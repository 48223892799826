import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { Button, Form } from 'react-bootstrap';
import Common from '../../../shared/Common';
import { SortUpIcon, SortDownIcon, MinusIcon, PlusIcon } from '../../../shared/icons/Svgs';
import type { ColumnDef, SortingState } from '@tanstack/react-table';
import type { IStartingLocation } from './NewAccounts';
import DefaultLocationPictures from '../../../shared/default-location-pictures/DefaultLocationPictures';
import type { IDefaultLocationPicture } from '../../../../interfaces/IDefaultLocationPicture';

import './style.css';

export interface IStartingLocationsTableProps {
  locations: IStartingLocation[];
  loading: boolean;
  setLocations: React.Dispatch<React.SetStateAction<IStartingLocation[]>>;
  setLocationsChanged: React.Dispatch<React.SetStateAction<boolean>>;
}

const SettingValueTable = (props: IStartingLocationsTableProps) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [showDefaultPictures, setShowDefaultPictures] = useState<boolean>(false);
  const [locationPictures, setLocationPictures] = useState<IDefaultLocationPicture[]>([]);
  const [selectedStartingLocation, setSelectedStartingLocation] = useState<IStartingLocation>();
  const fetchPicturesIdRef = useRef(0);

  const { locations, setLocations, setLocationsChanged, loading } = props;

  const fetchLocationPictures = useCallback(async () => {
    const fetchId = ++fetchPicturesIdRef.current;
    const response = await Common.authorizedFetch('api/locations/getLocationPictures');
    if (fetchId === fetchPicturesIdRef.current) {
      const data = await response.json();
      if (data.success) {
        setLocationPictures(data.result.locationPictures);
      }
    }
  }, []);

  useEffect(() => {
    fetchLocationPictures();
  }, [fetchLocationPictures]);

  const columns = useMemo<ColumnDef<IStartingLocation>[]>(
    () => [
      {
        id: 'actionButton',
        enableSorting: false,
        accessorFn: (row) => row.startingLocationId,
        header: () => (
          <div
            onClick={() => {
              setLocationsChanged(true);
              setLocations((prevState) => {
                const minIndex = Math.min(0, ...prevState.map((p) => p.startingLocationId)) - 1;
                console.log(minIndex);
                return [...prevState, { startingLocationId: minIndex, locationName: '' }];
              });
            }}
          >
            <PlusIcon style={{ cursor: 'pointer', width: '30px', height: '30px', fill: 'var(--app-main-color)' }} />
          </div>
        ),
        cell: (info) => (
          <div
            onClick={() => {
              setLocationsChanged(true);
              setLocations((prevState) => {
                const selectedValue = info.row.original;
                return prevState.filter((elem) => elem.startingLocationId !== selectedValue.startingLocationId);
              });
            }}
          >
            <MinusIcon style={{ cursor: 'pointer', width: '30px', height: '30px', fill: '#dc3545' }} />
          </div>
        ),
      },
      {
        id: 'locationName',
        enableSorting: true,
        accessorFn: (row) => row.locationName,
        header: () => <span>Nazwa</span>,
      },
      {
        id: 'picture',
        enableSorting: false,
        accessorFn: (row) => row.pictureBase64,
        header: () => <span>Obrazek</span>,
        cell: (info) => (
          <>
            <img
              src={
                info.row.original.pictureBase64 === '' ||
                info.row.original.pictureBase64 === undefined ||
                info.row.original.pictureBase64 === null
                  ? '/Static/icons/avatar.png'
                  : info.row.original.pictureBase64
              }
              height={'75px'}
              width={'75px'}
              alt='avatar'
            />
            <Button
              variant='link'
              onClick={() => {
                setShowDefaultPictures((prev) => !prev);
                setSelectedStartingLocation(info.row.original);
              }}
            >
              Zmień
            </Button>
          </>
        ),
      },
    ],
    [setLocations, setLocationsChanged]
  );

  const defaultColumn: Partial<ColumnDef<IStartingLocation>> = {
    cell: function Cell({ getValue, row: { index }, column: { id }, table }) {
      const initialValue = getValue();
      const [value, setValue] = useState(initialValue);

      const onBlur = () => {
        table.options.meta?.updateData?.(index, id, value);
      };

      useEffect(() => {
        setValue(initialValue);
      }, [initialValue]);

      return (
        <Form.Control
          size='sm'
          type='text'
          onChange={(e) => {
            setValue(e.target.value);
            setLocationsChanged(true);
          }}
          onBlur={onBlur}
          value={value as string}
        />
      );
    },
  };

  var items = locations ?? [];

  const table = useReactTable({
    data: items,
    columns,
    defaultColumn,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    meta: {
      updateData: (rowIndex, columnId, value) => {
        setLocations((prevState) => {
          prevState[rowIndex] = {
            ...prevState[rowIndex],
            [columnId]: value,
          };

          return [...prevState];
        });
      },
    },
  });

  const onPictureSelected = (selectedPicture: IDefaultLocationPicture | undefined) => {
    setLocations((prev) => {
      const index = prev.findIndex((l) => l.startingLocationId === selectedStartingLocation?.startingLocationId);
      if (index > -1) {
        prev[index].pictureBase64 = selectedPicture?.pictureBase64String;
        prev[index].defaultPictureId = selectedPicture?.defaultPictureId;
      }
      return prev;
    });
    setLocationsChanged(true);
  };

  return (
    <>
      <DefaultLocationPictures
        show={showDefaultPictures}
        setShow={setShowDefaultPictures}
        locationPictures={locationPictures}
        onPictureSelected={onPictureSelected}
      />
      <table id={'edited-new-locations-table'} className='table table-sm mt-2' aria-labelledby='tabelLabel'>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder ? null : (
                    <div
                      {...{
                        style: header.column.getCanSort()
                          ? {
                              cursor: 'pointer',
                            }
                          : {},
                        onClick: header.column.getToggleSortingHandler(),
                      }}
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {{
                        asc: <SortUpIcon style={{ marginLeft: '5px' }} />,
                        desc: <SortDownIcon style={{ marginLeft: '5px' }} />,
                      }[header.column.getIsSorted() as string] ?? null}
                    </div>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {loading
            ? Common.Ui.getSkeletonTableRows(5, 3, 35)
            : table.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                  ))}
                </tr>
              ))}
        </tbody>
      </table>
    </>
  );
};

export default SettingValueTable;
