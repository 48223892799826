import React, { useState, useCallback } from 'react';
import { Container } from 'react-bootstrap';
import Common from '../../shared/Common';
import type { ISummaryInfo } from '../../../interfaces/ISummaryInfo';
import SummariesTable from './SummariesTable';
import ApplicationAlert from '../../shared/application-alert/ApplicationAlert';
import type { IApplicationAlert } from '../../shared/application-alert/ApplicationAlert';

import './style.css';

const Summaries = () => {
  const [summaries, setRecurringOperations] = useState<ISummaryInfo[]>([]);
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState<IApplicationAlert>();
  const [fetchTrigger, setFetchTrigger] = useState(0);
  const fetchIdRef = React.useRef(0);

  const fetchData = useCallback(async () => {
    const fetchId = ++fetchIdRef.current;
    setLoading(true);
    const summariesResponse = await Common.authorizedFetch('api/summaries/getSummariesInfos');
    const summariesData = await summariesResponse.json();
    setLoading(false);
    if (summariesData.success) {
      if (fetchId === fetchIdRef.current) {
        setRecurringOperations(summariesData.result.summaries);
      }
    }
  }, []);

  return (
    <Container className='mt-2'>
      {status && (
        <ApplicationAlert variant={status?.variant} status={status?.status} messageTime={status?.messageTime} />
      )}
      <h2 className='mb-3' id='tabelLabel'>
        Podsumowania
      </h2>
      <SummariesTable
        data={summaries}
        loading={loading}
        setLoading={setLoading}
        fetchData={fetchData}
        fetchTrigger={fetchTrigger}
        setFetchTrigger={setFetchTrigger}
        status={status}
        setStatus={setStatus}
      />
    </Container>
  );
};

export default Summaries;
