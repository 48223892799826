import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { PropagateLoader } from 'react-spinners';
import { QueryParameterNames, LogoutActions, ApplicationPaths } from './ApiAuthorizationConstants';
import authService, { AuthenticationResultStatus } from './AuthorizeService';

// The main responsibility of this component is to handle the user's logout process.
// This is the starting point for the logout process, which is usually initiated when a
// user clicks on the logout button on the LoginMenu component.
const Logout = (props: any) => {
  const [message, setMessage] = useState<string | undefined>(undefined);
  const [isReady, setIsReady] = useState<boolean>(false);
  const location = useLocation();

  useEffect(() => {
    const logout = async (returnUrl: string) => {
      const state = { returnUrl };
      const isauthenticated = await authService.isAuthenticated();
      if (isauthenticated) {
        const result = await authService.signOut(state);
        switch (result.status) {
          case AuthenticationResultStatus.Redirect:
            break;
          case AuthenticationResultStatus.Success:
            await navigateToReturnUrl(returnUrl);
            break;
          case AuthenticationResultStatus.Fail:
            setMessage(result.message);
            break;
          default:
            throw new Error('Invalid authentication result status.');
        }
      } else {
        setMessage('You successfully logged out!');
      }
    };

    const processLogoutCallback = async () => {
      const url = window.location.href;
      const result = await authService.completeSignOut(url);
      switch (result.status) {
        case AuthenticationResultStatus.Redirect:
          // There should not be any redirects as the only time completeAuthentication finishes
          // is when we are doing a redirect sign in flow.
          throw new Error('Should not redirect.');
        case AuthenticationResultStatus.Success:
          await navigateToReturnUrl(getReturnUrl(result.state));
          break;
        case AuthenticationResultStatus.Fail:
          setMessage(result.message);
          break;
        default:
          throw new Error('Invalid authentication result status.');
      }
    };

    const populateAuthenticationState = async () => {
      await authService.isAuthenticated();
      setIsReady(true);
    };

    const action = props.action;
    switch (action) {
      case LogoutActions.Logout:
        if (!!location.state.local) {
          logout(getReturnUrl(undefined));
        } else {
          // This prevents regular links to <app>/authentication/logout from triggering a logout
          setMessage('The logout was not initiated from within the page.');
          setIsReady(true);
        }
        break;
      case LogoutActions.LogoutCallback:
        processLogoutCallback();
        break;
      case LogoutActions.LoggedOut:
        processLoggedOut();
        break;
      default:
        throw new Error(`Invalid action '${action}'`);
    }

    populateAuthenticationState();
  }, [props.action, location.state]);

  const renderContent = () => {
    if (!isReady) {
      return <div />;
    }
    if (!!message) {
      return <h2>{message}</h2>;
    } else {
      const action = props.action;
      switch (action) {
        case LogoutActions.Logout:
          return <h2 style={{ textAlign: 'center' }}>Wylogowanie</h2>;
        case LogoutActions.LogoutCallback:
          return <h2 style={{ textAlign: 'center' }}>Przekierowanie do wylogowania</h2>;
        case LogoutActions.LoggedOut:
          return <h2>{message}</h2>;
        default:
          throw new Error(`Invalid action '${action}'`);
      }
    }
  };

  return (
    <div
      style={{
        position: 'absolute',
        left: '50%',
        top: '40%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      {renderContent()}
      <PropagateLoader
        color='#343A40'
        loading={true}
        cssOverride={{
          top: '10px',
          left: '45%',
        }}
        size={25}
      />
    </div>
  );
};

const processLoggedOut = () => {
  const redirectUrl = `${window.location.origin}`;
  window.location.replace(redirectUrl);
};

const getReturnUrl = (state: any) => {
  const params = new URLSearchParams(window.location.search);
  const fromQuery = params.get(QueryParameterNames.ReturnUrl);
  if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
    // This is an extra check to prevent open redirects.
    throw new Error('Invalid return url. The return url needs to have the same origin as the current page.');
  }
  return (
    (state && (state.returnUrl as string)) || fromQuery || `${window.location.origin}${ApplicationPaths.LoggedOut}`
  );
};

const navigateToReturnUrl = (returnUrl: string) => window.location.replace(returnUrl);

export default Logout;
