import { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Tooltip } from 'react-tooltip';
import Common from '../shared/Common';
import type { IBillItem } from '../../interfaces/IBillItem';
import type { IDashboardElement } from '../../interfaces/IDashboardElement';
import './style.css';

export interface IDashboardCardProps {
  sortByPrice: boolean;
  showQuantities: boolean;
  showCategories: boolean;
  showGroups: boolean;
  data: IDashboardElement | null;
  onClick: React.MouseEventHandler<HTMLInputElement>;
  id: number;
}

const generateTimeString = (date: Date) => {
  var diffSeconds = Math.floor((Date.now() - date.getTime()) / 1000);
  if (diffSeconds < 0) {
    diffSeconds = 0;
  }
  const diffMinutes = Math.floor(diffSeconds / 60);
  const diffHours = Math.floor(diffMinutes / 60);
  const diffDays = Math.floor(diffHours / 24);
  const diffWeeks = Math.floor(diffDays / 7);
  const diffMonths = Math.floor(diffDays / 30);
  const diffYears = Math.floor(diffDays / 365);

  if (diffSeconds < 60) {
    return `${diffSeconds} sek.`;
  }

  if (diffMinutes < 60) {
    return `${diffMinutes} min.`;
  }

  if (diffHours < 24) {
    return `${diffHours} godz.`;
  }

  if (diffDays < 2) {
    return '1 dzień';
  }

  if (diffDays < 7) {
    return `${diffDays} dni`;
  }

  if (diffWeeks < 5) {
    return `${diffWeeks} tyg.`;
  }

  if (diffMonths < 12) {
    return `${diffMonths} mies.`;
  }

  if (diffYears < 2) {
    return '1 rok';
  }

  if (diffYears < 4) {
    return `${diffYears} lata`;
  }

  return `${diffYears} lat`;
};

const generateDateString = (date: Date) => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  let dayString = (day < 10 ? '0' : '') + day.toString();
  let monthString = (month < 10 ? '0' : '') + month.toString();
  let yearString = year.toString();
  return `${dayString}.${monthString}.${yearString}`;
};

const generateDateWithTimeString = (date: Date) => {
  const minutes = date.getMinutes();
  const hours = date.getHours();
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  let minutesString = (minutes < 10 ? '0' : '') + minutes.toString();
  let hoursString = (hours < 10 ? '0' : '') + hours.toString();
  let dayString = (day < 10 ? '0' : '') + day.toString();
  let monthString = (month < 10 ? '0' : '') + month.toString();
  let yearString = year.toString();
  return `${dayString}.${monthString}.${yearString} ${hoursString}:${minutesString}`;
};

const DashboardCard = (props: IDashboardCardProps) => {
  const [showAllItems, setShowAllItems] = useState<boolean>(false);
  const [pictureIsVertical, setPictureIsVertical] = useState<boolean>(false);
  const elementPicture: string | undefined = props.data?.elementPicture;

  useEffect(() => {
    const setPictureOrientation = async () => {
      if (!!elementPicture) {
        const isPictureVertical = await Common.Images.isPictureVertical(elementPicture);
        setPictureIsVertical(isPictureVertical);
      }
    };
    setPictureOrientation();
  }, [elementPicture]);

  const generateDashboardContent = (items: IBillItem[]) => {
    const maxElementsInList = 6;
    let tooManyElementsInList = items.length > maxElementsInList;
    let currentIndex = 0;
    const itemsToPresent = items.slice();
    if (props.sortByPrice) {
      itemsToPresent.sort((a, b) => (b.cost ?? 0) - (a.cost ?? 0));
    } else {
      itemsToPresent.sort((a, b) => (a.billItemId ?? 0) - (b.billItemId ?? 0));
    }
    const billItemsList = [];
    for (
      ;
      currentIndex < (tooManyElementsInList && !showAllItems ? maxElementsInList : itemsToPresent.length);
      currentIndex++
    ) {
      billItemsList.push(
        <li
          className='list-group-item py-1 d-flex justify-content-between align-items-center dashboard-card-list-element'
          key={currentIndex}
        >
          <Container>
            <Row className='p-0'>
              <span className='me-1 g-0'>
                {(itemsToPresent[currentIndex].description?.length ?? 0) > 250
                  ? itemsToPresent[currentIndex].description?.substring(0, 250).concat('...')
                  : itemsToPresent[currentIndex].description}
              </span>
            </Row>
            <Row>
              {props.showCategories &&
                (!!itemsToPresent[currentIndex].category ? (
                  <span
                    className='badge dashboard-card-badge-category me-1'
                    title={itemsToPresent[currentIndex].category?.categoryFullName}
                  >
                    {itemsToPresent[currentIndex].category?.categoryName}
                  </span>
                ) : (
                  <span className='badge dashboard-card-badge-empty-category me-1'>{'Brak'}</span>
                ))}
              {props.showGroups && (
                <span className='badge dashboard-card-badge-group'>
                  {itemsToPresent[currentIndex].group?.groupFullName}
                </span>
              )}
            </Row>
          </Container>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span className='badge dashboard-card-badge-price ms-1 px-2 py-1'>
              {Common.Utils.getCurrencyString(
                (Math.round((itemsToPresent[currentIndex].cost ?? 0) * 100) / 100).toFixed(2)
              )}
            </span>
            {itemsToPresent[currentIndex].quantityType?.shortName !== 'NA' && props.showQuantities && (
              <span className='badge dashboard-card-badge-quantity ms-1 px-2 py-1' style={{ marginTop: '2px' }}>
                {Number.isInteger(itemsToPresent[currentIndex].quantity ?? 0)
                  ? itemsToPresent[currentIndex].quantity ?? 0
                  : (Math.round((itemsToPresent[currentIndex].quantity ?? 0) * 100) / 100).toFixed(2)}
                &nbsp;
                {itemsToPresent[currentIndex].quantityType?.shortName}
              </span>
            )}
          </div>
        </li>
      );
    }
    if (tooManyElementsInList) {
      billItemsList.push(
        <button
          className='list-group-item list-group-item-action'
          key={currentIndex}
          onClick={(e: any) => {
            e.stopPropagation();
            setShowAllItems(!showAllItems);
          }}
        >
          {showAllItems ? 'Pokaż mniej...' : 'Pokaż więcej...'}
        </button>
      );
    }
    return (
      <ul style={{ width: '100%' }} className='list-group'>
        {billItemsList}
      </ul>
    );
  };

  return (
    <>
      <Container
        className='dashboard-card container-fluid'
        style={props.data ? { borderRadius: '5px' } : { backgroundColor: 'transparent' }}
        fluid
      >
        {!props.data ? (
          <table className='table dashboard-card-table'>
            <tbody>{Common.Ui.getSkeletonTableRows(1, 1, 400)}</tbody>
          </table>
        ) : (
          <Row className='g-0 justify-content-center' onClick={props.onClick}>
            <Col>
              <div className='d-flex h-100 flex-column'>
                <Row
                  className='g-0'
                  style={{
                    flexGrow: 0,
                    paddingTop: '10px',
                    height: '60px',
                  }}
                >
                  <Col
                    style={{
                      flex: '55px 0',
                      paddingLeft: '10px',
                    }}
                  >
                    <img
                      src={
                        !props.data.location?.picture
                          ? '/Static/icons/dashboard-navigation/bills.png'
                          : props.data.location?.picture
                      }
                      style={{
                        height: '36px',
                        width: '36px',
                      }}
                      alt=''
                    />
                  </Col>
                  <Col>
                    <Row
                      className='g-0'
                      style={{
                        color: '#050505',
                        fontSize: '15px',
                        borderTopLeftRadius: '5px',
                        fontWeight: 600,
                      }}
                    >
                      Rachunek nr {props.id} z dnia {generateDateString(new Date(props.data.payTime as string))}
                    </Row>
                    <Row
                      className='g-0'
                      style={{
                        color: '#385898',
                        fontSize: '13px',
                        fontWeight: 400,
                      }}
                    >
                      <span className='dashboard-card-date' id={'dateTip-' + props.data.billId}>
                        {props.data.location?.locationName ?? 'Nieznana'} ·{' '}
                        {generateTimeString(new Date(props.data.creationTime + 'Z'))} temu
                      </span>
                      <Tooltip
                        content={'Dodano ' + generateDateWithTimeString(new Date(props.data.creationTime + 'Z'))}
                        className='dashboard-card-date-tooltip'
                        anchorId={'dateTip-' + props.data.billId}
                        place='bottom'
                      />
                    </Row>
                  </Col>
                </Row>
                <Row className='half-containers g-0' style={{ flexGrow: 1 }}>
                  {generateDashboardContent(props.data.billItems)}
                </Row>
                <Row
                  className='half-containers g-0'
                  style={{
                    flexGrow: 0,
                    borderBottomLeftRadius: '5px',
                    color: '#050505',
                    fontSize: '15px',
                    borderTopLeftRadius: '5px',
                    fontWeight: 600,
                  }}
                >
                  <ul style={{ width: '100%' }} className='list-group'>
                    <h5>
                      <li className='list-group-item d-flex justify-content-between align-items-center'>
                        Razem
                        <span className='badge dashboard-card-badge-price ms-1 px-2'>
                          {Common.Utils.getCurrencyString(
                            (
                              Math.round(
                                props.data.billItems.reduce((partialSum, a) => partialSum + (a.cost ?? 0), 0) * 100
                              ) / 100
                            ).toFixed(2)
                          )}
                        </span>
                      </li>
                    </h5>
                  </ul>
                </Row>
              </div>
            </Col>
            {elementPicture && (
              <Col
                md={pictureIsVertical ? 6 : 12}
                className={
                  pictureIsVertical ? 'dashboard-card-vertical-image-column' : 'dashboard-card-horizontal-image-column'
                }
              >
                <Row className='g-0'>
                  <img
                    className={
                      pictureIsVertical
                        ? 'dashboard-card-vertical-image-column'
                        : 'dashboard-card-horizontal-image-column'
                    }
                    src={elementPicture}
                    alt=''
                  />
                </Row>
              </Col>
            )}
          </Row>
        )}
      </Container>
    </>
  );
};

export default DashboardCard;
