import { useReactTable, getCoreRowModel, flexRender } from '@tanstack/react-table';
import React from 'react';
import { Form } from 'react-bootstrap';
import type { IBill } from '../../../interfaces/IBill';
import type { IBillItem } from '../../../interfaces/IBillItem';
import type { ICategoryName } from '../../../interfaces/ICategoryName';
import type { IGroupName } from '../../../interfaces/IGroupName';
import type { IQuantityType } from '../../../interfaces/IQuantityType';
import CategoryAutosuggest from '../../shared/category-autosuggest/CategoryAutosuggest';
import Common from '../../shared/Common';
import GroupAutosuggest from '../../shared/group-autosuggest/GroupAutosuggest';
import { PlusIcon, MinusIcon } from '../../shared/icons/Svgs';
import type { ColumnDef } from '@tanstack/react-table';

export interface IEditableCellProps {
  value: any;
  row: any;
  column: any;
  updateBill: (rowIndex: number, columnId: string, value: any) => void;
  categoriesNames: ICategoryName[];
  groupsNames: IGroupName[];
}

export interface IBillItemsTableProps {
  billData: IBill;
  setBillData: React.Dispatch<React.SetStateAction<IBill>>;
  setPendingChanges: React.Dispatch<React.SetStateAction<boolean>>;
  quantityTypes: IQuantityType[];
  categoriesNames: ICategoryName[];
  groupsNames: IGroupName[];
  updateBill: (rowIndex: number, columnId: string, value: any) => void;
  showGroups: boolean;
  initialLoading: boolean;
}

function BillItemsTable(props: IBillItemsTableProps) {
  let quantityTypeItems: any[] = [];
  for (let ind = 0; ind !== (props.quantityTypes?.length ?? 0); ind++) {
    quantityTypeItems.push(
      <option key={props.quantityTypes[ind]?.quantityTypeId} value={JSON.stringify(props.quantityTypes[ind])}>
        {props.quantityTypes[ind]?.name ?? ''}
      </option>
    );
  }

  const { setBillData, setPendingChanges } = props;

  const columns = React.useMemo<ColumnDef<IBillItem>[]>(
    () => [
      {
        id: 'actionButton',
        header: () => (
          <div
            title='Dodaj element paragonu'
            onClick={() => {
              setPendingChanges(true);
              setBillData((prevState) => ({
                ...prevState,
                billItems: prevState.billItems.concat([
                  {
                    billItemId: undefined,
                    category: {
                      categoryId: undefined,
                      categoryName: '',
                      categoryFullName: '',
                    },
                    quantityType: {
                      quantityTypeId: 3,
                      shortName: 'szt.',
                      name: 'Sztuk',
                    },
                    description: '',
                    cost: 0,
                    quantity: 1,
                  },
                ]),
              }));
            }}
          >
            <PlusIcon style={{ cursor: 'pointer', width: '30px', height: '30px', fill: 'var(--app-main-color)' }} />
          </div>
        ),
        cell: (info) => (
          <div
            title='Usuń element paragonu'
            onClick={() => {
              setPendingChanges(true);
              setBillData((prevState) => {
                const selectedBillItems = [info.row.original];
                return {
                  ...prevState,
                  billItems: prevState.billItems.filter((item) => !selectedBillItems.includes(item)),
                };
              });
            }}
          >
            <MinusIcon style={{ cursor: 'pointer', width: '30px', height: '30px', fill: '#dc3545' }} />
          </div>
        ),
      },
      {
        header: () => <span style={{ userSelect: 'none' }}>Opis</span>,
        accessorFn: (row) => row.description,
        id: 'description',
      },
      {
        header: () => <span style={{ userSelect: 'none' }}>Kategoria</span>,
        accessorFn: (row) => row.category,
        id: 'category',
      },
      {
        header: () => <span style={{ userSelect: 'none' }}>Grupa</span>,
        accessorFn: (row) => row.group,
        id: 'group',
      },
      {
        header: () => <span style={{ userSelect: 'none' }}>Ile</span>,
        accessorFn: (row) => row.quantity,
        id: 'quantity',
      },
      {
        header: () => <span style={{ userSelect: 'none' }}>Jednostka</span>,
        accessorFn: (row) => row.quantityType,
        id: 'quantityType',
      },
      {
        header: () => <span style={{ userSelect: 'none' }}>Koszt</span>,
        accessorFn: (row) => (Math.round((row.cost ?? 0) * 100) / 100).toFixed(2),
        id: 'cost',
      },
    ],
    [setBillData, setPendingChanges]
  );

  const tbodyRef = React.useRef(null);

  const handleKeyDown = (event: any, row: any) => {
    event.stopPropagation();
    if (tbodyRef.current !== null) {
      const currentRow = (tbodyRef.current as any).children.namedItem(row.id);
      switch (event.key) {
        case 'ArrowUp':
          currentRow?.previousElementSibling?.focus();
          break;
        case 'ArrowDown':
          currentRow?.nextElementSibling?.focus();
          break;
        default:
          break;
      }
    }
  };

  const defaultColumn: Partial<ColumnDef<IBillItem>> = {
    cell: function Cell({ getValue, row, column: { id }, table }) {
      const initialValue = getValue();
      const [value, setValue] = React.useState(initialValue);

      const onChange = (e: any) => {
        setPendingChanges(true);
        setValue(e.target.value);
      };

      const onNumberBlur = () => {
        var stringValue = String(value);
        if (!stringValue) {
          stringValue = '0.00';
        }
        stringValue = stringValue.replace(',', '.');
        const numberValue = parseFloat(stringValue);
        setValue(numberValue);
        props.updateBill(row.index, id, numberValue);
      };

      const onQuantityTypeChange = (e: any) => {
        setPendingChanges(true);
        let obj = JSON.parse(e.target.value);
        setValue(obj);
      };

      const onCategoryChange = (value: string) => {
        setPendingChanges(true);
        let obj = JSON.parse(value);
        setValue(obj);
      };

      const onGroupChange = (value: string) => {
        setPendingChanges(true);
        if (value.length === 0) {
          setValue(null);
        } else {
          let obj = JSON.parse(value);
          setValue(obj);
        }
      };

      const onBlur = () => {
        props.updateBill(row.index, id, value);
      };

      React.useEffect(() => {
        setValue(initialValue);
      }, [initialValue]);

      switch (id) {
        case 'billItemId':
          return <>{value}</>;
        case 'description':
          return (
            <Form.Control
              size='sm'
              type='text'
              maxLength={128}
              onChange={onChange}
              onBlur={onBlur}
              value={value as string}
            />
          );
        case 'category':
          return (
            <CategoryAutosuggest
              selectedValue={value as ICategoryName}
              onChange={onCategoryChange}
              onBlur={onBlur}
              categories={table.options.meta?.categoriesNames ?? []}
            />
          );
        case 'group':
          return (
            <GroupAutosuggest
              selectedValue={value as IGroupName}
              onChange={onGroupChange}
              onBlur={onBlur}
              triggerOnChangeWhenEmpty={true}
              groups={table.options.meta?.groupsNames ?? []}
            />
          );
        case 'quantityType':
          return (
            <Form.Control
              size='sm'
              as='select'
              value={JSON.stringify(value)}
              onChange={onQuantityTypeChange}
              onBlur={onBlur}
            >
              {quantityTypeItems}
            </Form.Control>
          );
        case 'quantity':
        case 'cost':
          return (
            <Form.Control size='sm' type='text' onChange={onChange} onBlur={onNumberBlur} value={value as string} />
          );
        default:
          return <Form.Control size='sm' type='text' onChange={onChange} onBlur={onBlur} value={value as string} />;
      }
    },
  };

  var items = props.billData.billItems;

  const table = useReactTable({
    data: items,
    columns,
    state: {
      columnVisibility: {
        group: props.showGroups,
        quantity: !props.showGroups,
        quantityType: !props.showGroups,
      },
    },
    defaultColumn,
    meta: {
      categoriesNames: props.categoriesNames,
      groupsNames: props.groupsNames,
    },
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <div className='table-responsive'>
        <table
          id={'edited-bill-table' + (props.showGroups ? '-groups' : '')}
          className='table table-striped table-bordered table-sm mt-2'
          aria-labelledby='tabelLabel'
        >
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody ref={tbodyRef}>
            {props.initialLoading
              ? Common.Ui.getSkeletonTableRows(10, table.getAllColumns().filter((c) => c.getIsVisible()).length, 35)
              : table.getRowModel().rows.map((row) => (
                  <tr key={row.id} id={row.id} tabIndex={0} onKeyDown={(e) => handleKeyDown(e, row)}>
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                    ))}
                  </tr>
                ))}
            <tr>
              <td colSpan={10000}>
                Wydano w sumie{' '}
                {Common.Utils.getNumberWithSpaces(
                  props.billData.billItems.reduce((a, b) => a + (b.cost || 0), 0).toFixed(2)
                )}{' '}
                złotych
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
}

export default BillItemsTable;
