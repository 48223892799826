import { useCallback, useEffect, useRef, useState } from 'react';

import Common from '../../shared/Common';

const ConfirmEmail = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<string>('');
  const fetchIdRef = useRef(0);

  const confirmEmail = useCallback(async (userId: string | null, code: string | null) => {
    setLoading(true);
    const fetchId = ++fetchIdRef.current;

    if (fetchId === fetchIdRef.current) {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          userId: userId,
          code: code,
        }),
      };
      const response = await Common.authorizedFetch('idsrv/account/confirmEmail', requestOptions);
      const data = await response.json();
      if (data.success) {
        setStatus('Twój adres e-mail został zatwierdzony.');
      } else {
        setStatus(data.errors);
      }
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const codeParam = params.get('code');
    const userIdParam = params.get('userId');

    confirmEmail(userIdParam, codeParam);
  }, [confirmEmail]);

  return (
    <>
      {Common.Ui.showLoadingSpinnerFixed(loading)}
      <h1>Zatwierdzenie adresu e-mail</h1>
      <p>{status}</p>
    </>
  );
};

export default ConfirmEmail;
