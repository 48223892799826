import { Button, Modal } from 'react-bootstrap';
import { ProductHistoryType, type ICompanyProductHistory } from '../../../interfaces/ICompanyProductHistory';

interface IProductDetailsHistoryProps {
  showHistory: boolean;
  setShowHistory: React.Dispatch<React.SetStateAction<boolean>>;
  productHistory: ICompanyProductHistory[];
}

function ProductDetailsHistory(props: IProductDetailsHistoryProps) {
  const { showHistory, productHistory, setShowHistory } = props;

  const formatDate = (date: Date) => {
    date = new Date(date);
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    };

    const formattedDate = new Intl.DateTimeFormat('pl-PL', options).format(date);
    return formattedDate.replace(',', '');
  };

  const productEvents = productHistory.filter((p) => p.type === ProductHistoryType.Create);
  const productChanges = productHistory.filter((p) => p.type !== ProductHistoryType.Create);

  return (
    <Modal
      show={showHistory}
      onHide={() => {
        setShowHistory(false);
      }}
      size='lg'
    >
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>Historia zmian produktu</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>Zdarzenia</h5>
        <div className='table-responsive'>
          <table id='product-history-changes-table' className='table table-bordered'>
            <thead>
              <tr>
                <th scope='col'>Data</th>
                <th scope='col'>Zdarzenie</th>
              </tr>
            </thead>
            <tbody>
              {productEvents.map((historyItem: ICompanyProductHistory) => (
                <tr key={historyItem.id}>
                  <td>{formatDate(historyItem.date)}</td>
                  <td>Pierwsza obserwacja produktu</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <h5>Zmiany</h5>
        {productChanges.length === 0 ? (
          <span>Brak zmian w produkcie od pierwszej obserwacji</span>
        ) : (
          <div className='table-responsive'>
            <table id='product-history-events-table' className='table table-bordered'>
              <thead>
                <tr>
                  <th scope='col'>Data</th>
                  <th scope='col'>Pole</th>
                  <th scope='col'>Wcześniejsza wartość</th>
                  <th scope='col'>Nowa wartość</th>
                </tr>
              </thead>
              <tbody>
                {productChanges.map((historyItem: ICompanyProductHistory) => (
                  <tr key={historyItem.id}>
                    <td>{formatDate(historyItem.date)}</td>
                    <td>
                      {((type: ProductHistoryType) => {
                        switch (type) {
                          case ProductHistoryType.Ean:
                            return 'Kod EAN';
                          case ProductHistoryType.Brand:
                            return 'Marka';
                          case ProductHistoryType.ImageUrl:
                            return 'Obrazek';
                          case ProductHistoryType.Name:
                            return 'Nazwa';
                          case ProductHistoryType.Quantity:
                            return 'Ilość';
                          case ProductHistoryType.Url:
                            return 'Adres URL';
                          case ProductHistoryType.Wieght:
                            return 'Waga';
                          default:
                            return '';
                        }
                      })(historyItem.type)}
                    </td>
                    <td>{historyItem.oldValue}</td>
                    <td>{historyItem.newValue}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant='dark'
          onClick={() => {
            setShowHistory(false);
          }}
        >
          Zamknij
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ProductDetailsHistory;
