import {
  type ColumnDef,
  type SortingState,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import type { IScraper } from '../../../../interfaces/IScraper';
import { useState, useEffect } from 'react';
import Common from '../../../shared/Common';
import { SortUpIcon, SortDownIcon } from '../../../shared/icons/Svgs';

export interface ICompanyScrapersSummaryTableProps {
  columns: ColumnDef<IScraper>[];
  data: IScraper[];
  loading: boolean;
  fetchData: () => Promise<void>;
}

const CompanyScrapersSummaryTable = (props: ICompanyScrapersSummaryTableProps) => {
  const [sorting, setSorting] = useState<SortingState>([]);

  const table = useReactTable({
    data: props.data,
    columns: props.columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const { fetchData } = props;

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <div className='table-responsive'>
        <table className='table'>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          style: header.column.getCanSort() ? { cursor: 'pointer' } : {},
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: <SortUpIcon style={{ marginLeft: '5px' }} />,
                          desc: <SortDownIcon style={{ marginLeft: '5px' }} />,
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {props.loading
              ? Common.Ui.getSkeletonTableRows(8, 7, 35)
              : table.getRowModel().rows.map((row) => (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                    ))}
                  </tr>
                ))}
          </tbody>
          {!props.loading && (
            <tfoot>
              {table.getFooterGroups().map((footerGroup) => (
                <tr key={footerGroup.id}>
                  {footerGroup.headers.map((header) => (
                    <th key={header.id}>
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.footer, header.getContext())}
                    </th>
                  ))}
                </tr>
              ))}
            </tfoot>
          )}
        </table>
      </div>
    </>
  );
};

export default CompanyScrapersSummaryTable;
