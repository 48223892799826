import { useState, useEffect, useCallback, useRef } from 'react';
import Common from '../../shared/Common';

const AccessDenied = () => {
  const [userRoles, setUserRoles] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const fetchIdRef = useRef(0);

  const fetchRoleUsers = useCallback(async () => {
    setLoading(true);
    const fetchId = ++fetchIdRef.current;

    const response = await Common.authorizedFetch('api/users/getUserRoles');
    if (fetchId === fetchIdRef.current) {
      const data = await response.json();
      if (data.success) {
        setUserRoles(data.result.rolesNames);
      }
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchRoleUsers();
  }, [fetchRoleUsers]);

  return (
    <>
      <h1 className='text-danger'>Brak uprawnień</h1>
      <p className='text-danger'>Nie posiadasz uprawnień do korzystania z tego modułu.</p>
      <p>Aby uzyskać dostęp do tego modułu skontaktuj się z administratorem aplikacji.</p>
      {loading ? (
        Common.Ui.getSkeletonListElements(3, 25)
      ) : userRoles.length === 0 ? (
        <p>Aktualnie nie posiadasz żadnych uprawnień. </p>
      ) : (
        <>
          <p>Aktualnie posiadane przez Ciebie uprawnienia:</p>
          <ul className='list-group mb-4'>
            {userRoles.map((r) => (
              <li key={r} className='list-group-item'>
                {r}
              </li>
            ))}
          </ul>
        </>
      )}
    </>
  );
};

export default AccessDenied;
