import { Button, Collapse, Form, Modal } from 'react-bootstrap';
import { CheckIcon, BanIcon, CircleNotchSpinIcon } from '../../../shared/icons/Svgs';
import { ManuallyWorkerType, type IManuallyWorkerDataFeed } from '../../../../interfaces/IManuallyWorkerDataFeed';
import { useEffect, useState } from 'react';
import Common from '../../../shared/Common';

export interface IManaullyWorkersPanelProps {
  data: IManuallyWorkerDataFeed[];
  loading: boolean;
  fetchData: (setLoading: boolean) => Promise<void>;
}

const ManaullyWorkersPanel = (props: IManaullyWorkersPanelProps) => {
  const [showSelgrosManuallyWorker, setShowSelgrosManuallyWorker] = useState(true);
  const [showHaleBanachaManuallyWorker, setShowHaleBanachaManuallyWorker] = useState(true);
  const [loading, setLoading] = useState(false);
  const [workerName, setWorkerName] = useState('');
  const [waitTimeBetweenRequests, setWaitTimeBetweenRequests] = useState(1000);
  const [refillAllProducts, setRefillAllProducts] = useState(false);

  const refillDataForSelgrosData = props.data.filter((d) => d.workerType === ManuallyWorkerType.RefillDataForSelgros);
  const refillEanCodeForHaleBanachaData = props.data.filter(
    (d) => d.workerType === ManuallyWorkerType.RefillEanCodeForHaleBanacha
  );

  const { fetchData } = props;

  useEffect(() => {
    fetchData(true);

    const intervalId = setInterval(() => {
      fetchData(false);
    }, 60000);

    return () => {
      clearInterval(intervalId);
    };
  }, [fetchData]);

  const generateManualWorkerTypeTable = (data: IManuallyWorkerDataFeed[]) => {
    const getResultIcon = (value: IManuallyWorkerDataFeed) => {
      if (!value.finish) {
        return (
          <>
            <CircleNotchSpinIcon style={{ color: '#007bff' }} />{' '}
            {Math.floor((100 * value.currentIteration) / value.totalIterations)}%
          </>
        );
      }
      if (value.totalIterations === value.currentIteration) {
        return <CheckIcon style={{ color: 'green' }} />;
      }
      return (
        <>
          <BanIcon style={{ color: 'red', height: 15 }} />{' '}
          {Math.floor((100 * value.currentIteration) / value.totalIterations)}%
        </>
      );
    };

    const rows = [
      data.map((value, index) => (
        <tr key={index}>
          <td>{Common.Utils.getDateWithTimeWithoutZoneConverter(value.start)}</td>
          <td>{Common.Utils.getDateWithTimeWithoutZoneConverter(value.finish)}</td>
          <td>{value.paramsJson}</td>
          <td>
            {value.currentIteration}/{value.totalIterations}
          </td>
          <td>{getResultIcon(value)}</td>
        </tr>
      )),
    ];
    return (
      <table className='table table-border my-0'>
        <thead>
          <tr>
            <th>Rozpoczęcie</th>
            <th>Zakończenie</th>
            <th>Parametry</th>
            <th>Iteracje</th>
            <th>Postęp</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    );
  };

  const runWorker = async () => {
    setLoading(true);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        workerName,
        waitTimeBetweenRequests,
        refillAllProducts,
      }),
    };
    const response = await Common.authorizedFetch('api/scrapers/runManuallyWorker', requestOptions);
    const data = await response.json();
    if (!data.success) {
      alert(data.errors);
    }
    setLoading(false);
    setWorkerName('');
  };

  return (
    <>
      {!props.loading && (
        <div>
          <h6 className='mt-3'>Ręczne procesy</h6>
          <Modal
            show={workerName !== ''}
            onHide={() => {
              setWorkerName('');
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title id='contained-modal-title-vcenter'>Uruchomienie workera</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {Common.Ui.showLoadingSpinnerAbsolute(loading)}
              <Form>
                <Form.Group className='mb-3' controlId='workerName'>
                  <Form.Label>Nazwa workera</Form.Label>
                  <Form.Control value={workerName} onChange={(e) => setWorkerName(e.target.value)} disabled />
                  <Form.Text className='text-muted'>Nazwa uruchamianego workera.</Form.Text>
                </Form.Group>
                <Form.Group className='mb-3' controlId='waitTimeBetweenRequests'>
                  <Form.Label>Czas między iteracjami</Form.Label>
                  <Form.Control
                    value={waitTimeBetweenRequests}
                    onChange={(e) => setWaitTimeBetweenRequests(parseInt(e.target.value, 10) || 1000)}
                  />
                  <Form.Text className='text-muted'>Średni czas (w ms) oczekiwania między iteracjami.</Form.Text>
                </Form.Group>
                <Form.Group className='mb-3' controlId='refillAllProducts'>
                  <Form.Check
                    type='checkbox'
                    label='Wszystkie produkty'
                    checked={refillAllProducts}
                    onChange={(e) => setRefillAllProducts(e.target.checked)}
                  />
                  <Form.Text className='text-muted'>
                    Czy przeglądać wszystkie produkty czy takie, które nie mają uzupełnionego kodu EAN.
                  </Form.Text>
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant='dark'
                onClick={() => {
                  setWorkerName('');
                }}
              >
                Zamknij
              </Button>
              <Button
                onClick={() => {
                  runWorker();
                }}
                disabled={loading}
              >
                Uruchom
              </Button>
            </Modal.Footer>
          </Modal>
          <table className='table table-hover table-responsive mb-3'>
            <thead>
              <tr>
                <th>Typ procesu</th>
                <th>Liczba wykonań</th>
                <th>Akcje</th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ cursor: 'pointer' }} onClick={() => setShowSelgrosManuallyWorker((prev) => !prev)}>
                <td>RefillDataForSelgros</td>
                <td>{refillDataForSelgrosData.length}</td>
                <td>
                  <Button
                    onClick={(e: any) => {
                      setWorkerName('RefillDataForSelgros');
                      e.stopPropagation();
                    }}
                    size='sm'
                    disabled={refillDataForSelgrosData.length !== 0 && refillDataForSelgrosData[0].finish === null}
                  >
                    Uruchom
                  </Button>
                </td>
              </tr>
              {refillDataForSelgrosData.length > 0 && (
                <Collapse in={showSelgrosManuallyWorker}>
                  <tr>
                    <td colSpan={4}>{generateManualWorkerTypeTable(refillDataForSelgrosData)}</td>
                  </tr>
                </Collapse>
              )}
              <tr style={{ cursor: 'pointer' }} onClick={() => setShowHaleBanachaManuallyWorker((prev) => !prev)}>
                <td>RefillEanCodeForHaleBanacha</td>
                <td>{refillEanCodeForHaleBanachaData.length}</td>
                <td>
                  <Button
                    onClick={(e: any) => {
                      setWorkerName('RefillEanCodeForHaleBanacha');
                      e.stopPropagation();
                    }}
                    size='sm'
                    disabled={
                      refillEanCodeForHaleBanachaData.length !== 0 && refillEanCodeForHaleBanachaData[0].finish === null
                    }
                  >
                    Uruchom
                  </Button>
                </td>
              </tr>
              {refillEanCodeForHaleBanachaData.length > 0 && (
                <Collapse in={showHaleBanachaManuallyWorker}>
                  <tr>
                    <td colSpan={3}>{generateManualWorkerTypeTable(refillEanCodeForHaleBanachaData)}</td>
                  </tr>
                </Collapse>
              )}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default ManaullyWorkersPanel;
