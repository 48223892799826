import debounce from 'lodash.debounce';
import { useCallback, useRef, useEffect, useState, useMemo, useContext } from 'react';
import { Button, Col, Collapse, Container, Form, Row } from 'react-bootstrap';
import Common from '../shared/Common';
import DashboardCard from './DashboardCard';
import Bill from './bill/Bill';
import type { IDashboardElement } from '../../interfaces/IDashboardElement';
import './style.css';
import { DictionaryContext } from '../../App';
import { SpiderIcon } from '../shared/icons/Svgs';
import type { IBill } from '../../interfaces/IBill';

export interface IDashboardSearchSettings {
  item: string;
  location: string;
  category: string;
  group: string;
  showAllItems: boolean;
  showQuantities: boolean;
  showCategories: boolean;
  sortByPrice: boolean;
  searchNullLocations: boolean;
  searchNullCategories: boolean;
}

export interface IDashboardMainProps {
  setFetchDashboardStatisticsTrigger: React.Dispatch<React.SetStateAction<number>>;
}

const DashboardMain = (props: IDashboardMainProps) => {
  const [dashboardElements, setDashboardElements] = useState<{
    loaded: (IDashboardElement | null)[];
    toLoad: (IDashboardElement | null)[];
  }>({ loaded: [null, null, null, null], toLoad: [] });
  const [showSearchSettings, setShowSearchSettings] = useState(false);
  const [searchSettings, setSearchSettings] = useState<IDashboardSearchSettings>({
    item: '',
    location: '',
    category: '',
    group: '',
    showAllItems: false,
    showQuantities: true,
    showCategories: true,
    sortByPrice: true,
    searchNullLocations: false,
    searchNullCategories: false,
  });
  const [selectedBillId, setSelectedBillId] = useState(0);
  const [showBillModal, setShowBillModal] = useState(false);
  const [showGroups, setShowGroups] = useState(false);
  const [uploadedBillPictureBase64, setUploadedBillPictureBase64] = useState<string | null>(null);
  const fetchDashboardElementsRef = useRef(0);
  const elementsLoadingRef = useRef<boolean>(false);
  const allDashboardElementsLoadedRef = useRef<boolean>(false);
  const { predictionModel } = useContext(DictionaryContext);
  const [fetchDashboardElementsTrigger, setFetchDashboardElementsTrigger] = useState(0);

  const inputRef = useRef<HTMLInputElement>(null);

  const fetchDashboardElements = useCallback(
    async (initLoad: boolean, dashboardElementsCount: number) => {
      const fetchId = ++fetchDashboardElementsRef.current;
      if (elementsLoadingRef.current) {
        return;
      }
      elementsLoadingRef.current = true;
      if (initLoad) {
        const response = await Common.authorizedFetch(
          'api/dashboard/getDashboardElements?skip=0&take=6' +
            `&item=${searchSettings.item}&location=${searchSettings.location}&category=${searchSettings.category}&group=${searchSettings.group}` +
            `&showAllItems=${searchSettings.showAllItems}&searchNullLocations=${searchSettings.searchNullLocations}&searchNullCategories=${searchSettings.searchNullCategories}`
        );
        if (fetchId === fetchDashboardElementsRef.current) {
          const data = await response.json();

          if (data.result.dashboardElements.length < 6) {
            let newTable = [];
            for (let ind = 0; ind !== data.result.dashboardElements.length; ind++) {
              newTable.push(data.result.dashboardElements[ind]);
            }
            allDashboardElementsLoadedRef.current = true;
            elementsLoadingRef.current = false;
            setDashboardElements({
              toLoad: [],
              loaded: newTable,
            });
            return;
          }

          if (data.success) {
            setDashboardElements({
              toLoad: [data.result.dashboardElements[4], data.result.dashboardElements[5]],
              loaded: [
                data.result.dashboardElements[0],
                data.result.dashboardElements[1],
                data.result.dashboardElements[2],
                data.result.dashboardElements[3],
              ],
            });
            elementsLoadingRef.current = false;
          }
        }
      } else {
        const response = await Common.authorizedFetch(
          `api/dashboard/getDashboardElements?skip=${dashboardElementsCount - 2}&take=4` +
            `&item=${searchSettings.item}&location=${searchSettings.location}&category=${searchSettings.category}&group=${searchSettings.group}&showAllItems=${searchSettings.showAllItems}`
        );
        if (fetchId === fetchDashboardElementsRef.current) {
          const data = await response.json();
          if (data.success) {
            if (data.result.dashboardElements.length < 4) {
              allDashboardElementsLoadedRef.current = true;
              elementsLoadingRef.current = false;
              setDashboardElements((prevState) => {
                let newTable = prevState.loaded.slice();
                // usuwanie nulli
                newTable = newTable.filter((n) => n !== null);
                for (let ind = 0; ind !== data.result.dashboardElements.length; ind++) {
                  newTable.push(data.result.dashboardElements[ind]);
                }
                return {
                  toLoad: [],
                  loaded: newTable,
                };
              });
              return;
            }
            setDashboardElements((prevState) => {
              let newTable = prevState.loaded.slice();
              // usuwanie nulli
              newTable = newTable.filter((n) => n !== null);
              newTable.push(data.result.dashboardElements[0]);
              newTable.push(data.result.dashboardElements[1]);
              return {
                toLoad: [data.result.dashboardElements[2], data.result.dashboardElements[3]],
                loaded: newTable,
              };
            });
            elementsLoadingRef.current = false;
          }
        }
      }
    },
    [
      searchSettings.item,
      searchSettings.location,
      searchSettings.category,
      searchSettings.group,
      searchSettings.showAllItems,
      searchSettings.searchNullLocations,
      searchSettings.searchNullCategories,
    ]
  );

  const loadAdditionalDashboardElements = useCallback(async () => {
    if (elementsLoadingRef.current || allDashboardElementsLoadedRef.current) {
      return;
    }
    const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));
    await sleep(400);
    const elementCount = dashboardElements.loaded.length;
    if (elementsLoadingRef.current || allDashboardElementsLoadedRef.current) {
      return;
    }
    setDashboardElements((prevState) => {
      const newTable = prevState.loaded.slice();
      newTable.push(dashboardElements.toLoad[0]);
      newTable.push(dashboardElements.toLoad[1]);
      newTable.push(null);
      newTable.push(null);
      return {
        ...prevState,
        loaded: newTable,
      };
    });
    await fetchDashboardElements(false, elementCount + 4);
  }, [dashboardElements, fetchDashboardElements]);

  useEffect(() => {
    // sprawdzamy czy paragony zapełniły ekran (i nie ma scrollbara) - jeśli nie to zapełnij dokumenty ponownie
    if (
      dashboardElements.loaded.length > 0 &&
      dashboardElements.loaded[dashboardElements.loaded.length - 1] !== null &&
      document.body.scrollHeight <= document.body.clientHeight
    ) {
      loadAdditionalDashboardElements();
    }
  }, [dashboardElements, loadAdditionalDashboardElements]);

  const tryLoadAdditionalDashboardElements = useCallback(async () => {
    if (elementsLoadingRef.current || allDashboardElementsLoadedRef.current) {
      return;
    }
    // jeśli scroll znajduje się na samym dole to ładujemy kolejne elementy
    const isScrolledToBottom = Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight;
    if (isScrolledToBottom) {
      await loadAdditionalDashboardElements();
    }
  }, [loadAdditionalDashboardElements]);

  useEffect(() => {
    // próba załadowania elementów dashboardu po scrollowaniu i zmianie rozmiaru okna
    window.addEventListener('scroll', tryLoadAdditionalDashboardElements, {
      passive: true,
    });
    window.addEventListener('resize', tryLoadAdditionalDashboardElements, {
      passive: true,
    });
    // próba załadowania elementów dashboardu co interwał czasu
    const loadDashboardElementsInterval = setInterval(tryLoadAdditionalDashboardElements, 1000);

    return () => {
      window.removeEventListener('scroll', tryLoadAdditionalDashboardElements);
      window.removeEventListener('resize', tryLoadAdditionalDashboardElements);
      clearInterval(loadDashboardElementsInterval);
    };
  }, [tryLoadAdditionalDashboardElements]);

  useEffect(() => {
    allDashboardElementsLoadedRef.current = false;
    elementsLoadingRef.current = false;
    setDashboardElements((prevState) => ({
      ...prevState,
      loaded: [null, null, null, null],
    }));
    fetchDashboardElements(true, 0);
    return () => {
      setDashboardElements({ loaded: [], toLoad: [] });
    };
  }, [
    fetchDashboardElements,
    fetchDashboardElementsTrigger,
    searchSettings.showAllItems,
    searchSettings.item,
    searchSettings.location,
    searchSettings.category,
    searchSettings.group,
  ]);

  const generateDashboardCards = (dasbhoardElements: (IDashboardElement | null)[]) => {
    let children = [];
    for (let ind = 0; ind < dasbhoardElements.length; ind++) {
      const onDashboardCardClicked = () => {
        const selection = window.getSelection();
        if (selection !== null && selection.type === 'Range') {
          return;
        }

        setSelectedBillId(dasbhoardElements[ind]?.billId ?? 0);
        setShowBillModal(true);
      };
      children.push(
        <DashboardCard
          sortByPrice={searchSettings.sortByPrice}
          showQuantities={searchSettings.showQuantities}
          showCategories={searchSettings.showCategories}
          showGroups={showGroups}
          key={ind}
          data={dasbhoardElements[ind]}
          id={ind + 1}
          onClick={onDashboardCardClicked}
        />
      );
    }

    return children;
  };

  const addBillManually = () => {
    setSelectedBillId(0);
    setShowBillModal(true);
  };

  const addBillFromPicture = () => {
    inputRef.current?.click();
  };

  const searchDashboard = () => {
    setShowSearchSettings((prev) => !prev);
  };

  const debouncedSearchItemChanged = useMemo(
    () =>
      debounce((e: any) => {
        setSearchSettings((prev) => ({
          ...prev,
          item: e.target.value,
        }));
      }, 500),
    []
  );

  const debouncedSearchLocationChanged = useMemo(
    () =>
      debounce((e: any) => {
        setSearchSettings((prev) => ({
          ...prev,
          location: e.target.value,
        }));
      }, 500),
    []
  );

  const debouncedSearchCategoryChanged = useMemo(
    () =>
      debounce((e: any) => {
        setSearchSettings((prev) => ({
          ...prev,
          category: e.target.value,
        }));
      }, 500),
    []
  );

  const debouncedSearchGroupChanged = useMemo(
    () =>
      debounce((e: any) => {
        setSearchSettings((prev) => ({
          ...prev,
          group: e.target.value,
        }));
      }, 500),
    []
  );

  useEffect(
    () => () => {
      debouncedSearchItemChanged.cancel();
      debouncedSearchLocationChanged.cancel();
      debouncedSearchCategoryChanged.cancel();
      debouncedSearchGroupChanged.cancel();
    },
    [
      debouncedSearchItemChanged,
      debouncedSearchLocationChanged,
      debouncedSearchCategoryChanged,
      debouncedSearchGroupChanged,
    ]
  );

  const handleUploadBillImage = async (event: any) => {
    if (!!event.target.files[0]) {
      const compressedPictureBase64 = await Common.Images.compresUploadedImage(event.target.files[0]);
      setSelectedBillId(0);
      setUploadedBillPictureBase64(compressedPictureBase64);
      setShowBillModal(true);
      event.target.value = null;
    }
  };
  const formRef = useRef<HTMLFormElement>(null);

  const searchInputsGiven =
    searchSettings.item !== '' ||
    searchSettings.location !== '' ||
    searchSettings.category !== '' ||
    searchSettings.group !== '';

  return (
    <>
      <Container className='dashboard-card container-fluid' style={{ borderRadius: '5px' }} fluid>
        <Row className='g-0 p-2'>
          <Col xs={5}>
            <div className='dashboard-card-button' onClick={addBillManually}>
              <div
                style={{
                  display: 'flex',
                  marginRight: '12px',
                  marginTop: '8px',
                  marginBottom: '8px',
                }}
              >
                <img src='/Static/icons/add-new.png' style={{ height: '30px', width: '30px' }} alt='' />
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    marginBottom: '-5px',
                    marginTop: '-5px',
                  }}
                >
                  <span
                    className='d-none d-sm-block'
                    style={{
                      width: '100%',
                      fontSize: '.9375rem',
                      fontWeight: 500,
                      color: '#050505',
                    }}
                  >
                    Dodaj rachunek
                  </span>
                  <span
                    className='d-block d-sm-none'
                    style={{
                      width: '100%',
                      fontSize: '.9375rem',
                      fontWeight: 500,
                      color: '#050505',
                    }}
                  >
                    Dodaj
                  </span>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={5}>
            <input
              ref={inputRef}
              onChange={handleUploadBillImage}
              className='d-none'
              type='file'
              accept='image/*'
              capture='environment'
            />
            <div className='dashboard-card-button' style={{}} onClick={addBillFromPicture}>
              <div
                style={{
                  display: 'flex',
                  marginRight: '12px',
                  marginTop: '8px',
                  marginBottom: '8px',
                }}
              >
                <img src='/Static/icons/photo.png' style={{ height: '30px', width: '30px' }} alt='' />
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    marginBottom: '-5px',
                    marginTop: '-5px',
                    marginRight: '10px',
                  }}
                >
                  <span
                    className='d-none d-sm-block'
                    style={{
                      width: '100%',
                      fontSize: '.9375rem',
                      fontWeight: 500,
                      color: '#050505',
                    }}
                  >
                    Dodaj rachunek ze zdjęcia
                  </span>
                  <span
                    className='d-block d-sm-none'
                    style={{
                      width: '100%',
                      fontSize: '.9375rem',
                      fontWeight: 500,
                      color: '#050505',
                    }}
                  >
                    Zdjęcie
                  </span>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={2}>
            <div
              className='dashboard-card-button'
              style={
                searchSettings.item !== '' ||
                searchSettings.location !== '' ||
                searchSettings.category !== '' ||
                searchSettings.group !== '' ||
                searchSettings.searchNullLocations ||
                searchSettings.searchNullCategories
                  ? {
                      backgroundColor: 'rgb(101 103 107 / 30%)',
                    }
                  : showSearchSettings
                    ? {
                        backgroundColor: 'rgb(101 103 107 / 20%)',
                      }
                    : {}
              }
              onClick={searchDashboard}
            >
              <div
                style={{
                  display: 'flex',
                  marginRight: '12px',
                  marginTop: '8px',
                  marginBottom: '8px',
                }}
              >
                <img src='/Static/icons/search.png' style={{ height: '30px', width: '30px' }} alt='' />
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    marginBottom: '-5px',
                    marginTop: '-5px',
                  }}
                >
                  <span
                    className='d-none d-sm-block'
                    style={{
                      width: '100%',
                      fontSize: '.9375rem',
                      fontWeight: 500,
                      color: '#050505',
                    }}
                  >
                    Szukaj
                  </span>
                  <span
                    className='d-block d-sm-none'
                    style={{
                      width: '100%',
                      fontSize: '.9375rem',
                      fontWeight: 500,
                      color: '#050505',
                    }}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Collapse in={showSearchSettings}>
        <div>
          <Container
            className='px-2 py-3'
            style={{
              borderRadius: '5px',
              backgroundColor: 'white',
              marginBottom: '15px',
            }}
          >
            <Form ref={formRef}>
              <Row>
                <Col>
                  <Form.Control placeholder='Nazwa produktu' onChange={debouncedSearchItemChanged} />
                </Col>
                <Col>
                  <Form.Control placeholder='Lokalizacja' onChange={debouncedSearchLocationChanged} />
                </Col>
              </Row>
              <Row className='mt-2'>
                <Col>
                  <Form.Control placeholder='Kategoria' onChange={debouncedSearchCategoryChanged} />
                </Col>
                <Col>
                  <Form.Control placeholder='Grupa' onChange={debouncedSearchGroupChanged} />
                </Col>
              </Row>
              <Row className='mt-2'>
                <Col xs={6} md={4}>
                  <Form.Check
                    type='switch'
                    id='show-all-bill-items-switch'
                    style={{ userSelect: 'none' }}
                    label='Pokaż cały paragon'
                    defaultChecked={searchSettings.showAllItems}
                    onChange={() => {
                      setSearchSettings((prev) => ({
                        ...prev,
                        showAllItems: !prev.showAllItems,
                      }));
                    }}
                  />
                </Col>
                <Col xs={6} md={4}>
                  <Form.Check
                    type='switch'
                    id='sort-by-price-switch'
                    style={{ userSelect: 'none' }}
                    label='Sortuj po cenie'
                    defaultChecked={searchSettings.sortByPrice}
                    onChange={() => {
                      setSearchSettings((prev) => ({
                        ...prev,
                        sortByPrice: !prev.sortByPrice,
                      }));
                    }}
                  />
                </Col>
                <Col xs={6} md={4}>
                  <Form.Check
                    type='switch'
                    id='show-quantities-switch'
                    style={{ userSelect: 'none' }}
                    label='Pokaż ilość'
                    defaultChecked={searchSettings.showQuantities}
                    onChange={() => {
                      setSearchSettings((prev) => ({
                        ...prev,
                        showQuantities: !prev.showQuantities,
                      }));
                    }}
                  />
                </Col>
                <Col xs={6} md={4}>
                  <Form.Check
                    type='switch'
                    id='show-categories-switch'
                    style={{ userSelect: 'none' }}
                    label='Pokaż kategorie'
                    defaultChecked={searchSettings.showCategories}
                    onChange={() => {
                      setSearchSettings((prev) => ({
                        ...prev,
                        showCategories: !prev.showCategories,
                      }));
                    }}
                  />
                </Col>
                <Col xs={6} md={4}>
                  <Form.Check
                    type='switch'
                    id='show-groups-switch'
                    style={{ userSelect: 'none' }}
                    label='Pokaż grupy'
                    checked={showGroups}
                    onChange={() => {
                      setShowGroups(!showGroups);
                    }}
                  />
                </Col>
                <Col xs={6} md={4}>
                  <Form.Check
                    type='switch'
                    id='search-null-locations'
                    style={{ userSelect: 'none' }}
                    label='Tylko bez lokalizacji'
                    checked={searchSettings.searchNullLocations}
                    onChange={() => {
                      setSearchSettings((prev) => ({
                        ...prev,
                        searchNullLocations: !prev.searchNullLocations,
                      }));
                    }}
                  />
                </Col>
                <Col xs={6} md={4}>
                  <Form.Check
                    type='switch'
                    id='search-null-categories'
                    style={{ userSelect: 'none' }}
                    label='Tylko bez kategorii'
                    checked={searchSettings.searchNullCategories}
                    onChange={() => {
                      setSearchSettings((prev) => ({
                        ...prev,
                        searchNullCategories: !prev.searchNullCategories,
                      }));
                    }}
                  />
                </Col>
              </Row>
              <div className='text-end'>
                <Button
                  className='mt-2'
                  variant='danger'
                  onClick={() => {
                    formRef?.current?.reset();
                    setSearchSettings({
                      item: '',
                      location: '',
                      category: '',
                      group: '',
                      showAllItems: false,
                      showQuantities: true,
                      showCategories: true,
                      sortByPrice: true,
                      searchNullLocations: false,
                      searchNullCategories: false,
                    });
                  }}
                >
                  Wyczyść
                </Button>
              </div>
            </Form>
          </Container>
        </div>
      </Collapse>
      {dashboardElements.loaded.length ? (
        generateDashboardCards(dashboardElements.loaded)
      ) : (
        <Container className='mb-1'>
          <Row>
            <Col xs={12} sm={10} lg={8}>
              <Row>
                <h5>Brak rachunków</h5>
                {searchInputsGiven ? (
                  <p>Brak rachunków dla wybranych kryteriów wyszukiwania </p>
                ) : (
                  <p>Dodaj swój pierwszy rachunek, żeby go tutaj wyświetlić</p>
                )}
              </Row>
              <Row>
                <SpiderIcon />
              </Row>
            </Col>
          </Row>
        </Container>
      )}
      <Bill
        show={showBillModal}
        showGroups={showGroups}
        setShowGroups={setShowGroups}
        onHide={() => {
          setShowBillModal(false);
          setSelectedBillId(0);
          setUploadedBillPictureBase64(null);
        }}
        onBillAdd={() => {
          setDashboardElements({ loaded: [], toLoad: [] });
          setFetchDashboardElementsTrigger((prev) => prev + 1);
          props.setFetchDashboardStatisticsTrigger((prev) => prev + 1);
        }}
        onBillSave={(bill: IBill) => {
          setDashboardElements((prevState) => {
            const elemIndex = prevState.loaded.findIndex(
              (dashboardElements) => dashboardElements?.billId === bill.billId
            );
            if (elemIndex > -1) {
              var newLoaded = [...prevState.loaded];
              var elem = newLoaded[elemIndex];
              if (elem) {
                const location = bill.location?.locationName === '' ? undefined : bill.location;
                elem = {
                  ...elem,
                  billItems: [...bill.billItems],
                  payTime: bill.payTime,
                  location: location,
                  elementPicture:
                    bill.billBase64Pictures.length > 0 ? bill.billBase64Pictures[0].pictureBase64String : undefined,
                };
                newLoaded[elemIndex] = elem;
              }
              return {
                ...prevState,
                loaded: newLoaded,
              };
            }
            return prevState;
          });
          props.setFetchDashboardStatisticsTrigger((prev) => prev + 1);
        }}
        onBillRemove={(billId: number) => {
          setDashboardElements((prevState) => ({
            ...prevState,
            loaded: prevState.loaded.filter((dashboardElement) => dashboardElement?.billId !== billId),
          }));
          props.setFetchDashboardStatisticsTrigger((prev) => prev + 1);
        }}
        billId={selectedBillId}
        pictureToLoadBill={uploadedBillPictureBase64}
        predictionModel={predictionModel}
      />
    </>
  );
};

export default DashboardMain;
