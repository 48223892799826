import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import type { IDefaultLocationPicture } from '../../../interfaces/IDefaultLocationPicture';
import type { IDefaultLocationPictureCategory } from '../../../interfaces/IDefaultLocationPictureCategory';

interface IDefaultLocationPicturesProps {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  locationPictures: IDefaultLocationPicture[];
  onPictureSelected: (selectedPicture: IDefaultLocationPicture | undefined) => void;
}

const DefaultLocationPictures = (props: IDefaultLocationPicturesProps) => {
  const { show, locationPictures, setShow, onPictureSelected } = props;
  const handleClose = () => setShow(false);

  const locationPicturesByCategory = [];
  const pictureCategories: IDefaultLocationPictureCategory[] = [];
  locationPictures
    .map((p) => p.pictureCategory)
    .forEach((value) => {
      // pozbycie duplikatów
      if (value && pictureCategories.filter((e) => e.id === value.id).length === 0) {
        pictureCategories.push(value);
      }
    });
  for (let ind = 0; ind < pictureCategories.length; ind++) {
    const category = pictureCategories[ind];
    if (!category) {
      continue;
    }
    const pictures = locationPictures
      .filter((p) => p.pictureCategory?.id === category?.id)
      .map((p, index) => (
        <Col xs='auto' key={ind + '-' + index}>
          <img
            onClick={() => {
              onPictureSelected(p);
              handleClose();
            }}
            src={p.pictureBase64String}
            style={{
              cursor: 'pointer',
              height: '60px',
              width: '60px',
            }}
            alt=''
          />
        </Col>
      ));

    if (pictures.length === 0) {
      pictures.push(<div key={ind + '-0'} style={{ width: '100%', height: 60 }} />);
    }

    locationPicturesByCategory.push(
      <div key={ind} className='mb-3'>
        <h6 className='my-2'>{category.name}</h6>
        <Row>{pictures}</Row>
      </div>
    );
  }

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Domyślne obrazki</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>{locationPicturesByCategory}</Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='danger'
            onClick={() => {
              onPictureSelected(undefined);
              handleClose();
            }}
          >
            Usuń
          </Button>
          <Button variant='secondary' onClick={handleClose}>
            Zamknij
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DefaultLocationPictures;
