import { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import Common from '../../../shared/Common';
import type { IUserSettingsChildProps } from '../UserSettings';
import type { IUserPreference } from '../../../../interfaces/IUserPreference';

const Preferences = (props: IUserSettingsChildProps) => {
  const [userPreferencesChanged, setUserPreferencesChanged] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [userPreferencesToSave, setUserPreferencesToSave] = useState<IUserPreference>({
    recurringOperationDashboardStatsCount: 0,
    showWeeklyExpensesDashboardStats: false,
    showMonthlyExpensesDashboardStats: false,
    showYearlyExpensesDashboardStats: false,
    showSummaryTotalDashboardStats: false,
    showSummaryLocationsDashboardStats: false,
  });
  const { loading, userData, setStatus, setFetchUserDataTrigger } = props;

  useEffect(() => {
    if (userData?.userPreference) {
      setUserPreferencesToSave(userData?.userPreference);
    }
  }, [userData?.userPreference]);

  const updateUserPreferences = async () => {
    setDataLoading(true);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ userPreference: userPreferencesToSave }),
    };
    const response = await Common.authorizedFetch('api/users/updateUserPreference', requestOptions);
    const data = await response.json();
    setDataLoading(false);
    if (!data.success) {
      alert(data.errors);
    } else {
      setStatus({
        variant: 'success',
        status: 'Ustawienia zostały zaktualizowane.',
        messageTime: Date.now(),
      });
      setFetchUserDataTrigger((prev) => prev + 1);
    }
  };

  return (
    <>
      <h4>Preferencje użytkownika</h4>
      <h6 className='mt-3'>Wyświetlanie statystyk wydatków</h6>
      <Row className='mt-2'>
        <Col xs={6} md={4}>
          {loading || dataLoading ? (
            <Skeleton />
          ) : (
            <Form.Check
              type='switch'
              id='showWeeklyExpensesDashboardStats-switch'
              style={{ userSelect: 'none' }}
              label='Pokaż tygodniowe wydatki'
              checked={userPreferencesToSave.showWeeklyExpensesDashboardStats}
              onChange={() => {
                setUserPreferencesChanged(true);
                const updatedUserPreferencesToSave = { ...userPreferencesToSave };
                updatedUserPreferencesToSave.showWeeklyExpensesDashboardStats =
                  !updatedUserPreferencesToSave.showWeeklyExpensesDashboardStats;
                setUserPreferencesToSave(updatedUserPreferencesToSave);
              }}
            />
          )}
        </Col>
        <Col xs={6} md={4}>
          {loading || dataLoading ? (
            <Skeleton />
          ) : (
            <Form.Check
              type='switch'
              id='showMonthlyExpensesDashboardStats-switch'
              style={{ userSelect: 'none' }}
              label='Pokaż miesięczne wydatki'
              checked={userPreferencesToSave.showMonthlyExpensesDashboardStats}
              onChange={() => {
                setUserPreferencesChanged(true);
                const updatedUserPreferencesToSave = { ...userPreferencesToSave };
                updatedUserPreferencesToSave.showMonthlyExpensesDashboardStats =
                  !updatedUserPreferencesToSave.showMonthlyExpensesDashboardStats;
                setUserPreferencesToSave(updatedUserPreferencesToSave);
              }}
            />
          )}
        </Col>
        <Col xs={6} md={4}>
          {loading || dataLoading ? (
            <Skeleton />
          ) : (
            <Form.Check
              type='switch'
              id='showYearlyExpensesDashboardStats-switch'
              style={{ userSelect: 'none' }}
              label='Pokaż roczne wydatki'
              checked={userPreferencesToSave.showYearlyExpensesDashboardStats}
              onChange={() => {
                setUserPreferencesChanged(true);
                const updatedUserPreferencesToSave = { ...userPreferencesToSave };
                updatedUserPreferencesToSave.showYearlyExpensesDashboardStats =
                  !updatedUserPreferencesToSave.showYearlyExpensesDashboardStats;
                setUserPreferencesToSave(updatedUserPreferencesToSave);
              }}
            />
          )}
        </Col>
      </Row>
      <h6 className='mt-3'>Wyświetlanie statystyk ogólnych</h6>
      <Row className='mt-2'>
        <Col xs={6} md={4}>
          {loading || dataLoading ? (
            <Skeleton />
          ) : (
            <Form.Check
              type='switch'
              id='showSummaryTotalDashboardStats-switch'
              style={{ userSelect: 'none' }}
              label='Pokaż sumaryczne statystyki'
              checked={userPreferencesToSave.showSummaryTotalDashboardStats}
              onChange={() => {
                setUserPreferencesChanged(true);
                const updatedUserPreferencesToSave = { ...userPreferencesToSave };
                updatedUserPreferencesToSave.showSummaryTotalDashboardStats =
                  !updatedUserPreferencesToSave.showSummaryTotalDashboardStats;
                setUserPreferencesToSave(updatedUserPreferencesToSave);
              }}
            />
          )}
        </Col>
        <Col xs={6} md={4}>
          {loading || dataLoading ? (
            <Skeleton />
          ) : (
            <Form.Check
              type='switch'
              id='showSummaryLocationsDashboardStats-switch'
              style={{ userSelect: 'none' }}
              label='Pokaż najliczniejsze lokalizacje'
              checked={userPreferencesToSave.showSummaryLocationsDashboardStats}
              onChange={() => {
                setUserPreferencesChanged(true);
                const updatedUserPreferencesToSave = { ...userPreferencesToSave };
                updatedUserPreferencesToSave.showSummaryLocationsDashboardStats =
                  !updatedUserPreferencesToSave.showSummaryLocationsDashboardStats;
                setUserPreferencesToSave(updatedUserPreferencesToSave);
              }}
            />
          )}
        </Col>
      </Row>
      <h6 className='mt-3'>Wyświetlanie najbliższych operacji cyklicznych</h6>
      <Row className='mt-2'>
        <Col sm={6}>
          {loading || dataLoading ? (
            <Skeleton height={40} />
          ) : (
            <>
              <Form.Group as={Row} className='mb-3'>
                <Row className='align-items-center'>
                  <Col xs='auto' className='pe-0'>
                    <Form.Control
                      style={{ width: '50px' }}
                      type='number'
                      value={userPreferencesToSave.recurringOperationDashboardStatsCount}
                      size='sm'
                      max={7}
                      min={0}
                      onChange={(event) => {
                        const number = Number(event.target.value);
                        if (!isNaN(number)) {
                          setUserPreferencesChanged(true);
                          const updatedUserPreferencesToSave = { ...userPreferencesToSave };
                          updatedUserPreferencesToSave.recurringOperationDashboardStatsCount = number;
                          setUserPreferencesToSave(updatedUserPreferencesToSave);
                        }
                      }}
                    />
                  </Col>
                  <Col>
                    <Form.Label>Maksymalna liczba prezentowanych operacji cyklicznych</Form.Label>
                  </Col>
                </Row>
                <Row>
                  <Form.Range
                    className='ps-3'
                    min={0}
                    max={7}
                    value={userPreferencesToSave.recurringOperationDashboardStatsCount}
                    onChange={(event) => {
                      const number = Number(event.target.value);
                      if (!isNaN(number)) {
                        setUserPreferencesChanged(true);
                        const updatedUserPreferencesToSave = { ...userPreferencesToSave };
                        updatedUserPreferencesToSave.recurringOperationDashboardStatsCount = number;
                        setUserPreferencesToSave(updatedUserPreferencesToSave);
                      }
                    }}
                  />
                </Row>
              </Form.Group>
            </>
          )}
        </Col>
      </Row>
      <Button
        className='mt-3'
        variant='primary'
        onClick={updateUserPreferences}
        disabled={loading || dataLoading || !userPreferencesChanged}
      >
        Zapisz
      </Button>
    </>
  );
};

export default Preferences;
