import { Formik } from 'formik';
import { useCallback, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import * as yup from 'yup';

import Common from '../../shared/Common';

const ResetPassword = () => {
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [showConfirmation, setShowConfirmation] = useState<number>(0);

  const ResetPasswordForm = useCallback(() => {
    const validationSchema = yup.object().shape({
      email: yup.string().required('Pole z adresem e-mail jest wymagane').email('Podano nieprawidłowy adres e-mail'),
    });

    return (
      <Row>
        <Col md={6}>
          <Formik
            initialValues={{
              email: '',
            }}
            validationSchema={validationSchema}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true);
              setFormLoading(true);
              const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email: values.email }),
              };
              const response = await Common.authorizedFetch('idsrv/account/resetPassword', requestOptions);
              const data = await response.json();
              setFormLoading(false);
              if (data.success) {
                if (data.result.emailSendingDisabled) {
                  setShowConfirmation(2);
                } else {
                  setShowConfirmation(1);
                }
              }
              setSubmitting(false);
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
              <>
                <Form onSubmit={handleSubmit} autoComplete='off'>
                  <Form.Group>
                    <Form.Label>E-mail</Form.Label>
                    <Form.Control
                      type='text'
                      name='email'
                      placeholder='Twój adres e-mail'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      className={touched.email && errors.email ? 'error' : undefined}
                    />
                    {touched.email && errors.email ? <div className='error-message'>{errors.email}</div> : null}
                  </Form.Group>
                  <Button className='mt-2' variant='primary' type='submit' disabled={isSubmitting}>
                    Zatwierdź
                  </Button>
                </Form>
              </>
            )}
          </Formik>
        </Col>
      </Row>
    );
  }, []);

  return (
    <>
      {Common.Ui.showLoadingSpinnerFixed(formLoading)}
      <h1 className='mb-3'>Zapomniane hasło</h1>
      {showConfirmation === 0 && (
        <>
          <h4 id='external-login-title'>Wprowadź swój adres e-mail</h4>
          <hr />
          <ResetPasswordForm />
        </>
      )}
      {showConfirmation === 1 && (
        <p>
          Jeżeli konto istnieje w aplikacji, to na Twój adres e-mail został wysłany specjalny link do zresetowania
          hasła. Kliknij w niego, żeby zresetować hasło.
        </p>
      )}
      {showConfirmation === 2 && (
        <p className='text-danger'>
          Uwaga, aktualnie funkcjonalność wysyłania poczty elektronicznej jest wyłączona. Skontaktuj się proszę z
          administratorem aplikacji w celu ręcznego wygenerowania linku do zresetowania hasła.
        </p>
      )}
    </>
  );
};

export default ResetPassword;
