import { useState, useEffect, useCallback, useRef } from 'react';
import { Card, Col, Container, ListGroup, Row, Tab, Tabs } from 'react-bootstrap';
import Common from '../../shared/Common';
import Skeleton from 'react-loading-skeleton';

import './style.css';

interface IStatisticValue {
  name: string;
  count: number;
}

interface IStatisticsData {
  totalCount: number;
  totalAmmount: number;
  categoryCount: number;
  locationCount: number;
  groupCount: number;
  locationCountData: IStatisticValue[];
  categoryCountData: IStatisticValue[];
  topBillItemsCostData: ITopBillItemCostData[];
}

interface ITopBillItemCostData {
  year: number;
  payTime: string;
  description: string;
  cost: number;
  locationName: string;
  categoryName: string;
  categoryFullName: string;
}

const Statistics = () => {
  const [statisticsData, setStatisticsData] = useState<IStatisticsData | undefined>(undefined);

  const fetchIdRef = useRef(0);

  const fetchUserData = useCallback(async () => {
    const fetchId = ++fetchIdRef.current;

    const response = await Common.authorizedFetch('api/statistics/getStatisticsData');
    setStatisticsData(undefined);
    if (fetchId === fetchIdRef.current) {
      const data = await response.json();
      if (data.success) {
        setStatisticsData(data.result);
      }
    }
  }, []);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  const generateLocationDataTable = () => {
    if (statisticsData === undefined) {
      return (
        <Col className='mt-3'>
          <Card>
            <Card.Header className='statistics-card-header'>Najpopularniejsze lokalizacje</Card.Header>
            <Card.Body>
              <Skeleton height={200} />
            </Card.Body>
          </Card>
        </Col>
      );
    }

    const tableElements = [];

    for (var ind = 0; ind < statisticsData.locationCountData.length; ind++) {
      tableElements.push(
        <ListGroup.Item key={ind}>
          <div key={ind}>
            {statisticsData.locationCountData[ind].name ?? <i>Brak lokalizacji</i>}{' '}
            <span className='badge statistics-card-count-badge ms-2'>
              {statisticsData.locationCountData[ind].count}
            </span>
          </div>
        </ListGroup.Item>
      );
    }

    return (
      <Col className='mt-3'>
        <Card>
          <Card.Header className='statistics-card-header'>Najpopularniejsze lokalizacje</Card.Header>
          <Card.Body>
            {statisticsData.locationCountData.length === 0 ? (
              <div>Aktualnie nie masz wprowadzonych żadnych lokalizacji.</div>
            ) : (
              <div>
                Na tej karcie pokazane są najczęściej wykorzystywane lokalizacje. Obok nazwy znajduje się liczba
                rachunków z przypisaną daną lokalizacją.
              </div>
            )}
          </Card.Body>
          <ListGroup className='list-group-flush'>{tableElements}</ListGroup>
        </Card>
      </Col>
    );
  };

  const generateCategoryDataTable = () => {
    if (statisticsData === undefined) {
      return (
        <Col className='mt-3'>
          <Card>
            <Card.Header className='statistics-card-header'>Najpopularniejsze kategorie</Card.Header>
            <Card.Body>
              <Skeleton height={200} />
            </Card.Body>
          </Card>
        </Col>
      );
    }

    const tableElements = [];

    for (var ind = 0; ind < statisticsData.categoryCountData.length; ind++) {
      tableElements.push(
        <ListGroup.Item key={ind}>
          <div key={ind}>
            {statisticsData.categoryCountData[ind].name ?? <i>Brak kategorii</i>}{' '}
            <span className='badge statistics-card-count-badge ms-2'>
              {statisticsData.categoryCountData[ind].count}
            </span>
          </div>
        </ListGroup.Item>
      );
    }

    return (
      <>
        <Col className='mt-3'>
          <Card>
            <Card.Header className='statistics-card-header'>Najpopularniejsze kategorie</Card.Header>
            <Card.Body>
              {statisticsData.categoryCountData.length === 0 ? (
                <div>Aktualnie nie masz wprowadzonych żadnych kategorii.</div>
              ) : (
                <div>
                  Na tej karcie pokazane są najczęściej wykorzystywane kategorie. Obok nazwy znajduje się liczba
                  produktów z przypisaną daną kategorią.
                </div>
              )}
            </Card.Body>
            <ListGroup className='list-group-flush'>{tableElements}</ListGroup>
          </Card>
        </Col>
      </>
    );
  };

  const generateTopBillCostDataTable = () => {
    if (statisticsData === undefined) {
      return <></>;
    }

    const tabs: JSX.Element[] = [];
    var yearGroup = Common.Utils.groupBy(statisticsData.topBillItemsCostData, (i) => i.year);
    const yearsDescending = Object.keys(yearGroup)
      .map((e) => Number(e))
      .sort()
      .reverse();
    for (var ind = 0; ind < yearsDescending.length; ind++) {
      const year = yearsDescending[ind];
      const yearData = yearGroup[year];
      const items = [];
      for (var itemInd = 0; itemInd < yearData.length; itemInd++) {
        items.push(
          <tr key={year + '-' + itemInd}>
            <td>
              <b>{itemInd + 1}</b>
            </td>
            <td>{yearData[itemInd].description}</td>
            <td>{Common.Utils.getCurrencyString(yearData[itemInd].cost.toFixed(2))}</td>
            <td>{Common.Utils.getDatetimeWithoutZoneConverter(yearData[itemInd].payTime)}</td>
            <td>{yearData[itemInd].locationName}</td>
            <td title={yearData[itemInd].categoryFullName}>{yearData[itemInd].categoryName}</td>
          </tr>
        );
      }
      if (items.length > 0) {
        tabs.push(
          <Tab key={ind + '-' + year} eventKey={ind + '-' + year} title={year}>
            <div className='table-responsive-sm'>
              <table className='table statistics-bill-table table-without-border-bottom'>
                <thead>
                  <tr>
                    <th scope='col'>#</th>
                    <th scope='col'>Wydatek</th>
                    <th scope='col'>Koszt</th>
                    <th scope='col'>Data</th>
                    <th scope='col'>Lokalizacja</th>
                    <th scope='col'>Kategoria</th>
                  </tr>
                </thead>
                <tbody>{items}</tbody>
              </table>
            </div>
          </Tab>
        );
      }
    }

    return (
      <Card className='mt-3 mb-2'>
        <Card.Header className='statistics-card-header'>Największe wydatki w danym roku</Card.Header>
        <Card.Body className='pb-0 pt-2'>
          <Tabs key='' className='mb-3'>
            {tabs}
          </Tabs>
        </Card.Body>
      </Card>
    );
  };

  return (
    <Container className='mt-2'>
      <h2 id='tabelLabel'>Statystyki</h2>
      <Card className='mt-3'>
        <Card.Header className='statistics-card-header'>Ogólne</Card.Header>
        <Container>
          <table className='table table-without-border-bottom' style={{ marginBottom: '11px' }}>
            <tbody>
              <tr>
                <td style={{ width: '220px' }}>Suma wydanych pieniędzy</td>
                <td>{!statisticsData ? <Skeleton /> : Common.Utils.getCurrencyString(statisticsData.totalAmmount)}</td>
              </tr>
              <tr>
                <td>Liczba dodanych rachunków</td>
                <td>{!statisticsData ? <Skeleton /> : statisticsData.totalCount}</td>
              </tr>
              <tr>
                <td>Liczba lokalizacji</td>
                <td>{!statisticsData ? <Skeleton /> : statisticsData.locationCount}</td>
              </tr>
              <tr>
                <td>Liczba kategorii</td>
                <td>{!statisticsData ? <Skeleton /> : statisticsData.categoryCount}</td>
              </tr>
              <tr>
                <td>Liczba grup</td>
                <td>{!statisticsData ? <Skeleton /> : statisticsData.groupCount}</td>
              </tr>
            </tbody>
          </table>
        </Container>
      </Card>
      <Row>
        {generateLocationDataTable()}
        {generateCategoryDataTable()}
      </Row>
      {generateTopBillCostDataTable()}
    </Container>
  );
};

export default Statistics;
