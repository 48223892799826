import React, { useCallback, useRef, useState, useContext } from 'react';
import Common from '../../shared/Common';
import type { PredictionModel } from '../../../classes/PredictionModel';
import type { IBill } from '../../../interfaces/IBill';
import type { ICategoryName } from '../../../interfaces/ICategoryName';
import { DictionaryContext } from '../../../App';
import BillModal from './BillModal';

import './style.css';

export interface IBillProps {
  show: boolean;
  showGroups: boolean;
  setShowGroups: React.Dispatch<React.SetStateAction<boolean>>;
  onHide: () => void;
  onBillAdd: () => void;
  onBillSave: (bill: IBill) => void;
  onBillRemove: (billId: number) => void;
  billId: number;
  pictureToLoadBill: string | null;
  predictionModel?: PredictionModel;
}

export interface IBillItemMapping {
  from: string;
  to: string;
  category: ICategoryName;
}

const Bill = (props: IBillProps) => {
  const [billData, setBillData] = useState<IBill>({
    billId: 0,
    billItems: [],
    location: {
      locationId: -1,
      locationName: '',
      picture: '',
      mappings: [],
    },
    payTime: new Date(),
    billBase64Pictures: [],
  });
  const [copyMode, setCopyMode] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [initialLoading, setInitialLoading] = useState<boolean>(false);
  const fetchBillIdRef = useRef(0);
  const fetchBillFromPictureIdRef = useRef(0);
  const { categoriesNames, quantityTypes, groupsNames, locationNames } = useContext(DictionaryContext);

  const hideModal = () => {
    props.onHide();
    setBillData({
      billId: 0,
      billItems: [],
      location: {
        locationId: -1,
        locationName: '',
        picture: '',
        mappings: [],
      },
      payTime: new Date(),
      billBase64Pictures: [],
    });
    setCopyMode(false);
    setLoading(false);
    setInitialLoading(false);
  };

  const fetchBill = useCallback(async (billId: number) => {
    if (billId !== 0) {
      setLoading(true);
      setInitialLoading(true);
      const fetchId = ++fetchBillIdRef.current;

      const response = await Common.authorizedFetch('api/bills/getBill?billId=' + billId);
      if (fetchId === fetchBillIdRef.current) {
        const data = await response.json();
        if (data.success) {
          var bill = data.result.bill as IBill;
          if (!bill.location) {
            bill.location = {
              locationId: -1,
              locationName: '',
              picture: '',
              mappings: [],
            };
          }
          setBillData(bill);
        }
        setInitialLoading(false);
      }
      setLoading(false);
    }
  }, []);

  const fetchBillFromPicture = useCallback(
    async (billPictureBase64: string | null) => {
      if (billPictureBase64 !== null) {
        setLoading(true);
        setInitialLoading(true);
        const fetchId = ++fetchBillFromPictureIdRef.current;
        const response = await Common.authorizedFetch('api/bills/getBillFromPicture', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ billPictureBase64 }),
        });
        if (fetchId === fetchBillFromPictureIdRef.current) {
          const data = await response.json();
          if (data.success) {
            var bill = data.result.bill as IBill;
            if (!bill.location) {
              bill.location = {
                locationId: -1,
                locationName: '',
                picture: '',
                mappings: [],
              };
            }
            bill.billId = props.billId;
            const billItemMappings = (data.result.mappings ?? []) as IBillItemMapping[];
            for (const billItem of bill.billItems) {
              const mappingIndex = billItemMappings.findIndex(
                (i) => i.to === billItem.description && billItem.billItemId === -1
              );
              if (mappingIndex > -1) {
                billItem.mappedFrom = billItemMappings[mappingIndex].from;
                if (billItemMappings[mappingIndex].category) {
                  billItem.mappedCategory = true;
                }
              }
              if (billItem.category?.categoryId !== undefined && billItem.category?.categoryId > 0) {
                continue;
              }
              billItem.predictCategory = true;
              const predictedCategoryName = props.predictionModel?.predictCategory(billItem.description as string);
              const predictedCategory = categoriesNames.find((x) => x.categoryFullName === predictedCategoryName);
              if (predictedCategory) {
                billItem.category = predictedCategory;
              }
            }
            setBillData(bill);
          }
        }
        setInitialLoading(false);
        setLoading(false);
      }
    },
    [props.predictionModel, props.billId, categoriesNames]
  );

  return (
    <div>
      <BillModal
        show={props.show}
        showGroups={props.showGroups}
        onHide={hideModal}
        onBillAdd={props.onBillAdd}
        onBillSave={props.onBillSave}
        onBillRemove={props.onBillRemove}
        fetchBillFromPicture={fetchBillFromPicture}
        fetchBill={fetchBill}
        billData={billData}
        quantityTypes={quantityTypes}
        categoriesNames={categoriesNames}
        groupsNames={groupsNames}
        locationNames={locationNames}
        setBillData={setBillData}
        billId={props.billId}
        setShowGroups={props.setShowGroups}
        copyMode={copyMode}
        setCopyMode={setCopyMode}
        pictureToLoadBill={props.pictureToLoadBill}
        loading={loading}
        setLoading={setLoading}
        initialLoading={initialLoading}
      />
    </div>
  );
};

export default Bill;
