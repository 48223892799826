import { Container } from 'react-bootstrap';

const Offline = () => (
  <Container className='mt-2'>
    <h1>Wystąpił problem</h1>
    <h2 className='text-danger mb-3'>Brak połączenia z internetem</h2>
    <p>
      Aby zapewnić bezpieczne działanie aplikacji <b>Pan Rachunek</b>, wymagane jest połączenie z internetem. Bez niego
      nie możemy zagwarantować ochrony Twoich danych i zapewnić pełnej funkcjonalności aplikacji. Sprawdź swoje
      połączenie internetowe.
    </p>
    <div className='mt-3 mb-2'>
      <b>Aby sprawdzić swoje połączenie internetowe możesz wykonać następujące czynności:</b>
      <ol>
        <li>
          Upewnij się, że jesteś podłączony do działającej sieci. Możesz spróbować otworzyć w przeglądarce stronę
          internetową taką jak <a href='https://google.com'>google.com</a>. Jeśli nie możesz otworzyć strony, może to
          oznaczać problem z połączeniem.
        </li>
        <li>
          Sprawdź stan swojego routera. Upewnij się, że jest włączony i poprawnie skonfigurowany. Spróbuj zresetować
          router, odłączając go na kilka sekund od zasilania, a następnie podłączając ponownie.
        </li>
        <li>
          Skontaktuj się z dostawcą usług internetowych, aby sprawdzić, czy nie ma żadnych problemów z dostawą internetu
          w Twojej okolicy. Mogą oni również pomóc Ci zdiagnozować i rozwiązać problemy z połączeniem.
        </li>
      </ol>
    </div>
  </Container>
);

export default Offline;
