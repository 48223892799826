import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import React from 'react';
import { Form } from 'react-bootstrap';
import Common from '../../../shared/Common';
import { SortUpIcon, SortDownIcon, MinusIcon, PlusIcon } from '../../../shared/icons/Svgs';
import type { ColumnDef, SortingState } from '@tanstack/react-table';
import type { IDefaultLocationPicture } from '../../../../interfaces/IDefaultLocationPicture';
import type { IDefaultLocationPictureCategory } from '../../../../interfaces/IDefaultLocationPictureCategory';

import './style.css';

export interface IPictureCategoriesTableProps {
  pictureCategories: IDefaultLocationPictureCategory[];
  locationPictures: IDefaultLocationPicture[];
  loading: boolean;
  setPictureCategories: React.Dispatch<React.SetStateAction<IDefaultLocationPictureCategory[]>>;
  setDataChanged: React.Dispatch<React.SetStateAction<boolean>>;
}

const PictureCategoriesTable = (props: IPictureCategoriesTableProps) => {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const { pictureCategories, locationPictures, setPictureCategories, setDataChanged, loading } = props;

  const columns = React.useMemo<ColumnDef<IDefaultLocationPictureCategory>[]>(
    () => [
      {
        id: 'actionButton',
        enableSorting: false,
        accessorFn: (row) => row.id,
        header: () => (
          <div
            onClick={() => {
              setDataChanged(true);
              setPictureCategories((prevState) => {
                const minId = Math.min(0, ...prevState.map((c) => c.id)) - 1;
                return [...prevState, { id: minId, name: '', order: prevState.length + 1 }];
              });
            }}
          >
            <PlusIcon style={{ cursor: 'pointer', width: '30px', height: '30px', fill: 'var(--app-main-color)' }} />
          </div>
        ),
        cell: (info) => {
          const selectedValue = info.row.original;
          const canRemove = locationPictures.filter((p) => p.pictureCategory?.id === selectedValue.id).length === 0;
          return (
            <div
              onClick={() => {
                if (!canRemove) {
                  return;
                }
                setPictureCategories((prevState) => {
                  setDataChanged(true);
                  return prevState.filter((elem) => elem !== selectedValue);
                });
              }}
            >
              <MinusIcon
                style={
                  canRemove
                    ? { cursor: 'pointer', width: '30px', height: '30px', fill: '#dc3545' }
                    : { cursor: 'pointer', width: '30px', height: '30px', fill: 'gray' }
                }
              />
            </div>
          );
        },
      },
      {
        id: 'name',
        enableSorting: true,
        accessorFn: (row) => row.name,
        header: () => <span>Nazwa</span>,
      },
      {
        id: 'order',
        enableSorting: true,
        accessorFn: (row) => row.order,
        header: () => <span>Kolejność</span>,
      },
    ],
    [setPictureCategories, setDataChanged, locationPictures]
  );

  const defaultColumn: Partial<ColumnDef<IDefaultLocationPictureCategory>> = {
    cell: function Cell({ getValue, row: { index }, column: { id }, table }) {
      const initialValue = getValue();
      const [value, setValue] = React.useState(initialValue);

      // liczenie możliwych kolejności
      const orderOptions: any[] = [];
      var maxOrder = 0;
      if (table.options.meta?.extra?.pictureCategories) {
        const categories = table.options.meta?.extra?.pictureCategories as IDefaultLocationPictureCategory[];
        const maxValue = Math.max(...categories.map((c) => c.order));
        maxOrder = Math.max(categories.length, maxValue);
      }
      for (let ind = 0; ind !== maxOrder; ind++) {
        orderOptions.push(
          <option key={ind} value={ind + 1}>
            {ind + 1}
          </option>
        );
      }

      const onBlur = () => {
        table.options.meta?.updateData?.(index, id, value);
      };

      React.useEffect(() => {
        setValue(initialValue);
      }, [initialValue]);

      if (id === 'order') {
        return (
          <Form.Control
            size='sm'
            as='select'
            value={value as string}
            onBlur={onBlur}
            onChange={(e) => {
              setValue(e.target.value);
              setDataChanged(true);
            }}
          >
            {orderOptions}
          </Form.Control>
        );
      } else {
        return (
          <Form.Control
            size='sm'
            type='text'
            onChange={(e) => {
              setValue(e.target.value);
              setDataChanged(true);
            }}
            onBlur={onBlur}
            value={value as string}
          />
        );
      }
    },
  };

  var items = pictureCategories ?? [];

  const table = useReactTable({
    data: items,
    columns,
    defaultColumn,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    meta: {
      updateData: (rowIndex, columnId, value) => {
        setPictureCategories((prevState) => {
          prevState[rowIndex] = {
            ...prevState[rowIndex],
            [columnId]: value,
          };

          return [...prevState];
        });
      },
      extra: {
        pictureCategories: pictureCategories,
      },
    },
  });

  return (
    <>
      <table id={'edited-picture-categories-table'} className='table table-sm mt-2' aria-labelledby='tabelLabel'>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder ? null : (
                    <div
                      {...{
                        style: header.column.getCanSort()
                          ? {
                              cursor: 'pointer',
                            }
                          : {},
                        onClick: header.column.getToggleSortingHandler(),
                      }}
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {{
                        asc: <SortUpIcon style={{ marginLeft: '5px' }} />,
                        desc: <SortDownIcon style={{ marginLeft: '5px' }} />,
                      }[header.column.getIsSorted() as string] ?? null}
                    </div>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {loading
            ? Common.Ui.getSkeletonTableRows(5, 3, 35)
            : table.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                  ))}
                </tr>
              ))}
        </tbody>
      </table>
    </>
  );
};

export default PictureCategoriesTable;
