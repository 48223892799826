import { useState, useEffect, useCallback, useRef } from 'react';
import { Container, Nav, Row, Col } from 'react-bootstrap';
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import Common from '../../shared/Common';
import AlertMessages from '../../shared/application-alert/AlertMessages';
import ApplicationAlert from '../../shared/application-alert/ApplicationAlert';
import Disable2fa from './2fa/Disable2fa';
import EnableAuthenticator from './2fa/EnableAuthenticator';
import GenerateRecoveryCodes from './2fa/GenerateRecoveryCodes';
import ResetAuthenticator from './2fa/ResetAuthenticator';
import ShowRecoveryCodes from './2fa/ShowRecoveryCodes';
import TwoFactorAuthentication from './2fa/TwoFactorAuthentication';
import ChangePassword from './change-password/ChangePassword';
import Email from './email/Email';
import ExternalLogin from './external-login/ExternalLogin';
import PersonalData from './personal-data/PersonalData';
import RemoveAccount from './personal-data/RemoveAccount';
import Profile from './profile/Profile';
import type { IUserData } from '../../../interfaces/IUserData';
import type { IApplicationAlert } from '../../shared/application-alert/ApplicationAlert';
import Preferences from './preferences/Preferences';

export interface IUserSettingsChildProps {
  userData: IUserData | null;
  loading: boolean;
  fetchUserDataTrigger: number;
  setFetchUserDataTrigger: React.Dispatch<React.SetStateAction<number>>;
  setStatus: React.Dispatch<React.SetStateAction<IApplicationAlert | undefined>>;
  additionalData?: any;
}

const UserSettings = () => {
  const location = useLocation();
  const [status, setStatus] = useState<IApplicationAlert>();
  const [userData, setUserData] = useState<IUserData | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [fetchUserDataTrigger, setFetchUserDataTrigger] = useState(0);
  const fetchIdRef = useRef(0);

  const fetchUserData = useCallback(async () => {
    setLoading(true);
    const fetchId = ++fetchIdRef.current;

    const response = await Common.authorizedFetch('api/users/getUserData');
    if (fetchId === fetchIdRef.current) {
      const data = await response.json();
      if (data.success) {
        setUserData(data.result);
      }
      setLoading(false);
    }
  }, []);

  // wyświetlanie komunikatu po redirect
  useEffect(() => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let resultInfo = params.get('result');
    if (!!resultInfo) {
      const info = AlertMessages.getMessageByCode(resultInfo);
      setStatus({
        variant: info.variant,
        status: info.message,
        messageTime: Date.now(),
      });
    }
  }, []);

  // w przypadku przekierowań chcemy uciąć prefiks dziecka, żeby poprawnie wyświetlać aktywnie wybraną zakładkę
  const trimLocationPath = (path: string, index: number) =>
    // ze ścieżki wycinamy wszystkie napisy po wystąpieniu (index - 1) '/'
    path.split('/', index).join('/');
  useEffect(() => {
    fetchUserData();
  }, [fetchUserData, fetchUserDataTrigger]);

  return (
    <Container>
      <h2 className='mt-1 mb-3'>Ustawienia użytkownika</h2>
      <hr />
      <Row>
        <Col md={3} className='mb-3'>
          <Nav variant='pills' className='flex-column' defaultActiveKey={trimLocationPath(location.pathname, 4)}>
            <Nav.Link eventKey='/settings/user/profile' as={Link} to='/settings/user/profile'>
              Profil
            </Nav.Link>
            <Nav.Link eventKey='/settings/user/preferences' as={Link} to='/settings/user/preferences'>
              Preferencje
            </Nav.Link>
            <Nav.Link eventKey='/settings/user/email' as={Link} to='/settings/user/email'>
              E-mail
            </Nav.Link>
            <Nav.Link eventKey='/settings/user/change-password' as={Link} to='/settings/user/change-password'>
              Hasło
            </Nav.Link>
            <Nav.Link eventKey='/settings/user/external-login' as={Link} to='/settings/user/external-login'>
              Logowanie zewnętrzne
            </Nav.Link>
            <Nav.Link eventKey='/settings/user/2fa' as={Link} to='/settings/user/2fa'>
              Uwierzytelnienie dwuskładnikowe
            </Nav.Link>
            <Nav.Link eventKey='/settings/user/personal-data' as={Link} to='/settings/user/personal-data'>
              Informacje o koncie
            </Nav.Link>
          </Nav>
        </Col>
        <Col md={9} className='mb-3'>
          {status && (
            <ApplicationAlert variant={status?.variant} status={status?.status} messageTime={status?.messageTime} />
          )}
          <Routes>
            <Route
              path='user/'
              element={
                <Profile
                  userData={userData}
                  loading={loading}
                  fetchUserDataTrigger={fetchUserDataTrigger}
                  setFetchUserDataTrigger={setFetchUserDataTrigger}
                  setStatus={setStatus}
                />
              }
            />
            <Route
              path='profile'
              element={
                <Profile
                  userData={userData}
                  loading={loading}
                  fetchUserDataTrigger={fetchUserDataTrigger}
                  setFetchUserDataTrigger={setFetchUserDataTrigger}
                  setStatus={setStatus}
                />
              }
            />
            <Route
              path='preferences'
              element={
                <Preferences
                  userData={userData}
                  loading={loading}
                  fetchUserDataTrigger={fetchUserDataTrigger}
                  setFetchUserDataTrigger={setFetchUserDataTrigger}
                  setStatus={setStatus}
                />
              }
            />
            <Route
              path='email'
              element={
                <Email
                  userData={userData}
                  loading={loading}
                  fetchUserDataTrigger={fetchUserDataTrigger}
                  setFetchUserDataTrigger={setFetchUserDataTrigger}
                  setStatus={setStatus}
                />
              }
            />
            <Route
              path='change-password'
              element={
                <ChangePassword
                  userData={userData}
                  loading={loading}
                  fetchUserDataTrigger={fetchUserDataTrigger}
                  setFetchUserDataTrigger={setFetchUserDataTrigger}
                  setStatus={setStatus}
                />
              }
            />
            <Route
              path='external-login'
              element={
                <ExternalLogin
                  userData={userData}
                  loading={loading}
                  fetchUserDataTrigger={fetchUserDataTrigger}
                  setFetchUserDataTrigger={setFetchUserDataTrigger}
                  setStatus={setStatus}
                />
              }
            />
            <Route
              path='2fa'
              element={
                <TwoFactorAuthentication
                  userData={userData}
                  loading={loading}
                  fetchUserDataTrigger={fetchUserDataTrigger}
                  setFetchUserDataTrigger={setFetchUserDataTrigger}
                  setStatus={setStatus}
                />
              }
            />
            <Route
              path='2fa/enable-authenticator'
              element={
                <EnableAuthenticator
                  userData={userData}
                  loading={loading}
                  fetchUserDataTrigger={fetchUserDataTrigger}
                  setFetchUserDataTrigger={setFetchUserDataTrigger}
                  setStatus={setStatus}
                />
              }
            />
            <Route
              path='2fa/reset-authenticator'
              element={
                <ResetAuthenticator
                  userData={userData}
                  loading={loading}
                  fetchUserDataTrigger={fetchUserDataTrigger}
                  setFetchUserDataTrigger={setFetchUserDataTrigger}
                  setStatus={setStatus}
                />
              }
            />
            <Route
              path='2fa/disable'
              element={
                <Disable2fa
                  userData={userData}
                  loading={loading}
                  fetchUserDataTrigger={fetchUserDataTrigger}
                  setFetchUserDataTrigger={setFetchUserDataTrigger}
                  setStatus={setStatus}
                />
              }
            />
            <Route
              path='2fa/generate-recovery-codes'
              element={
                <GenerateRecoveryCodes
                  userData={userData}
                  loading={loading}
                  fetchUserDataTrigger={fetchUserDataTrigger}
                  setFetchUserDataTrigger={setFetchUserDataTrigger}
                  setStatus={setStatus}
                />
              }
            />
            <Route
              path='2fa/show-recovery-codes'
              element={
                <ShowRecoveryCodes
                  userData={userData}
                  loading={loading}
                  fetchUserDataTrigger={fetchUserDataTrigger}
                  setFetchUserDataTrigger={setFetchUserDataTrigger}
                  setStatus={setStatus}
                  additionalData={{
                    generatedCodes: location.state?.generatedCodes,
                  }}
                />
              }
            />
            <Route
              path='personal-data'
              element={
                <PersonalData
                  userData={userData}
                  loading={loading}
                  fetchUserDataTrigger={fetchUserDataTrigger}
                  setFetchUserDataTrigger={setFetchUserDataTrigger}
                  setStatus={setStatus}
                />
              }
            />
            <Route
              path='personal-data/remove-account'
              element={
                <RemoveAccount
                  userData={userData}
                  loading={loading}
                  fetchUserDataTrigger={fetchUserDataTrigger}
                  setFetchUserDataTrigger={setFetchUserDataTrigger}
                  setStatus={setStatus}
                />
              }
            />
          </Routes>
        </Col>
      </Row>
    </Container>
  );
};

export default UserSettings;
