import { useParams } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useReactTable, getCoreRowModel, getSortedRowModel, flexRender } from '@tanstack/react-table';
import type { ColumnDef } from '@tanstack/react-table';
import type { ISummary } from '../../interfaces/ISummary';
import type { ISummaryElement } from '../../interfaces/ISummaryElement';
import Common from '../shared/Common';
import { Gallery, Item, useGallery } from 'react-photoswipe-gallery';
import { SortUpIcon, SortDownIcon, GalleryIcon } from '../shared/icons/Svgs';

import './style.css';

const Summary = () => {
  type ImageWithSize = { pictureBase64: string; width: number; height: number };

  const [summary, setSummary] = useState<ISummary>({
    name: '',
    elements: [],
  });
  const [selectedPicture, setSelectedPicture] = useState<ImageWithSize | undefined>();
  const [galleryOpenTrigger, setGalleryOpenTrigger] = useState<number>(0);
  const [summaryLoading, setSummaryLoading] = useState<boolean>(true);
  const [pictureLoading, setPictureLoading] = useState<boolean>(false);
  const [summaryNotFound, setSummaryNotFound] = useState<boolean>(false);
  const [loadedPictures, setLoadedPictures] = useState<Map<number, ImageWithSize>>(new Map<number, ImageWithSize>());
  const [viewMode, setViewMode] = useState<number>(0);

  const GalleryContent = ({
    selectedPicture,
    openTrigger,
  }: {
    selectedPicture: ImageWithSize | undefined;
    openTrigger: number;
  }) => {
    const { open } = useGallery();

    useEffect(() => {
      if (openTrigger > 0) {
        open(0);
      }
    }, [openTrigger, open]);

    return (
      <Item original={selectedPicture?.pictureBase64} width={selectedPicture?.width} height={selectedPicture?.height}>
        {({ ref }) => <span ref={ref} />}
      </Item>
    );
  };

  var showPrices = (viewMode & 0b000001) > 0;
  var showLocations = (viewMode & 0b000010) > 0;
  var showCategories = (viewMode & 0b000100) > 0;
  var showDates = (viewMode & 0b001000) > 0;
  var showPictures = (viewMode & 0b010000) > 0;
  var showStatistics = (viewMode & 0b100000) > 0;

  const fetchIdRef = useRef(0);
  const params = useParams();

  const columns = useMemo<ColumnDef<ISummaryElement>[]>(() => {
    const columns: ColumnDef<ISummaryElement>[] = [];
    if (showDates) {
      columns.push({
        accessorFn: (row) => (row.payTime ? new Date(row.payTime) : new Date()),
        id: 'payTime',
        header: () => <span style={{ userSelect: 'none' }}>Data</span>,
        cell: (row) => <span>{Common.Utils.getDatetimeWithoutZoneConverter(row.row.original.payTime ?? '')}</span>,
      });
    }
    columns.push({
      accessorFn: (row) => row.description,
      id: 'name',
      header: () => <span style={{ userSelect: 'none' }}>Opis</span>,
    });
    if (showLocations) {
      columns.push({
        accessorFn: (row) => row.locationName ?? '',
        id: 'locationName',
        header: () => <span style={{ userSelect: 'none' }}>Lokalizacja</span>,
      });
    }
    if (showCategories) {
      columns.push({
        accessorFn: (row) => row.categoryName ?? '',
        id: 'categoryName',
        header: () => <span style={{ userSelect: 'none' }}>Kategoria</span>,
      });
    }
    if (showPrices) {
      columns.push({
        accessorFn: (row) => row.cost,
        id: 'cost',
        header: () => <span style={{ userSelect: 'none' }}>Koszt</span>,
        cell: (row) => <div>{Common.Utils.getCurrencyString(row.row.original.cost ?? 0)}</div>,
      });
    }
    if (showPictures) {
      columns.push({
        accessorFn: (row) => row.categoryName ?? '',
        id: 'picture',
        header: () => <span style={{ userSelect: 'none' }} />,
        cell: (info) => (
          <div>
            {(info.row.original.billItemPictureId ?? 0) > 0 && (
              <div
                style={{ cursor: 'pointer' }}
                title='Pokaż zdjęcie'
                onClick={async () => {
                  const loadedPicture = loadedPictures.get(info.row.original.billItemPictureId!);
                  if (loadedPicture) {
                    setSelectedPicture(loadedPicture);
                    setGalleryOpenTrigger((prev) => prev + 1);
                  } else {
                    const fetchId = ++fetchIdRef.current;

                    const response = await Common.authorizedFetch(
                      'api/summaries/getSummaryElementPicture?summaryId=' +
                        params.id +
                        '&pictureId=' +
                        info.row.original.billItemPictureId
                    );
                    if (fetchId === fetchIdRef.current) {
                      const data = await response.json();
                      if (data.success && data.result.billPicture) {
                        const img = new Image();
                        img.onload = function () {
                          const picture: ImageWithSize = {
                            width: img.width,
                            height: img.height,
                            pictureBase64: img.src,
                          };
                          setSelectedPicture(picture);
                          setGalleryOpenTrigger((prev) => prev + 1);
                          setLoadedPictures(new Map(loadedPictures.set(info.row.original.billItemPictureId!, picture)));
                        };
                        img.src = data.result.billPicture;
                      }
                    }
                    setPictureLoading(false);
                  }
                }}
              >
                <GalleryIcon
                  style={{
                    color: 'var(--app-main-color)',
                    width: '28px',
                    height: '28px',
                  }}
                />
              </div>
            )}
          </div>
        ),
      });
    }
    return columns;
  }, [showPrices, showLocations, showDates, showCategories, showPictures, loadedPictures, params.id]);

  const table = useReactTable({
    data: summary?.elements ?? [],
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });
  const fetchUserData = useCallback(async (id: string) => {
    setSummaryLoading(true);
    const fetchId = ++fetchIdRef.current;

    const response = await Common.authorizedFetch('api/summaries/getSummary?id=' + id);
    if (fetchId === fetchIdRef.current) {
      const data = await response.json();
      if (data.success) {
        if (!data.result.summary) {
          setSummaryNotFound(true);
        } else {
          setViewMode(data.result.viewMode);
          setSummary(data.result.summary);
        }
      }
      setSummaryLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchUserData(params.id ?? '');
  }, [fetchUserData, params.id]);

  const getOverallStatisticsCardContent = () => {
    const jsMaxTimestamp = 8640000000000000;
    const maxDate = new Date(
      Math.max(
        ...summary.elements.map((e) => (e.payTime ? new Date(e.payTime) : new Date(-jsMaxTimestamp))).map(Number)
      )
    );
    const minDate = new Date(
      Math.min(...summary.elements.map((e) => (e.payTime ? new Date(e.payTime) : new Date(jsMaxTimestamp))).map(Number))
    );

    const datediff = (first: Date, second: Date) =>
      Math.round((second.valueOf() - first.valueOf()) / (1000 * 60 * 60 * 24));

    const diff = datediff(minDate, maxDate);

    return (
      <Container>
        <table className='table table-without-border-bottom' style={{ marginBottom: '11px' }}>
          <tbody>
            {showPrices && (
              <tr>
                <td style={{ width: '220px' }}>Suma wydanych pieniędzy</td>
                <td>{Common.Utils.getCurrencyString(totalPrice)}</td>
              </tr>
            )}
            <tr>
              <td style={{ width: '220px' }}>Liczba elementów</td>
              <td>{summary.elements.length}</td>
            </tr>
            {showDates && (
              <>
                <tr>
                  <td>Czas trwania</td>
                  <td>{Number.isNaN(diff) ? 0 : diff}</td>
                </tr>
                <tr>
                  <td>Rozpoczęto</td>
                  <td>{Common.Utils.getDatetimeWithoutZoneConverter(minDate.toDateString())}</td>
                </tr>
                <tr>
                  <td>Ostatnie</td>
                  <td>{Common.Utils.getDatetimeWithoutZoneConverter(maxDate.toDateString())}</td>
                </tr>
              </>
            )}
          </tbody>
        </table>
      </Container>
    );
  };

  const getLocationsStatisticsCardContent = () => {
    const getTotalPriceForSummaryElements = (elements: ISummaryElement[]) =>
      elements.reduce((previousValue, currentValue) => previousValue + (currentValue.cost ?? 0), 0);
    const locationsRows = [];
    const locationsGroups = Common.Utils.groupBy(summary.elements, (e) => e.locationName ?? '');
    const sortableArray = Object.entries(locationsGroups);
    var sortedArray = sortableArray.sort(
      ([, a], [, b]) => getTotalPriceForSummaryElements(b) - getTotalPriceForSummaryElements(a)
    );
    const sortedLocationsGroups = Object.fromEntries(sortedArray);
    const sortedLocationsKeys = Object.keys(sortedLocationsGroups);
    for (var ind = 0; ind < sortedLocationsKeys.length; ind++) {
      const location = sortedLocationsKeys[ind];
      const elements = sortedLocationsGroups[location];
      const totalPrice = getTotalPriceForSummaryElements(elements);
      locationsRows.push(
        <tr key={ind}>
          <td>{location}</td>
          <td>{Common.Utils.getCurrencyString(totalPrice)}</td>
        </tr>
      );
    }
    return (
      <Container>
        <table className='table table-without-border-bottom' style={{ marginBottom: '11px' }}>
          <tbody>{locationsRows}</tbody>
        </table>
      </Container>
    );
  };

  const getCategoriesStatisticsCardContent = () => {
    const getTotalPriceForSummaryElements = (elements: ISummaryElement[]) =>
      elements.reduce((previousValue, currentValue) => previousValue + (currentValue.cost ?? 0), 0);
    const categoriesRows = [];
    const categoriesGroups = Common.Utils.groupBy(summary.elements, (e) => e.categoryName ?? '');
    const sortableArray = Object.entries(categoriesGroups);
    var sortedArray = sortableArray.sort(
      ([, a], [, b]) => getTotalPriceForSummaryElements(b) - getTotalPriceForSummaryElements(a)
    );
    const sortedCategoriesGroups = Object.fromEntries(sortedArray);
    const sortedCategoriesKeys = Object.keys(sortedCategoriesGroups);
    for (var ind = 0; ind < sortedCategoriesKeys.length; ind++) {
      const location = sortedCategoriesKeys[ind];
      const elements = sortedCategoriesGroups[location];
      const totalPrice = getTotalPriceForSummaryElements(elements);
      categoriesRows.push(
        <tr key={ind}>
          <td>{location}</td>
          <td>{Common.Utils.getCurrencyString(totalPrice)}</td>
        </tr>
      );
    }
    return (
      <Container>
        <table className='table table-without-border-bottom' style={{ marginBottom: '11px' }}>
          <tbody>{categoriesRows}</tbody>
        </table>
      </Container>
    );
  };

  const totalPrice = summary.elements
    .map((e) => e.cost ?? 0)
    .reduce((previousValue, currentValue) => previousValue + currentValue, 0);

  return (
    <>
      <div>
        {selectedPicture && (
          <Gallery>
            <GalleryContent selectedPicture={selectedPicture} openTrigger={galleryOpenTrigger} />
          </Gallery>
        )}
      </div>
      <Container className='mt-2'>
        {summaryNotFound ? (
          <h1 className='text-danger'>Podsumowanie niedostępne</h1>
        ) : (
          <>
            {Common.Ui.showLoadingSpinnerFixed(summaryLoading || pictureLoading)}
            {summaryLoading && <h3 className='mb-3'>Trwa ładowanie podsumowania</h3>}
            <Container>
              <Row className='mt-2'>
                {!summaryLoading && (
                  <div>
                    <Card className='my-2 p-0'>
                      <Card.Header className='summary-statistics-card-header'>
                        Podsumowanie <b>{summary.name}</b>
                      </Card.Header>
                      <Card.Body className='py-0'>
                        <div className='table-responsive-sm'>
                          <table
                            id='summary-elements-table'
                            className='table table-without-border-bottom'
                            style={{ marginBottom: '11px' }}
                          >
                            <thead>
                              {table.getHeaderGroups().map((headerGroup) => (
                                <tr key={headerGroup.id}>
                                  {headerGroup.headers.map((header) => (
                                    <th key={header.id}>
                                      {header.isPlaceholder ? null : (
                                        <div
                                          {...{
                                            style: header.column.getCanSort()
                                              ? {
                                                  cursor: 'pointer',
                                                }
                                              : {},
                                            onClick: header.column.getToggleSortingHandler(),
                                          }}
                                        >
                                          {flexRender(header.column.columnDef.header, header.getContext())}
                                          {{
                                            asc: <SortUpIcon style={{ marginLeft: '5px' }} />,
                                            desc: <SortDownIcon style={{ marginLeft: '5px' }} />,
                                          }[header.column.getIsSorted() as string] ?? null}
                                        </div>
                                      )}
                                    </th>
                                  ))}
                                </tr>
                              ))}
                            </thead>
                            <tbody>
                              {summaryLoading
                                ? Common.Ui.getSkeletonTableRows(6, 6, 35)
                                : table.getRowModel().rows.map((row) => (
                                    <tr key={row.id}>
                                      {row.getVisibleCells().map((cell) => (
                                        <td key={cell.id}>
                                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </td>
                                      ))}
                                    </tr>
                                  ))}
                            </tbody>
                          </table>
                        </div>
                      </Card.Body>
                    </Card>
                    {showStatistics && (
                      <Row>
                        <Col xl={4} md={6}>
                          <Card className='my-2'>
                            <Card.Header className='summary-statistics-card-header'>Statystyki</Card.Header>
                            {getOverallStatisticsCardContent()}
                          </Card>
                        </Col>
                        {showLocations && (
                          <Col xl={4} md={6}>
                            <Card className='my-2'>
                              <Card.Header className='summary-statistics-card-header'>Lokalizacje</Card.Header>
                              {getLocationsStatisticsCardContent()}
                            </Card>
                          </Col>
                        )}
                        {showCategories && (
                          <Col xl={4} md={6}>
                            <Card className='my-2'>
                              <Card.Header className='summary-statistics-card-header'>Kategorie</Card.Header>
                              {getCategoriesStatisticsCardContent()}
                            </Card>
                          </Col>
                        )}
                      </Row>
                    )}
                  </div>
                )}
              </Row>
            </Container>
          </>
        )}
      </Container>
    </>
  );
};

export default Summary;
