export interface ICompanyProductHistory {
  id: number;
  date: Date;
  type: ProductHistoryType;
  oldValue: string;
  newValue: string;
}

export enum ProductHistoryType {
  Create = 1,
  Wieght = 2,
  Ean = 3,
  Brand = 4,
  Name = 5,
  ImageUrl = 6,
  Quantity = 7,
  Url = 8,
}
