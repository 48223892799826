import React, { useState, useCallback, useContext } from 'react';
import { Container } from 'react-bootstrap';
import Common from '../../shared/Common';
import type { IGroup } from '../../../interfaces/IGroup';
import type { ColumnDef } from '@tanstack/react-table';
import type { IGroupData } from '../../../interfaces/IGroupData';
import AddGroupModal from './AddGroupModal';
import { DictionaryContext } from '../../../App';
import GroupsTable from './GroupsTable';

import './style.css';

const Groups = () => {
  const [groups, setGroups] = useState<IGroupData[]>([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [removeButtonDisabled, setRemoveButtonDisabled] = useState(true);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const [updatedGroups, setUpdatedGroups] = useState<IGroup[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<IGroupData | null>(null);
  const [selectedGroupChilds, setSelectedGroupChilds] = useState<IGroupData[]>([]);
  const [selectedGroupAncestors, setSelectedGroupAncestors] = useState<IGroupData[]>([]);

  const [fetchTrigger, setFetchTrigger] = useState(0);
  const fetchDataIdRef = React.useRef(0);
  const { groupsNames } = useContext(DictionaryContext);

  const fetchData = useCallback(async () => {
    const fetchId = ++fetchDataIdRef.current;
    setLoading(true);
    const response = await Common.authorizedFetch('api/groups/getGroupsData');
    const data = await response.json();
    if (data.success) {
      if (fetchId === fetchDataIdRef.current) {
        setGroups(data.result.groups);
        setLoading(false);
      }
      setRemoveButtonDisabled(true);
      setSaveButtonDisabled(true);
      setSelectedGroup(null);
      setSelectedGroupChilds([]);
      setSelectedGroupAncestors([]);
      setUpdatedGroups([]);
    }
  }, []);

  const columns = React.useMemo<ColumnDef<IGroupData>[]>(
    () => [
      {
        header: () => <span>Pełna nazwa</span>,
        accessorFn: (row) => row.group?.groupFullName,
        id: 'groupFullName',
      },
      {
        header: () => <span>Nazwa</span>,
        accessorFn: (row) => row.group?.groupName,
        id: 'groupName',
      },
      {
        header: () => <span>Głębokość</span>,
        accessorFn: (row) => row.group?.groupLevel,
        id: 'groupLevel',
      },
      {
        header: () => <span>Grupa nadrzędna</span>,
        accessorFn: (row) => row.parentGroup?.groupFullName,
        id: 'parentGroupFullName',
      },
      {
        header: () => <span>Wykorzystano</span>,
        accessorFn: (row) => row.count,
        id: 'count',
      },
      {
        header: () => <span>Zapłacono</span>,
        accessorFn: (row) => (Math.round(row.totalCost * 100) / 100).toFixed(2),
        id: 'totalCost',
      },
    ],
    []
  );

  const updateGroupName = (rowIndex: number, columnId: string, value: any) => {
    setGroups((prevState) => {
      const updated = prevState;
      if (updated[rowIndex].group.groupName !== value) {
        const ind = updatedGroups.findIndex((l) => l.groupId === updated[rowIndex].group.groupId);
        if (ind === -1) {
          updatedGroups.push({
            ...updated[rowIndex].group,
            groupName: value,
          });
        } else {
          updatedGroups[ind].groupName = value;
        }
        setUpdatedGroups(updatedGroups);
      }
      updated[rowIndex] = {
        ...prevState[rowIndex],
        [columnId]: value,
      };

      return updated;
    });
  };

  const updateParentGroupId = (rowIndex: number, columnId: string, value: any) => {
    setGroups((prevState) => {
      const updated = prevState;
      if (updated[rowIndex].group.groupId !== 1 && updated[rowIndex].parentGroup?.groupFullName !== value) {
        const ind = updatedGroups.findIndex((l) => l.groupId === updated[rowIndex].group.groupId);
        if (ind === -1) {
          updatedGroups.push({
            ...updated[rowIndex].group,
            parentGroupId: value,
          });
        } else {
          updatedGroups[ind].parentGroupId = value;
        }
        setUpdatedGroups(updatedGroups);
      }
      updated[rowIndex] = {
        ...prevState[rowIndex],
        [columnId]: value,
      };

      return updated;
    });
  };

  return (
    <Container className='mt-2'>
      <h2 className='mb-3' id='tabelLabel'>
        Grupy
      </h2>
      {Common.Ui.showLoadingSpinnerFixed(showSpinner)}
      <GroupsTable
        columns={columns}
        data={groups}
        loading={loading}
        setShowSpinner={setShowSpinner}
        fetchData={fetchData}
        fetchTrigger={fetchTrigger}
        setFetchTrigger={setFetchTrigger}
        updateGroupName={updateGroupName}
        updateParentGroupId={updateParentGroupId}
        updatedGroups={updatedGroups}
        setShowModal={setShowModal}
        saveButtonDisabled={saveButtonDisabled}
        removeButtonDisabled={removeButtonDisabled}
        setSaveButtonDisabled={setSaveButtonDisabled}
        setRemoveButtonDisabled={setRemoveButtonDisabled}
        selectedGroup={selectedGroup}
        setSelectedGroup={setSelectedGroup}
        selectedGroupChilds={selectedGroupChilds}
        setSelectedGroupChilds={setSelectedGroupChilds}
        selectedGroupAncestors={selectedGroupAncestors}
        setSelectedGroupAncestors={setSelectedGroupAncestors}
        groupsNames={groupsNames}
      />
      <AddGroupModal
        show={showModal}
        onHide={() => {
          setShowModal(false);
        }}
        onSave={() => {
          setFetchTrigger(fetchTrigger + 1);
        }}
        groupsNames={groupsNames}
      />
    </Container>
  );
};

export default Groups;
