import { useState, useRef, useEffect, useCallback } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { useParams } from 'react-router-dom';
import type { ILoginProvider } from '../../../../interfaces/ILoginProvider';
import type { IPowerBiReportPage } from '../../../../interfaces/IPowerBiReportPage';
import type { IUserPreference } from '../../../../interfaces/IUserPreference';
import type { IApplicationAlert } from '../../../shared/application-alert/ApplicationAlert';
import ApplicationAlert from '../../../shared/application-alert/ApplicationAlert';
import { Common } from '../../../shared/Common';

export interface IUserToManageData {
  id: string;
  userName: string;
  phoneNumber: string;
  hasAvatar: boolean;
  isEmailConfirmed: boolean;
  email: string;
  hasPassword: boolean;
  currentLogins: ILoginProvider[];
  hasAuthenticator: boolean;
  is2faEnabled: boolean;
  userRoles: string[];
  allRoles: string[];
  userReportPages: IPowerBiReportPage[];
  allPowerBiReportPages: IPowerBiReportPage[];
  userPreference: IUserPreference;
}

export interface IRoleValue {
  roleName: string;
  roleValue: boolean;
}

export interface IPowerBiReportPageValue {
  page: IPowerBiReportPage;
  value: boolean;
}

const ManageUser = () => {
  const [fetchDataTrigger, setFetchDataTrigger] = useState<number>(0);
  const [userData, setUserData] = useState<IUserToManageData | undefined>(undefined);
  const [userRolesToSave, setUserRolesToSave] = useState<IRoleValue[]>([]);
  const [userPowerBiPagesToSave, setUserPowerBiPagesToSave] = useState<IPowerBiReportPageValue[]>([]);
  const [userPreferenceToSave, setUserPreferenceToSave] = useState<IUserPreference | undefined>(undefined);
  const [userRolesChanged, setUserRolesChanged] = useState(false);
  const [userPowerBiPagesChanged, setUserPowerBiPagesChanged] = useState(false);
  const [userPreferencesChanged, setUserPreferencesChanged] = useState(false);

  const [showLoadingSpinner, setShowLoadingSpinner] = useState<boolean>(false);
  const [status, setStatus] = useState<IApplicationAlert>();
  const [loading, setLoading] = useState<boolean>(false);
  const [rolesLoading, setRolesLoading] = useState<boolean>(false);
  const [powerBiPagesLoading, setPowerBiPagesLoading] = useState<boolean>(false);
  const [userPreferencesLoading, setUserPreferencesLoading] = useState<boolean>(false);

  const fetchIdRef = useRef(0);
  const params = useParams();

  const fetchUserData = useCallback(async (id: string) => {
    setLoading(true);
    const fetchId = ++fetchIdRef.current;

    const response = await Common.authorizedFetch('api/users/getUserToManageData?id=' + id);
    if (fetchId === fetchIdRef.current) {
      const data = await response.json();
      if (data.success) {
        setUserData(data.result);
      }
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchUserData(params.id ?? '');
  }, [fetchUserData, params.id, fetchDataTrigger]);

  useEffect(() => {
    if (userData) {
      setLoading(true);
      var userRolesData: IRoleValue[] = [];
      userData?.allRoles.forEach((r) => {
        if (userData.userRoles.findIndex((ur) => ur === r) > -1) {
          userRolesData.push({ roleName: r, roleValue: true });
        } else {
          userRolesData.push({ roleName: r, roleValue: false });
        }
      });
      var userPowerBiPages: IPowerBiReportPageValue[] = [];
      userData?.allPowerBiReportPages.forEach((r) => {
        if (userData.userReportPages.findIndex((p) => p.id === r.id) > -1) {
          userPowerBiPages.push({ page: r, value: true });
        } else {
          userPowerBiPages.push({ page: r, value: false });
        }
      });
      setUserRolesToSave(userRolesData);
      setUserPowerBiPagesToSave(userPowerBiPages);
      if (userData !== undefined) {
        setUserPreferenceToSave({ ...userData.userPreference });
      }
      setLoading(false);
    }
  }, [userData]);

  const generateUserRolesSwitches = (currentRoles: IRoleValue[]) => {
    let userRolesSwitches = [];
    for (let ind = 0; ind < currentRoles.length; ind++) {
      userRolesSwitches.push(
        <Col xs={6} md={4} key={ind}>
          <Form.Check
            type='switch'
            id={currentRoles[ind].roleName.toLowerCase() + '-switch'}
            style={{ userSelect: 'none' }}
            label={currentRoles[ind].roleName}
            defaultChecked={currentRoles[ind].roleValue}
            onChange={() => {
              setUserRolesChanged(true);
              const updatedRolesToSave = [...userRolesToSave];
              updatedRolesToSave[ind] = { ...updatedRolesToSave[ind], roleValue: !updatedRolesToSave[ind].roleValue };
              setUserRolesToSave(updatedRolesToSave);
            }}
          />
        </Col>
      );
    }
    return userRolesSwitches;
  };

  const generateUserPowerBiPagesSwitches = (currentPages: IPowerBiReportPageValue[]) => {
    const ind = userRolesToSave.findIndex((r) => r.roleName === 'ReportViewer' && r.roleValue === true);
    let powerBiReportPageSwitchesDisabled = ind === -1;
    let userPagesSwitches = [];
    for (let ind = 0; ind < currentPages.length; ind++) {
      userPagesSwitches.push(
        <Col xs={6} md={4} key={ind}>
          <Form.Check
            disabled={powerBiReportPageSwitchesDisabled}
            type='switch'
            id={currentPages[ind].page.code.toLowerCase() + '-switch'}
            style={{ userSelect: 'none' }}
            label={currentPages[ind].page.name}
            defaultChecked={currentPages[ind].value}
            onChange={() => {
              setUserPowerBiPagesChanged(true);
              const updatedUserPowerBiPagesToSave = [...userPowerBiPagesToSave];
              updatedUserPowerBiPagesToSave[ind] = {
                ...userPowerBiPagesToSave[ind],
                value: !userPowerBiPagesToSave[ind].value,
              };
              setUserPowerBiPagesToSave(updatedUserPowerBiPagesToSave);
            }}
          />
        </Col>
      );
    }
    return userPagesSwitches;
  };

  const generateUserPreferencesSwitches = (userPreferences: IUserPreference) => (
    <>
      <Col xs={6} md={4}>
        <Form.Group as={Row} className='mb-3 align-items-center'>
          <Col xs='auto' className='pe-0'>
            <Form.Control
              style={{ width: '50px' }}
              type='number'
              defaultValue={userPreferences.recurringOperationDashboardStatsCount}
              size='sm'
              max={7}
              min={0}
              onChange={(event) => {
                const number = Number(event.target.value);
                if (number) {
                  setUserPreferencesChanged(true);
                  const updatedUserPreferencesToSave = { ...userPreferences };
                  updatedUserPreferencesToSave.recurringOperationDashboardStatsCount = number;
                  setUserPreferenceToSave(updatedUserPreferencesToSave);
                }
              }}
            />
          </Col>
          <Col>
            <Form.Label>Liczba prezentowanych operacji cyklicznych</Form.Label>
          </Col>
        </Form.Group>
      </Col>
      <Col xs={6} md={4}>
        <Form.Check
          type='switch'
          id='showWeeklyExpensesDashboardStats-switch'
          style={{ userSelect: 'none' }}
          label='Pokaż tygodniowe wydatki'
          defaultChecked={userPreferences.showWeeklyExpensesDashboardStats}
          onChange={() => {
            setUserPreferencesChanged(true);
            const updatedUserPreferencesToSave = { ...userPreferences };
            updatedUserPreferencesToSave.showWeeklyExpensesDashboardStats =
              !updatedUserPreferencesToSave.showWeeklyExpensesDashboardStats;
            setUserPreferenceToSave(updatedUserPreferencesToSave);
          }}
        />
      </Col>
      <Col xs={6} md={4}>
        <Form.Check
          type='switch'
          id='showMonthlyExpensesDashboardStats-switch'
          style={{ userSelect: 'none' }}
          label='Pokaż miesięczne wydatki'
          defaultChecked={userPreferences.showMonthlyExpensesDashboardStats}
          onChange={() => {
            setUserPreferencesChanged(true);
            const updatedUserPreferencesToSave = { ...userPreferences };
            updatedUserPreferencesToSave.showMonthlyExpensesDashboardStats =
              !updatedUserPreferencesToSave.showMonthlyExpensesDashboardStats;
            setUserPreferenceToSave(updatedUserPreferencesToSave);
          }}
        />
      </Col>
      <Col xs={6} md={4}>
        <Form.Check
          type='switch'
          id='showYearlyExpensesDashboardStats-switch'
          style={{ userSelect: 'none' }}
          label='Pokaż roczne wydatki'
          defaultChecked={userPreferences.showYearlyExpensesDashboardStats}
          onChange={() => {
            setUserPreferencesChanged(true);
            const updatedUserPreferencesToSave = { ...userPreferences };
            updatedUserPreferencesToSave.showYearlyExpensesDashboardStats =
              !updatedUserPreferencesToSave.showYearlyExpensesDashboardStats;
            setUserPreferenceToSave(updatedUserPreferencesToSave);
          }}
        />
      </Col>
      <Col xs={6} md={4}>
        <Form.Check
          type='switch'
          id='showSummaryTotalDashboardStats-switch'
          style={{ userSelect: 'none' }}
          label='Pokaż sumaryczne statystyki'
          defaultChecked={userPreferences.showSummaryTotalDashboardStats}
          onChange={() => {
            setUserPreferencesChanged(true);
            const updatedUserPreferencesToSave = { ...userPreferences };
            updatedUserPreferencesToSave.showSummaryTotalDashboardStats =
              !updatedUserPreferencesToSave.showSummaryTotalDashboardStats;
            setUserPreferenceToSave(updatedUserPreferencesToSave);
          }}
        />
      </Col>
      <Col xs={6} md={4}>
        <Form.Check
          type='switch'
          id='showSummaryLocationsDashboardStats-switch'
          style={{ userSelect: 'none' }}
          label='Pokaż najliczniejsze lokalizacje'
          defaultChecked={userPreferences.showSummaryLocationsDashboardStats}
          onChange={() => {
            setUserPreferencesChanged(true);
            const updatedUserPreferencesToSave = { ...userPreferences };
            updatedUserPreferencesToSave.showSummaryLocationsDashboardStats =
              !updatedUserPreferencesToSave.showSummaryLocationsDashboardStats;
            setUserPreferenceToSave(updatedUserPreferencesToSave);
          }}
        />
      </Col>
    </>
  );

  const generateUserResetPasswordLink = async (id: string) => {
    setShowLoadingSpinner(true);
    const fetchId = ++fetchIdRef.current;

    const response = await Common.authorizedFetch(
      'api/applicationLogicConfiguration/generateResetUserPasswordLink?id=' + id
    );
    if (fetchId === fetchIdRef.current) {
      const data = await response.json();
      if (data.success) {
        console.log(data.result.resetPasswordLink);
        setStatus({
          variant: 'info',
          status: data.result.resetPasswordLink,
          messageTime: Date.now(),
        });
      } else {
        setStatus({
          variant: 'error',
          status: data.errors,
          messageTime: Date.now(),
        });
      }
      setShowLoadingSpinner(false);
    }
  };

  const updateUserData = async () => {
    const promises: Promise<Boolean>[] = [];
    if (userRolesChanged) {
      promises.push(updateUserRoles());
    }
    if (userPowerBiPagesChanged) {
      promises.push(updateUserPowerBiPages());
    }
    if (userPreferencesChanged) {
      promises.push(updateUserPreferences());
    }
    if (promises.length > 0) {
      const results = await Promise.all(promises);
      if (results.includes(true)) {
        setFetchDataTrigger((prev) => prev + 1);
      }
    }
    setUserRolesChanged(false);
    setUserPowerBiPagesChanged(false);
    setUserPreferencesChanged(false);
    return;
  };

  const updateUserRoles = async () => {
    setRolesLoading(true);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        userRoles: userRolesToSave,
        userId: params.id ?? '',
      }),
    };
    const response = await Common.authorizedFetch('api/roles/updateUserRoles', requestOptions);
    const data = await response.json();
    setRolesLoading(false);
    if (!data.success) {
      alert(data.errors);
      return false;
    } else {
      return true;
    }
  };

  const updateUserPreferences = async () => {
    setUserPreferencesLoading(true);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ userPreference: userPreferenceToSave, userId: params.id ?? '' }),
    };
    const response = await Common.authorizedFetch(
      'api/applicationLogicConfiguration/updateUserPreferences',
      requestOptions
    );
    const data = await response.json();
    setUserPreferencesLoading(false);
    if (!data.success) {
      alert(data.errors);
      return false;
    } else {
      return true;
    }
  };

  const updateUserPowerBiPages = async () => {
    setPowerBiPagesLoading(true);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        userPowerBiPages: userPowerBiPagesToSave,
        userId: params.id ?? '',
      }),
    };
    const response = await Common.authorizedFetch(
      'api/applicationLogicConfiguration/updateUserPowerBiPages',
      requestOptions
    );
    const data = await response.json();
    setPowerBiPagesLoading(false);
    if (!data.success) {
      alert(data.errors);
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      {Common.Ui.showLoadingSpinnerFixed(showLoadingSpinner)}
      {status && (
        <ApplicationAlert variant={status?.variant} status={status?.status} messageTime={status?.messageTime} />
      )}
      <h4>Zarządzaj użytkownikiem</h4>
      <h5>Akcje</h5>
      <Button
        variant='dark'
        onClick={() => generateUserResetPasswordLink(params.id ?? '')}
        disabled={loading || rolesLoading || powerBiPagesLoading || userPreferencesLoading}
      >
        Zmiana hasła
      </Button>
      <h5 className='mt-3'>Informacje</h5>
      <div className='table-responsive-sm'>
        <table className='table'>
          <thead className='thead-dark'>
            <tr>
              <th scope='col' style={{ width: '30%' }}>
                Nazwa
              </th>
              <th scope='col'>Wartość</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Identiyfikator</td>
              <td>{loading ? <Skeleton /> : userData?.id}</td>
            </tr>
            <tr>
              <td>Nazwa użytkownika</td>
              <td>{loading ? <Skeleton /> : userData?.userName}</td>
            </tr>
            <tr>
              <td>Numer telefonu</td>
              <td>{loading ? <Skeleton /> : userData?.phoneNumber}</td>
            </tr>
            <tr>
              <td>Adres e-mail</td>
              <td>{loading ? <Skeleton /> : userData?.email}</td>
            </tr>
            <tr>
              <td>Role użytkownika</td>
              <td>{loading || rolesLoading ? <Skeleton /> : userData?.userRoles?.join(', ')}</td>
            </tr>
            <tr>
              <td>Widoczne strony Power BI</td>
              <td>
                {loading || powerBiPagesLoading ? (
                  <Skeleton />
                ) : (
                  userData?.userReportPages?.map((p) => p.name).join(', ')
                )}
              </td>
            </tr>
            <tr>
              <td>Dostawcy logowania</td>
              <td>{loading ? <Skeleton /> : userData?.currentLogins?.map((l) => l.providerDisplayName)?.join(', ')}</td>
            </tr>
            <tr>
              <td>Czy ma hasło</td>
              <td>{loading ? <Skeleton /> : userData?.isEmailConfirmed ? 'Tak' : 'Nie'}</td>
            </tr>
            <tr>
              <td>Czy e-mail potwierdzony</td>
              <td>{loading ? <Skeleton /> : userData?.isEmailConfirmed ? 'Tak' : 'Nie'}</td>
            </tr>
            <tr>
              <td>Czy ma avatar</td>
              <td>{loading ? <Skeleton /> : userData?.hasAvatar ? 'Tak' : 'Nie'}</td>
            </tr>
            <tr>
              <td>Czy ma włączone 2FA</td>
              <td>{loading ? <Skeleton /> : userData?.is2faEnabled ? 'Tak' : 'Nie'}</td>
            </tr>
            <tr>
              <td>Czy ma autentykator</td>
              <td>{loading ? <Skeleton /> : userData?.hasAuthenticator ? 'Tak' : 'Nie'}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <h5 className='mt-3'>Role</h5>
      <Row className='mt-2 table-responsive-sm'>
        {loading || rolesLoading || userRolesToSave.length === 0 ? (
          <Skeleton />
        ) : (
          generateUserRolesSwitches(userRolesToSave)
        )}
      </Row>
      <h5 className='mt-3'>Raporty Power BI</h5>
      <Row className='mt-2 table-responsive-sm'>
        {loading || powerBiPagesLoading || userPowerBiPagesToSave.length === 0 ? (
          <Skeleton />
        ) : (
          generateUserPowerBiPagesSwitches(userPowerBiPagesToSave)
        )}
      </Row>
      <h5 className='mt-3'>Ustawienia użytkownika</h5>
      <Row className='mt-2'>
        {loading || userPreferencesLoading || userPreferenceToSave === undefined ? (
          <Skeleton />
        ) : (
          generateUserPreferencesSwitches(userPreferenceToSave)
        )}
      </Row>
      <Button
        className='mt-3'
        variant='primary'
        onClick={updateUserData}
        disabled={
          loading ||
          rolesLoading ||
          powerBiPagesLoading ||
          userPreferencesLoading ||
          (!userRolesChanged && !userPowerBiPagesChanged && !userPreferencesChanged)
        }
      >
        Zapisz
      </Button>
    </>
  );
};

export default ManageUser;
