import { useCallback, useEffect, useRef, useState } from 'react';
import { Card, CloseButton, Col, Collapse, Container, ListGroup, Row } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import type { IUserPreference } from '../../interfaces/IUserPreference';
import Common from '../shared/Common';
import { SettingsIcon } from '../shared/icons/Svgs';
import './style.css';

interface IRecurringOperationStatistic {
  description: string;
  cost: number;
  nextUpdate: string;
  locationImage: string | undefined;
}

interface IExpenseStatistic {
  ammount: number;
  lastAmmount: number;
  count: number;
  lastCount: number;
}

interface IDashboardExpenseStatistic {
  weeklyExpenses?: IExpenseStatistic;
  monthlyExpenses?: IExpenseStatistic;
  yearlyExpenses?: IExpenseStatistic;
}

interface ISummaryStatistic {
  totalCount: number;
  totalAmmount: number;
  mostNumerousLocationName: string;
  mostNumerousLocationPicture: string;
  mostNumerousLocationCount: number;
}

export interface IDashboardStatisticsProps {
  fetchDashboardStatisticsTrigger: number;
}

const DashboardStatistics = (props: IDashboardStatisticsProps) => {
  const [recurringOperationsStatistics, setRecurringOperationsStatistics] = useState<
    IRecurringOperationStatistic[] | undefined
  >();
  const [expesnsesStatistics, setExpesnsesStatistics] = useState<IDashboardExpenseStatistic>({});
  const [summaryStatistics, setSummaryStatistics] = useState<ISummaryStatistic | undefined>();
  const [dataExpensesReloading, setDataExpensesReloading] = useState(false);
  const [summaryReloading, setSummaryReloading] = useState(false);
  const [userPreference, setUserPreference] = useState<IUserPreference | undefined>(undefined);
  const [collapseDataExpenses, setCollapseDataExpenses] = useState(false);
  const [collapseRecurringOprations, setCollapseRecurringOprations] = useState(false);
  const [collapseSummary, setCollapseSummary] = useState(false);
  const [hideDataExpenses, setHideDataExpenses] = useState(false);
  const [hideRecurringOprations, setHideRecurringOprations] = useState(false);
  const [hideSummary, setHideSummary] = useState(false);

  const fetchRecurringOperationsIdRef = useRef(0);
  const fetchUserPreferencesIdRef = useRef(0);
  const fetchExpensesIdRef = useRef(0);
  const fetchSummaryIdRef = useRef(0);

  const fetchUserPreferences = useCallback(async () => {
    const fetchId = ++fetchUserPreferencesIdRef.current;
    const userPreferencesResponse = await Common.authorizedFetch('api/users/getUserPreferences');
    const userPreferencesData = await userPreferencesResponse.json();
    if (userPreferencesData.success) {
      if (fetchId === fetchUserPreferencesIdRef.current) {
        setUserPreference(userPreferencesData.result.userPreference);
      }
    }
  }, []);

  const fetchRecurringOperationsStatisticsData = useCallback(async () => {
    const fetchId = ++fetchRecurringOperationsIdRef.current;
    const recurringOperationsResponse = await Common.authorizedFetch(
      'api/dashboard/getRecurringOperationsStatistics?count=' + userPreference?.recurringOperationDashboardStatsCount
    );
    const recurringOperationsData = await recurringOperationsResponse.json();
    if (recurringOperationsData.success) {
      if (fetchId === fetchRecurringOperationsIdRef.current) {
        setRecurringOperationsStatistics(recurringOperationsData.result.recurringOperationsStatistics);
      }
    }
  }, [setRecurringOperationsStatistics, userPreference]);

  const fetchExpensesStatisticsData = useCallback(async () => {
    setDataExpensesReloading(true);
    const fetchId = ++fetchExpensesIdRef.current;
    const expensesResponse = await Common.authorizedFetch(
      `api/dashboard/getExpensesStatistics?showWeekly=${userPreference?.showWeeklyExpensesDashboardStats}` +
        `&showMonthly=${userPreference?.showMonthlyExpensesDashboardStats}` +
        `&showYearly=${userPreference?.showYearlyExpensesDashboardStats}`
    );
    const expensesData = await expensesResponse.json();
    if (expensesData.success) {
      if (fetchId === fetchExpensesIdRef.current) {
        setExpesnsesStatistics({
          weeklyExpenses: expensesData.result.weeklyExpenses,
          monthlyExpenses: expensesData.result.monthlyExpenses,
          yearlyExpenses: expensesData.result.yearlyExpenses,
        });
      }
    }
    setDataExpensesReloading(false);
  }, [setDataExpensesReloading, userPreference]);

  const fetchSummaryStatisticsData = useCallback(async () => {
    setSummaryReloading(true);
    const fetchId = ++fetchSummaryIdRef.current;
    const expensesResponse = await Common.authorizedFetch(
      `api/dashboard/getSummaryStatistics?showTotal=${userPreference?.showSummaryTotalDashboardStats}` +
        `&showLocations=${userPreference?.showSummaryLocationsDashboardStats}`
    );
    const summaryData = await expensesResponse.json();
    if (summaryData.success) {
      if (fetchId === fetchSummaryIdRef.current) {
        setSummaryStatistics(summaryData.result);
      }
    }
    setSummaryReloading(false);
  }, [setSummaryReloading, userPreference]);

  useEffect(() => {
    fetchUserPreferences();
  }, [fetchUserPreferences]);

  useEffect(() => {
    if ((userPreference?.recurringOperationDashboardStatsCount ?? 0) > 0) {
      fetchRecurringOperationsStatisticsData();
    }
    if (
      userPreference?.showWeeklyExpensesDashboardStats ||
      userPreference?.showMonthlyExpensesDashboardStats ||
      userPreference?.showYearlyExpensesDashboardStats
    ) {
      fetchExpensesStatisticsData();
    }
    if (userPreference?.showSummaryTotalDashboardStats || userPreference?.showSummaryLocationsDashboardStats) {
      fetchSummaryStatisticsData();
    }
  }, [userPreference, fetchRecurringOperationsStatisticsData, fetchExpensesStatisticsData, fetchSummaryStatisticsData]);

  useEffect(() => {
    if (props.fetchDashboardStatisticsTrigger > 0) {
      if (
        userPreference?.showWeeklyExpensesDashboardStats ||
        userPreference?.showMonthlyExpensesDashboardStats ||
        userPreference?.showYearlyExpensesDashboardStats
      ) {
        fetchExpensesStatisticsData();
      }
      if (userPreference?.showSummaryTotalDashboardStats || userPreference?.showSummaryLocationsDashboardStats) {
        fetchSummaryStatisticsData();
      }
    }
  }, [
    props.fetchDashboardStatisticsTrigger,
    fetchExpensesStatisticsData,
    fetchSummaryStatisticsData,
    userPreference?.showWeeklyExpensesDashboardStats,
    userPreference?.showMonthlyExpensesDashboardStats,
    userPreference?.showYearlyExpensesDashboardStats,
    userPreference?.showSummaryTotalDashboardStats,
    userPreference?.showSummaryLocationsDashboardStats,
  ]);

  const generateRecurringOperationStatisticsCard = () => {
    if (
      userPreference === undefined ||
      hideRecurringOprations ||
      userPreference.recurringOperationDashboardStatsCount === 0 ||
      recurringOperationsStatistics?.length === 0
    ) {
      return <></>;
    }
    let statistics = [];
    if (recurringOperationsStatistics === undefined) {
      for (let ind = 0; ind < userPreference.recurringOperationDashboardStatsCount; ind++) {
        statistics.push(
          <ListGroup.Item key={'recurring-operation-statistics-skeleton' + ind}>
            <Row className='py-0 px-1'>
              <Skeleton height={50} />
            </Row>
          </ListGroup.Item>
        );
      }
    } else {
      for (let ind = 0; ind < recurringOperationsStatistics.length; ind++) {
        statistics.push(
          <ListGroup.Item key={'recurring-operation-statistics-' + ind}>
            <Row className='py-0 px-1 justify-content-center align-items-center align-middle'>
              <Col sm={'auto'} className='p-0'>
                <img
                  src={
                    (recurringOperationsStatistics[ind]?.locationImage?.length ?? 0) > 0
                      ? recurringOperationsStatistics[ind].locationImage
                      : '/Static/icons/dashboard-navigation/bills.png'
                  }
                  style={{
                    height: '34px',
                    width: '34px',
                  }}
                  alt=''
                />
              </Col>
              <Col>
                {ind === 0 ? 'Najbliższa operacja to ' : 'Następna operacja to '}
                <b>{recurringOperationsStatistics[ind].description}</b> i zostanie wprowadzona{' '}
                <b>{Common.Utils.getDatetimeWithoutZoneConverter(recurringOperationsStatistics[ind].nextUpdate)}</b>.
                Kwota operacji będzie wynosić{' '}
                <b>{Common.Utils.getCurrencyString(recurringOperationsStatistics[ind].cost.toFixed(2))}</b>.
              </Col>
            </Row>
          </ListGroup.Item>
        );
      }
    }
    return (
      <Card>
        <Card.Header
          className='dashboard-statistics-card-header'
          onClick={() => setCollapseRecurringOprations((prev) => !prev)}
        >
          <CloseButton
            className='dashboard-statistics-card-header-btn-close'
            variant='white'
            onClick={() => setHideRecurringOprations(true)}
            style={{ position: 'absolute', right: 10 }}
          />
          <Link to='/settings/user/preferences'>
            <div className='dashboard-statistics-card-header-btn-settings'>
              <SettingsIcon
                style={{
                  stroke: 'white',
                  position: 'absolute',
                  right: 42,
                  top: 10,
                  color: 'white',
                  width: '20px',
                  height: '20px',
                }}
              />
            </div>
          </Link>
          Operacje cykliczne
        </Card.Header>
        <Collapse in={!collapseRecurringOprations}>
          <ListGroup className='list-group-flush' as={Link} to='/recurring-operations'>
            {statistics}
          </ListGroup>
        </Collapse>
      </Card>
    );
  };

  const generateExpenseStatisticsCard = () => {
    if (
      userPreference === undefined ||
      (!userPreference.showWeeklyExpensesDashboardStats &&
        !userPreference.showMonthlyExpensesDashboardStats &&
        !userPreference.showYearlyExpensesDashboardStats) ||
      hideDataExpenses
    ) {
      return <></>;
    }

    const getExpenseDeclination = (count: number) => {
      if (count === 1) {
        return 'wydatek';
      }
      if (count > 9 && count < 20) {
        return 'wydatków';
      }
      if (count % 10 === 2 || count % 10 === 3 || count % 10 === 4) {
        return 'wydatki';
      }
      return 'wydatków';
    };

    const generateCompareCountDescription = (expense: IExpenseStatistic) => {
      if (expense.count > expense.lastCount) {
        const diff = expense.count - expense.lastCount;
        return (
          <span>
            o{' '}
            <b>
              {diff} {Common.Utils.getNumberWithSpaces(getExpenseDeclination(diff))} więcej
            </b>
          </span>
        );
      }
      if (expense.count < expense.lastCount) {
        const diff = expense.lastCount - expense.count;
        return (
          <span>
            o{' '}
            <b>
              {diff} {Common.Utils.getNumberWithSpaces(getExpenseDeclination(diff))} mniej
            </b>
          </span>
        );
      }
      return (
        <span>
          <b>tyle samo</b> wydatków
        </span>
      );
    };

    const generateCompareAmmountDescription = (expense: IExpenseStatistic) => {
      if (expense.ammount > expense.lastAmmount) {
        const diff = (expense.ammount - expense.lastAmmount).toFixed(2);
        return (
          <span>
            za kwotę o <b className='text-danger'>{Common.Utils.getCurrencyString(diff)} większą</b>
          </span>
        );
      }
      if (expense.ammount < expense.lastAmmount) {
        const diff = (expense.lastAmmount - expense.ammount).toFixed(2);
        return (
          <span>
            za kwotę o <b className='text-success'>{Common.Utils.getCurrencyString(diff)} mniejszą</b>
          </span>
        );
      }
      return <span>za taką samą kwotę</span>;
    };

    return (
      <Card style={{ marginTop: '15px' }}>
        <Card.Header
          className='dashboard-statistics-card-header'
          onClick={() => setCollapseDataExpenses((prev) => !prev)}
        >
          <CloseButton
            className='dashboard-statistics-card-header-btn-close'
            variant='white'
            onClick={() => setHideDataExpenses(true)}
            style={{ position: 'absolute', right: 10 }}
          />
          <Link to='/settings/user/preferences'>
            <div className='dashboard-statistics-card-header-btn-settings'>
              <SettingsIcon
                style={{
                  stroke: 'white',
                  position: 'absolute',
                  right: 42,
                  top: 10,
                  color: 'white',
                  width: '20px',
                  height: '20px',
                }}
              />
            </div>
          </Link>
          Wydatki
        </Card.Header>
        <Collapse in={!collapseDataExpenses}>
          <ListGroup className='list-group-flush'>
            {userPreference.showWeeklyExpensesDashboardStats && (
              <ListGroup.Item>
                {dataExpensesReloading || expesnsesStatistics.weeklyExpenses === undefined ? (
                  <Skeleton height={50} />
                ) : (
                  <>
                    W tym tygodniu wykonano <b>{expesnsesStatistics.weeklyExpenses.count}</b>{' '}
                    {getExpenseDeclination(expesnsesStatistics.weeklyExpenses.count)} za łączną kwotę{' '}
                    <b>{Common.Utils.getCurrencyString(expesnsesStatistics.weeklyExpenses.ammount.toFixed(2))}</b>. To{' '}
                    {generateCompareCountDescription(expesnsesStatistics.weeklyExpenses)}{' '}
                    {generateCompareAmmountDescription(expesnsesStatistics.weeklyExpenses)} w porównaniu z analogicznym
                    okresem w zeszłym tygodniu.
                  </>
                )}
              </ListGroup.Item>
            )}
            {userPreference.showMonthlyExpensesDashboardStats && (
              <ListGroup.Item>
                {dataExpensesReloading || expesnsesStatistics.monthlyExpenses === undefined ? (
                  <Skeleton height={50} />
                ) : (
                  <>
                    W tym miesiącu wykonano <b>{expesnsesStatistics.monthlyExpenses.count}</b>{' '}
                    {getExpenseDeclination(expesnsesStatistics.monthlyExpenses.count)} za łączną kwotę{' '}
                    <b>{Common.Utils.getCurrencyString(expesnsesStatistics.monthlyExpenses.ammount.toFixed(2))}</b>. To{' '}
                    {generateCompareCountDescription(expesnsesStatistics.monthlyExpenses)}{' '}
                    {generateCompareAmmountDescription(expesnsesStatistics.monthlyExpenses)} w porównaniu z analogicznym
                    okresem w zeszłym miesiącu.
                  </>
                )}
              </ListGroup.Item>
            )}
            {userPreference.showYearlyExpensesDashboardStats && (
              <ListGroup.Item>
                {dataExpensesReloading || expesnsesStatistics.yearlyExpenses === undefined ? (
                  <Skeleton height={50} />
                ) : (
                  <>
                    W tym roku wykonano <b>{expesnsesStatistics.yearlyExpenses.count}</b>{' '}
                    {getExpenseDeclination(expesnsesStatistics.yearlyExpenses.count)} za łączną kwotę{' '}
                    <b>{Common.Utils.getCurrencyString(expesnsesStatistics.yearlyExpenses.ammount.toFixed(2))}</b>. To{' '}
                    {generateCompareCountDescription(expesnsesStatistics.yearlyExpenses)}{' '}
                    {generateCompareAmmountDescription(expesnsesStatistics.yearlyExpenses)} w porównaniu z analogicznym
                    okresem w zeszłym roku.
                  </>
                )}
              </ListGroup.Item>
            )}
          </ListGroup>
        </Collapse>
      </Card>
    );
  };

  const generateSummaryStatisticsCard = () => {
    const getBillsDeclination = (count: number) => {
      if (count === 1) {
        return 'rachunek';
      }
      if (count > 9 && count < 20) {
        return 'rachunków';
      }
      if (count % 10 === 2 || count % 10 === 3 || count % 10 === 4) {
        return 'rachunki';
      }
      return 'rachunków';
    };

    if (
      userPreference === undefined ||
      (!userPreference.showSummaryTotalDashboardStats && !userPreference.showSummaryLocationsDashboardStats) ||
      hideSummary
    ) {
      return <></>;
    }

    return (
      <Card style={{ marginTop: '15px' }}>
        <Card.Header className='dashboard-statistics-card-header' onClick={() => setCollapseSummary((prev) => !prev)}>
          <CloseButton
            className='dashboard-statistics-card-header-btn-close'
            variant='white'
            onClick={() => setHideSummary(true)}
            style={{ position: 'absolute', right: 10 }}
          />
          <Link to='/settings/user/preferences'>
            <div className='dashboard-statistics-card-header-btn-settings'>
              <SettingsIcon
                style={{
                  stroke: 'white',
                  position: 'absolute',
                  right: 42,
                  top: 10,
                  color: 'white',
                  width: '20px',
                  height: '20px',
                }}
              />
            </div>
          </Link>
          Statystyki
        </Card.Header>
        <Collapse in={!collapseSummary}>
          <ListGroup className='list-group-flush' as={Link} to='/statistics'>
            {userPreference.showSummaryTotalDashboardStats && (
              <ListGroup.Item>
                {summaryReloading || summaryStatistics === undefined ? (
                  <Skeleton height={50} />
                ) : (
                  <>
                    Do tej pory wprowadzono <b>{summaryStatistics.totalCount}</b>{' '}
                    {getBillsDeclination(summaryStatistics.totalCount)} za łączną kwotę{' '}
                    <b>{Common.Utils.getCurrencyString(summaryStatistics.totalAmmount.toFixed(2))}</b>.
                  </>
                )}
              </ListGroup.Item>
            )}
            {userPreference.showSummaryLocationsDashboardStats && (
              <ListGroup.Item>
                {summaryReloading || summaryStatistics === undefined ? (
                  <Skeleton height={50} />
                ) : (
                  <Row className='py-0 px-1 justify-content-center align-items-center align-middle'>
                    <Col sm={'auto'} className='p-0'>
                      <img
                        src={
                          (summaryStatistics?.mostNumerousLocationPicture?.length ?? 0) > 0
                            ? summaryStatistics?.mostNumerousLocationPicture
                            : '/Static/icons/dashboard-navigation/bills.png'
                        }
                        style={{
                          height: '34px',
                          width: '34px',
                        }}
                        alt=''
                      />
                    </Col>
                    <Col>
                      {summaryStatistics.mostNumerousLocationName ? (
                        <>
                          Najpopularniejsza lokalizacja to <b>{summaryStatistics.mostNumerousLocationName}</b> dla
                          której wprowadzo <b>{summaryStatistics.mostNumerousLocationCount}</b>{' '}
                          {getBillsDeclination(summaryStatistics.mostNumerousLocationCount)}.
                        </>
                      ) : (
                        <>Na tę chwilę nie masz żadnego rachunku z przypisaną lokalizacją.</>
                      )}
                    </Col>
                  </Row>
                )}
              </ListGroup.Item>
            )}
          </ListGroup>
        </Collapse>
      </Card>
    );
  };

  return (
    <>
      <Container className='dashboard-statistics-upper'>
        {generateRecurringOperationStatisticsCard()}
        {generateExpenseStatisticsCard()}
        {generateSummaryStatisticsCard()}
      </Container>
    </>
  );
};

export default DashboardStatistics;
