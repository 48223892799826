import React, { useCallback, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Nav, Navbar, Row } from 'react-bootstrap';
import Tree from 'react-d3-tree';
import { Range } from 'react-range';
import type { ICategoryData } from '../../../interfaces/ICategoryData';
import Common from '../../shared/Common';

import './tree.css';

interface CategoryDatum {
  name: string;
  totalCost: number;
  totalCount: number;
  attributes?: Record<string, string>;
  children?: CategoryDatum[];
}

interface Point {
  x: number;
  y: number;
}

export interface ICategoriesMapProps {
  onCategoriesMapClicked: number;
}

export interface ICategoriesRangeProps {
  rangeDateMin: Date;
  rangeDateMax: Date;
  rangeDateStart: Date;
  rangeDateEnd: Date;
  setRangeDateStart: React.Dispatch<React.SetStateAction<Date>>;
  setRangeDateEnd: React.Dispatch<React.SetStateAction<Date>>;
  setSearchDateFrom: React.Dispatch<React.SetStateAction<Date | undefined>>;
  setSearchDateTo: React.Dispatch<React.SetStateAction<Date | undefined>>;
}

const renderForeignObjectNode = ({ nodeDatum, toggleNode, foreignObjectProps }: any) => (
  <g>
    <foreignObject {...foreignObjectProps}>
      <Card style={{ height: '150px' }} bg='dark' text='light'>
        <Card.Body>
          <Card.Title>{nodeDatum.name}</Card.Title>
          <Card.Text>
            <Row>
              <Col sm={3}>
                <Form.Label>{nodeDatum.attributes?.produkty}</Form.Label>
              </Col>
              <Col sm={9}>
                <Form.Label>{nodeDatum.attributes?.zapłacono}</Form.Label>
              </Col>
            </Row>
          </Card.Text>
          {nodeDatum.children && (
            <Button style={{ width: '100%' }} onClick={toggleNode}>
              {nodeDatum.__rd3t.collapsed ? 'Rozwiń' : 'Zwiń'}
            </Button>
          )}
        </Card.Body>
      </Card>
    </foreignObject>
  </g>
);

var lastRangeChangeTime = new Date();

const CategoriesRange = (props: ICategoriesRangeProps) => {
  const [rangeValues, setRangeValues] = useState<number[]>([0, 100]);
  const onRangeChange = (values: number[]) => {
    const timeDiffInterval = 100;
    const start = values[0];
    const end = values[1];
    const dateFromChanged = rangeValues[0] !== values[0];
    const dateToChanged = rangeValues[1] !== values[1];
    setRangeValues(values);
    var dateTimeDifference = props.rangeDateMax.getTime() - props.rangeDateMin.getTime();
    var daysToAddToMinDate = (dateTimeDifference * end) / 100;
    var daysToRemoveFromMaxDate = (dateTimeDifference * (100 - start)) / 100;
    var endDate = new Date(props.rangeDateMin);
    var startDate = new Date(props.rangeDateMax);
    endDate.setTime(endDate.getTime() + daysToAddToMinDate);
    startDate.setTime(startDate.getTime() - daysToRemoveFromMaxDate);
    props.setRangeDateEnd(endDate);
    props.setRangeDateStart(startDate);
    lastRangeChangeTime = new Date();
    setTimeout(() => {
      const currentTime = new Date();

      if (
        (end === 100 && dateToChanged) ||
        (start === 0 && dateFromChanged) ||
        currentTime.getTime() - lastRangeChangeTime.getTime() > timeDiffInterval
      ) {
        if (dateFromChanged) {
          props.setSearchDateFrom(startDate);
        }
        if (dateToChanged) {
          props.setSearchDateTo(endDate);
        }
      }
    }, timeDiffInterval);
  };

  return (
    <Form>
      <Form.Group controlId='formBasicRange'>
        <Form.Label className='range-left-label'>{props.rangeDateStart.toLocaleDateString()}</Form.Label>
        <Form.Label className='range-right-label'>{props.rangeDateEnd.toLocaleDateString()}</Form.Label>
        <Range
          step={0.1}
          min={0}
          max={100}
          values={rangeValues}
          onChange={(values) => onRangeChange(values)}
          renderTrack={({ props, children }) => (
            <div
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}
              style={{
                ...props.style,
                height: '36px',
                display: 'flex',
                width: '100%',
              }}
            >
              <div
                ref={props.ref}
                style={{
                  height: '5px',
                  width: '100%',
                  borderRadius: '4px',
                  alignSelf: 'center',
                  backgroundColor: '#ccc',
                }}
              >
                {children}
              </div>
            </div>
          )}
          renderThumb={({ props, isDragged }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: '42px',
                width: '22px',
                borderRadius: '4px',
                backgroundColor: '#FFF',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                boxShadow: '0px 2px 6px #AAA',
              }}
            >
              <div
                style={{
                  height: '16px',
                  width: '5px',
                  backgroundColor: isDragged ? '#548BF4' : '#CCC',
                }}
              />
            </div>
          )}
        />
      </Form.Group>
    </Form>
  );
};

const CategoriesMap = (props: ICategoriesMapProps) => {
  const ref = React.useRef<HTMLHeadingElement>(null);
  const [categoriesMap, setCategoriesMap] = useState<CategoryDatum>({
    name: 'Wszystko',
    totalCount: 0,
    totalCost: 0,
  });
  const [translate, setTranslate] = useState<Point>({ x: 0, y: 0 });
  const [rangeDateMin, setRangeDateMin] = useState<Date>(new Date());
  const [rangeDateMax, setRangeDateMax] = useState<Date>(new Date());
  const [rangeDateStart, setRangeDateStart] = useState<Date>(new Date());
  const [rangeDateEnd, setRangeDateEnd] = useState<Date>(new Date());
  const [searchDateFrom, setSearchDateFrom] = useState<Date | undefined>(undefined);
  const [searchDateTo, setSearchDateTo] = useState<Date | undefined>(undefined);
  const fetchDataIdRef = React.useRef(0);

  const generateCategoriesMap = (categories: ICategoryData[]) => {
    categories = categories.filter((c) => !!c.category);
    const categoriesMap: CategoryDatum = {
      name: 'Wszystko',
      totalCount: 0,
      totalCost: 0,
      children: [],
    };

    categoriesMap.totalCost = categories
      .filter((c) => c.category.parentCategoryId === null)
      .map((c) => c.totalCost)
      .reduce((a, b) => a + b, 0);
    categoriesMap.totalCount = categories
      .filter((c) => c.category.parentCategoryId === null)
      .map((c) => c.count)
      .reduce((a, b) => a + b, 0);
    const payedString = Common.Utils.getCurrencyString(
      (Math.round((categoriesMap.totalCost ?? 0) * 100) / 100).toFixed(2)
    );

    categoriesMap.attributes = {
      zapłacono: payedString,
      produkty: String(categoriesMap.totalCount),
    };

    const generateSubTree = (root: CategoryDatum, category: ICategoryData) => {
      const payedString = Common.Utils.getCurrencyString(
        (Math.round((category.totalCost ?? 0) * 100) / 100).toFixed(2)
      );
      const node: CategoryDatum = {
        totalCount: category.count,
        totalCost: category.totalCost,
        name: category.category.categoryName,
        attributes: {
          zapłacono: payedString,
          produkty: String(category.count),
        },
      };
      const children = categories.filter((c) => c.category.parentCategoryId === category.category.categoryId);
      if (children.length > 0) {
        node.children = [];
      }
      children.forEach((c) => {
        generateSubTree(node, c);
      });
      root?.children?.push(node);
      return root;
    };

    categories
      .filter((c) => c.category.parentCategoryId === null)
      .forEach((c) => {
        generateSubTree(categoriesMap, c);
      });

    return categoriesMap;
  };

  const fetchData = useCallback(async (startDate: Date | undefined, endDate: Date | undefined) => {
    const sortCategoriesNode = (node: CategoryDatum) => {
      node.children = node.children?.sort((a, b) => b.totalCost - a.totalCost);
      node.children?.forEach((c) => sortCategoriesNode(c));
    };
    const fetchId = ++fetchDataIdRef.current;
    let dateRangeQuery = '?';
    if (startDate !== undefined) {
      dateRangeQuery = `${dateRangeQuery}dateFrom=${startDate.toJSON()}&`;
    }
    if (endDate !== undefined) {
      dateRangeQuery = `${dateRangeQuery}dateTo=${endDate.toJSON()}&`;
    }
    const response = await Common.authorizedFetch(`api/categories/getCategoriesData${dateRangeQuery}`);
    const data = await response.json();
    if (data.success) {
      if (fetchId === fetchDataIdRef.current) {
        const categories = generateCategoriesMap(data.result.categories);
        const minDate = data.result.getFirstBillDate;
        const maxDate = data.result.getLastBillDate;

        sortCategoriesNode(categories);
        setCategoriesMap(categories);
        if (fetchId === 1) {
          setRangeDateMin(new Date(minDate + 'Z'));
          setRangeDateMax(new Date(maxDate + 'Z'));
          setRangeDateStart(new Date(minDate + 'Z'));
          setRangeDateEnd(new Date(maxDate + 'Z'));
        }
      }
    }
  }, []);

  useEffect(() => {
    fetchData(searchDateFrom, searchDateTo);
  }, [fetchData, searchDateFrom, searchDateTo]);

  useEffect(() => {
    setTranslate({ x: (ref.current?.offsetWidth ?? 0) / 2, y: 150 });
  }, [props.onCategoriesMapClicked]);

  const nodeSize = { x: 250, y: 340 };
  const foreignObjectProps = { width: 230, height: 200, x: -115, y: -110 };
  return (
    <div className='tree-wrapper' ref={ref}>
      <Navbar bg='light' variant='light' fixed='bottom'>
        <Nav>
          <div className='range-container'>
            <CategoriesRange
              rangeDateMin={rangeDateMin}
              rangeDateMax={rangeDateMax}
              rangeDateStart={rangeDateStart}
              rangeDateEnd={rangeDateEnd}
              setRangeDateStart={setRangeDateStart}
              setRangeDateEnd={setRangeDateEnd}
              setSearchDateFrom={setSearchDateFrom}
              setSearchDateTo={setSearchDateTo}
            />
          </div>
        </Nav>
      </Navbar>
      <Tree
        initialDepth={1}
        data={categoriesMap}
        nodeSize={nodeSize}
        renderCustomNodeElement={(rd3tProps) =>
          renderForeignObjectNode({
            ...rd3tProps,
            foreignObjectProps,
          })
        }
        translate={translate}
        orientation='vertical'
      />
    </div>
  );
};

export default CategoriesMap;
