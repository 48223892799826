import { Formik } from 'formik';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import * as yup from 'yup';
import Common from '../../../shared/Common';
import type { IUserSettingsChildProps } from '../UserSettings';

import 'react-loading-skeleton/dist/skeleton.css';

const Profile = (props: IUserSettingsChildProps) => {
  const [formLoading, setFormLoading] = useState<boolean>(false);

  const { setFetchUserDataTrigger, fetchUserDataTrigger, loading, userData, setStatus } = props;
  const [avatarBase64, setAvatarBase64] = useState<string | undefined>(userData?.avatar);
  const [avatarChanged, setAvatarChanged] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setAvatarBase64(userData?.avatar);
  }, [userData?.avatar]);

  const ProfileForm = useCallback(() => {
    const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

    const validationSchema = yup.object().shape({
      userName: yup.string(),
      phoneNumber: yup.string().matches(phoneRegExp, 'Numer telefonu jest nieprawidłowy'),
    });

    const handleUploadBillImage = async (event: any) => {
      if (!!event.target.files[0]) {
        const billPictureBase64 = (await Common.Images.toBase64(event.target.files[0])) as string;
        setAvatarBase64(billPictureBase64);
        setAvatarChanged(true);
      }
    };

    return (
      <Row>
        <Col md={6}>
          <Formik
            initialValues={{
              avatar: userData?.avatar ?? '',
              userName: userData?.userName ?? '',
              phoneNumber: userData?.phoneNumber ?? '',
            }}
            validationSchema={validationSchema}
            onSubmit={async (values, { setFieldError, setSubmitting }) => {
              setSubmitting(true);
              setFormLoading(true);
              const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                  phoneNumber: values.phoneNumber,
                  avatar: avatarBase64,
                  avatarChanged: avatarChanged,
                }),
              };
              const response = await Common.authorizedFetch('api/users/saveUserProfile', requestOptions);
              const data = await response.json();
              setFormLoading(false);
              if (!data.success) {
                setFieldError('phoneNumber', data.errors);
              } else {
                setStatus({
                  variant: 'success',
                  status: 'Zaktualizowałeś swój profil.',
                  messageTime: Date.now(),
                });
                setFetchUserDataTrigger(fetchUserDataTrigger + 1);
                window.location.reload();
                return;
              }
              setSubmitting(false);
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
              <>
                <Form onSubmit={handleSubmit} autoComplete='off'>
                  <Form.Group>
                    <Form.Label>Avatar</Form.Label>
                    {loading ? (
                      <Skeleton height={35} />
                    ) : (
                      <Container>
                        <img
                          src={
                            avatarBase64 === '' || avatarBase64 === undefined || avatarBase64 === null
                              ? '/Static/icons/avatar.png'
                              : avatarBase64
                          }
                          height={200}
                          width={'auto'}
                          alt='avatar'
                        />
                        <Row>
                          <input
                            name='avatar'
                            className='mt-2'
                            ref={inputRef}
                            onChange={(e) => {
                              handleUploadBillImage(e);
                              handleChange(e);
                            }}
                            type='file'
                            accept='image/*'
                            onBlur={handleBlur}
                          />
                        </Row>
                        <Button
                          className='mt-2'
                          size='sm'
                          variant='danger'
                          onClick={(e) => {
                            setAvatarBase64('');
                            setAvatarChanged(true);
                          }}
                        >
                          Usuń avatar
                        </Button>
                      </Container>
                    )}
                  </Form.Group>
                  <Form.Group className='mt-3'>
                    <Form.Label>Nazwa użytkownika</Form.Label>
                    {loading ? (
                      <Skeleton height={35} />
                    ) : (
                      <Form.Control
                        type='text'
                        name='userName'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.userName}
                        disabled
                      />
                    )}
                  </Form.Group>
                  <Form.Group className='mt-3'>
                    <Form.Label>Numer telefonu</Form.Label>
                    {loading ? (
                      <Skeleton height={35} />
                    ) : (
                      <>
                        <Form.Control
                          type='text'
                          name='phoneNumber'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.phoneNumber}
                          className={touched.phoneNumber && errors.phoneNumber ? 'error' : undefined}
                        />
                        {touched.phoneNumber && errors.phoneNumber ? (
                          <div className='error-message'>{errors.phoneNumber}</div>
                        ) : null}
                      </>
                    )}
                  </Form.Group>
                  <Button className='mt-3' variant='primary' type='submit' disabled={isSubmitting || loading}>
                    Zapisz
                  </Button>
                </Form>
              </>
            )}
          </Formik>
        </Col>
      </Row>
    );
  }, [
    setFetchUserDataTrigger,
    fetchUserDataTrigger,
    loading,
    userData,
    setStatus,
    avatarBase64,
    avatarChanged,
    setAvatarChanged,
  ]);
  return (
    <>
      <h4>Profil</h4>
      {Common.Ui.showLoadingSpinnerFixed(formLoading)}
      <ProfileForm />
    </>
  );
};

export default Profile;
