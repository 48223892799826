import { Form } from 'react-bootstrap';
import type { IAuthenticationScheme } from '../../../interfaces/IAuthenticationScheme';
import { FacebookIcon, GoogleIcon } from '../icons/Svgs';
import './style.css';

export interface IExternalLogins {
  loginProviders: IAuthenticationScheme[];
  actionType: ActionType;
  identityServerUrl: string;
  returnUrl?: string;
}

export declare type ActionType = 'linkLogin' | 'signIn';

const ExternalLogins = (props: IExternalLogins) => {
  const getLoginButtonByProviderName = (provider: IAuthenticationScheme) => {
    const providerButtonClick = (value: string) => {
      switch (props.actionType) {
        case 'linkLogin':
          handleLinkLogin(value, props.identityServerUrl);
          break;
        case 'signIn':
          handleSignIn(value, props.identityServerUrl, props.returnUrl);
          break;
      }
    };

    switch (provider.name) {
      case 'Facebook':
        return (
          <button
            type='button'
            disabled={props.identityServerUrl === ''}
            key='Facebook'
            id='login-button-facebook'
            className='fb-button'
            name='facebook'
            value='Facebook'
            onClick={(_) => providerButtonClick('Facebook')}
            title='Zaloguj się używając swojego konta Facebook'
          >
            <FacebookIcon style={{ marginRight: '10px' }} />
            Zaloguj się przez Facebooka
          </button>
        );
      case 'Google':
        return (
          <button
            type='button'
            disabled={props.identityServerUrl === ''}
            key='Google'
            id='login-button-google'
            className='google-button'
            name='google'
            value='Google'
            onClick={(_) => providerButtonClick('Google')}
            title='Zaloguj się używając swojego konta Google'
          >
            <GoogleIcon style={{ marginRight: '10px' }} />
            Zaloguj się przez Google
          </button>
        );
      default:
        return (
          <button
            key={provider.name}
            disabled={props.identityServerUrl === ''}
            className='btn btn-primary'
            id={'ling-login-button-' + provider.name}
            name='provider'
            value={provider.name}
            onClick={(_) => providerButtonClick(provider.name)}
            title={'Zaloguj się używając swojego konta ' + provider.displayName}
          >
            {'Zaloguj się używając swojego konta ' + provider.displayName}
          </button>
        );
    }
  };

  const loginsTable = [];
  for (let loginProvider of props.loginProviders) {
    var loginButton = getLoginButtonByProviderName(loginProvider);
    loginsTable.push(loginButton);
  }
  return <Form className='form-horizontal'>{loginsTable}</Form>;
};

const handleLinkLogin = (value: string, identityServerUrl: string) => {
  const externalProviderChallangeUrl = `${identityServerUrl}/idsrv/externalProviders/linkLogin?provider=${value}`;
  window.location.replace(externalProviderChallangeUrl);
};

const handleSignIn = (value: string, identityServerUrl: string, redirectUrl?: string) => {
  const externalProviderChallangeUrl = `${identityServerUrl}/idsrv/externalProviders/signIn?provider=${value}&ReturnUrl=${redirectUrl}`;
  window.location.replace(externalProviderChallangeUrl);
};

export default ExternalLogins;
