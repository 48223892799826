import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import React, { useCallback, useState, useEffect } from 'react';
import Common from '../../../shared/Common';
import type { ColumnDef, SortingState } from '@tanstack/react-table';
import { SortUpIcon, SortDownIcon, MoreIcon } from '../../../shared/icons/Svgs';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';

export interface IUserToManage {
  id: string;
  userName: string;
  userRoles: string[];
}

export interface ITableProps {
  columns: ColumnDef<IUserToManage>[];
  data: IUserToManage[];
  loading: boolean;
  fetchData: () => Promise<void>;
  fetchTrigger: number;
  setFetchTrigger: React.Dispatch<React.SetStateAction<number>>;
}

function Table(props: ITableProps) {
  const [sorting, setSorting] = React.useState<SortingState>([]);

  const table = useReactTable({
    data: props.data,
    columns: props.columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const { fetchData, fetchTrigger } = props;

  useEffect(() => {
    fetchData();
  }, [fetchData, fetchTrigger]);

  return (
    <>
      <div className='table-responsive'>
        <table id='users-table' className='table'>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          style: header.column.getCanSort() ? { cursor: 'pointer' } : {},
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: <SortUpIcon style={{ marginLeft: '5px' }} />,
                          desc: <SortDownIcon style={{ marginLeft: '5px' }} />,
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {props.loading
              ? Common.Ui.getSkeletonTableRows(6, 4, 35)
              : table.getRowModel().rows.map((row) => (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                    ))}
                  </tr>
                ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

const ManageUsers = () => {
  const [users, setUsers] = useState<IUserToManage[]>([]);
  const [loading, setLoading] = useState(true);
  const [fetchTrigger, setFetchTrigger] = useState(0);
  const [showRemoved, setShowRemoved] = useState<boolean>(false);
  const fetchIdRef = React.useRef(0);

  const fetchData = useCallback(async () => {
    setLoading(true);
    const fetchId = ++fetchIdRef.current;
    const response = await Common.authorizedFetch(`api/users/getAllUsers?showRemoved=${showRemoved}`);
    const data = await response.json();
    if (data.success) {
      if (fetchId === fetchIdRef.current) {
        setUsers(data.result.users);
      }
    }
    setLoading(false);
  }, [showRemoved]);

  const columns = React.useMemo<ColumnDef<IUserToManage>[]>(
    () => [
      {
        accessorFn: (row) => row.userName,
        id: 'name',
        header: () => <span>Nazwa</span>,
      },
      {
        accessorFn: (row) => row.id,
        id: 'id',
        header: () => <span>Id</span>,
      },
      {
        accessorFn: (row) => row?.userRoles?.join(', '),
        id: 'users',
        header: () => <span>Role</span>,
      },
      {
        id: 'update',
        header: 'Akcja',
        cell: (info) => (
          <Link to={'user/' + info.row.original.id} title='Zarządzaj'>
            <MoreIcon
              style={{
                color: 'black',
                width: '38px',
                height: '38px',
              }}
            />
          </Link>
        ),
        enableSorting: false,
      },
    ],
    []
  );

  return (
    <>
      <h4>Zarządzaj użytkownikami w aplikacji</h4>
      <Form.Check
        className='my-2'
        type='switch'
        id='show-removed-switch'
        label='Pokaż także nieaktywne konta'
        style={{ userSelect: 'none' }}
        checked={showRemoved}
        onChange={() => {
          setShowRemoved((prev) => !prev);
        }}
      />
      <Table
        columns={columns}
        data={users}
        loading={loading}
        fetchData={fetchData}
        fetchTrigger={fetchTrigger}
        setFetchTrigger={setFetchTrigger}
      />
    </>
  );
};

export default ManageUsers;
