import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import { useEffect, useState, useMemo } from 'react';
import { Form } from 'react-bootstrap';
import Common from '../../../shared/Common';
import { SortUpIcon, SortDownIcon, MinusIcon, PlusIcon } from '../../../shared/icons/Svgs';
import type { ColumnDef, SortingState } from '@tanstack/react-table';
import type { IStartingCategory } from './NewAccounts';

import './style.css';

export interface IStartingLocationsTableProps {
  categories: IStartingCategory[];
  fetchedCategories: IStartingCategory[];
  loading: boolean;
  setCategories: React.Dispatch<React.SetStateAction<IStartingCategory[]>>;
  setCategoriesChanged: React.Dispatch<React.SetStateAction<boolean>>;
  parentCategoriesIds: number[];
}

const StartingCategoriesTable = (props: IStartingLocationsTableProps) => {
  const [sorting, setSorting] = useState<SortingState>([{ id: 'categoryFullName', desc: false }]);

  const { categories, fetchedCategories, parentCategoriesIds, setCategories, setCategoriesChanged, loading } = props;

  const columns = useMemo<ColumnDef<IStartingCategory>[]>(
    () => [
      {
        id: 'actionButton',
        enableSorting: false,
        accessorFn: (row) => row.startingCategoryId,
        header: () => (
          <div
            onClick={() => {
              setCategoriesChanged(true);
              setCategories((prevState) => {
                const minIndex = Math.min(0, ...prevState.map((p) => p.startingCategoryId)) - 1;
                console.log(minIndex);
                return [...prevState, { startingCategoryId: minIndex, categoryName: '' }];
              });
            }}
          >
            <PlusIcon style={{ cursor: 'pointer', width: '30px', height: '30px', fill: 'var(--app-main-color)' }} />
          </div>
        ),
        cell: (info) => {
          const selectedValue = info.row.original;
          const canRemove = parentCategoriesIds.filter((p) => p === selectedValue.startingCategoryId).length === 0;
          return (
            <div
              onClick={() => {
                if (!canRemove) {
                  return;
                }
                setCategoriesChanged(true);
                setCategories((prevState) => {
                  const selectedValue = info.row.original;
                  return prevState.filter((elem) => elem.startingCategoryId !== selectedValue.startingCategoryId);
                });
              }}
            >
              <MinusIcon
                style={
                  canRemove
                    ? { cursor: 'pointer', width: '30px', height: '30px', fill: '#dc3545' }
                    : { cursor: 'pointer', width: '30px', height: '30px', fill: 'gray' }
                }
              />
            </div>
          );
        },
      },
      {
        id: 'categoryName',
        enableSorting: true,
        accessorFn: (row) => row.categoryName,
        header: () => <span>Nazwa</span>,
      },
      {
        id: 'parentCategoryId',
        enableSorting: true,
        accessorFn: (row) => row.parentCategoryId,
        header: () => <span>Kategoria nadrzędna</span>,
      },
      {
        id: 'categoryFullName',
        enableSorting: true,
        accessorFn: (row) => {
          const relationArray = [];
          let currentCategory = row;
          while (true) {
            relationArray.push(currentCategory.categoryName);
            if (!currentCategory.parentCategoryId) {
              break;
            }
            const id = currentCategory.parentCategoryId;
            const index = categories.findIndex((c) => c.startingCategoryId === id);
            if (index === -1) {
              break;
            }
            currentCategory = categories[index];
            if (relationArray.length > 10) {
              break;
            }
          }
          return relationArray.reverse().join(' > ') + (relationArray.length > 10 ? '...' : '');
        },
        header: () => <span>Pełna nazwa</span>,
      },
    ],
    [setCategories, setCategoriesChanged, categories, parentCategoriesIds]
  );

  const defaultColumn: Partial<ColumnDef<IStartingCategory>> = {
    cell: function Cell({ getValue, row: { index }, column: { id }, table }) {
      const initialValue = getValue();
      const [value, setValue] = useState(initialValue);

      // liczenie możliwych kolejności
      const categoriesOptions: any[] = [<option key={-1} value={undefined} />];
      if (table.options.meta?.extra?.fetchedCategories) {
        const categories = table.options.meta?.extra?.fetchedCategories as IStartingCategory[];
        for (let ind = 0; ind !== categories.length; ind++) {
          categoriesOptions.push(
            <option key={ind} value={categories[ind].startingCategoryId}>
              {categories[ind].categoryName}
            </option>
          );
        }
      }

      const onBlur = () => {
        table.options.meta?.updateData?.(index, id, value);
      };

      useEffect(() => {
        setValue(initialValue);
      }, [initialValue]);

      if (id === 'parentCategoryId') {
        return (
          <Form.Control
            size='sm'
            as='select'
            value={(value ?? '') as string}
            onBlur={onBlur}
            onChange={(e) => {
              setValue(e.target.value);
              setCategoriesChanged(true);
            }}
          >
            {categoriesOptions}
          </Form.Control>
        );
      } else if (id === 'categoryName') {
        return (
          <Form.Control
            size='sm'
            type='text'
            onChange={(e) => {
              setValue(e.target.value);
              setCategoriesChanged(true);
            }}
            onBlur={onBlur}
            value={value as string}
          />
        );
      } else {
        return <>{value}</>;
      }
    },
  };

  var items = categories ?? [];

  const table = useReactTable({
    data: items,
    columns,
    defaultColumn,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    meta: {
      updateData: (rowIndex, columnId, value) => {
        setCategories((prevState) => {
          prevState[rowIndex] = {
            ...prevState[rowIndex],
            [columnId]: value,
          };

          return [...prevState];
        });
      },
      extra: {
        fetchedCategories: fetchedCategories,
      },
    },
  });

  return (
    <>
      <table id={'edited-new-categories-table'} className='table table-sm mt-2' aria-labelledby='tabelLabel'>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder ? null : (
                    <div
                      {...{
                        style: header.column.getCanSort()
                          ? {
                              cursor: 'pointer',
                            }
                          : {},
                        onClick: header.column.getToggleSortingHandler(),
                      }}
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {{
                        asc: <SortUpIcon style={{ marginLeft: '5px' }} />,
                        desc: <SortDownIcon style={{ marginLeft: '5px' }} />,
                      }[header.column.getIsSorted() as string] ?? null}
                    </div>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {loading
            ? Common.Ui.getSkeletonTableRows(5, 4, 35)
            : table.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                  ))}
                </tr>
              ))}
        </tbody>
      </table>
    </>
  );
};

export default StartingCategoriesTable;
