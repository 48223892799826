import { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import type { Variant } from './AlertMessages';

export interface IApplicationAlert {
  variant: Variant;
  status: string | undefined;
  messageTime: number;
}

function ApplicationAlert(props: IApplicationAlert) {
  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    setShow(true);
  }, [props.messageTime]);

  const getAlertVariant = (variant: Variant) => {
    switch (variant) {
      case 'info':
        return 'primary';
      case 'error':
        return 'danger';
      case 'success':
        return 'success';
      default:
        return 'primary';
    }
  };

  const getAlertHeading = (variant: Variant) => {
    switch (variant) {
      case 'info':
        return <></>;
      case 'error':
        return <Alert.Heading>Błąd</Alert.Heading>;
      case 'success':
        return <></>;
      default:
        return <></>;
    }
  };

  if (show) {
    return (
      <Alert
        style={{ wordWrap: 'break-word' }}
        variant={getAlertVariant(props.variant)}
        onClose={() => setShow(false)}
        dismissible
      >
        {getAlertHeading(props.variant)}
        <p>{props.status}</p>
      </Alert>
    );
  }
  return <></>;
}

export default ApplicationAlert;
