import React, { useState, useCallback, useContext } from 'react';
import { Container } from 'react-bootstrap';
import Common from '../../shared/Common';
import type { IRecurringOperation } from '../../../interfaces/IRecurringOperation';
import AddOrUpdateRecurringOperationModal from './AddOrUpdateRecurringOperationModal';
import RecurringOperationsTable from './RecurringOperationsTable';
import { DictionaryContext } from '../../../App';

import './style.css';

const RecurringOperations = () => {
  const [recurringOperations, setRecurringOperations] = useState<IRecurringOperation[]>([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(-1);
  const [selectedRecurringOperation, setSelectedRecurringOperation] = useState<IRecurringOperation | null>(null);
  const { categoriesNames, locationNames } = useContext(DictionaryContext);
  const [fetchTrigger, setFetchTrigger] = useState(0);
  const fetchIdRef = React.useRef(0);

  const fetchData = useCallback(async () => {
    const fetchId = ++fetchIdRef.current;
    setLoading(true);

    const recurringOperationsResponse = await Common.authorizedFetch('api/recurringOperations/getRecurringOperations');

    const recurringOperationsData = await recurringOperationsResponse.json();
    if (recurringOperationsData.success) {
      if (fetchId === fetchIdRef.current) {
        setRecurringOperations(recurringOperationsData.result.recurringOperations);
        setLoading(false);
      }
      setSelectedRecurringOperation(null);
      setSelectedRowId(-1);
    }
  }, []);

  return (
    <Container className='mt-2'>
      <h2 className='mb-3' id='tabelLabel'>
        Operacje cykliczne
      </h2>
      <RecurringOperationsTable
        data={recurringOperations}
        loading={loading}
        fetchData={fetchData}
        fetchTrigger={fetchTrigger}
        setFetchTrigger={setFetchTrigger}
        setShowModal={setShowModal}
        selectedRowId={selectedRowId}
        setSelectedRowId={setSelectedRowId}
        selectedRecurringOperation={selectedRecurringOperation}
        setSelectedRecurringOperation={setSelectedRecurringOperation}
      />
      <AddOrUpdateRecurringOperationModal
        selectedRecurringOperation={selectedRecurringOperation}
        show={showModal}
        onHide={() => {
          setShowModal(false);
          setSelectedRecurringOperation(null);
          setSelectedRowId(-1);
        }}
        onSave={() => {
          setFetchTrigger(fetchTrigger + 1);
        }}
        locationsNames={locationNames}
        categoriesNames={categoriesNames}
      />
    </Container>
  );
};

export default RecurringOperations;
