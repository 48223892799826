import { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Common from '../../../shared/Common';
import type { IUserSettingsChildProps } from '../UserSettings';

const PersonalData = (props: IUserSettingsChildProps) => {
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const downloadPersonalData = async () => {
    setFormLoading(true);
    const response = await Common.authorizedFetch('api/users/getPersonalData');
    const data = await response.blob();
    var url = window.URL.createObjectURL(data);
    var a = document.createElement('a');
    a.href = url;
    a.download = 'PersonalData.json';
    document.body.appendChild(a);
    a.click();
    a.remove();
    setFormLoading(false);
  };

  return (
    <>
      {Common.Ui.showLoadingSpinnerFixed(formLoading)}
      <h4>Informacje o koncie</h4>
      <Row>
        <Col>
          <p>
            Twoje konto zawiera informacje, które zostały wprowadzone przez Ciebie podczas korzystania z aplikacji.
            Tutaj możesz pobrać te informacje albo usunąć konto.
          </p>
          <p>
            <strong>Usunięcie konta jest operacją bezpowrotną i nie będzie można go przywrócić.</strong>
          </p>
          <p>
            <Button variant='primary' onClick={downloadPersonalData}>
              Pobierz informacje o koncie
            </Button>
          </p>
          <p>
            <Link to='/settings/user/personal-data/remove-account' className='btn btn-danger'>
              Usuń konto
            </Link>
          </p>
        </Col>
      </Row>
    </>
  );
};

export default PersonalData;
