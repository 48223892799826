import React, { useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import type { IDefaultLocationPicture } from '../../../../interfaces/IDefaultLocationPicture';
import type { IDefaultLocationPictureCategory } from '../../../../interfaces/IDefaultLocationPictureCategory';
import Common from '../../../shared/Common';
import { MinusIcon, PlusIcon } from '../../../shared/icons/Svgs';

import './style.css';

export interface IPictureCategoriesTableProps {
  pictureCategories: IDefaultLocationPictureCategory[];
  locationPictures: IDefaultLocationPicture[];
  loading: boolean;
  setLocationPictures: React.Dispatch<React.SetStateAction<IDefaultLocationPicture[]>>;
  setDataChanged: React.Dispatch<React.SetStateAction<boolean>>;
}

const PictureLocationsPanel = (props: IPictureCategoriesTableProps) => {
  const { pictureCategories, locationPictures, setLocationPictures, setDataChanged, loading } = props;
  const [draggedElementHoverCategory, setDraggedElementHoverCategory] = useState<boolean>(false);
  const [draggedLocationPicture, setDraggedLocationPicture] = useState<IDefaultLocationPicture | undefined>(undefined);
  const [hoveredPictureCategory, setHoveredPictureCategory] = useState<IDefaultLocationPictureCategory | undefined>(
    undefined
  );

  const inputRef = useRef<HTMLInputElement>(null);

  const handleUploadLocationPicture = async (event: any) => {
    if (!!event.target.files[0]) {
      const compressedPictureBase64 = await Common.Images.compresUploadedImage(event.target.files[0]);
      setDataChanged(true);
      setLocationPictures((prev) => {
        const currentCategoryMaxOrder = Math.max(
          0,
          ...prev.filter((p) => !p.pictureCategory).map((p) => p.pictureOrder)
        );
        return [
          ...prev,
          {
            defaultPictureId: 0,
            pictureOrder: currentCategoryMaxOrder + 1,
            pictureBase64String: compressedPictureBase64,
          },
        ];
      });
      event.target.value = null;
    }
  };

  // obsługa drag&drop dla zdjęcia
  const handlePictureDragStart = (event: React.DragEvent, locationPicture: IDefaultLocationPicture) => {
    setDraggedLocationPicture(locationPicture);
    event.dataTransfer.effectAllowed = 'move';
  };

  const handlePictureDragEnd = (event: React.DragEvent) => {
    setDraggedLocationPicture(undefined);
  };

  // obsługa drag&drop dla sekcji kategorii
  const handleCategoryDrop = (event: React.DragEvent, category: IDefaultLocationPictureCategory | undefined) => {
    event.preventDefault();
    setLocationPictures((prev) => {
      const index = prev.findIndex((p) => p === draggedLocationPicture);
      const currentCategoryMaxOrder = Math.max(
        0,
        ...prev
          .filter((p) => getPictureCategoryKey(p.pictureCategory) === getPictureCategoryKey(hoveredPictureCategory))
          .map((p) => p.pictureOrder)
      );
      prev[index].pictureOrder = currentCategoryMaxOrder + 1;
      prev[index].pictureCategory = hoveredPictureCategory;
      return [...prev.sort((a, b) => a.pictureOrder - b.pictureOrder)];
    });
    setDataChanged(true);
    setHoveredPictureCategory(category);
    setDraggedElementHoverCategory(false);
    setDraggedLocationPicture(undefined);
  };

  const handleCategoryEnter = (event: React.DragEvent, category: IDefaultLocationPictureCategory | undefined) => {
    event.preventDefault();
    setHoveredPictureCategory(category);
    setDraggedElementHoverCategory(true);
  };

  const handleCategoryOver = (event: React.DragEvent, category: IDefaultLocationPictureCategory | undefined) => {
    event.preventDefault();
    setHoveredPictureCategory(category);
    setDraggedElementHoverCategory(true);
  };

  const handleCategoryLeave = (event: React.DragEvent) => {
    event.preventDefault();
    setHoveredPictureCategory(undefined);
    setDraggedElementHoverCategory(false);
  };

  // obsługa drag&drop dla kosza
  const handleRemoveDrop = (event: React.DragEvent) => {
    event.preventDefault();
    setLocationPictures((prev) => prev.filter((p) => p !== draggedLocationPicture));
    setDataChanged(true);
    setHoveredPictureCategory(undefined);
    setDraggedElementHoverCategory(false);
    setDraggedLocationPicture(undefined);
  };

  const getPictureCategoryKey = (category: IDefaultLocationPictureCategory | undefined) => {
    if (!category) {
      return 'undefined';
    }
    return category.id;
  };

  const getStyleForCategorySection = (category: IDefaultLocationPictureCategory | undefined) => {
    const borderUnselectedStyle = { border: '2px solid transparent' };
    const borderSelectedStyle = { border: '2px dashed red' };
    const borderHoverStyle = { border: '2px solid red' };
    if (
      draggedElementHoverCategory &&
      draggedLocationPicture &&
      getPictureCategoryKey(category) === getPictureCategoryKey(hoveredPictureCategory)
    ) {
      return borderHoverStyle;
    }
    return !draggedLocationPicture ? borderUnselectedStyle : borderSelectedStyle;
  };

  const locationPicturesByCategory = [];

  for (let ind = 0; ind < pictureCategories.length; ind++) {
    const category = pictureCategories[ind];
    const pictures = locationPictures
      .filter((p) => getPictureCategoryKey(p?.pictureCategory) === getPictureCategoryKey(category))
      .map((p, index) => (
        <Col xs='auto' key={ind + '-' + index}>
          <div
            draggable={true}
            onDragStart={(event: React.DragEvent) => {
              handlePictureDragStart(event, p);
            }}
            onDragEnd={(event: React.DragEvent) => {
              handlePictureDragEnd(event);
            }}
          >
            <img
              src={p.pictureBase64String}
              style={{
                height: '60px',
                width: '60px',
                cursor: 'grab',
              }}
              alt=''
            />
          </div>
        </Col>
      ));

    if (pictures.length === 0) {
      pictures.push(<div key={ind + '-0'} style={{ width: '100%', height: 60 }} />);
    }

    locationPicturesByCategory.push(
      <div key={ind} className='mb-3'>
        <h6 className='my-2'>{category.name}</h6>
        {loading ? (
          <Skeleton height={60} style={getStyleForCategorySection(undefined)} />
        ) : (
          <Row
            onDrop={(event: React.DragEvent) => {
              handleCategoryDrop(event, category);
            }}
            onDragEnter={(event: React.DragEvent) => {
              handleCategoryEnter(event, category);
            }}
            onDragOver={(event: React.DragEvent) => {
              handleCategoryOver(event, category);
            }}
            onDragLeave={(event: React.DragEvent) => {
              handleCategoryLeave(event);
            }}
            style={getStyleForCategorySection(category)}
          >
            {pictures}
          </Row>
        )}
      </div>
    );
  }

  const noAssignedPictures = locationPictures
    .filter((p) => !p.pictureCategory)
    .map((p, index) => (
      <Col xs='auto' key={'-1-' + index}>
        <div
          draggable
          onDragStart={(event: React.DragEvent) => {
            handlePictureDragStart(event, p);
          }}
          onDragEnd={(event: React.DragEvent) => {
            handlePictureDragEnd(event);
          }}
        >
          <img
            src={p.pictureBase64String}
            style={{
              height: '60px',
              width: '60px',
              cursor: 'grab',
            }}
            alt=''
          />
        </div>
      </Col>
    ));

  if (noAssignedPictures.length === 0) {
    noAssignedPictures.push(<div key={'-1-0'} style={{ width: '100%', height: 60 }} />);
  }

  locationPicturesByCategory.push(
    <div key={'-1'}>
      <h6 className='my-2'>Nieprzypisane</h6>
      {loading ? (
        <Skeleton height={60} style={getStyleForCategorySection(undefined)} />
      ) : (
        <Row
          onDrop={(event: React.DragEvent) => {
            handleCategoryDrop(event, undefined);
          }}
          onDragEnter={(event: React.DragEvent) => {
            handleCategoryEnter(event, undefined);
          }}
          onDragOver={(event: React.DragEvent) => {
            handleCategoryOver(event, undefined);
          }}
          onDragLeave={(event: React.DragEvent) => {
            handleCategoryLeave(event);
          }}
          style={getStyleForCategorySection(undefined)}
        >
          {noAssignedPictures}
        </Row>
      )}
    </div>
  );

  return (
    <Container className='mt-2'>
      {locationPicturesByCategory}
      <h6 className='mt-3'>Akcje</h6>
      <Row className='my-2'>
        <input
          ref={inputRef}
          onChange={handleUploadLocationPicture}
          className='d-none'
          type='file'
          accept='image/*'
          capture='environment'
        />
        <Col
          xs='auto'
          onClick={() => {
            inputRef.current?.click();
          }}
          title='Dodaj nowy obrazek'
        >
          <PlusIcon style={{ cursor: 'pointer', width: '50px', height: '50px', fill: 'black' }} />
        </Col>
        <Col
          xs='auto'
          title='Przeciągnij zdjęcię na przycisk, aby usunąć obrazek'
          onDrop={(event: React.DragEvent) => {
            handleRemoveDrop(event);
          }}
          onDragEnter={(event: React.DragEvent) => {
            event.preventDefault();
          }}
          onDragOver={(event: React.DragEvent) => {
            event.preventDefault();
          }}
        >
          <MinusIcon
            style={
              draggedLocationPicture
                ? { width: '50px', height: '50px', fill: '#dc3545' }
                : { width: '50px', height: '50px', fill: 'gray' }
            }
          />
        </Col>
      </Row>
    </Container>
  );
};

export default PictureLocationsPanel;
