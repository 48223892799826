import { useState, useRef, useEffect, useContext } from 'react';
import { Modal, Container, Row, Col, Button, Form } from 'react-bootstrap';
import { Gallery, Item } from 'react-photoswipe-gallery';
import type { IBill } from '../../../interfaces/IBill';
import type { ICategoryName } from '../../../interfaces/ICategoryName';
import type { IGroupName } from '../../../interfaces/IGroupName';
import type { ILocation } from '../../../interfaces/ILocation';
import type { IQuantityType } from '../../../interfaces/IQuantityType';
import Common from '../../shared/Common';
import LocationsAutosuggest from '../../shared/locations-autosuggest/LocationsAutosuggest';
import WarningModal from '../../shared/shared-modals/WarningModal';
import { DictionaryContext } from '../../../App';
import BillItemsTable from './BillItemsTable';
import ReactDOMServer from 'react-dom/server';

import './style.css';
import type { SlideData, UIElementData } from 'photoswipe';
import type PhotoSwipe from 'photoswipe';
import { Gallery2Icon, MinusIcon, RightRotateIcon } from '../../shared/icons/Svgs';

export interface IBillModalProps {
  show: boolean;
  showGroups: boolean;
  onHide: () => void;
  onBillAdd: () => void;
  onBillSave: (bill: IBill) => void;
  onBillRemove: (billId: number) => void;
  fetchBill: (billId: number) => Promise<void>;
  fetchBillFromPicture: (billPictureFile: string | null) => Promise<void>;
  billData: IBill;
  setBillData: React.Dispatch<React.SetStateAction<IBill>>;
  billId: number;
  quantityTypes: IQuantityType[];
  categoriesNames: ICategoryName[];
  groupsNames: IGroupName[];
  locationNames: ILocation[];
  setShowGroups: React.Dispatch<React.SetStateAction<boolean>>;
  copyMode: boolean;
  setCopyMode: React.Dispatch<React.SetStateAction<boolean>>;
  pictureToLoadBill: string | null;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  initialLoading: boolean;
}

enum BillWarningType {
  RemoveConfirmation = 0,
  PendingChanges = 1,
  ExistingMappings = 2,
  BillPayTimeOlderThanOneYear = 3,
  FetchBillFromPicture = 4,
}

type BillImageSize = { width: number; height: number };

function BillModal(props: IBillModalProps) {
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
  const [warningType, setWarningType] = useState<BillWarningType>(0);
  const [warningParams, setWarningParams] = useState<any>(undefined);
  const [pendingChanges, setPendingChanges] = useState<boolean>(false);
  const [picturesSizes, setPictureSizes] = useState<BillImageSize[]>([]);
  const { reloadLocationNames } = useContext(DictionaryContext);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleUpload = () => {
    inputRef.current?.click();
  };

  const showRemoveBillWarningModal = () => {
    setWarningType(BillWarningType.RemoveConfirmation);
    setShowWarningModal(true);
  };

  const showClosePendingChangesWarningModal = () => {
    setWarningType(BillWarningType.PendingChanges);
    setShowWarningModal(true);
  };

  const showExistingMappingsWarningModal = () => {
    setWarningType(BillWarningType.ExistingMappings);
    setShowWarningModal(true);
  };

  const showBillPayTimeOlderThanOneYearWarningModal = () => {
    setWarningType(BillWarningType.BillPayTimeOlderThanOneYear);
    setShowWarningModal(true);
  };

  const showFetchBillFromPictureWarningModal = (base64picture: string) => {
    setWarningParams(base64picture);
    setWarningType(BillWarningType.FetchBillFromPicture);
    setShowWarningModal(true);
  };

  const { fetchBill, fetchBillFromPicture, billId, pictureToLoadBill, billData, setBillData } = props;

  const handleUploadBillImage = async (event: any) => {
    if (!!event.target.files[0]) {
      const billPictureBase64 = await Common.Images.compresUploadedImage(event.target.files[0]);
      if (billData.billBase64Pictures.map((p) => p.pictureBase64String).indexOf(billPictureBase64) === -1) {
        showFetchBillFromPictureWarningModal(billPictureBase64);
      }
      event.target.value = null;
    }
  };

  useEffect(() => {
    if (!!pictureToLoadBill) {
      setPendingChanges(true);
    }
    fetchBillFromPicture(pictureToLoadBill);
    fetchBill(billId);
    return () => {
      setBillData({
        billId: 0,
        billItems: [],
        location: {
          locationId: -1,
          locationName: '',
          picture: '',
          mappings: [],
        },
        payTime: new Date(),
        billBase64Pictures: [],
      });
    };
  }, [setBillData, fetchBill, fetchBillFromPicture, billId, pictureToLoadBill]);

  useEffect(() => {
    if (props.billData.billBase64Pictures.length > 0) {
      const sizesPromises = props.billData.billBase64Pictures.map(
        (base64Picture) =>
          new Promise((resolve: (value: BillImageSize) => void) => {
            const img = new Image();
            img.onload = function () {
              const size: BillImageSize = {
                width: img.width,
                height: img.height,
              };
              resolve(size);
            };
            img.src = base64Picture.pictureBase64String;
          })
      );

      Promise.all(sizesPromises).then((sizes) => {
        setPictureSizes(sizes);
      });
    } else {
      setPictureSizes([]);
    }
  }, [props.billData.billBase64Pictures, setPictureSizes]);

  const saveBill = async () => {
    props.setLoading(true);
    if (!props.copyMode && props.billId !== 0) {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ bill: props.billData }),
      };
      const response = await Common.authorizedFetch('api/bills/saveBill', requestOptions);
      const data = await response.json();
      if (!data.success) {
        alert(data.errors);
      } else {
        if (data.result.newLocationAdded && reloadLocationNames) {
          reloadLocationNames();
        }
        props.onBillSave(props.billData);
      }
    } else {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ bill: props.billData }),
      };
      const response = await Common.authorizedFetch('api/bills/addBill', requestOptions);
      const data = await response.json();
      if (!data.success) {
        alert(data.errors);
      } else {
        if (data.result.newLocationAdded && reloadLocationNames) {
          reloadLocationNames();
        }
        props.onBillAdd();
      }
    }
    setPendingChanges(false);
    props.onHide();
  };

  const removeBill = async () => {
    props.setLoading(true);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ billId: props.billData.billId }),
    };
    const response = await Common.authorizedFetch('api/bills/removeBill', requestOptions);
    const data = await response.json();
    if (!data.success) {
      alert(data.errors);
    } else {
      props.onBillRemove(props.billData.billId);
    }
    setShowWarningModal(false);
    setPendingChanges(false);
    props.onHide();
  };

  const applyProductsMappings = () => {
    props.setBillData((prevState) => {
      const billItems = prevState.billItems.map((billItem) => {
        const descriptionUpper = billItem.description?.toUpperCase() ?? '';
        prevState.location?.mappings?.forEach((mapping) => {
          const fromUpper = mapping.from.toUpperCase();
          if (descriptionUpper.includes(fromUpper)) {
            billItem = { ...billItem, description: mapping.to };
          }
        });

        return billItem;
      });

      return { ...prevState, billItems };
    });
  };

  const updateBill = (rowIndex: number, columnId: string, value: any) => {
    setBillData((prevState) => {
      const updatedBillItems = prevState.billItems;
      updatedBillItems[rowIndex] = {
        ...prevState.billItems[rowIndex],
        [columnId]: value,
      };

      return {
        ...prevState,
        billItems: updatedBillItems,
      };
    });
  };

  const gallerryUiElements: UIElementData[] = [
    {
      name: 'remove-button',
      ariaLabel: 'Remove button',
      title: 'Usuń',
      order: 9,
      isButton: true,
      html: ReactDOMServer.renderToStaticMarkup(
        <MinusIcon style={{ cursor: 'pointer', width: '28x', height: '28px', fill: '#dc3545' }} />
      ),
      appendTo: 'bar',
      onClick: (e, el, pswpInstance: PhotoSwipe) => {
        props.setBillData((prevState) => {
          const billPicturesArray = [...prevState.billBase64Pictures];
          billPicturesArray.splice(currentIndex, 1);
          return {
            ...prevState,
            billBase64Pictures: billPicturesArray,
          };
        });
        // obsługa stanu PhotoSwipe
        const currentIndex = pswpInstance.currIndex;
        if (pswpInstance.options.dataSource) {
          const dataSourceArray = pswpInstance.options.dataSource as SlideData[];
          if (dataSourceArray) {
            dataSourceArray.splice(currentIndex, 1);
            if (dataSourceArray.length === currentIndex) {
              pswpInstance.goTo(currentIndex - 1);
            } else {
              for (let i = currentIndex; i < dataSourceArray.length; i++) {
                pswpInstance.refreshSlideContent(i);
              }
            }
          }
        }
      },
    },
    {
      name: 'rotate-button',
      ariaLabel: 'Rotate button',
      title: 'Obróć',
      order: 9,
      isButton: true,
      html: ReactDOMServer.renderToStaticMarkup(
        <RightRotateIcon style={{ width: '28x', height: '28px', stroke: '#fff' }} />
      ),
      appendTo: 'bar',
      onClick: async (e, el, pswpInstance: PhotoSwipe) => {
        if (pswpInstance.options.dataSource) {
          const currentItem = (pswpInstance.options.dataSource as SlideData[])[pswpInstance.currIndex];
          if (currentItem) {
            const pictureToRotate = currentItem.src;
            if (pictureToRotate) {
              const rotatedImageBase64 = await Common.Images.rotatePicture(pictureToRotate);
              props.setBillData((prevState) => {
                const billPicturesArray = [...prevState.billBase64Pictures];
                billPicturesArray[pswpInstance.currIndex].pictureBase64String = rotatedImageBase64;
                return {
                  ...prevState,
                  billBase64Pictures: billPicturesArray,
                };
              });
              const temp = currentItem.w;
              currentItem.w = currentItem.h;
              currentItem.h = temp;
              currentItem.src = rotatedImageBase64;
              pswpInstance.refreshSlideContent(pswpInstance.currIndex);
            }
          }
        }
      },
    },
  ];
  return (
    <>
      <Modal
        show={props.show}
        onHide={() => {
          if (pendingChanges) {
            showClosePendingChangesWarningModal();
          } else {
            setPendingChanges(false);
            props.onHide();
          }
        }}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        style={{ maxWidth: '100%', overflowX: 'auto', ...(showWarningModal ? { zIndex: 1049 } : {}) }}
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            {props.billId === 0 || props.copyMode
              ? props.pictureToLoadBill === null
                ? 'Dodaj nowy rachunek'
                : 'Wgraj rachunek ze zdjęcia'
              : 'Rachunek numer ' + props.billId}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {Common.Ui.showLoadingSpinnerFixed(props.loading)}
          <Container>
            <Row>
              <Col xs={{ span: 6, order: 0 }} sm={{ span: 4, order: 0 }}>
                <Form.Label>
                  <b>Lokalizacja</b>
                </Form.Label>
              </Col>
              <Col xs={{ span: 6, order: 1 }} sm={{ span: 4, order: 1 }}>
                <Form.Label>
                  <b>Data</b>
                </Form.Label>
              </Col>
              <Col xs={{ span: 6, order: 5 }} sm={{ span: 4, order: 2 }} />
              <Col xs={{ span: 6, order: 2 }} sm={{ span: 4, order: 3 }} className='pb-2'>
                <LocationsAutosuggest
                  key={props.billData.location?.locationId}
                  locations={props.locationNames}
                  selectedValue={props.billData.location}
                  onChange={(location: ILocation) => {
                    setPendingChanges(true);
                    props.setBillData((prevState) => ({
                      ...prevState,
                      location: location,
                    }));
                    const billItems = props.billData.billItems;

                    billItems.forEach((billItem) => {
                      const descriptionUpper = billItem.description?.toUpperCase() ?? '';
                      location!.mappings?.forEach((mapping) => {
                        const fromUpper = mapping.from.toUpperCase();
                        if (descriptionUpper.includes(fromUpper)) {
                          showExistingMappingsWarningModal();
                        }
                      });
                    });
                  }}
                  inputClassName='form-control form-control-sm'
                />
              </Col>
              <Col xs={{ span: 6, order: 3 }} sm={{ span: 4, order: 4 }} className='pb-2'>
                <Form.Control
                  className='form-control form-control-sm bill-date-form'
                  type='date'
                  name='billPayTime'
                  placeholder='Data zakupu'
                  onChange={(e: any) => {
                    const date = e.target.value;
                    setPendingChanges(true);
                    let updatedDate = new Date(date);
                    props.setBillData((prevState) => ({
                      ...prevState,
                      payTime: updatedDate,
                    }));
                  }}
                  value={
                    !props.billData.payTime
                      ? new Date().toLocaleString('en-CA').split(',')[0]
                      : new Date(props.billData.payTime).toLocaleString('en-CA').split(',')[0]
                  }
                />
              </Col>
              <Col xs={{ span: 6, order: 4 }} sm={{ span: 4, order: 5 }} className='pb-2'>
                <div className='float-sm-end'>
                  <Form.Check
                    type='switch'
                    id='show-groups-bill-switch'
                    label='Pokaż grupy'
                    style={{ width: '130px', userSelect: 'none' }}
                    defaultChecked={props.showGroups}
                    onChange={() => {
                      props.setShowGroups(!props.showGroups);
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <BillItemsTable
                  billData={props.billData}
                  setBillData={props.setBillData}
                  quantityTypes={props.quantityTypes}
                  updateBill={updateBill}
                  showGroups={props.showGroups}
                  categoriesNames={props.categoriesNames}
                  groupsNames={props.groupsNames}
                  initialLoading={props.initialLoading}
                  setPendingChanges={setPendingChanges}
                />
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <div className='me-sm-auto'>
            {props.billData.billBase64Pictures[0] != null && (
              <Gallery options={{ zoom: false }} uiElements={gallerryUiElements}>
                <Item
                  original={props.billData.billBase64Pictures[0].pictureBase64String}
                  width={picturesSizes[0]?.width ?? 600}
                  height={picturesSizes[0]?.height ?? 600}
                >
                  {({ ref, open }) => (
                    <Button ref={ref} variant='dark' onClick={open}>
                      <Gallery2Icon
                        style={{
                          fill: 'white',
                          width: '16',
                          height: '16',
                        }}
                      />
                    </Button>
                  )}
                </Item>
                {props.billData.billBase64Pictures.slice(1).map((picture, index) => (
                  <Item
                    key={index}
                    original={picture.pictureBase64String}
                    width={picturesSizes[index + 1]?.width ?? 600}
                    height={picturesSizes[index + 1]?.height ?? 600}
                  >
                    {({ ref }) => <span ref={ref} />}
                  </Item>
                ))}
              </Gallery>
            )}
            <input ref={inputRef} onChange={handleUploadBillImage} className='d-none' type='file' accept='image/*' />
            <Button variant='dark' className='ms-2' onClick={handleUpload}>
              Wgraj zdjęcie
            </Button>
          </div>
          <Button
            variant='dark'
            onClick={() => {
              if (pendingChanges) {
                showClosePendingChangesWarningModal();
              } else {
                setPendingChanges(false);
                props.onHide();
              }
            }}
          >
            Zamknij
          </Button>
          <Button
            variant='dark'
            disabled={props.loading || props.initialLoading || props.billId === 0 || props.copyMode}
            onClick={() => {
              props.setCopyMode(true);
              props.setBillData((prevState) => ({
                ...prevState,
                payTime: new Date(),
                billBase64Pictures: [],
              }));
            }}
          >
            Kopiuj
          </Button>
          <Button
            variant='danger'
            disabled={props.loading || props.initialLoading || props.billId === 0 || props.copyMode}
            onClick={showRemoveBillWarningModal}
          >
            Usuń
          </Button>
          <Button
            variant='success'
            disabled={props.loading || props.initialLoading}
            onClick={() => {
              const currentDate = new Date();
              const oneYearAgo = new Date(currentDate);
              oneYearAgo.setFullYear(currentDate.getFullYear() - 1);
              const payTimeDate = new Date(props.billData.payTime as string);
              if (pendingChanges && payTimeDate < oneYearAgo && props.billId === 0) {
                showBillPayTimeOlderThanOneYearWarningModal();
              } else {
                saveBill();
              }
            }}
          >
            Zapisz
          </Button>
        </Modal.Footer>
      </Modal>
      <WarningModal
        show={showWarningModal}
        modalBodyText={(() => {
          switch (warningType) {
            case BillWarningType.RemoveConfirmation:
              return 'Czy na pewno chcesz usunąć ten paragon?';
            case BillWarningType.PendingChanges:
              return 'Wprowadzone zmiany w paragonie nie zostały zapisane. Czy na pewno chcesz kontynuować?';
            case BillWarningType.ExistingMappings:
              return 'Znaleziono mapowania produktów dla wybranej lokalizacji. Czy chcesz je zastosować?';
            case BillWarningType.BillPayTimeOlderThanOneYear:
              return 'Data paragonu jest starsza niż 1 rok. Czy na pewno data jest poprawna i chcesz ją zapisać?';
            case BillWarningType.FetchBillFromPicture:
              return 'Czy chcesz wygenerować paragon za pomocą dodanego zdjęcia?';
            default:
              return '';
          }
        })()}
        onHide={() => {
          switch (warningType) {
            case BillWarningType.FetchBillFromPicture:
              setShowWarningModal(false);
              if (warningParams) {
                const billPictureBase64 = warningParams as string;
                setBillData((prevState) => ({
                  ...prevState,
                  billBase64Pictures: prevState.billBase64Pictures.concat({
                    billPictureId: 0,
                    pictureBase64String: billPictureBase64,
                    pictureMd5Hash: '',
                  }),
                }));
              }
              setWarningParams(undefined);
          }
          setShowWarningModal(false);
          setPendingChanges(false);
        }}
        onConfirmation={() => {
          switch (warningType) {
            case BillWarningType.RemoveConfirmation:
              removeBill();
              return;
            case BillWarningType.PendingChanges:
              setShowWarningModal(false);
              setPendingChanges(false);
              props.onHide();
              return;
            case BillWarningType.ExistingMappings:
              applyProductsMappings();
              setShowWarningModal(false);
              setPendingChanges(true);
              return;
            case BillWarningType.BillPayTimeOlderThanOneYear:
              setShowWarningModal(false);
              saveBill();
              return;
            case BillWarningType.FetchBillFromPicture:
              setShowWarningModal(false);
              if (warningParams) {
                const billPictureBase64 = warningParams as string;
                const currentPictures = [...billData.billBase64Pictures];
                fetchBillFromPicture(billPictureBase64).then(() =>
                  setBillData((prevState) => ({
                    ...prevState,
                    billBase64Pictures: currentPictures.concat({
                      billPictureId: 0,
                      pictureBase64String: billPictureBase64,
                      pictureMd5Hash: '',
                    }),
                  }))
                );
              }
              setWarningParams(undefined);
              return;
          }
        }}
      />
    </>
  );
}

export default BillModal;
