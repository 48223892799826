import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import React, { useEffect } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import Common from '../../shared/Common';
import type { ISummaryInfo } from '../../../interfaces/ISummaryInfo';
import type { ColumnDef, SortingState } from '@tanstack/react-table';
import { SortUpIcon, SortDownIcon, MinusIcon, CopyIcon } from '../../shared/icons/Svgs';
import { useNavigate } from 'react-router-dom';
import WarningModal from '../../shared/shared-modals/WarningModal';
import type { IApplicationAlert } from '../../shared/application-alert/ApplicationAlert';

import './style.css';

export interface ITableProps {
  data: ISummaryInfo[];
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  fetchData: () => Promise<void>;
  fetchTrigger: number;
  setFetchTrigger: React.Dispatch<React.SetStateAction<number>>;
  status: IApplicationAlert | undefined;
  setStatus: React.Dispatch<React.SetStateAction<IApplicationAlert | undefined>>;
}

function SummariesTable(props: ITableProps) {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [summaryToRemove, setSummaryToRemove] = React.useState<string | undefined>(undefined);
  const navigate = useNavigate();
  const { setStatus } = props;
  const columns = React.useMemo<ColumnDef<ISummaryInfo>[]>(
    () => [
      {
        accessorFn: (row) => row.name,
        id: 'name',
        header: () => <span>Nazwa</span>,
      },
      {
        accessorFn: (row) => row.totalElements,
        id: 'totalElements',
        header: () => <span>Liczba elementów</span>,
      },
      {
        accessorFn: (row) => Common.Utils.getCurrencyString(row.totalPrice),
        id: 'totalPrice',
        header: () => <span>Sumaryczny koszt</span>,
      },
      {
        accessorFn: (row) => row.public,
        id: 'public',
        header: () => <span>Dostępne publicznie</span>,
        cell: (row) => <>{row.row.original.public ? 'TAK' : 'NIE'}</>,
      },
      {
        id: 'actions',
        header: () => <div className='text-center'>Akcje</div>,
        cell: (info) => (
          <Row className='justify-content-center'>
            {info.row.original.public && (
              <Col xs='auto' className='p-0'>
                <div
                  style={{ cursor: 'pointer' }}
                  title='Kopiuj link do udostępnienia'
                  onClick={(e: any) => {
                    const shareLink =
                      'https://' + window.location.host + '/summary/' + info.row.original.shareIdentifier;
                    navigator.clipboard.writeText(shareLink);
                    setStatus({
                      variant: 'info',
                      status: 'Adres skopiowano do schowka.',
                      messageTime: Date.now(),
                    });
                    e.stopPropagation();
                  }}
                >
                  <CopyIcon
                    style={{
                      color: 'var(--app-main-color)',
                      width: '38px',
                      height: '38px',
                    }}
                  />
                </div>
              </Col>
            )}
            <Col xs='auto' className='p-0'>
              <div
                title='Usuń podsumowanie'
                onClick={(e: any) => {
                  setSummaryToRemove(info.row.original.guid);
                  e.stopPropagation();
                }}
              >
                <MinusIcon style={{ cursor: 'pointer', width: '38px', height: '38px', fill: '#dc3545' }} />
              </div>
            </Col>
          </Row>
        ),
        enableSorting: false,
      },
    ],
    [setStatus]
  );

  const removeSummary = async (guid: string) => {
    props.setLoading(true);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ guid }),
    };
    const response = await Common.authorizedFetch('api/summaries/removeSummaryConfig', requestOptions);
    const data = await response.json();
    props.setLoading(false);
    if (!data.success) {
      alert(data.errors);
    } else {
      props.setFetchTrigger((prev) => prev + 1);
    }
  };

  const table = useReactTable({
    data: props.data,
    columns: columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const { fetchData, fetchTrigger } = props;

  useEffect(() => {
    fetchData();
  }, [fetchData, fetchTrigger]);

  return (
    <>
      <Button className='me-2' variant='success' onClick={() => navigate('create')}>
        Dodaj
      </Button>
      <div className='mt-2 pb-5 table-responsive-sm'>
        <table id='summaries-table' className='table table-hover'>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          style: header.column.getCanSort()
                            ? {
                                cursor: 'pointer',
                              }
                            : {},
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: <SortUpIcon style={{ marginLeft: '5px' }} />,
                          desc: <SortDownIcon style={{ marginLeft: '5px' }} />,
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {props.loading
              ? Common.Ui.getSkeletonTableRows(6, 5, 35)
              : table.getRowModel().rows.map((row) => (
                  <tr key={row.id} style={{ cursor: 'pointer' }} onClick={() => navigate(row.original.guid)}>
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                    ))}
                  </tr>
                ))}
          </tbody>
        </table>
      </div>
      <WarningModal
        show={summaryToRemove !== undefined}
        modalBodyText={'Czy na pewno chcesz usunąć te podsumowanie?'}
        onHide={() => {
          setSummaryToRemove(undefined);
        }}
        onConfirmation={() => {
          if (summaryToRemove !== undefined) {
            removeSummary(summaryToRemove);
          }
          setSummaryToRemove(undefined);
        }}
      />
    </>
  );
}

export default SummariesTable;
