import { Col, Row } from 'react-bootstrap';
import type { IUserSettingsChildProps } from '../UserSettings';
import './style.css';

const ShowRecoveryCodes = (props: IUserSettingsChildProps) => {
  const { additionalData } = props;

  const showRecoveryCodes = (codes: string[]) => {
    const codesList = [];
    for (let row = 0, ind = 0; row < codes.length; row += 2) {
      codesList.push(
        <div key={ind++}>
          <code key={ind++}>{codes[row]}</code>&nbsp;
          <code key={ind++}>{codes[row + 1]}</code>
          <br />
        </div>
      );
    }
    return <>{codesList}</>;
  };

  return (
    <>
      <h4>Kody odzyskiwania</h4>
      <div className='alert alert-warning' role='alert'>
        <p>
          <strong>Odłóż te kody w bezpieczne miejsce.</strong>
        </p>
        <p>
          Jeśli zgubisz swoje urządzenie i nie będziesz miał dostępu do kodów odzyskiwania, stracisz dostęp do swojego
          konta.
        </p>
      </div>
      <Row>
        <Col md={12}>
          {(additionalData?.generatedCodes ?? false) && showRecoveryCodes(additionalData?.generatedCodes)}
        </Col>
      </Row>
    </>
  );
};

export default ShowRecoveryCodes;
