import { Formik } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import * as yup from 'yup';
import Common from '../../shared/Common';
import ApplicationAlert from '../../shared/application-alert/ApplicationAlert';
import ExternalLogins from '../../shared/external-logins/ExternalLogins';
import type { IApplicationAlert } from '../../shared/application-alert/ApplicationAlert';

const Register = () => {
  const [status, setStatus] = useState<IApplicationAlert>();
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [returnUrl, setReturnUrl] = useState<string>('');

  const identityServerUrl = import.meta.env.VITE_IDENTITY_SERVER_URL ?? '';

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const returnUrl = params.get('ReturnUrl');
    if (!!returnUrl) {
      setReturnUrl(returnUrl);
    }
  }, []);

  const LoginForm = useCallback(() => {
    const validationSchema = yup.object().shape({
      userName: yup.string().required('Pole z adresem e-mail jest wymagane').email('Podano nieprawidłowy adres e-mail'),
      password: yup
        .string()
        .required('Nie wprowadzono hasła')
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
          'Musi zawierać minimum jedną wielką literę, jedną małą literę i jedną cyfrę'
        )
        .min(8, 'Hasło jest za krótkie - hasło powinno zawierać minimum 8 znaków'),
      confirmedPassword: yup.string().test('passwords-match', 'Hasła muszą być takie same', function (value) {
        return this.parent.password === value;
      }),
    });

    return (
      <Formik
        initialValues={{
          userName: '',
          password: '',
          confirmedPassword: '',
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          setFormLoading(true);
          const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              username: values.userName,
              password: values.password,
            }),
          };
          setSubmitting(false);
          const response = await Common.authorizedFetch('idsrv/account/register', requestOptions);
          const data = await response.json();
          setFormLoading(false);
          if (data.success) {
            window.location.replace(`${identityServerUrl}${returnUrl}`);
            return;
          } else {
            setStatus({
              variant: 'error',
              status: data.errors,
              messageTime: Date.now(),
            });
            return;
          }
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
          <>
            <Form onSubmit={handleSubmit}>
              <Form.Group>
                <Form.Label>E-mail</Form.Label>
                <Form.Control
                  autoComplete='on'
                  type='text'
                  name='userName'
                  placeholder='Twój adres e-mail'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.userName}
                  className={touched.userName && errors.userName ? 'error' : undefined}
                />
                {touched.userName && errors.userName ? <div className='error-message'>{errors.userName}</div> : null}
              </Form.Group>
              <Form.Group className='mt-2'>
                <Form.Label>Hasło</Form.Label>
                <Form.Control
                  autoComplete='on'
                  type='password'
                  name='password'
                  placeholder='Twoje hasło'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  className={touched.password && errors.password ? 'error' : undefined}
                />
                {touched.password && errors.password ? (
                  <div className='error-large-message'>{errors.password}</div>
                ) : null}
              </Form.Group>
              <Form.Group className='mt-2'>
                <Form.Label>Potwierdź hasło</Form.Label>
                <Form.Control
                  autoComplete='off'
                  type='password'
                  name='confirmedPassword'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.confirmedPassword}
                  className={touched.confirmedPassword && errors.confirmedPassword ? 'error' : undefined}
                  placeholder='Wpisz hasło ponownie'
                />
                {touched.confirmedPassword && errors.confirmedPassword ? (
                  <div className='error-message'>{errors.confirmedPassword}</div>
                ) : null}
              </Form.Group>
              <Form.Group className='mt-2'>
                <Button className='mt-2' variant='primary' type='submit' disabled={isSubmitting}>
                  Zarejestruj
                </Button>
              </Form.Group>
            </Form>
          </>
        )}
      </Formik>
    );
  }, [identityServerUrl, returnUrl]);

  const LoginPanel = () => (
    <Row>
      <Col md={6} className='mt-3 order-lg-2'>
        <h4>Stwórz konto przez aplikację</h4>
        <hr />
        <ExternalLogins
          returnUrl={returnUrl}
          identityServerUrl={identityServerUrl}
          actionType='signIn'
          loginProviders={[
            { name: 'Facebook', displayName: 'Facebook' },
            { name: 'Google', displayName: 'Google' },
          ]}
        />
      </Col>
      <Col md={4} className='mt-3 order-lg-1'>
        <h4>Stwórz konto</h4>
        <hr />
        <LoginForm />
      </Col>
    </Row>
  );

  return (
    <>
      {Common.Ui.showLoadingSpinnerFixed(formLoading)}
      <h1>Rejestracja</h1>
      {status && (
        <ApplicationAlert variant={status?.variant} status={status?.status} messageTime={status?.messageTime} />
      )}
      <LoginPanel />
    </>
  );
};

export default Register;
