import { useState, useEffect, useCallback, useRef } from 'react';
import { Container, Button, Tab, Tabs } from 'react-bootstrap';
import Common from '../../../shared/Common';
import StartingCategoriesTable from './StartingCategoriesTable';
import StartingLocationsTable from './StartingLocationsTable';

export interface IStartingLocation {
  startingLocationId: number;
  locationName: string;
  pictureBase64?: string;
  defaultPictureId?: number;
}

export interface IStartingCategory {
  startingCategoryId: number;
  categoryName: string;
  parentCategoryId?: number;
}

const NewAccounts = () => {
  const [locations, setLocations] = useState<IStartingLocation[]>([]);
  const [fetchLocationsTrigger, setFetchLocationsTrigger] = useState(0);
  const [loadingLocations, setLoadingLocations] = useState<boolean>(false);
  const [locationsChanged, setLocationsChanged] = useState<boolean>(false);
  const [categories, setCategories] = useState<IStartingCategory[]>([]);
  const [parentCategoriesIds, setParentCategoriesIds] = useState<number[]>([]);
  const [fetchCategoriesTrigger, setFetchCategoriesTrigger] = useState(0);
  const [loadingCategories, setLoadingCategories] = useState<boolean>(false);
  const [categoriesChanged, setCategoriesChanged] = useState<boolean>(false);
  // wykorzystywane do selecta z nadrzędną kategorią
  const [fetchedCategories, setFetchedCategories] = useState<IStartingCategory[]>([]);
  const fetchLocationsIdRef = useRef(0);
  const fetchCategoriesIdRef = useRef(0);

  const fetchLocations = useCallback(async () => {
    setLoadingLocations(true);
    const fetchId = ++fetchLocationsIdRef.current;
    const response = await Common.authorizedFetch('api/applicationLogicConfiguration/getStartingLocations');
    if (fetchId === fetchLocationsIdRef.current) {
      const data = await response.json();
      if (data.success) {
        setLocations(data.result.startingLocations);
      }
      setLoadingLocations(false);
    }
  }, []);

  const fetchCategories = useCallback(async () => {
    setLoadingCategories(true);
    const fetchId = ++fetchCategoriesIdRef.current;
    const response = await Common.authorizedFetch('api/applicationLogicConfiguration/getStartingCategories');
    if (fetchId === fetchCategoriesIdRef.current) {
      const data = await response.json();
      if (data.success) {
        const categories = data.result.startingCategories as IStartingCategory[];
        setCategories(categories);
        setFetchedCategories(categories);
        const categoriesArray: number[] = [];
        for (var ind = 0; ind < categories.length; ind++) {
          const elem = categories[ind].parentCategoryId;
          if (elem) {
            categoriesArray.push(elem);
          }
        }
        setParentCategoriesIds(categoriesArray.filter((value, index, array) => array.indexOf(value) === index));
      }
      setLoadingCategories(false);
    }
  }, []);

  const updateData = async () => {
    const promises: Promise<void>[] = [];
    if (locationsChanged) {
      promises.push(updateLocations());
    }
    if (categoriesChanged) {
      promises.push(updateCategories());
    }
    setLocationsChanged(false);
    if (promises.length > 0) {
      await Promise.all(promises);
    }
  };

  const updateLocations = async () => {
    setLoadingLocations(true);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        startingLocations: locations,
      }),
    };
    const response = await Common.authorizedFetch(
      'api/applicationLogicConfiguration/updateStartingLocations',
      requestOptions
    );
    const data = await response.json();
    if (!data.success) {
      alert(data.errors);
      setLoadingLocations(false);
    } else {
      setFetchLocationsTrigger((prev) => prev + 1);
    }
  };

  const updateCategories = async () => {
    setLoadingCategories(true);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        startingCategories: categories,
      }),
    };
    const response = await Common.authorizedFetch(
      'api/applicationLogicConfiguration/updateStartingCategories',
      requestOptions
    );
    const data = await response.json();
    if (!data.success) {
      alert(data.errors);
      setLoadingCategories(false);
    } else {
      setFetchCategoriesTrigger((prev) => prev + 1);
    }
  };

  useEffect(() => {
    fetchLocations();
  }, [fetchLocations, fetchLocationsTrigger]);

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories, fetchCategoriesTrigger]);

  return (
    <>
      <h4 className='mb-3'>Nowe konta</h4>
      <Container>
        <Tabs defaultActiveKey={'locations'} className='mb-3'>
          <Tab eventKey={'locations'} title='Lokalizacje startowe'>
            <StartingLocationsTable
              loading={loadingLocations}
              locations={locations}
              setLocations={setLocations}
              setLocationsChanged={setLocationsChanged}
            />
          </Tab>
          <Tab eventKey={'categories'} title='Kategorie startowe'>
            <StartingCategoriesTable
              loading={loadingCategories}
              categories={categories}
              setCategories={setCategories}
              setCategoriesChanged={setCategoriesChanged}
              parentCategoriesIds={parentCategoriesIds}
              fetchedCategories={fetchedCategories}
            />
          </Tab>
        </Tabs>
        <Button
          className='mt-3'
          variant='primary'
          onClick={updateData}
          disabled={!locationsChanged && !categoriesChanged}
        >
          Zapisz
        </Button>
      </Container>
    </>
  );
};

export default NewAccounts;
