import { useState, useCallback, useRef, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import Common from '../../shared/Common';
import type { ILocationWithMappings } from '../../../interfaces/ILocationWithMappings';
import type { ILocationData } from '../../../interfaces/ILocationData';
import AddLocationModal from './AddLocationModal';
import LocationsTable from './LocationsTable';
import type { ColumnFiltersState, SortingState } from '@tanstack/react-table';
import type { IDefaultLocationPicture } from '../../../interfaces/IDefaultLocationPicture';

import './style.css';

export interface IPaginationProps {
  pageIndex: number;
  pageSize: number;
  sortBy: SortingState;
  filters: ColumnFiltersState;
}

const Locations = () => {
  const [locations, setLocations] = useState<ILocationData[]>([]);
  const [loading, setLoading] = useState(true);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showAddLocationModal, setShowAddLocationModal] = useState(false);
  const [updatedLocations, setUpdatedLocations] = useState<ILocationWithMappings[]>([]);
  const [selectedRowId, setSelectedRowId] = useState(-1);
  const [locationPictures, setLocationPictures] = useState<IDefaultLocationPicture[]>([]);
  const [fetchTrigger, setFetchTrigger] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const fetchPicturesIdRef = useRef(0);
  const fetchIdRef = useRef(0);

  const fetchData = useCallback(
    async ({ pageIndex, pageSize, sortBy, filters }: IPaginationProps) => {
      setLoading(true);
      const fetchId = ++fetchIdRef.current;
      const filterRequest = filters.map((f) => '&' + f.id + 'Filter=' + f.value).join('');
      var sortByRequest = '';
      if (sortBy.length > 0) {
        sortByRequest = sortBy.map((s) => '&sortBy=' + s.id + (s.desc ? ' DESC' : ' ASC')).join('');
      } else {
        sortByRequest = '&sortBy=locationName ASC';
      }
      const requestUrl =
        'api/locations/getLocationsData?pageIndex=' +
        pageIndex +
        '&pageSize=' +
        pageSize +
        filterRequest +
        sortByRequest;
      const response = await Common.authorizedFetch(requestUrl);
      const data = await response.json();
      if (data.success) {
        if (data.success && fetchId === fetchIdRef.current) {
          if ((data.result.locations?.length ?? 0) > 0) {
            setLocations(() => {
              var locations: ILocationData[] = data.result.locations;
              // nadpisywanie wcześniej wprowadzonych zmian, które zostałyby usunięte
              // po np. kliknięciu na stronę w paginacji
              if (locations.length > 0) {
                for (let i = 0; i < updatedLocations.length; i++) {
                  const ind = locations.findIndex((l) => l.location?.locationId === updatedLocations[i].locationId);
                  if (ind > -1) {
                    locations[ind].location = {
                      locationId: updatedLocations[i].locationId,
                      locationName: updatedLocations[i].locationName,
                      picture: updatedLocations[i].picture,
                      mappings: updatedLocations[i].mappings,
                    };
                  }
                }
              }
              return locations;
            });
            setPageCount(Math.ceil(data.result.locations[0].totalCount / pageSize));
          } else {
            setLocations([]);
            setPageCount(0);
          }
        }
        setLoading(false);
      }
    },
    [updatedLocations]
  );

  useEffect(() => {
    // nie uruchamiamy za pierwszym razem ze względu na aktualizację setUpdatedLocations,
    // która wpływa na ponowne ładowanie listy z lokalizacjami
    if (fetchTrigger > 0) {
      setUpdatedLocations([]);
      setSelectedRowId(-1);
    }
  }, [fetchTrigger]);

  const fetchLocationPictures = useCallback(async () => {
    const fetchId = ++fetchPicturesIdRef.current;
    const response = await Common.authorizedFetch('api/locations/getLocationPictures');
    if (fetchId === fetchPicturesIdRef.current) {
      const data = await response.json();
      if (data.success) {
        setLocationPictures(data.result.locationPictures);
      }
    }
  }, []);

  useEffect(() => {
    fetchLocationPictures();
  }, [fetchLocationPictures]);

  const updateLocation = (rowIndex: number, columnId: string, value: any) => {
    setLocations((prevState) => {
      const updated = prevState;
      const ind = updatedLocations.findIndex((l) => l.locationId === updated[rowIndex].location?.locationId);
      if (ind === -1) {
        if (value.type === 'name') {
          updatedLocations.push({
            locationId: updated[rowIndex].location?.locationId ?? -1,
            locationName: value.value,
            picture: updated[rowIndex].location?.picture ?? '',
            mappings: updated[rowIndex].location?.mappings ?? [],
          });
        } else if (value.type === 'picture') {
          updatedLocations.push({
            locationId: updated[rowIndex].location?.locationId ?? -1,
            locationName: updated[rowIndex].location?.locationName ?? '',
            picture: value.value,
            mappings: updated[rowIndex].location?.mappings ?? [],
          });
        } else if (value.type === 'defaultPicture') {
          updatedLocations.push({
            locationId: updated[rowIndex].location?.locationId ?? -1,
            locationName: updated[rowIndex].location?.locationName ?? '',
            picture: value.value.picture,
            defaultPictureId: value.value.defaultPictureId,
            mappings: updated[rowIndex].location?.mappings ?? [],
          });
        }
      } else {
        if (value.type === 'name') {
          updatedLocations[ind].locationName = value.value;
        } else if (value.type === 'picture') {
          updatedLocations[ind].picture = value.value;
        } else if (value.type === 'defaultPicture') {
          updatedLocations[ind].picture = value.value.picture;
          updatedLocations[ind].defaultPictureId = value.value.defaultPictureId;
        }
      }
      setUpdatedLocations(updatedLocations);
      if (value.type === 'picture' || value.type === 'defaultPicture') {
        updated[rowIndex] = {
          ...prevState[rowIndex],
          location: {
            locationId: prevState[rowIndex].location!.locationId,
            locationName: prevState[rowIndex].location!.locationName,
            picture: value.type === 'picture' ? value.value : value.value.picture,
            mappings: prevState[rowIndex].location?.mappings ?? [],
          },
        };
      } else if (value.type === 'name') {
        updated[rowIndex] = {
          ...prevState[rowIndex],
          location: {
            locationId: prevState[rowIndex].location!.locationId,
            locationName: value.value,
            picture: prevState[rowIndex].location!.picture,
            mappings: prevState[rowIndex].location?.mappings ?? [],
          },
        };
      } else {
        updated[rowIndex] = {
          ...prevState[rowIndex],
          [columnId]: value.value,
        };
      }
      return [...updated];
    });
  };

  const updateLocationMappings = (value: ILocationData) => {
    const index = locations.findIndex((l) => l.location?.locationId === value.location?.locationId);
    if (index > -1) {
      if (JSON.stringify(value.location?.mappings) !== JSON.stringify(locations[index].location?.mappings)) {
        setLocations((prevState) => {
          const updated = prevState;
          const updatedLocationsIndex = updatedLocations.findIndex(
            (l) => l.locationId === updated[index].location?.locationId
          );
          if (updatedLocationsIndex === -1) {
            updatedLocations.push({
              locationId: updated[index].location?.locationId ?? -1,
              locationName: updated[index].location?.locationName ?? '',
              picture: updated[index].location?.picture ?? '',
              mappings: value.location?.mappings ?? [],
            });
          } else {
            updatedLocations[updatedLocationsIndex].mappings = value.location?.mappings ?? [];
          }
          setUpdatedLocations(updatedLocations);
          updated[index].location!.mappings = value.location?.mappings ?? [];
          return updated;
        });
      }
    }
  };

  return (
    <Container className='mt-2'>
      <h2 className='mb-3' id='tabelLabel'>
        Lokalizacje
      </h2>
      {Common.Ui.showLoadingSpinnerFixed(showSpinner)}
      <LocationsTable
        data={locations}
        loading={loading}
        setShowSpinner={setShowSpinner}
        updateLocationMappings={updateLocationMappings}
        fetchData={fetchData}
        fetchTrigger={fetchTrigger}
        setFetchTrigger={setFetchTrigger}
        updateLocation={updateLocation}
        updatedLocations={updatedLocations}
        setShowAddLocationModal={setShowAddLocationModal}
        selectedRowId={selectedRowId}
        setSelectedRowId={setSelectedRowId}
        locationPictures={locationPictures}
        totalPageCount={pageCount}
      />
      <AddLocationModal
        show={showAddLocationModal}
        onHide={() => {
          setShowAddLocationModal(false);
        }}
        onSave={() => {
          setFetchTrigger(fetchTrigger + 1);
        }}
        setShowSpinner={setShowSpinner}
        locationPictures={locationPictures}
      />
    </Container>
  );
};

export default Locations;
