export const renderHomeIcon = (
  active: boolean,
  activeIconStyle?: React.CSSProperties,
  nonActiveIconStyle?: React.CSSProperties
) => {
  if (active) {
    return (
      <svg viewBox='0 0 28 28' style={activeIconStyle} fill='currentColor'>
        <path d='M25.825 12.29C25.824 12.289 25.823 12.288 25.821 12.286L15.027 2.937C14.752 2.675 14.392 2.527 13.989 2.521 13.608 2.527 13.248 2.675 13.001 2.912L2.175 12.29C1.756 12.658 1.629 13.245 1.868 13.759 2.079 14.215 2.567 14.479 3.069 14.479L5 14.479 5 23.729C5 24.695 5.784 25.479 6.75 25.479L11 25.479C11.552 25.479 12 25.031 12 24.479L12 18.309C12 18.126 12.148 17.979 12.33 17.979L15.67 17.979C15.852 17.979 16 18.126 16 18.309L16 24.479C16 25.031 16.448 25.479 17 25.479L21.25 25.479C22.217 25.479 23 24.695 23 23.729L23 14.479 24.931 14.479C25.433 14.479 25.921 14.215 26.132 13.759 26.371 13.245 26.244 12.658 25.825 12.29' />
      </svg>
    );
  } else {
    return (
      <svg viewBox='0 0 28 28' style={nonActiveIconStyle} fill='currentColor'>
        <path d='M17.5 23.979 21.25 23.979C21.386 23.979 21.5 23.864 21.5 23.729L21.5 13.979C21.5 13.427 21.949 12.979 22.5 12.979L24.33 12.979 14.017 4.046 3.672 12.979 5.5 12.979C6.052 12.979 6.5 13.427 6.5 13.979L6.5 23.729C6.5 23.864 6.615 23.979 6.75 23.979L10.5 23.979 10.5 17.729C10.5 17.04 11.061 16.479 11.75 16.479L16.25 16.479C16.939 16.479 17.5 17.04 17.5 17.729L17.5 23.979ZM21.25 25.479 17 25.479C16.448 25.479 16 25.031 16 24.479L16 18.327C16 18.135 15.844 17.979 15.652 17.979L12.348 17.979C12.156 17.979 12 18.135 12 18.327L12 24.479C12 25.031 11.552 25.479 11 25.479L6.75 25.479C5.784 25.479 5 24.695 5 23.729L5 14.479 3.069 14.479C2.567 14.479 2.079 14.215 1.868 13.759 1.63 13.245 1.757 12.658 2.175 12.29L13.001 2.912C13.248 2.675 13.608 2.527 13.989 2.521 14.392 2.527 14.753 2.675 15.027 2.937L25.821 12.286C25.823 12.288 25.824 12.289 25.825 12.29 26.244 12.658 26.371 13.245 26.133 13.759 25.921 14.215 25.434 14.479 24.931 14.479L23 14.479 23 23.729C23 24.695 22.217 25.479 21.25 25.479Z' />
      </svg>
    );
  }
};

export const renderReportsIcon = (
  active: boolean,
  activeIconStyle?: React.CSSProperties,
  nonActiveIconStyle?: React.CSSProperties
) => {
  if (active) {
    return (
      <svg viewBox='0 0 262.727 262.727' fill='currentColor' style={activeIconStyle}>
        <rect
          x='204.927'
          y='37.391'
          style={{
            fill: '#FEDE94',
          }}
          width='30.822'
          height='208.996'
        />
        <rect
          x='126.75'
          y='89.68'
          style={{
            fill: '#AAE1E9',
          }}
          width='30.854'
          height='156.707'
        />
        <rect
          x='46.298'
          y='137.387'
          style={{
            fill: '#F47C6D',
          }}
          width='30.822'
          height='109'
        />
        <path
          style={{
            fill: '#2D213F',
          }}
          d='M252.025,246.418v8.106c0,4.486-3.62,8.138-8.138,8.138h-47.131c-4.486,0-8.138-3.653-8.138-8.138v-8.106L252.025,246.418L252.025,246.418z'
        />
        <path
          style={{
            fill: '#2D213F',
          }}
          d='M204.927,246.386h30.822V37.391h-30.822V246.386z M252.025,246.418h-63.407V29.252c0-4.518,3.653-8.138,8.138-8.138h47.131c4.518,0,8.138,3.621,8.138,8.138V246.418z'
        />
        <path
          style={{
            fill: '#2D213F',
          }}
          d='M262.727,254.557c0,4.518-3.653,8.17-8.138,8.17h-10.701v-0.064c4.518,0,8.138-3.653,8.138-8.138v-8.106h2.563C259.074,246.418,262.727,250.071,262.727,254.557z'
        />
        <path
          style={{
            fill: '#2D213F',
          }}
          d='M173.88,246.418v8.106c0,4.486-3.653,8.138-8.138,8.138h-47.131c-4.486,0-8.138-3.653-8.138-8.138v-8.106L173.88,246.418L173.88,246.418z'
        />
        <path
          style={{
            fill: '#2D213F',
          }}
          d='M173.88,246.418h-63.407V81.541c0-4.486,3.653-8.138,8.138-8.138h47.131c4.486,0,8.138,3.653,8.138,8.138V246.418z M126.75,246.386h30.854V89.68H126.75V246.386z'
        />
        <path
          style={{
            fill: '#2D213F',
          }}
          d='M188.619,254.525c0,4.486,3.653,8.138,8.138,8.138h47.131v0.064h-78.145v-0.064c4.486,0,8.138-3.653,8.138-8.138v-8.106h14.738L188.619,254.525L188.619,254.525z'
        />
        <path
          style={{
            fill: '#2D213F',
          }}
          d='M93.428,246.418v8.106c0,4.486-3.653,8.138-8.138,8.138H38.159c-4.518,0-8.138-3.653-8.138-8.138v-8.106L93.428,246.418L93.428,246.418z'
        />
        <path
          style={{
            fill: '#2D213F',
          }}
          d='M93.428,246.418H30.021v-117.17c0-4.518,3.621-8.138,8.138-8.138H85.29c4.486,0,8.138,3.621,8.138,8.138V246.418z M46.298,246.386H77.12v-109H46.298V246.386z'
        />
        <path
          style={{
            fill: '#2D213F',
          }}
          d='M118.612,262.663h47.131v0.064H85.29v-0.064c4.486,0,8.138-3.653,8.138-8.138v-8.106h17.045v8.106C110.473,259.01,114.126,262.663,118.612,262.663z'
        />
        <path
          style={{
            fill: '#2D213F',
          }}
          d='M38.159,262.663H85.29v0.064H8.138c-4.486,0-8.138-3.653-8.138-8.17V8.138C0,3.621,3.653,0,8.138,0c4.518,0,8.17,3.621,8.17,8.138v238.28h13.713v8.106C30.021,259.01,33.642,262.663,38.159,262.663z'
        />
      </svg>
    );
  } else {
    return (
      <svg viewBox='0 0 262.727 262.727' style={nonActiveIconStyle} fill='currentColor'>
        <rect
          x='204.927'
          y='37.391'
          style={{
            fill: 'none',
          }}
          width='30.822'
          height='208.996'
        />
        <rect
          x='126.75'
          y='89.68'
          style={{
            fill: 'none',
          }}
          width='30.854'
          height='156.707'
        />
        <rect
          x='46.298'
          y='137.387'
          style={{
            fill: 'none',
          }}
          width='30.822'
          height='109'
        />
        <path
          style={{
            fill: 'var(--app-secondary-color)',
          }}
          d='M252.025,246.418v8.106c0,4.486-3.62,8.138-8.138,8.138h-47.131c-4.486,0-8.138-3.653-8.138-8.138v-8.106L252.025,246.418L252.025,246.418z'
        />
        <path
          style={{
            fill: 'var(--app-secondary-color)',
          }}
          d='M204.927,246.386h30.822V37.391h-30.822V246.386z M252.025,246.418h-63.407V29.252c0-4.518,3.653-8.138,8.138-8.138h47.131c4.518,0,8.138,3.621,8.138,8.138V246.418z'
        />
        <path
          style={{
            fill: 'var(--app-secondary-color)',
          }}
          d='M262.727,254.557c0,4.518-3.653,8.17-8.138,8.17h-10.701v-0.064c4.518,0,8.138-3.653,8.138-8.138v-8.106h2.563C259.074,246.418,262.727,250.071,262.727,254.557z'
        />
        <path
          style={{
            fill: 'var(--app-secondary-color)',
          }}
          d='M173.88,246.418v8.106c0,4.486-3.653,8.138-8.138,8.138h-47.131c-4.486,0-8.138-3.653-8.138-8.138v-8.106L173.88,246.418L173.88,246.418z'
        />
        <path
          style={{
            fill: 'var(--app-secondary-color)',
          }}
          d='M173.88,246.418h-63.407V81.541c0-4.486,3.653-8.138,8.138-8.138h47.131c4.486,0,8.138,3.653,8.138,8.138V246.418z M126.75,246.386h30.854V89.68H126.75V246.386z'
        />
        <path
          style={{
            fill: 'var(--app-secondary-color)',
          }}
          d='M188.619,254.525c0,4.486,3.653,8.138,8.138,8.138h47.131v0.064h-78.145v-0.064c4.486,0,8.138-3.653,8.138-8.138v-8.106h14.738L188.619,254.525L188.619,254.525z'
        />
        <path
          style={{
            fill: 'var(--app-secondary-color)',
          }}
          d='M93.428,246.418v8.106c0,4.486-3.653,8.138-8.138,8.138H38.159c-4.518,0-8.138-3.653-8.138-8.138v-8.106L93.428,246.418L93.428,246.418z'
        />
        <path
          style={{
            fill: 'var(--app-secondary-color)',
          }}
          d='M93.428,246.418H30.021v-117.17c0-4.518,3.621-8.138,8.138-8.138H85.29c4.486,0,8.138,3.621,8.138,8.138V246.418z M46.298,246.386H77.12v-109H46.298V246.386z'
        />
        <path
          style={{
            fill: 'var(--app-secondary-color)',
          }}
          d='M118.612,262.663h47.131v0.064H85.29v-0.064c4.486,0,8.138-3.653,8.138-8.138v-8.106h17.045v8.106C110.473,259.01,114.126,262.663,118.612,262.663z'
        />
        <path
          style={{
            fill: 'var(--app-secondary-color)',
          }}
          d='M38.159,262.663H85.29v0.064H8.138c-4.486,0-8.138-3.653-8.138-8.17V8.138C0,3.621,3.653,0,8.138,0c4.518,0,8.17,3.621,8.17,8.138v238.28h13.713v8.106C30.021,259.01,33.642,262.663,38.159,262.663z'
        />
      </svg>
    );
  }
};

export const renderSettingsIcon = (
  active: boolean,
  activeIconStyle?: React.CSSProperties,
  nonActiveIconStyle?: React.CSSProperties
) => {
  if (active) {
    return (
      <svg viewBox='0 -12 536 536' style={activeIconStyle} fill='currentColor'>
        <path d='M199 376L112 402 64 318 131 256 64 194 112 110 199 136 220 48 316 48 337 136 424 110 472 194 405 256 472 318 424 402 337 376 316 464 220 464 199 376ZM269 320Q296 320 314 301 331 282 331 254 331 227 313 210 295 192 269 192 241 192 223 210 204 228 204 256 204 283 223 302 241 320 269 320Z' />
      </svg>
    );
  } else {
    return (
      <svg viewBox='0 0 15 15' style={nonActiveIconStyle} fill='none'>
        <path
          d='M5.944 0.5L5.858 0.936707L5.52901 2.53467C5.00301 2.73554 4.526 3.02037 4.095 3.35815L2.487 2.8205L2.05501 2.68658L1.83101 3.07233L0.723999 4.9231L0.5 5.3089L0.828003 5.5957L2.07201 6.65399C2.02701 6.93081 1.96901 7.20461 1.96901 7.49542C1.96901 7.78623 2.02701 8.0601 2.07201 8.33691L0.828003 9.3952L0.5 9.68201L0.723999 10.0677L1.83101 11.9186L2.05501 12.3053L2.487 12.1704L4.095 11.6328C4.526 11.9705 5.00301 12.2553 5.52901 12.4562L5.858 14.0541L5.944 14.4909H9.05501L9.142 14.0541L9.47 12.4562C9.996 12.2553 10.473 11.9705 10.904 11.6328L12.512 12.1704L12.944 12.3053L13.169 11.9186L14.275 10.0677L14.5 9.68201L14.171 9.3952L12.927 8.33691C12.973 8.0601 13.03 7.78623 13.03 7.49542C13.03 7.20461 12.973 6.93081 12.927 6.65399L14.171 5.5957L14.5 5.3089L14.275 4.9231L13.169 3.07233L12.944 2.68658L12.512 2.8205L10.904 3.35815C10.473 3.02037 9.996 2.73554 9.47 2.53467L9.142 0.936707L9.05501 0.5H5.944Z'
          stroke='var(--app-secondary-color)'
          strokeLinecap='square'
          strokeLinejoin='round'
        />
        <path
          d='M9.49963 7.49542C9.49963 8.5987 8.60363 9.49414 7.49963 9.49414C6.39563 9.49414 5.49963 8.5987 5.49963 7.49542C5.49963 6.39214 6.39563 5.49677 7.49963 5.49677C8.60363 5.49677 9.49963 6.39214 9.49963 7.49542Z'
          stroke='var(--app-secondary-color)'
          strokeLinecap='square'
          strokeLinejoin='round'
        />
      </svg>
    );
  }
};

export const renderBookmarksIcon = (
  active: boolean,
  activeIconStyle?: React.CSSProperties,
  nonActiveIconStyle?: React.CSSProperties
) => {
  if (active) {
    return (
      <svg viewBox='0 0 28 28' style={activeIconStyle} fill='currentColor'>
        <path d='M23.5 4a1.5 1.5 0 110 3h-19a1.5 1.5 0 110-3h19zm0 18a1.5 1.5 0 110 3h-19a1.5 1.5 0 110-3h19zm0-9a1.5 1.5 0 110 3h-19a1.5 1.5 0 110-3h19z' />
      </svg>
    );
  } else {
    return (
      <svg viewBox='0 0 28 28' style={nonActiveIconStyle} fill='currentColor'>
        <path d='M23.5 4a1.5 1.5 0 110 3h-19a1.5 1.5 0 110-3h19zm0 18a1.5 1.5 0 110 3h-19a1.5 1.5 0 110-3h19zm0-9a1.5 1.5 0 110 3h-19a1.5 1.5 0 110-3h19z' />
      </svg>
    );
  }
};
