import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { SortDownIcon, SortUpIcon } from '../../shared/icons/Svgs';
import debounce from 'lodash.debounce';
import React, { useEffect, useRef } from 'react';
import { Pagination, Row, Col, Button, ButtonGroup, Form } from 'react-bootstrap';
import Common from '../../shared/Common';
import type { IBill } from '../../../interfaces/IBill';
import type { Column, ColumnDef, ColumnFiltersState, PaginationState, SortingState } from '@tanstack/react-table';
import type { ISummaryElement } from '../../../interfaces/ISummaryElement';

import './style.css';

export interface IPaginationProps {
  pageIndex: number;
  pageSize: number;
  sortBy: SortingState;
  filters: ColumnFiltersState;
  showBillElements: boolean;
}

export interface ITableProps {
  data: IBill[];
  loading: boolean;
  totalCount: number;
  totalPageCount: number;
  totalCost: number;
  fetchData: ({ pageIndex, pageSize, sortBy, filters, showBillElements }: IPaginationProps) => Promise<void>;
  fetchTrigger: number;
  showEditBillModal: (billId: number, uploadedBillPictureBase64: string | null) => void;
  showBillElements: boolean;
  setShowBillElements: React.Dispatch<React.SetStateAction<boolean>>;
  showGroups: boolean;
  setShowGroups: React.Dispatch<React.SetStateAction<boolean>>;
  summaryElementsSelection: boolean;
  selectedSummaryElements?: ISummaryElement[];
  setSelectedSummaryElements?: React.Dispatch<React.SetStateAction<ISummaryElement[]>>;
}

export interface IDefaultColumnFilterProps {
  column: {
    filterValue: any;
    preFilteredRows: any;
    setFilter: any;
  };
}

function BillsTable(props: ITableProps) {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [{ pageIndex, pageSize }, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const datetimeWithoutZoneConverter = (bill: IBill) => {
    if (bill.payTime instanceof Date) {
      return bill.payTime.toJSON().substring(0, 10);
    }
    return bill.payTime?.substring(0, 10);
  };

  const columns: ColumnDef<IBill>[] = React.useMemo<ColumnDef<IBill>[]>(() => {
    const columns: ColumnDef<IBill>[] = [];
    columns.push({
      header: () => <div>Data</div>,
      accessorFn: (row) => datetimeWithoutZoneConverter(row),
      id: 'payTime',
    });
    columns.push({
      header: () => <span>Opis</span>,
      accessorFn: (row) => row.description,
      id: 'description',
      enableSorting: false,
    });
    columns.push({
      header: () => <span>Kategoria</span>,
      accessorFn: (row) => row.categoryFullName,
      id: 'categoryFullName',
    });
    if (props.showGroups) {
      columns.push({
        header: () => <span>Grupa</span>,
        accessorFn: (row) => row.groupFullName,
        id: 'groupFullName',
      });
    }
    columns.push({
      header: () => <span>Miejsce</span>,
      accessorFn: (row) => row.location?.locationName,
      id: 'locationName',
    });
    columns.push({
      header: () => <span>Koszt</span>,
      accessorFn: (row) => Common.Utils.getNumberWithSpaces((Math.round((row.totalCost ?? 0) * 100) / 100).toFixed(2)),
      id: 'totalCost',
      enableColumnFilter: false,
    });
    return columns;
  }, [props.showGroups]);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleUpload = () => {
    inputRef.current?.click();
  };

  const handleUploadBillImage = async (event: any) => {
    if (!!event.target.files[0]) {
      const billPictureBase64 = await Common.Images.compresUploadedImage(event.target.files[0]);
      props.showEditBillModal(0, billPictureBase64);
      event.target.value = null;
    }
  };

  const table = useReactTable({
    data: props.data,
    columns: columns,
    pageCount: props.totalPageCount,
    state: {
      columnFilters,
      sorting,
      pagination,
    },
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    manualSorting: true,
    manualFiltering: true,
  });

  const debouncedFilterSearchChanged = React.useMemo(
    () =>
      debounce((e: React.ChangeEvent<HTMLInputElement>, column: Column<IBill, unknown>) => {
        column.setFilterValue(e.target.value);
        table.setPageIndex(0);
      }, 500),
    [table]
  );

  const { fetchData, fetchTrigger, showBillElements } = props;

  useEffect(() => {
    fetchData({
      pageIndex,
      pageSize,
      sortBy: sorting,
      filters: columnFilters,
      showBillElements,
    });
  }, [fetchData, pageIndex, pageSize, sorting, columnFilters, fetchTrigger, showBillElements]);

  let items = [];
  for (
    let ind = 0,
      number = pageIndex < 2 ? 0 : pageIndex + 2 >= table.getPageCount() ? table.getPageCount() - 5 : pageIndex - 2;
    ind < 5;
    number++
  ) {
    if (number + 1 > table.getPageCount()) {
      break;
    }
    if (number + 1 <= 0) {
      continue;
    }
    items.push(
      <Pagination.Item key={number} active={number === pageIndex} onClick={() => table.setPageIndex(number)}>
        {number + 1}
      </Pagination.Item>
    );
    ind++;
  }
  return (
    <>
      <Row className='mb-1'>
        <Col>
          <Pagination>
            <Pagination.First onClick={() => table.setPageIndex(0)} disabled={!table.getCanPreviousPage()} />
            <Pagination.Prev onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()} />
            {items}
            <Pagination.Next onClick={() => table.nextPage()} disabled={!table.getCanNextPage()} />
            <Pagination.Last
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={!table.getCanNextPage()}
            />
          </Pagination>
        </Col>
        {!props.summaryElementsSelection && (
          <Col className='bills-right-alignment'>
            <input
              ref={inputRef}
              onChange={handleUploadBillImage}
              className='d-none'
              type='file'
              accept='image/*'
              capture='environment'
            />
            <ButtonGroup aria-label='Dodawanie rachunków'>
              <Button variant='dark' onClick={handleUpload}>
                Wgraj zdjęcie
              </Button>
              <Button
                variant='outline-dark'
                onClick={() => {
                  props.showEditBillModal(0, null);
                }}
              >
                Dodaj rachunek
              </Button>
            </ButtonGroup>
          </Col>
        )}
      </Row>
      <Row>
        <Col>
          <div className='table-responsive-sm'>
            <table id='bills-table' className='table table-hover' aria-labelledby='tabelLabel'>
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <th key={header.id}>
                        {header.isPlaceholder ? null : (
                          <>
                            {header.column.getCanFilter() ? (
                              <div>
                                <input
                                  onChange={(e) => {
                                    debouncedFilterSearchChanged(e, header.column);
                                  }}
                                  placeholder={'Szukaj...'}
                                  className='no-border-input'
                                />
                              </div>
                            ) : null}
                            <div
                              {...{
                                style: header.column.getCanSort()
                                  ? {
                                      cursor: 'pointer',
                                    }
                                  : {},
                                onClick: header.column.getToggleSortingHandler(),
                              }}
                            >
                              {flexRender(header.column.columnDef.header, header.getContext())}
                              {{
                                asc: <SortUpIcon style={{ marginLeft: '5px' }} />,
                                desc: <SortDownIcon style={{ marginLeft: '5px' }} />,
                              }[header.column.getIsSorted() as string] ?? null}
                            </div>
                          </>
                        )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {props.loading
                  ? Common.Ui.getSkeletonTableRows(10, table.getAllColumns().filter((c) => c.getIsVisible()).length, 35)
                  : table.getRowModel().rows.map((row) => (
                      <tr
                        key={row.id}
                        onClick={() => {
                          if (props.summaryElementsSelection && props.setSelectedSummaryElements) {
                            props.setSelectedSummaryElements((prev) => {
                              const index = prev.findIndex((e) => e.billItemId === row.original.billItemId);
                              if (index > -1) {
                                return prev.filter((e) => e.billItemId !== row.original.billItemId);
                              } else {
                                return prev.concat([
                                  {
                                    billItemId: row.original.billItemId,
                                    description: row.original.description,
                                    locationName: row.original.location?.locationName,
                                    categoryName: row.original.categoryFullName,
                                    payTime: row.original.payTime as string | undefined,
                                    cost: row.original.totalCost as number | undefined,
                                    billItemPictureId:
                                      row.original.billBase64Pictures?.length > 0 ? row.original.billItemId : 0,
                                  },
                                ]);
                              }
                            });
                          } else {
                            props.showEditBillModal(row.original.billId, null);
                          }
                        }}
                        style={
                          (props.selectedSummaryElements?.findIndex((e) => e.billItemId === row.original.billItemId) ??
                            -1) > -1
                            ? { backgroundColor: 'rgba(0,256,0,0.1)', cursor: 'pointer' }
                            : { cursor: 'pointer' }
                        }
                      >
                        {row.getVisibleCells().map((cell) => (
                          <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                        ))}
                      </tr>
                    ))}
                <tr>
                  {props.loading ? (
                    <td colSpan={10000}>Wczytywanie...</td>
                  ) : (
                    <td colSpan={10000}>
                      Razem {props.totalCount} {props.showBillElements ? 'elementów ' : ''} rachunków
                      <span style={{ float: 'right' }}>
                        Wydano w sumie{' '}
                        {Common.Utils.getNumberWithSpaces((Math.round(props.totalCost * 100) / 100).toFixed(2))} złotych
                      </span>
                    </td>
                  )}
                </tr>
              </tbody>
            </table>
          </div>
        </Col>
      </Row>
      {!props.summaryElementsSelection && (
        <>
          <Row>
            <Col>
              <Form.Check
                type='switch'
                id='show-bill-items-switch'
                label='Pokaż elementy rachunków'
                style={{ userSelect: 'none' }}
                defaultChecked={props.showBillElements}
                onChange={() => {
                  table.setPageIndex(0);
                  props.setShowBillElements(!props.showBillElements);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Check
                type='switch'
                id='show-groups-switch'
                label='Pokaż grupy'
                style={{ userSelect: 'none' }}
                checked={props.showGroups}
                onChange={() => {
                  props.setShowGroups(!props.showGroups);
                }}
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
}

export default BillsTable;
