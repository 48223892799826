import { useContext } from 'react';
import { Container, Row } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { UserContext } from '../../App';
import './style.css';

const DashboardNavigation = () => {
  const { user } = useContext(UserContext);
  const location = useLocation();

  const generateNavigationListElement = (src: string, text: string, destination: string) => {
    const active =
      (destination === '/' && location.pathname === '/') ||
      (destination !== '/' && location.pathname.includes(destination));
    return (
      <li>
        <div style={{ paddingLeft: '8px', paddingRight: '8px' }}>
          <Link
            to={destination}
            style={{
              textDecoration: 'none',
              WebkitUserSelect: 'none',
            }}
          >
            <div
              style={
                active
                  ? {
                      backgroundColor: 'rgba(101, 103, 107, .2)',
                      borderRadius: '5px',
                    }
                  : {}
              }
              className='navigation-action-link'
            >
              <div
                style={{
                  display: 'flex',
                  marginRight: '12px',
                  marginTop: '8px',
                  marginBottom: '8px',
                }}
              >
                <img src={src} style={{ height: '36px', width: '36px' }} alt='' />
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    marginBottom: '-5px',
                    marginTop: '-5px',
                  }}
                >
                  <span
                    style={{
                      width: '100%',
                      fontSize: '.9375rem',
                      fontWeight: 500,
                      color: '#050505',
                    }}
                  >
                    {text}
                  </span>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </li>
    );
  };

  return (
    <>
      <Container className='dashboard-navigation sticky-top d-flex flex-column justify-content-between'>
        <Row className='dashboard-navigation-upper'>
          <ul style={{ listStyleType: 'none', margin: 0, padding: 0 }}>
            {generateNavigationListElement('/Static/icons/dashboard-navigation/dashboard.png', 'Przegląd', '/')}
            {generateNavigationListElement(
              '/Static/icons/dashboard-navigation/prices.png',
              'Ceny produktów',
              '/prices'
            )}
            {generateNavigationListElement('/Static/icons/dashboard-navigation/bills.png', 'Rachunki', '/bills')}
            {generateNavigationListElement('/Static/icons/dashboard-navigation/stats.png', 'Statystyki', '/statistics')}
            {generateNavigationListElement(
              '/Static/icons/dashboard-navigation/summaries.png',
              'Podsumowania',
              '/summaries'
            )}
            {generateNavigationListElement(
              '/Static/icons/dashboard-navigation/location.png',
              'Lokalizacje',
              '/dictionaries/locations'
            )}
            {generateNavigationListElement(
              '/Static/icons/dashboard-navigation/recurring-operations.png',
              'Operacje cykliczne',
              '/recurring-operations'
            )}
            {generateNavigationListElement(
              '/Static/icons/dashboard-navigation/groups.png',
              'Grupy',
              '/dictionaries/groups'
            )}
            {generateNavigationListElement(
              '/Static/icons/dashboard-navigation/categories.png',
              'Kategorie',
              '/dictionaries/categories'
            )}
            {user?.isDictionariesManager() &&
              generateNavigationListElement(
                '/Static/icons/dashboard-navigation/quantity-type.png',
                'Jednostki',
                '/dictionaries/quantity-types'
              )}
          </ul>
        </Row>
        <Row className='dashboard-navigation-footer'>
          <ul>
            <li>
              <a href='/privacy'>Prywatność</a>
              <span>
                <span>&nbsp;</span>
                <span aria-hidden='true'> · </span>
                <span>&nbsp;</span>
              </span>
            </li>
            <li>
              <a href='/privacy#cookies'>Pliki cookie</a>
              <span>
                <span>&nbsp;</span>
                <span aria-hidden='true'> · </span>
              </span>
            </li>
          </ul>
          <a className='p-0' target='_blank' rel='noopener noreferrer' href='https://maciejos.pl'>
            Maciej Jakubiak © {new Date().getFullYear()}
          </a>
        </Row>
      </Container>
    </>
  );
};

export default DashboardNavigation;
