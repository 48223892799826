import NavMenu from './NavMenu';

export interface ILayoutProps {
  children: React.ReactNode;
}

const Layout = (props: ILayoutProps) => (
  <div>
    <NavMenu />
    {props.children}
  </div>
);

export default Layout;
