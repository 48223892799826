import { useNavigate, useParams } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, ButtonGroup, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import ApplicationAlert from '../../shared/application-alert/ApplicationAlert';
import type { IApplicationAlert } from '../../shared/application-alert/ApplicationAlert';
import Common from '../../shared/Common';
import type { ISummaryConfig } from '../../../interfaces/ISummaryConfig';
import { useReactTable, getCoreRowModel, getSortedRowModel, flexRender } from '@tanstack/react-table';
import type { SortingState, ColumnDef } from '@tanstack/react-table';
import { SortUpIcon, SortDownIcon, MinusIcon } from '../../shared/icons/Svgs';
import Bills from '../bills/Bills';
import type { ISummaryElement } from '../../../interfaces/ISummaryElement';
import WarningModal from '../../shared/shared-modals/WarningModal';

import './style.css';

const SummaryConfig = () => {
  const [fetchDataTrigger, setFetchDataTrigger] = useState<number>(0);
  const [summaryConfig, setSummaryConfig] = useState<ISummaryConfig>({
    summaryId: 0,
    shareIdentifier: '',
    guid: '',
    name: '',
    public: true,
    showLocations: true,
    showPrices: true,
    showCategories: true,
    showDates: true,
    showPictures: false,
    showStatistics: true,
    elements: [],
  });
  const [summaryElements, setSummaryElements] = useState<ISummaryElement[]>([]);

  const [summaryConfigChanged, setSummaryConfigChanged] = useState(false);
  const [showLoadingSpinner, setShowLoadingSpinner] = useState<boolean>(false);
  const [summaryExists, setSummaryExists] = useState<boolean>(true);
  const [status, setStatus] = useState<IApplicationAlert>();
  const [loading, setLoading] = useState<boolean>(false);
  const [summaryNotFound, setSummaryNotFound] = useState<boolean>(false);
  const [showBillElementsModal, setShowBillElementsModal] = useState(false);
  const [showRegenerateShareLinkWarningModal, setShowRegenerateShareLinkWarningModal] = useState(false);
  const [showRemoveSummaryWarningModal, setShowRemoveSummaryWarningModal] = useState(false);
  const [sorting, setSorting] = useState<SortingState>([{ id: 'payTime', desc: true }]);
  const navigate = useNavigate();

  const columns = useMemo<ColumnDef<ISummaryElement>[]>(
    () => [
      {
        accessorFn: (row) => (row.payTime ? new Date(row.payTime) : new Date()),
        id: 'payTime',
        header: () => <span>Data</span>,
        cell: (row) => <>{Common.Utils.getDatetimeWithoutZoneConverter(row.row.original.payTime ?? '')}</>,
      },
      {
        accessorFn: (row) => row.description,
        id: 'name',
        header: () => <span>Nazwa</span>,
      },
      {
        accessorFn: (row) => row.cost,
        id: 'cost',
        header: () => <span>Koszt</span>,
        cell: (row) => (
          <div style={{ textAlign: 'right' }}>{Common.Utils.getCurrencyString(row.row.original.cost ?? 0)}</div>
        ),
      },
      {
        accessorFn: (row) => row.locationName ?? '',
        id: 'locationName',
        header: () => <span>Lokalizacja</span>,
      },
      {
        accessorFn: (row) => row.categoryName ?? '',
        id: 'categoryName',
        header: () => <span>Kategoria</span>,
      },
      {
        accessorFn: (row) => row.billItemPictureId,
        id: 'billItemPictureId',
        header: () => <span>Zdjęcie</span>,
        cell: (row) => <>{(row.row.original.billItemPictureId ?? 0) > 0 ? 'TAK' : 'NIE'}</>,
      },
      {
        id: 'update',
        header: 'Akcja',
        cell: (info) => (
          <div
            style={{ textAlign: 'center' }}
            title='Usuń element podsumowania'
            onClick={() => {
              setSummaryConfigChanged(true);
              setSummaryConfig((prev) => ({
                ...prev,
                elements: prev.elements.filter((e) => e.billItemId !== info.row.original.billItemId),
              }));
            }}
          >
            <MinusIcon style={{ cursor: 'pointer', width: '28px', height: '28px', fill: '#dc3545' }} />
          </div>
        ),
        enableSorting: false,
      },
    ],
    []
  );

  const table = useReactTable({
    data: summaryConfig?.elements ?? [],
    columns: columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });
  const fetchIdRef = useRef(0);
  const params = useParams();
  const fetchUserData = useCallback(async (id: string) => {
    setShowLoadingSpinner(true);
    setLoading(true);
    const fetchId = ++fetchIdRef.current;

    const response = await Common.authorizedFetch('api/summaries/getSummaryConfig?guid=' + id);
    if (fetchId === fetchIdRef.current) {
      const data = await response.json();
      if (data.success) {
        if (!data.result.summary) {
          setSummaryNotFound(true);
        } else {
          setSummaryConfig(data.result.summary);
          setSummaryElements(data.result.summary.elements);
        }
      }
      setShowLoadingSpinner(false);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (params.id === 'create') {
      setSummaryExists(false);
      setSummaryConfig({
        summaryId: 0,
        shareIdentifier: '',
        guid: '',
        name: '',
        public: true,
        showLocations: true,
        showPrices: true,
        showCategories: true,
        showDates: true,
        showPictures: false,
        showStatistics: true,
        elements: [],
      });
      setSummaryElements([]);
    } else {
      setSummaryExists(true);
      fetchUserData(params.id ?? '');
    }
  }, [fetchUserData, params.id, fetchDataTrigger]);

  const getShareReportPath = useCallback(
    () => 'https://' + window.location.host + '/summary/' + summaryConfig?.shareIdentifier,
    [summaryConfig?.shareIdentifier]
  );

  const generateSummaryDataView = () => (
    <>
      <Row className='mt-2'>
        <Form.Label>Nazwa</Form.Label>
      </Row>
      <Row>
        <Col className='mb-2' xs={12} sm={8}>
          <Form.Control
            value={summaryConfig?.name}
            onChange={(e) => {
              setSummaryConfigChanged(true);
              setSummaryConfig((prev) => {
                if (prev === undefined) {
                  return prev;
                }
                return {
                  ...prev,
                  name: e.target.value,
                };
              });
            }}
          />
        </Col>
      </Row>
    </>
  );

  const generateSummaryShareView = () => (
    <>
      <Row>
        <Col>
          <Form.Check
            type='switch'
            id='public-switch'
            style={{ userSelect: 'none' }}
            label='Udostępnij publicznie'
            checked={summaryConfig?.public}
            onChange={() => {
              setSummaryConfigChanged(true);
              setSummaryConfig((prev) => {
                if (prev === undefined) {
                  return prev;
                }
                return {
                  ...prev,
                  public: !prev.public,
                };
              });
            }}
          />
        </Col>
      </Row>
      {summaryExists && (
        <>
          <Row className='mt-2'>
            <Form.Label>Link do udostępnienia</Form.Label>
          </Row>
          <Row>
            <Col className='mb-2' xs={12} sm={8}>
              <Form.Control disabled={true} readOnly value={getShareReportPath()} />
            </Col>
            <Col className='mb-2' xs={12} sm={4}>
              <ButtonGroup className='mb-2'>
                <Button
                  variant='outline-dark'
                  disabled={!summaryConfig?.public}
                  onClick={() => {
                    navigator.clipboard.writeText(getShareReportPath());
                    setStatus({
                      variant: 'info',
                      status: 'Adres skopiowano do schowka.',
                      messageTime: Date.now(),
                    });
                  }}
                >
                  Kopiuj
                </Button>
                <Button
                  variant='dark'
                  disabled={!summaryConfig?.public}
                  onClick={() => {
                    setShowRegenerateShareLinkWarningModal(true);
                  }}
                >
                  Wygeneruj
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        </>
      )}
      <Row>
        <Col xs={6} sm={4}>
          <Form.Check
            type='switch'
            id='showLocations-switch'
            style={{ userSelect: 'none' }}
            label='Prezentuj lokalizacje'
            checked={summaryConfig?.showLocations}
            disabled={!summaryConfig?.public}
            onChange={() => {
              setSummaryConfigChanged(true);
              setSummaryConfig((prev) => {
                if (prev === undefined) {
                  return prev;
                }
                return {
                  ...prev,
                  showLocations: !prev.showLocations,
                };
              });
            }}
          />
        </Col>
        <Col xs={6} sm={4}>
          <Form.Check
            type='switch'
            id='showCategories-switch'
            style={{ userSelect: 'none' }}
            label='Prezentuj kategorie'
            checked={summaryConfig?.showCategories}
            disabled={!summaryConfig?.public}
            onChange={() => {
              setSummaryConfigChanged(true);
              setSummaryConfig((prev) => {
                if (prev === undefined) {
                  return prev;
                }
                return {
                  ...prev,
                  showCategories: !prev.showCategories,
                };
              });
            }}
          />
        </Col>
        <Col xs={6} sm={4}>
          <Form.Check
            type='switch'
            id='showDates-switch'
            style={{ userSelect: 'none' }}
            label='Prezentuj daty'
            checked={summaryConfig?.showDates}
            disabled={!summaryConfig?.public}
            onChange={() => {
              setSummaryConfigChanged(true);
              setSummaryConfig((prev) => {
                if (prev === undefined) {
                  return prev;
                }
                return {
                  ...prev,
                  showDates: !prev.showDates,
                };
              });
            }}
          />
        </Col>
        <Col xs={6} sm={4}>
          <Form.Check
            type='switch'
            id='showPrices-switch'
            style={{ userSelect: 'none' }}
            label='Prezentuj ceny'
            checked={summaryConfig?.showPrices}
            disabled={!summaryConfig?.public}
            onChange={() => {
              setSummaryConfigChanged(true);
              setSummaryConfig((prev) => {
                if (prev === undefined) {
                  return prev;
                }
                return {
                  ...prev,
                  showPrices: !prev.showPrices,
                };
              });
            }}
          />
        </Col>
        <Col xs={6} sm={4}>
          <Form.Check
            type='switch'
            id='showPictures-switch'
            style={{ userSelect: 'none' }}
            label='Prezentuj zdjęcia'
            checked={summaryConfig?.showPictures}
            disabled={!summaryConfig?.public}
            onChange={() => {
              setSummaryConfigChanged(true);
              setSummaryConfig((prev) => {
                if (prev === undefined) {
                  return prev;
                }
                return {
                  ...prev,
                  showPictures: !prev.showPictures,
                };
              });
            }}
          />
        </Col>
        <Col xs={6} sm={4}>
          <Form.Check
            type='switch'
            id='showStatistics-switch'
            style={{ userSelect: 'none' }}
            label='Prezentuj statystyki'
            checked={summaryConfig?.showStatistics}
            disabled={!summaryConfig?.public}
            onChange={() => {
              setSummaryConfigChanged(true);
              setSummaryConfig((prev) => {
                if (prev === undefined) {
                  return prev;
                }
                return {
                  ...prev,
                  showStatistics: !prev.showStatistics,
                };
              });
            }}
          />
        </Col>
      </Row>
    </>
  );

  const generateSummaryElementsView = () => {
    const handleCloseBillElementsModal = () => {
      setSummaryElements([...(summaryConfig?.elements ?? [])]);
      setShowBillElementsModal(false);
    };
    const handleShowBillElementsModal = () => {
      setSummaryElements([...(summaryConfig?.elements ?? [])]);
      setShowBillElementsModal(true);
    };
    const handleSaveBillElementsModal = () => {
      if (
        summaryConfig?.elements
          ?.map((e) => e.billItemId)
          .sort()
          .join(',') !==
        summaryElements
          .map((e) => e.billItemId)
          .sort()
          .join(',')
      ) {
        setSummaryConfigChanged(true);
      }
      setSummaryConfig((prev) => ({
        ...prev,
        elements: [...summaryElements],
      }));
      setShowBillElementsModal(false);
    };
    return (
      <div>
        <Button variant='dark' onClick={handleShowBillElementsModal}>
          Wybierz elementy
        </Button>
        <Row>
          <div className='table-responsive-sm'>
            <table id='summary-elements-table' className='table table-bordered table-striped mt-2'>
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <th key={header.id}>
                        {header.isPlaceholder ? null : (
                          <div
                            {...{
                              style: header.column.getCanSort()
                                ? {
                                    cursor: 'pointer',
                                  }
                                : {},
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            {flexRender(header.column.columnDef.header, header.getContext())}
                            {{
                              asc: <SortUpIcon style={{ marginLeft: '5px' }} />,
                              desc: <SortDownIcon style={{ marginLeft: '5px' }} />,
                            }[header.column.getIsSorted() as string] ?? null}
                          </div>
                        )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {loading
                  ? Common.Ui.getSkeletonTableRows(6, 5, 35)
                  : table.getRowModel().rows.map((row) => (
                      <tr key={row.id}>
                        {row.getVisibleCells().map((cell) => (
                          <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                        ))}
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
        </Row>
        <Modal size='lg' show={showBillElementsModal} onHide={handleCloseBillElementsModal}>
          <Modal.Header closeButton>
            <Modal.Title>Wybierz elementy podsumowania</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Bills
              summaryElementsSelection={true}
              selectedSummaryElements={summaryElements}
              setSelectedSummaryElements={setSummaryElements}
            />
          </Modal.Body>
          <Modal.Footer>
            <div className='me-sm-auto'>Liczba wybranych elementów: {summaryElements.length}</div>
            <Button variant='dark' onClick={handleCloseBillElementsModal}>
              Zamknij
            </Button>
            <Button variant='primary' onClick={handleSaveBillElementsModal}>
              Wybierz
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };

  const regenerateShareIdentifier = async () => {
    setLoading(true);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ guid: summaryConfig?.guid }),
    };
    const response = await Common.authorizedFetch('api/summaries/regenerateShareIdentifier', requestOptions);
    const data = await response.json();
    setLoading(false);
    setSummaryConfigChanged(false);
    if (!data.success) {
      alert(data.errors);
    } else {
      setFetchDataTrigger((prev) => prev + 1);
    }
  };

  const removeSummary = async () => {
    setLoading(true);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ guid: summaryConfig?.guid }),
    };
    const response = await Common.authorizedFetch('api/summaries/removeSummaryConfig', requestOptions);
    const data = await response.json();
    setLoading(false);
    if (!data.success) {
      alert(data.errors);
    } else {
      navigate('/summaries');
    }
  };

  const updateSummary = async () => {
    setLoading(true);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ summary: summaryConfig }),
    };
    const response = await Common.authorizedFetch('api/summaries/updateSummaryConfig', requestOptions);
    const data = await response.json();
    setLoading(false);
    setSummaryConfigChanged(false);
    if (!data.success) {
      alert(data.errors);
    } else {
      setFetchDataTrigger((prev) => prev + 1);
    }
  };

  const addSummary = async () => {
    setLoading(true);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ summary: summaryConfig }),
    };
    const response = await Common.authorizedFetch('api/summaries/addSummaryConfig', requestOptions);
    const data = await response.json();
    setLoading(false);
    setSummaryConfigChanged(false);
    if (!data.success) {
      alert(data.errors);
    } else {
      const guid = data.result.guid;
      navigate('/summaries/' + guid);
    }
  };

  return (
    <Container className='mt-2'>
      {summaryNotFound ? (
        <h1 className='text-danger'>Podsumowanie niedostępne</h1>
      ) : (
        <>
          <WarningModal
            show={showRegenerateShareLinkWarningModal || showRemoveSummaryWarningModal}
            modalBodyText={
              showRemoveSummaryWarningModal
                ? 'Czy na pewno chcesz usunąć te podsumowanie?'
                : 'Akcja powoduje wygenerowanie nowego unikalnego adresu do udostępnienia. Wcześniej udostępnione linki do tego podglądu przestaną działać. Czy na pewno chcesz to zrobić?'
            }
            onHide={() => {
              setShowRegenerateShareLinkWarningModal(false);
              setShowRemoveSummaryWarningModal(false);
            }}
            onConfirmation={() => {
              if (showRegenerateShareLinkWarningModal) {
                regenerateShareIdentifier();
              }
              if (showRemoveSummaryWarningModal) {
                removeSummary();
              }
              setShowRegenerateShareLinkWarningModal(false);
              setShowRemoveSummaryWarningModal(false);
            }}
          />
          {Common.Ui.showLoadingSpinnerFixed(showLoadingSpinner)}
          {status && (
            <ApplicationAlert variant={status?.variant} status={status?.status} messageTime={status?.messageTime} />
          )}
          <h4 className='mb-3' id='tabelLabel'>
            {summaryExists ? 'Modyfikuj podsumowanie' : 'Stwórz podsumowanie'}
          </h4>
          <h5 className='mt-3'>Wyświetlanie</h5>
          <Container>
            <Row className='mt-2'>{loading ? <Skeleton height={60} /> : generateSummaryDataView()}</Row>
          </Container>
          <h5 className='mt-3'>Udostępnianie</h5>
          <Container>
            <Row className='mt-2'>{loading ? <Skeleton height={120} /> : generateSummaryShareView()}</Row>
          </Container>
          <h5 className='mt-3'>Elementy</h5>
          <Container>
            <Row className='mt-2 mb-3'>{loading ? <Skeleton height={120} /> : generateSummaryElementsView()}</Row>
          </Container>
          {summaryExists && (
            <Button
              className='mb-1 me-2'
              variant='danger'
              onClick={() => {
                setShowRemoveSummaryWarningModal(true);
              }}
            >
              Usuń
            </Button>
          )}
          {summaryExists && (
            <Button
              className='mb-1 me-2'
              variant='dark'
              onClick={() => {
                setSummaryConfigChanged(true);
                setSummaryExists(false);
              }}
            >
              Kopiuj
            </Button>
          )}
          <Button
            className='mb-1'
            variant='primary'
            onClick={() => {
              if (summaryExists) {
                updateSummary();
              } else {
                addSummary();
              }
            }}
            disabled={loading || !summaryConfigChanged}
          >
            {summaryExists ? 'Zapisz' : 'Stwórz'}
          </Button>
        </>
      )}
    </Container>
  );
};

export default SummaryConfig;
