import { Formik } from 'formik';
import { useCallback, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { Navigate } from 'react-router-dom';
import * as yup from 'yup';
import Common from '../../../shared/Common';
import type { IUserSettingsChildProps } from '../UserSettings';

import 'react-loading-skeleton/dist/skeleton.css';

const RemoveAccount = (props: IUserSettingsChildProps) => {
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [redirect, setRedirect] = useState<boolean>(false);

  const { loading, userData, setStatus } = props;

  const RemoveAccountForm = useCallback(() => {
    const validationSchema = yup.object().shape({
      userPassword: yup.string().test('password-required', 'Nie wprowadzono hasła', function (value) {
        return !userData?.hasPassword || (!!value && value.length > 0);
      }),
    });

    return (
      <Row>
        <Col md={6}>
          <Formik
            initialValues={{
              userPassword: '',
            }}
            validationSchema={validationSchema}
            onSubmit={async (values, { setFieldError, setSubmitting }) => {
              setSubmitting(true);
              setFormLoading(true);
              const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                  password: values.userPassword,
                }),
              };
              const response = await Common.authorizedFetch('idsrv/account/removeUserAccount', requestOptions);
              setFormLoading(false);
              setSubmitting(false);
              const data = await response.json();
              if (!data.success) {
                setStatus({
                  variant: 'error',
                  status: data.errors,
                  messageTime: Date.now(),
                });
              } else {
                setRedirect(true);
              }
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
              <>
                <Form onSubmit={handleSubmit} autoComplete='off'>
                  {!(userData?.hasPassword === false) && (
                    <Form.Group>
                      <Form.Label>Hasło</Form.Label>
                      {loading ? (
                        <Skeleton height={35} />
                      ) : (
                        <Form.Control
                          type='password'
                          name='userPassword'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.userPassword}
                        />
                      )}
                      {touched.userPassword && errors.userPassword ? (
                        <div className='error-message'>{errors.userPassword}</div>
                      ) : null}
                    </Form.Group>
                  )}
                  <Button className='mt-2' variant='danger' type='submit' disabled={isSubmitting || loading}>
                    Usuń konto
                  </Button>
                </Form>
              </>
            )}
          </Formik>
        </Col>
      </Row>
    );
  }, [loading, userData, setStatus]);
  return (
    <>
      {redirect && <Navigate to={'/authentication/logout'} state={{ local: true }} replace />}
      <h4>Usuń konto</h4>
      {Common.Ui.showLoadingSpinnerFixed(formLoading)}
      <div className='alert alert-danger' role='alert'>
        <p>
          <strong>Usuwając konto usuniesz je na zawsze, bez możliwości jego przywrócenia.</strong>
        </p>
      </div>
      <RemoveAccountForm />
    </>
  );
};

export default RemoveAccount;
