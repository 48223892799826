import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import React, { useState, useEffect, useContext } from 'react';
import { Container, Form, Button, Navbar, Nav } from 'react-bootstrap';
import Common from '../../shared/Common';
import GroupAutosuggest from '../../shared/group-autosuggest/GroupAutosuggest';
import WarningModal from '../../shared/shared-modals/WarningModal';
import type { IGroup } from '../../../interfaces/IGroup';
import type { IGroupName } from '../../../interfaces/IGroupName';
import type { ColumnDef, SortingState } from '@tanstack/react-table';
import { SortDownIcon, SortUpIcon } from '../../shared/icons/Svgs';
import type { IGroupData } from '../../../interfaces/IGroupData';

import './style.css';
import { DictionaryContext } from '../../../App';

export interface ITableProps {
  columns: ColumnDef<IGroupData>[];
  data: IGroupData[];
  loading: boolean;
  fetchData: () => Promise<void>;
  fetchTrigger: number;
  setShowSpinner: React.Dispatch<React.SetStateAction<boolean>>;
  setFetchTrigger: React.Dispatch<React.SetStateAction<number>>;
  updateGroupName: (rowIndex: number, columnId: string, value: any) => void;
  updateParentGroupId: (rowIndex: number, columnId: string, value: any) => void;
  updatedGroups: IGroup[];
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  saveButtonDisabled: boolean;
  removeButtonDisabled: boolean;
  setSaveButtonDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  setRemoveButtonDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  selectedGroup: IGroupData | null;
  selectedGroupChilds: IGroupData[];
  selectedGroupAncestors: IGroupData[];
  setSelectedGroup: React.Dispatch<React.SetStateAction<IGroupData | null>>;
  setSelectedGroupChilds: React.Dispatch<React.SetStateAction<IGroupData[]>>;
  setSelectedGroupAncestors: React.Dispatch<React.SetStateAction<IGroupData[]>>;
  groupsNames: IGroupName[];
}

export interface IEditableCellProps {
  value: any;
  row: any;
  column: any;
  updateGroupName: (rowIndex: number, columnId: number, value: any) => void;
  updateParentGroupId: (rowIndex: number, columnId: number, value: any) => void;
  groupsNames: IGroupName[];
}

function GroupsTable(props: ITableProps) {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const { reloadGroupsNames } = useContext(DictionaryContext);

  const defaultColumn: Partial<ColumnDef<IGroupData>> = {
    cell: function Cell({ getValue, row: { index }, column: { id }, table }) {
      const initialValue = getValue();
      const [value, setValue] = React.useState(initialValue);

      const onGroupNameChange = (e: any) => {
        if (e.target.value === '') props.setSaveButtonDisabled(true);
        else props.setSaveButtonDisabled(false);
        setValue(e.target.value);
      };

      const onParentGroupChange = (e: string) => {
        if (e === '') {
          if (value === undefined) props.setSaveButtonDisabled(true);
          else props.setSaveButtonDisabled(false);
          setValue(undefined);
        } else {
          let obj = JSON.parse(e);
          if (obj.groupId === value) props.setSaveButtonDisabled(true);
          else props.setSaveButtonDisabled(false);
          setValue(obj.groupId);
        }
      };

      const onGroupNameBlur = () => {
        props.updateGroupName(index, id, value);
      };

      const onParentGroupBlur = () => {
        props.updateParentGroupId(index, id, value);
      };

      React.useEffect(() => {
        setValue(initialValue);
      }, [initialValue]);

      switch (id) {
        case 'groupName':
          return (
            <Form.Control type='text' onChange={onGroupNameChange} onBlur={onGroupNameBlur} value={value as string} />
          );
        case 'parentGroupFullName':
          const groupName: IGroupName = {
            groupId: 0,
            groupFullName: value as string,
          };
          return (
            <GroupAutosuggest
              selectedValue={groupName}
              onChange={onParentGroupChange}
              onBlur={onParentGroupBlur}
              groups={table.options.meta?.groupsNames ?? []}
              triggerOnChangeWhenEmpty={true}
            />
          );
        case 'totalCost':
          return <>{Common.Utils.getNumberWithSpaces(value as string)}</>;

        default:
          return <>{value}</>;
      }
    },
  };

  const table = useReactTable({
    data: props.data,
    columns: props.columns,
    defaultColumn,
    state: {
      sorting,
    },
    meta: {
      groupsNames: props.groupsNames,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const { fetchData, fetchTrigger } = props;

  useEffect(() => {
    fetchData();
  }, [fetchData, fetchTrigger]);

  const saveGroups = async () => {
    props.setShowSpinner(true);
    props.setSaveButtonDisabled(true);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ groups: props.updatedGroups }),
    };
    const response = await Common.authorizedFetch('api/groups/saveGroups', requestOptions);
    const data = await response.json();
    if (!data.success) {
      alert(data.errors);
    } else {
      if (reloadGroupsNames) {
        reloadGroupsNames();
      }
      props.setFetchTrigger(props.fetchTrigger + 1);
    }
    props.setShowSpinner(false);
  };

  const onTableRowClick = (group: IGroupData) => {
    props.setSelectedGroup(group);
    if (group.count === 0 && group.group.groupId !== 1) {
      props.setRemoveButtonDisabled(false);
    } else {
      props.setRemoveButtonDisabled(true);
    }
    const ancestorGroups: IGroupData[] = [];
    const childGroups: IGroupData[] = [];
    let parentGroupId: number | undefined = group.group.parentGroupId;
    while (parentGroupId !== undefined) {
      let groupIdToSearch: number = parentGroupId;
      let parentGroup = props.data.find((c) => c.group.groupId === groupIdToSearch);
      if (parentGroup === undefined) break;
      ancestorGroups.push(parentGroup);
      parentGroupId = parentGroup.group.parentGroupId;
    }
    fillChildGroupTable(childGroups, group.group.groupId);
    props.setSelectedGroupAncestors(ancestorGroups);
    props.setSelectedGroupChilds(childGroups);
  };

  const fillChildGroupTable = (groups: IGroupData[], groupId: number) => {
    props.data
      .filter((c) => c.group.parentGroupId === groupId)
      .forEach((c) => {
        groups.push(c);
        fillChildGroupTable(groups, c.group.groupId);
      });
  };

  const removeGroup = async () => {
    props.setShowSpinner(true);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        groupId: props.selectedGroup?.group.groupId,
      }),
    };
    const response = await Common.authorizedFetch('api/groups/removeGroup', requestOptions);
    const data = await response.json();
    if (!data.success) {
      alert(data.errors);
    } else {
      if (reloadGroupsNames) {
        reloadGroupsNames();
      }
      props.setFetchTrigger(props.fetchTrigger + 1);
      setShowWarningModal(false);
    }
    props.setShowSpinner(false);
  };

  const getClassNameForRow = (rowGroupId: number) => {
    if (rowGroupId === props.selectedGroup?.group.groupId) return 'selected-row';
    if (props.selectedGroupChilds.findIndex((c) => c.group.groupId === rowGroupId) !== -1) {
      return 'child-selected-row';
    }
    if (props.selectedGroupAncestors.findIndex((c) => c.group.groupId === rowGroupId) !== -1) {
      return 'ancestor-selected-row';
    }
    return undefined;
  };

  return (
    <>
      <Navbar style={{ backgroundColor: 'transparent' }} variant='light' fixed='bottom'>
        <Container>
          <Nav className='me-auto' />
          <Nav>
            <Button
              disabled={props.removeButtonDisabled}
              className='me-2'
              variant='danger'
              onClick={() => {
                setShowWarningModal(true);
              }}
            >
              Usuń
            </Button>
            <Button
              className='me-2'
              variant='success'
              onClick={() => {
                props.setShowModal(true);
              }}
            >
              Dodaj
            </Button>
            <Button
              disabled={props.saveButtonDisabled}
              className='me-2'
              variant='dark'
              onClick={() => {
                saveGroups();
              }}
            >
              Zapisz
            </Button>
          </Nav>
        </Container>
      </Navbar>
      <div className='mt-1 pb-5 table-responsive-sm'>
        <table id='groups-table' className='table'>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          style: header.column.getCanSort()
                            ? {
                                cursor: 'pointer',
                              }
                            : {},
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: <SortUpIcon style={{ marginLeft: '5px' }} />,
                          desc: <SortDownIcon style={{ marginLeft: '5px' }} />,
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {props.loading
              ? Common.Ui.getSkeletonTableRows(25, 6, 35)
              : table.getRowModel().rows.map((row) => (
                  <tr
                    key={row.id}
                    className={getClassNameForRow(row.original.group.groupId)}
                    onClick={() => {
                      onTableRowClick(row.original);
                    }}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                    ))}
                  </tr>
                ))}
          </tbody>
        </table>
      </div>
      <WarningModal
        show={showWarningModal}
        modalBodyText={`Czy na pewno chcesz usunąć grupę '${props.selectedGroup?.group.groupFullName}'?`}
        onHide={() => {
          setShowWarningModal(false);
        }}
        onConfirmation={() => removeGroup()}
      />
    </>
  );
}

export default GroupsTable;
