import React, { useState, useCallback, useContext } from 'react';
import { Container } from 'react-bootstrap';
import Common from '../../shared/Common';
import type { ICategory } from '../../../interfaces/ICategory';
import type { ColumnDef } from '@tanstack/react-table';
import AddCategoryModal from './AddCategoryModal';
import CategoriesTable from './CategoriesTable';
import { DictionaryContext } from '../../../App';
import type { ICategoryData } from '../../../interfaces/ICategoryData';

import './style.css';

const Categories = () => {
  const [categories, setCategories] = useState<ICategoryData[]>([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [removeButtonDisabled, setRemoveButtonDisabled] = useState(true);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const [updatedCategories, setUpdatedCategories] = useState<ICategory[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<ICategoryData | null>(null);
  const [selectedCategoryChilds, setSelectedCategoryChilds] = useState<ICategoryData[]>([]);
  const [selectedCategoryAncestors, setSelectedCategoryAncestors] = useState<ICategoryData[]>([]);

  const [fetchTrigger, setFetchTrigger] = useState(0);
  const fetchDataIdRef = React.useRef(0);
  const { categoriesNames } = useContext(DictionaryContext);

  const fetchData = useCallback(async () => {
    const fetchId = ++fetchDataIdRef.current;
    setLoading(true);
    const response = await Common.authorizedFetch('api/categories/getCategoriesData');
    const data = await response.json();
    if (data.success) {
      if (fetchId === fetchDataIdRef.current) {
        setCategories(data.result.categories);
        setLoading(false);
      }
      setRemoveButtonDisabled(true);
      setSaveButtonDisabled(true);
      setSelectedCategory(null);
      setSelectedCategoryChilds([]);
      setSelectedCategoryAncestors([]);
      setUpdatedCategories([]);
    }
  }, []);

  const columns = React.useMemo<ColumnDef<ICategoryData>[]>(
    () => [
      {
        header: () => <span>Pełna nazwa</span>,
        accessorFn: (row) => (!!row.category ? <span>{row.category?.categoryFullName}</span> : <i>Nieprzypisane</i>),
        id: 'categoryFullName',
      },
      {
        header: () => <span>Nazwa</span>,
        accessorFn: (row) => row.category?.categoryName,
        id: 'categoryName',
      },
      {
        header: () => <span>Głębokość</span>,
        accessorFn: (row) => row.category?.categoryLevel,
        id: 'categoryLevel',
      },
      {
        header: () => <span>Kategoria nadrzędna</span>,
        accessorFn: (row) => row.parentCategory?.categoryFullName,
        id: 'parentCategoryFullName',
      },
      {
        header: () => <span>Wykorzystano</span>,
        accessorFn: (row) => row.count,
        id: 'count',
      },
      {
        header: () => <span>Zapłacono</span>,
        accessorFn: (d) => (Math.round(d.totalCost * 100) / 100).toFixed(2),
        id: 'totalCost',
      },
    ],
    []
  );

  const updateCategoryName = (rowIndex: number, columnId: string, value: any) => {
    setCategories((prevState) => {
      const updated = prevState;
      if (updated[rowIndex].category?.categoryId !== 1 && updated[rowIndex].category?.categoryName !== value) {
        const ind = updatedCategories.findIndex((l) => l.categoryId === updated[rowIndex].category?.categoryId);
        if (ind === -1) {
          updatedCategories.push({
            ...updated[rowIndex].category,
            categoryName: value,
          });
        } else {
          updatedCategories[ind].categoryName = value;
        }
        setUpdatedCategories(updatedCategories);
      }
      updated[rowIndex] = {
        ...prevState[rowIndex],
        [columnId]: value,
      };

      return updated;
    });
  };

  const updateParentCategoryId = (rowIndex: number, columnId: string, value: any) => {
    setCategories((prevState) => {
      const updated = prevState;
      if (
        updated[rowIndex].category?.categoryId !== 1 &&
        updated[rowIndex].parentCategory?.categoryFullName !== value
      ) {
        const ind = updatedCategories.findIndex((l) => l.categoryId === updated[rowIndex].category?.categoryId);
        if (ind === -1) {
          updatedCategories.push({
            ...updated[rowIndex].category,
            parentCategoryId: value,
          });
        } else {
          updatedCategories[ind].parentCategoryId = value;
        }
        setUpdatedCategories(updatedCategories);
      }
      updated[rowIndex] = {
        ...prevState[rowIndex],
        [columnId]: value,
      };

      return updated;
    });
  };

  return (
    <Container className='mt-2'>
      <h2 className='mb-3' id='tabelLabel'>
        Kategorie
      </h2>
      {Common.Ui.showLoadingSpinnerFixed(showSpinner)}
      <CategoriesTable
        columns={columns}
        data={categories}
        loading={loading}
        setShowSpinner={setShowSpinner}
        fetchData={fetchData}
        fetchTrigger={fetchTrigger}
        setFetchTrigger={setFetchTrigger}
        updateCategoryName={updateCategoryName}
        updateParentCategoryId={updateParentCategoryId}
        updatedCategories={updatedCategories}
        setShowModal={setShowModal}
        saveButtonDisabled={saveButtonDisabled}
        removeButtonDisabled={removeButtonDisabled}
        setSaveButtonDisabled={setSaveButtonDisabled}
        setRemoveButtonDisabled={setRemoveButtonDisabled}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        selectedCategoryChilds={selectedCategoryChilds}
        setSelectedCategoryChilds={setSelectedCategoryChilds}
        selectedCategoryAncestors={selectedCategoryAncestors}
        setSelectedCategoryAncestors={setSelectedCategoryAncestors}
        categoriesNames={categoriesNames}
      />
      <AddCategoryModal
        show={showModal}
        onHide={() => {
          setShowModal(false);
        }}
        onSave={() => {
          setFetchTrigger(fetchTrigger + 1);
        }}
        categoriesNames={categoriesNames}
      />
    </Container>
  );
};

export default Categories;
