import { Formik } from 'formik';
import { useCallback, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import * as yup from 'yup';

import Common from '../../../shared/Common';
import type { IUserSettingsChildProps } from '../UserSettings';

const ChangePassword = (props: IUserSettingsChildProps) => {
  const [formLoading, setFormLoading] = useState<boolean>(false);

  const { loading, userData, setFetchUserDataTrigger, fetchUserDataTrigger, setStatus } = props;

  const ChangePasswordForm = useCallback(() => {
    const validationSchema = yup.object().shape({
      userPassword: yup.string().test('password-required', 'Nie wprowadzono hasła', function (value) {
        return !userData?.hasPassword || (!!value && value.length > 0);
      }),
      newPassword: yup
        .string()
        .required('Nie wprowadzono hasła')
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
          'Musi zawierać minimum jedną wielką literę, jedną małą literę i jedną cyfrę'
        )
        .min(8, 'Hasło jest za krótkie - hasło powinno zawierać minimum 8 znaków'),
      confirmedNewPassword: yup.string().test('passwords-match', 'Hasła muszą być takie same', function (value) {
        return this.parent.newPassword === value;
      }),
    });

    return (
      <>
        {!userData?.hasPassword && (
          <Row>
            <Col>
              <p className='text-info'>
                Aktualnie Twoje konto nie ma ustawionego hasła. Jeśli chcesz korzystać z aplikacji za pomocą hasła
                możesz je dodać tutaj.
              </p>
            </Col>
          </Row>
        )}
        <Row>
          <Col md={6}>
            <Formik
              initialValues={{
                userPassword: '',
                newPassword: '',
                confirmedNewPassword: '',
              }}
              validationSchema={validationSchema}
              onSubmit={async (values, { setSubmitting }) => {
                setSubmitting(true);
                setFormLoading(true);
                const requestOptions = {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({
                    oldPassword: values.userPassword,
                    newPassword: values.newPassword,
                  }),
                };
                const response = await Common.authorizedFetch('api/users/changeUserPassword', requestOptions);
                const data = await response.json();
                setFormLoading(false);
                setSubmitting(false);
                if (!data.success) {
                  setStatus({
                    variant: 'error',
                    status: data.errors,
                    messageTime: Date.now(),
                  });
                } else {
                  setStatus({
                    variant: 'success',
                    status: data.informations,
                    messageTime: Date.now(),
                  });
                  setFetchUserDataTrigger(fetchUserDataTrigger + 1);
                }
              }}
            >
              {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                <>
                  <Form onSubmit={handleSubmit}>
                    {userData?.hasPassword && (
                      <Form.Group>
                        <Form.Label>Aktualne hasło</Form.Label>
                        <Form.Control
                          type='password'
                          name='userPassword'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.userPassword}
                          className={touched.userPassword && errors.userPassword ? 'error' : undefined}
                          placeholder='Wpisz swoje aktualne hasło'
                        />
                        {touched.userPassword && errors.userPassword ? (
                          <div className='error-large-message'>{errors.userPassword}</div>
                        ) : null}
                      </Form.Group>
                    )}
                    <Form.Group className='mt-3'>
                      <Form.Label>Nowe hasło</Form.Label>
                      <Form.Control
                        autoComplete='off'
                        type='password'
                        name='newPassword'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.newPassword}
                        className={touched.newPassword && errors.newPassword ? 'error' : undefined}
                        placeholder='Wpisz swoje nowe hasło'
                      />
                      {touched.newPassword && errors.newPassword ? (
                        <div className='error-large-message'>{errors.newPassword}</div>
                      ) : null}
                    </Form.Group>
                    <Form.Group className='mt-3'>
                      <Form.Label>Potwierdź hasło</Form.Label>
                      <Form.Control
                        autoComplete='off'
                        type='password'
                        name='confirmedNewPassword'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.confirmedNewPassword}
                        className={touched.confirmedNewPassword && errors.confirmedNewPassword ? 'error' : undefined}
                        placeholder='Wpisz nowe hasło ponownie'
                      />
                      {touched.confirmedNewPassword && errors.confirmedNewPassword ? (
                        <div className='error-message'>{errors.confirmedNewPassword}</div>
                      ) : null}
                    </Form.Group>
                    <Button className='mt-3' variant='primary' type='submit' disabled={isSubmitting || loading}>
                      Zapisz
                    </Button>
                  </Form>
                </>
              )}
            </Formik>
          </Col>
        </Row>
      </>
    );
  }, [loading, setFetchUserDataTrigger, fetchUserDataTrigger, userData, setStatus]);
  return (
    <>
      {userData?.hasPassword ? <h4>Zmień hasło</h4> : <h4>Dodaj hasło</h4>}
      {Common.Ui.showLoadingSpinnerFixed(loading || formLoading)}
      <ChangePasswordForm />
    </>
  );
};

export default ChangePassword;
