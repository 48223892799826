import { useCallback, useEffect, useRef, useState } from 'react';
import type { ICompanyProductDetails } from '../../../interfaces/ICompanyProductDetails';
import Common from '../../shared/Common';
import {
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
  ZAxis,
} from 'recharts';
import type { ICompanyProductPriceHistory } from '../../../interfaces/ICompanyProductPriceHistory';
import { Form } from 'react-bootstrap';
import type { ISimilarCompanyProductPriceHistory } from '../../../interfaces/ISimilarCompanyProductPriceHistory';

interface IProductDetailsPriceHistoryProps {
  priceHistory: ICompanyProductPriceHistory[];
  selectedProduct: ICompanyProductDetails | undefined;
  resetDataTrigger: number;
}

function ProductDetailsPriceHistory(props: IProductDetailsPriceHistoryProps) {
  const [showSimilarProductsPrices, setShowSimilarProductsPrices] = useState(false);
  const [similarProductsPriceHistory, setSimilarProductsPriceHistory] = useState<ISimilarCompanyProductPriceHistory[]>(
    []
  );
  const [loading, setLoading] = useState(false);
  const fetchIdRef = useRef(0);

  const { priceHistory, selectedProduct, resetDataTrigger } = props;

  useEffect(() => {
    setSimilarProductsPriceHistory([]);
    setShowSimilarProductsPrices(false);
  }, [resetDataTrigger]);

  const fetchSimilarProductsPriceHistoryData = useCallback(async () => {
    if (
      similarProductsPriceHistory.length > 0 ||
      !showSimilarProductsPrices ||
      (selectedProduct?.similarProductIds?.length ?? 0) === 0
    ) {
      return;
    }
    setLoading(true);
    const fetchId = ++fetchIdRef.current;
    const response = await Common.authorizedFetch(
      'api/prices/getSimilarProductsPriceHistoryData?similarProductIds=' +
        selectedProduct?.similarProductIds?.join('&similarProductIds=')
    );
    const data = await response.json();
    if (data.success) {
      if (fetchId === fetchIdRef.current) {
        if (data.result.similarProductsPriceHistory) {
          setSimilarProductsPriceHistory(
            data.result.similarProductsPriceHistory.map((h: ISimilarCompanyProductPriceHistory) => ({
              companyLocationName: h.companyLocationName,
              productUrl: h.productUrl,
              history: h.history.map((value: any) => ({
                dateValue: new Date(value.date).valueOf(),
                price: value.price,
                company: h.companyLocationName,
              })),
            }))
          );
        }
      }
    }
    setLoading(false);
  }, [similarProductsPriceHistory, showSimilarProductsPrices, selectedProduct?.similarProductIds, setLoading]);

  const generatePriceHistoryScattersChartContent = useCallback(() => {
    const getScatterFillColor = (company: string) => {
      if (company === 'Auchan') {
        return '#00904B';
      }
      if (company === 'Carrefour') {
        return '#33348E';
      }
      if (company === 'Frisco') {
        return '#A5004E';
      }
      if (company === 'Lisek') {
        return '#E88601';
      }
      if (company === 'Hale Banacha') {
        return '#005CA2';
      }
      if (company === 'Polski Koszyk') {
        return '#000000';
      }
      if (company === 'Delio') {
        return '#004946';
      }
      if (company === 'Selgros') {
        return '#E20026';
      }
      return '#000000';
    };
    const scatters = [];
    scatters.push(
      <Scatter
        key={0}
        name={selectedProduct?.companyName}
        data={priceHistory}
        fill={getScatterFillColor(selectedProduct?.companyName ?? '')}
        line={{ strokeWidth: 0.4 }}
        cursor='pointer'
        onClick={(_) => {
          const productUrl = selectedProduct?.url;
          if (productUrl) {
            window.open(productUrl, '_blank')?.focus();
          }
        }}
      />
    );
    if (showSimilarProductsPrices) {
      for (var ind = 0; ind < similarProductsPriceHistory.length; ind++) {
        scatters.push(
          <Scatter
            key={ind + 1}
            name={similarProductsPriceHistory[ind].companyLocationName}
            data={similarProductsPriceHistory[ind].history}
            fill={getScatterFillColor(similarProductsPriceHistory[ind].companyLocationName ?? '')}
            line={{
              strokeWidth: 0.2,
            }}
            cursor='pointer'
            onClick={(e: any) => {
              const company = e.company ?? '';
              var product = similarProductsPriceHistory.find((p) => p.companyLocationName === company);
              const productUrl = product?.productUrl;
              if (productUrl) {
                window.open(productUrl, '_blank')?.focus();
              }
            }}
          />
        );
      }
    }

    return scatters;
  }, [
    priceHistory,
    similarProductsPriceHistory,
    selectedProduct?.companyName,
    showSimilarProductsPrices,
    selectedProduct?.url,
  ]);

  useEffect(() => {
    fetchSimilarProductsPriceHistoryData();
  }, [fetchSimilarProductsPriceHistoryData]);

  return (
    <>
      {Common.Ui.showLoadingSpinnerAbsolute(loading)}
      {(selectedProduct?.similarProductIds?.length ?? 0) > 0 && (
        <Form.Check
          className='my-2'
          type='switch'
          id='show-similar-products-prices-switch'
          label='Porównaj ceny produktu w innych sklepach'
          style={{ userSelect: 'none' }}
          checked={showSimilarProductsPrices}
          onChange={() => setShowSimilarProductsPrices((prev) => !prev)}
        />
      )}
      <div className='overflow-auto'>
        <ResponsiveContainer minWidth={600} width='100%' height={400}>
          <ScatterChart
            margin={{
              left: 5,
              right: 5,
              top: 5,
              bottom: 5,
            }}
          >
            {generatePriceHistoryScattersChartContent()}
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis
              dataKey='dateValue'
              name='Data'
              type='number'
              domain={['dataMin', 'dataMax']}
              tickFormatter={(value) => new Date(value).toLocaleDateString()}
            />
            <YAxis
              padding={{ bottom: 10 }}
              type='number'
              dataKey='price'
              tickFormatter={(value) => (value >= 100 ? value.toFixed(0) : value.toFixed(2))}
              name='Cena'
              unit=' zł'
              domain={([dataMin, dataMax]) => {
                var diffValue = 0; // aktualnie ustawione 0, żeby min i maks był ceną minimalną i maksymalną
                var domainMin = dataMin - diffValue > 0 ? dataMin - diffValue : 0;
                var domainMax = dataMax + diffValue;
                var roundDecimals = domainMax >= 1000;
                if (roundDecimals) {
                  domainMin = Math.round(domainMin);
                  domainMax = Math.round(domainMax);
                }
                return [domainMin, domainMax];
              }}
            />
            <ZAxis type='category' dataKey='company' name='Sklep' />
            <Legend
              verticalAlign='top'
              iconSize={10}
              height={36}
              onClick={(e: any) => {
                const company = e.value ?? '';
                var productUrl: string | undefined = undefined;
                if (e.value === selectedProduct?.companyName) {
                  productUrl = selectedProduct?.url;
                } else {
                  var product = similarProductsPriceHistory.find((p) => p.companyLocationName === company);
                  productUrl = product?.productUrl;
                }
                if (productUrl) {
                  window.open(productUrl, '_blank');
                }
              }}
            />
            <Tooltip
              formatter={(value, name, _) => {
                if (name === 'Data') {
                  return new Date(value as number).toLocaleString();
                }
                return value;
              }}
            />
          </ScatterChart>
        </ResponsiveContainer>
      </div>
    </>
  );
}

export default ProductDetailsPriceHistory;
