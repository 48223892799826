import 'bootstrap/dist/css/bootstrap.css';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';

import './custom.css';
import './variables.css';
import 'react-tooltip/dist/react-tooltip.css';
import 'photoswipe/dist/photoswipe.css';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') ?? '';
const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <BrowserRouter basename={baseUrl}>
    <App />
  </BrowserRouter>
);
