import { Container, Row, Form } from 'react-bootstrap';
import Common from '../../../shared/Common';
import { ClockIcon, CheckIcon, BanIcon, CircleNotchSpinIcon } from '../../../shared/icons/Svgs';
import type { IScraperSchedule } from '../../../../interfaces/IScraperSchedule';
import { type IScraperScheduleExecution, ScheduleStatus } from '../../../../interfaces/IScraperScheduleExecution';

export interface IScheduleTableProps {
  schedules: IScraperSchedule[];
  loading: boolean;
  scheduleDate: Date;
  setScheduleDate: React.Dispatch<React.SetStateAction<Date>>;
}

const ScheduleTable = (props: IScheduleTableProps) => {
  const generateCellValueForDate = (execution: IScraperScheduleExecution) => {
    const getResultIcon = (status: ScheduleStatus) => {
      switch (status) {
        case ScheduleStatus.Scheduled:
          return <ClockIcon style={{ width: '40px', height: '40px', marginTop: '-12px', marginBottom: '-12px' }} />;
        case ScheduleStatus.Completed:
          return <CheckIcon style={{ color: 'green' }} />;
        case ScheduleStatus.CustomErrorsOccured:
        case ScheduleStatus.NoRecordsCollected:
        case ScheduleStatus.DataFeedNotFound:
          return (
            <BanIcon
              style={{ color: 'red', height: '40px', width: '40px', marginTop: '-12px', marginBottom: '-12px' }}
            />
          );
        case ScheduleStatus.Running:
          return <CircleNotchSpinIcon style={{ color: '#007bff' }} />;
      }
    };
    const getResultTitle = (execution: IScraperScheduleExecution) => {
      switch (execution.status) {
        case ScheduleStatus.Scheduled:
          return 'Zaplanowano';
        case ScheduleStatus.Completed:
          return `Przetworzono ${execution.processedRecords} rekordów`;
        case ScheduleStatus.CustomErrorsOccured:
          return 'Nieprzewidziany błąd';
        case ScheduleStatus.NoRecordsCollected:
          return 'Nie pobrano żadnych rekordów';
        case ScheduleStatus.DataFeedNotFound:
          return 'Nie znaleziono próby wykonania';
        case ScheduleStatus.Running:
          return 'Aktualnie przetwarzane';
      }
    };
    const minutes =
      new Date(execution.time).getMinutes() > 9
        ? new Date(execution.time).getMinutes()
        : '0' + new Date(execution.time).getMinutes();
    const time = `${new Date(execution.time).getHours()}:${minutes}`;

    return (
      <Container title={getResultTitle(execution)}>
        <Row className='justify-content-center'>{time}</Row>
        <Row className='my-1 justify-content-center'>{getResultIcon(execution.status)}</Row>
      </Container>
    );
  };

  return (
    <>
      <h6 className='mt-3'>Harmonogramy wykonań</h6>
      {!props.loading && (
        <Form.Control
          style={{ width: 200 }}
          type='date'
          name='schedules'
          placeholder='Dzień wykonania'
          onChange={(e: any) => {
            props.setScheduleDate(new Date(e.target.value));
          }}
          defaultValue={props.scheduleDate.toLocaleString('en-CA').split(',')[0]}
        />
      )}
      <div className='mt-2 table-responsive'>
        <table className='table table-sm'>
          <tbody>
            {props.loading
              ? Common.Ui.getSkeletonTableRows(8, 1, 35)
              : props.schedules.map((value, index) => (
                  <tr key={index}>
                    <td key={-1}>{value.companyName}</td>
                    {Array.from({ length: 24 }, (_, index) => {
                      const executionsForHour = value.executions.filter(
                        (scheduleValue) => new Date(scheduleValue.time).getHours() === index
                      );

                      return (
                        <td key={index}>
                          {executionsForHour.map((execution, executionIndex) => (
                            <div key={executionIndex}>{generateCellValueForDate(execution)}</div>
                          ))}
                        </td>
                      );
                    })}
                  </tr>
                ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ScheduleTable;
