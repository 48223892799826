import { Formik } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import * as yup from 'yup';

import Common from '../../shared/Common';
import ApplicationAlert from '../../shared/application-alert/ApplicationAlert';
import type { IApplicationAlert } from '../../shared/application-alert/ApplicationAlert';

const CreateExternalLogin = () => {
  const [status, setStatus] = useState<IApplicationAlert>();
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [provider, setProvider] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [redirectUrl, setRedirectUrl] = useState<string>('');

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const providerParam = params.get('provider');
    const emailParam = params.get('email');
    const redirect = params.get('ReturnUrl');
    if (!!redirect) {
      setRedirectUrl(redirect);
    }
    if (!!providerParam) {
      setProvider(providerParam);
    }
    if (!!emailParam) {
      setEmail(emailParam);
    }
  }, []);

  const CreateExternalLoginForm = useCallback(() => {
    const validationSchema = yup.object().shape({
      email: yup.string(),
    });

    return (
      <Row>
        <Col md={6}>
          <Formik
            initialValues={{
              email: email ?? '',
            }}
            validationSchema={validationSchema}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true);
              setFormLoading(true);
              const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email: values.email }),
              };
              const response = await Common.authorizedFetch('idsrv/account/createAccount', requestOptions);
              const data = await response.json();
              setFormLoading(false);
              if (!data.success) {
                setStatus({
                  variant: 'error',
                  status: data.errors,
                  messageTime: Date.now(),
                });
              } else {
                window.location.replace(`${redirectUrl}`);
                return;
              }
              setSubmitting(false);
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
              <>
                <Form onSubmit={handleSubmit} autoComplete='off'>
                  <Form.Group>
                    <Form.Label>E-mail</Form.Label>
                    <Form.Control
                      type='text'
                      name='email'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      className={touched.email && errors.email ? 'error' : undefined}
                    />
                    {touched.email && errors.email ? <div className='error-message'>{errors.email}</div> : null}
                  </Form.Group>
                  <Button className='mt-2' variant='primary' type='submit' disabled={isSubmitting}>
                    Zarejestruj
                  </Button>
                </Form>
              </>
            )}
          </Formik>
        </Col>
      </Row>
    );
  }, [setStatus, email, redirectUrl]);

  return (
    <>
      {status && (
        <ApplicationAlert variant={status?.variant} status={status?.status} messageTime={status?.messageTime} />
      )}
      {Common.Ui.showLoadingSpinnerFixed(formLoading)}
      <h1>Rejestracja</h1>
      <h4 id='external-login-title'>Powiąż swoje konto {provider}.</h4>
      <hr />
      <p id='external-login-description' className='text-info'>
        Udało Ci się powiązać konto z <strong>{provider}</strong>. Wprowadź poniżej swój adres e-mail i kliknij przycisk
        "Zarejestruj", aby zakończyć rejestrację.
      </p>
      <CreateExternalLoginForm />
    </>
  );
};

export default CreateExternalLogin;
