import { useState, useEffect, useCallback, useRef } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import type { IFeatureFlag } from '../../../../interfaces/IFeatureFlag';
import Common from '../../../shared/Common';

const FeatureFlags = () => {
  const [featureFlags, setFeatureFlags] = useState<IFeatureFlag[]>([]);
  const [fetchFeatureFlagsTrigger, setFetchFeatureFlagsTrigger] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);
  const fetchIdRef = useRef(0);

  const enableEmailSendingFeatureFlagName = 'EnableEmailSending';

  const fetchFeatureFlags = useCallback(async () => {
    setLoading(true);
    const fetchId = ++fetchIdRef.current;
    const response = await Common.authorizedFetch('api/applicationLogicConfiguration/getFeatureFlags');
    if (fetchId === fetchIdRef.current) {
      const data = await response.json();
      if (data.success) {
        setFeatureFlags(data.result.featureFlags);
      }
      setLoading(false);
    }
  }, []);

  const updateFeatureFlags = async () => {
    setLoading(true);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        featureFlags: featureFlags,
      }),
    };
    const response = await Common.authorizedFetch(
      'api/applicationLogicConfiguration/updateFeatureFlags',
      requestOptions
    );
    const data = await response.json();
    if (!data.success) {
      alert(data.errors);
      setLoading(false);
    } else {
      setFetchFeatureFlagsTrigger((prev) => prev + 1);
    }
  };

  useEffect(() => {
    fetchFeatureFlags();
  }, [fetchFeatureFlags, fetchFeatureFlagsTrigger]);

  return (
    <>
      <h4 className='mb-3'>Feature flagi w aplikacji</h4>
      <Container>
        {loading ? (
          <Skeleton height={25} />
        ) : (
          <Row className='mt-2'>
            <Col>
              <Form.Check
                type='switch'
                id='enable-email-sending'
                style={{ userSelect: 'none' }}
                label='Funkcjonalność wysyłania e-maili'
                defaultChecked={
                  featureFlags.find((flag) => flag.featureFlagName === enableEmailSendingFeatureFlagName)
                    ?.featureFlagValue
                }
                onChange={() => {
                  setFeatureFlags((prev) => {
                    const index = prev.findIndex((flag) => flag.featureFlagName === enableEmailSendingFeatureFlagName);
                    if (index > -1) {
                      prev[index].featureFlagValue = !prev[index].featureFlagValue;
                    }
                    return prev;
                  });
                }}
              />
            </Col>
          </Row>
        )}
        <Button className='mt-3' variant='primary' onClick={updateFeatureFlags}>
          Zapisz
        </Button>
      </Container>
    </>
  );
};

export default FeatureFlags;
