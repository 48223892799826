import { useState } from 'react';
import Autosuggest from 'react-autosuggest';
import type { ICategoryName } from '../../../interfaces/ICategoryName';
import '../custom-react-autosuggest.css';

export interface ICategoryAutosuggestProps {
  selectedValue?: ICategoryName;
  categories: ICategoryName[];
  onChange: (value: string) => void;
  onBlur?: () => void;
}

function CategoryAutosuggest({ selectedValue, onChange, onBlur, categories }: ICategoryAutosuggestProps) {
  const [value, setValue] = useState(selectedValue?.categoryName ?? '');
  const [suggestions, setSuggestions] = useState<string[]>([]);

  function getSuggestions(value: string): string[] {
    var filteredCategories: ICategoryName[] = [];
    if (value === selectedValue?.categoryName) {
      const parts = selectedValue.categoryFullName.split(' › ');
      const rootCategory = parts.shift() ?? '';
      filteredCategories = [
        selectedValue, // wybrana pozycja na początku listy sugestii
        ...categories.filter(
          (category) =>
            category.categoryFullName.toLowerCase().includes(rootCategory.trim().toLowerCase()) &&
            category.categoryFullName !== selectedValue.categoryFullName // unikamy powtórzeń
        ),
      ];
    } else {
      filteredCategories = categories.filter((category) =>
        category.categoryFullName.toLowerCase().includes(value.trim().toLowerCase())
      );
    }
    return filteredCategories.map((category) => JSON.stringify(category));
  }

  const theme = {
    container: 'autosuggest',
    input: 'form-control',
    suggestionsList: `dropdown-menu ${suggestions.length ? 'show' : ''}`,
    suggestion: 'dropdown-item',
    suggestionHighlighted: 'active',
  };

  return (
    <div>
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsClearRequested={() => setSuggestions([])}
        onSuggestionsFetchRequested={({ value }) => {
          setValue(value);
          setSuggestions(getSuggestions(value));
        }}
        onSuggestionSelected={(_, { suggestionValue }) => {
          setValue(JSON.parse(suggestionValue).categoryName);
          onChange(suggestionValue);
        }}
        getSuggestionValue={(suggestion) => suggestion}
        renderSuggestion={(suggestion) => {
          const categoryFullName = JSON.parse(suggestion).categoryFullName;
          const selectedCategory = categoryFullName === selectedValue?.categoryFullName;
          return <span className={selectedCategory ? 'fw-bold' : ''}>{JSON.parse(suggestion).categoryFullName}</span>;
        }}
        inputProps={{
          value: value,
          onChange: (_, { newValue, method }) => {
            setValue(newValue);
            onChange(
              JSON.stringify({
                categoryId: undefined,
                categoryName: '',
                categoryFullName: '',
              })
            );
          },
          onBlur: () => {
            if (onBlur !== undefined) {
              onBlur();
            }
          },
        }}
        renderInputComponent={(inputProps) => <input title={selectedValue?.categoryFullName} {...inputProps} />}
        theme={theme}
        highlightFirstSuggestion={true}
      />
    </div>
  );
}

export default CategoryAutosuggest;
