import { useState } from 'react';
import { Col, Tab, Tabs } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import DashboardMain from './DashboardMain';
import DashboardStatistics from './DashboardStatistics';
import Bills from './bills/Bills';
import Categories from './categories/Categories';
import CategoriesMap from './categories/CategoriesMap';
import Groups from './groups/Groups';
import GroupsMap from './groups/GroupsMap';
import Locations from './locations/Locations';
import QuantityTypes from './quantity-types/QuantityTypes';
import Statistics from './statistics/Statistics';
import RecurringOperations from './recurring-operations/RecurringOperations';
import Summaries from './summaries/Summaries';
import SummaryConfig from './summaries/SummaryConfig';
import Prices from './prices/Prices';
import './style.css';

const DashboardContent = () => {
  const [onCategoriesMapClicked, setOnCategoriesMapClicked] = useState<number>(0);
  const [onGroupsMapClicked, setOnGroupsMapClicked] = useState<number>(0);
  const [fetchDashboardStatisticsTrigger, setFetchDashboardStatisticsTrigger] = useState(0);

  const location = useLocation();

  const getDashboardContentForPath = (path: string) => {
    switch (path) {
      case '/dashboard':
      case '/':
        return (
          <>
            <Col md={12} lg={8} xl={6} className='dashboard-main'>
              <DashboardMain setFetchDashboardStatisticsTrigger={setFetchDashboardStatisticsTrigger} />
            </Col>
            <Col md={0} lg={3} xl={3} className='d-none d-xl-block'>
              <Col className='dashboard-statistics'>
                <DashboardStatistics fetchDashboardStatisticsTrigger={fetchDashboardStatisticsTrigger} />
              </Col>
            </Col>
          </>
        );
      case '/bills':
        return (
          <Col>
            <Bills summaryElementsSelection={false} />
          </Col>
        );
      case '/statistics':
        return (
          <Col>
            <Statistics />
          </Col>
        );
      case '/summaries':
        return (
          <Col>
            <Summaries />
          </Col>
        );
      case '/recurring-operations':
        return (
          <Col>
            <RecurringOperations />
          </Col>
        );
      case '/dictionaries/locations':
        return (
          <Col>
            <Locations />
          </Col>
        );
      case '/dictionaries/categories':
        return (
          <Col className='mt-1'>
            <Tabs key='categories-tabs' className='mb-3'>
              <Tab eventKey='categories' title='Kategorie'>
                <Categories />
              </Tab>
              <Tab
                eventKey='categories-map'
                title='Drzewo kategorii'
                onEntered={() => {
                  setOnCategoriesMapClicked((prev) => prev + 1);
                }}
              >
                <CategoriesMap onCategoriesMapClicked={onCategoriesMapClicked} />
              </Tab>
            </Tabs>
          </Col>
        );
      case '/dictionaries/categories-map':
        return (
          <Col>
            <CategoriesMap onCategoriesMapClicked={onCategoriesMapClicked} />
          </Col>
        );
      case '/dictionaries/groups':
        return (
          <Col className='mt-1'>
            <Tabs key='groups-tabs' className='mb-3'>
              <Tab eventKey='groups' title='Grupy'>
                <Groups />
              </Tab>
              <Tab
                eventKey='groups-map'
                title='Drzewo grup'
                onEntered={() => {
                  setOnGroupsMapClicked((prev) => prev + 1);
                }}
              >
                <GroupsMap onGroupsMapClicked={onGroupsMapClicked} />
              </Tab>
            </Tabs>
          </Col>
        );
      case '/dictionaries/groups-map':
        return (
          <Col>
            <GroupsMap onGroupsMapClicked={onGroupsMapClicked} />
          </Col>
        );
      case '/dictionaries/quantity-types':
        return (
          <Col>
            <QuantityTypes />
          </Col>
        );
      default:
        if (path.includes('/summaries/')) {
          return (
            <Col>
              <SummaryConfig />
            </Col>
          );
        }
        if (path.includes('/prices')) {
          return (
            <Col>
              <Prices />
            </Col>
          );
        }
        return <></>;
    }
  };

  return getDashboardContentForPath(location.pathname);
};

export default DashboardContent;
