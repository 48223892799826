import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import React, { useState, useEffect, useContext } from 'react';
import { Container, Form, Button, Navbar, Nav } from 'react-bootstrap';
import Common from '../../shared/Common';
import CategoryAutosuggest from '../../shared/category-autosuggest/CategoryAutosuggest';
import WarningModal from '../../shared/shared-modals/WarningModal';
import type { ICategory } from '../../../interfaces/ICategory';
import type { ICategoryName } from '../../../interfaces/ICategoryName';
import type { ColumnDef, SortingState } from '@tanstack/react-table';
import { SortDownIcon, SortUpIcon } from '../../shared/icons/Svgs';
import type { ICategoryData } from '../../../interfaces/ICategoryData';
import { DictionaryContext } from '../../../App';

export interface ITableProps {
  columns: ColumnDef<ICategoryData>[];
  data: ICategoryData[];
  loading: boolean;
  fetchData: () => Promise<void>;
  fetchTrigger: number;
  setShowSpinner: React.Dispatch<React.SetStateAction<boolean>>;
  setFetchTrigger: React.Dispatch<React.SetStateAction<number>>;
  updateCategoryName: (rowIndex: number, columnId: string, value: any) => void;
  updateParentCategoryId: (rowIndex: number, columnId: string, value: any) => void;
  updatedCategories: ICategory[];
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  saveButtonDisabled: boolean;
  removeButtonDisabled: boolean;
  setSaveButtonDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  setRemoveButtonDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  selectedCategory: ICategoryData | null;
  selectedCategoryChilds: ICategoryData[];
  selectedCategoryAncestors: ICategoryData[];
  setSelectedCategory: React.Dispatch<React.SetStateAction<ICategoryData | null>>;
  setSelectedCategoryChilds: React.Dispatch<React.SetStateAction<ICategoryData[]>>;
  setSelectedCategoryAncestors: React.Dispatch<React.SetStateAction<ICategoryData[]>>;
  categoriesNames: ICategoryName[];
}

export interface IEditableCellProps {
  value: any;
  row: any;
  column: any;
  updateCategoryName: (rowIndex: number, columnId: number, value: any) => void;
  updateParentCategoryId: (rowIndex: number, columnId: number, value: any) => void;
  categoriesNames: ICategoryName[];
}

function CategoriesTable(props: ITableProps) {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const { reloadCategoriesNames } = useContext(DictionaryContext);

  const defaultColumn: Partial<ColumnDef<ICategoryData>> = {
    cell: function Cell({ getValue, row, column: { id }, table }) {
      const initialValue = getValue();
      const [value, setValue] = React.useState(initialValue);

      const onCategoryNameChange = (e: any) => {
        if (e.target.value === '') props.setSaveButtonDisabled(true);
        else props.setSaveButtonDisabled(false);
        setValue(e.target.value);
      };

      const onParentCategoryChange = (e: string) => {
        if (e === '') {
          if (value === undefined) props.setSaveButtonDisabled(true);
          else props.setSaveButtonDisabled(false);
          setValue(undefined);
        } else {
          let obj = JSON.parse(e);
          if (obj.categoryId === value) props.setSaveButtonDisabled(true);
          else props.setSaveButtonDisabled(false);
          setValue(obj.categoryId);
        }
      };

      const onCategoryNameBlur = () => {
        props.updateCategoryName(row.index, id, value);
      };

      const onParentCategoryBlur = () => {
        props.updateParentCategoryId(row.index, id, value);
      };

      React.useEffect(() => {
        setValue(initialValue);
      }, [initialValue]);

      switch (id) {
        case 'categoryName':
          if (!row.original.category) {
            return <></>;
          }
          return (
            <Form.Control
              type='text'
              onChange={onCategoryNameChange}
              onBlur={onCategoryNameBlur}
              value={value as string}
            />
          );
        case 'parentCategoryFullName':
          if (!row.original.category) {
            return <></>;
          }
          const categoryName: ICategoryName = {
            categoryId: 0,
            categoryFullName: value as string,
            categoryName: '',
          };
          return (
            <CategoryAutosuggest
              selectedValue={categoryName}
              onChange={onParentCategoryChange}
              onBlur={onParentCategoryBlur}
              categories={table.options.meta?.categoriesNames ?? []}
            />
          );
        case 'totalCost':
          return <>{Common.Utils.getNumberWithSpaces(value as string)}</>;
        default:
          return <>{value}</>;
      }
    },
  };

  const table = useReactTable({
    data: props.data,
    columns: props.columns,
    defaultColumn,
    state: {
      sorting,
    },
    meta: {
      categoriesNames: props.categoriesNames,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const { fetchData, fetchTrigger } = props;

  useEffect(() => {
    fetchData();
  }, [fetchData, fetchTrigger]);

  const saveCategories = async () => {
    props.setShowSpinner(true);
    props.setSaveButtonDisabled(true);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ categories: props.updatedCategories }),
    };
    const response = await Common.authorizedFetch('api/categories/saveCategories', requestOptions);
    const data = await response.json();
    if (!data.success) {
      alert(data.errors);
    } else {
      if (reloadCategoriesNames) {
        reloadCategoriesNames();
      }
      props.setFetchTrigger(props.fetchTrigger + 1);
    }
    props.setShowSpinner(false);
  };

  const onTableRowClick = (category: ICategoryData) => {
    props.setSelectedCategory(category);
    if (category.count === 0) {
      props.setRemoveButtonDisabled(false);
    } else {
      props.setRemoveButtonDisabled(true);
    }
    const ancestorCategories: ICategoryData[] = [];
    const childCategories: ICategoryData[] = [];
    let parentCategoryId: number | undefined = category.category?.parentCategoryId;
    while (parentCategoryId !== undefined) {
      let categoryIdToSearch: number = parentCategoryId;
      let parentCategory = props.data.find((c) => c.category?.categoryId === categoryIdToSearch);
      if (parentCategory === undefined) break;
      ancestorCategories.push(parentCategory);
      parentCategoryId = parentCategory.category?.parentCategoryId;
    }
    fillChildCategoryTable(childCategories, category.category?.categoryId);
    props.setSelectedCategoryAncestors(ancestorCategories);
    props.setSelectedCategoryChilds(childCategories);
  };

  const fillChildCategoryTable = (categories: ICategoryData[], categoryId: number) => {
    props.data
      .filter((c) => c.category?.parentCategoryId === categoryId)
      .forEach((c) => {
        categories.push(c);
        fillChildCategoryTable(categories, c.category?.categoryId);
      });
  };

  const removeCategory = async () => {
    props.setShowSpinner(true);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        categoryId: props.selectedCategory?.category?.categoryId,
      }),
    };
    const response = await Common.authorizedFetch('api/categories/removeCategory', requestOptions);
    const data = await response.json();
    if (!data.success) {
      alert(data.errors);
    } else {
      if (reloadCategoriesNames) {
        reloadCategoriesNames();
      }
      props.setFetchTrigger(props.fetchTrigger + 1);
      setShowWarningModal(false);
    }
    props.setShowSpinner(false);
  };

  const getClassNameForRow = (rowCategoryId: number) => {
    if (rowCategoryId === props.selectedCategory?.category?.categoryId) return 'selected-row';
    if (props.selectedCategoryChilds.findIndex((c) => c.category?.categoryId === rowCategoryId) !== -1) {
      return 'child-selected-row';
    }
    if (props.selectedCategoryAncestors.findIndex((c) => c.category?.categoryId === rowCategoryId) !== -1) {
      return 'ancestor-selected-row';
    }
    return undefined;
  };

  return (
    <>
      <Navbar style={{ backgroundColor: 'transparent' }} variant='light' fixed='bottom'>
        <Container>
          <Nav className='me-auto' />
          <Nav>
            <Button
              disabled={props.removeButtonDisabled}
              className='me-2'
              variant='danger'
              onClick={() => {
                setShowWarningModal(true);
              }}
            >
              Usuń
            </Button>
            <Button
              className='me-2'
              variant='success'
              onClick={() => {
                props.setShowModal(true);
              }}
            >
              Dodaj
            </Button>
            <Button
              disabled={props.saveButtonDisabled}
              className='me-2'
              variant='dark'
              onClick={() => {
                saveCategories();
              }}
            >
              Zapisz
            </Button>
          </Nav>
        </Container>
      </Navbar>
      <div className='mt-1 pb-5'>
        <div className='table-responsive-sm'>
          <table id='categories-table' className='table'>
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th key={header.id}>
                      {header.isPlaceholder ? null : (
                        <div
                          {...{
                            style: header.column.getCanSort()
                              ? {
                                  cursor: 'pointer',
                                }
                              : {},
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(header.column.columnDef.header, header.getContext())}
                          {{
                            asc: <SortUpIcon style={{ marginLeft: '5px' }} />,
                            desc: <SortDownIcon style={{ marginLeft: '5px' }} />,
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {props.loading
                ? Common.Ui.getSkeletonTableRows(25, 6, 35)
                : table.getRowModel().rows.map((row) => (
                    <tr
                      key={row.id}
                      className={getClassNameForRow(row.original.category?.categoryId)}
                      onClick={() => {
                        onTableRowClick(row.original);
                      }}
                    >
                      {row.getVisibleCells().map((cell) => (
                        <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                      ))}
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>
      </div>
      <WarningModal
        show={showWarningModal}
        modalBodyText={`Czy na pewno chcesz usunąć kategorię '${props.selectedCategory?.category?.categoryFullName}'?`}
        onHide={() => {
          setShowWarningModal(false);
        }}
        onConfirmation={() => removeCategory()}
      />
    </>
  );
}

export default CategoriesTable;
