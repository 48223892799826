import { useContext, useState } from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import Common from '../../shared/Common';
import GroupAutosuggest from '../../shared/group-autosuggest/GroupAutosuggest';
import type { IGroupName } from '../../../interfaces/IGroupName';
import { DictionaryContext } from '../../../App';

import './style.css';

export interface IAddGroupModalProps {
  show: boolean;
  onHide: () => void;
  onSave: () => void;
  groupsNames: IGroupName[];
}

function AddGroupModal(props: IAddGroupModalProps) {
  const [groupName, setGroupName] = useState<string>('');
  const [parentGroupId, setParentGroupId] = useState<number | undefined>(undefined);
  const { reloadGroupsNames } = useContext(DictionaryContext);

  const addGroup = async () => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ groupName, parentGroupId }),
    };
    const response = await Common.authorizedFetch('api/groups/addGroup', requestOptions);
    const data = await response.json();
    if (!data.success) {
      alert(data.errors);
    } else {
      if (reloadGroupsNames) {
        reloadGroupsNames();
      }
      setGroupName('');
      setParentGroupId(undefined);
      props.onHide();
      props.onSave();
    }
  };

  return (
    <Modal centered show={props.show} onHide={props.onHide} aria-labelledby='contained-modal-title-vcenter'>
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>Dodaj nową grupę</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId='formBasicGroupName'>
            <Form.Label>Nazwa grupy</Form.Label>
            <Form.Control
              type='text'
              placeholder='Wpisz nazwę'
              onChange={(e: any) => {
                setGroupName(e.target.value);
              }}
            />
            <Form.Text className='text-muted'>Nazwa grupy określająca grupę produktu.</Form.Text>
          </Form.Group>
          <Form.Group controlId='formBasicParentGroupId'>
            <Form.Label>Grupa rodzica</Form.Label>
            <GroupAutosuggest
              onChange={(groupJson) => {
                setParentGroupId(JSON.parse(groupJson).groupId);
              }}
              groups={props.groupsNames}
            />
            <Form.Text className='text-muted'>Nazwa grupy będącej agregatem dodawanej grupy.</Form.Text>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='dark' onClick={props.onHide}>
          Zamknij
        </Button>
        <Button variant='success' onClick={addGroup}>
          Zapisz
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddGroupModal;
