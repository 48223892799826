import { Container, Nav, Row, Col } from 'react-bootstrap';
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import ManageRoles from './manage-roles/ManageRoles';
import UserRoles from './user-roles/UserRoles';
import LoadBillSettings from './load-bill/LoadBillSettings';
import FeatureFlags from './feature-flags/FeatureFlags';
import ManageUsers from './manage-users/ManageUsers';
import ManageUser from './manage-users/ManageUser';
import NewAccounts from './new-accounts/NewAccounts';
import DefaultPictures from './default-pictures/DefaultPictures';
import ProductsScrapers from './products-scrapers/ProductsScrapers';

const ApplicationSettings = () => {
  const userRolesPath = '/settings/application/user-roles';
  const manageRolesPath = '/settings/application/manage-roles';
  const manageUsersPath = '/settings/application/manage-users';
  const loadBillPath = '/settings/application/load-bill';
  const defaultPicturesPath = '/settings/application/default-pictures';
  const newAccountsPath = '/settings/application/new-accounts';
  const featureFlagsPath = '/settings/application/feature-flags';
  const productsScrapersPath = '/settings/application/products-scrapers';

  const location = useLocation();
  return (
    <Container>
      <h3 className='mt-2 mb-3'>Ustawienia aplikacji</h3>
      <hr />
      <Row>
        <Col md={3} className='mb-3'>
          <Nav variant='pills' className='flex-column' defaultActiveKey={location.pathname}>
            <Nav.Item as='li'>
              <Nav.Link eventKey={userRolesPath} as={Link} to={userRolesPath}>
                Role użytkownika
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as='li'>
              <Nav.Link eventKey={manageRolesPath} as={Link} to={manageRolesPath}>
                Zarządzaj rolami
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as='li'>
              <Nav.Link eventKey={manageUsersPath} as={Link} to={manageUsersPath}>
                Zarządzaj użytkownikami
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as='li'>
              <Nav.Link eventKey={loadBillPath} as={Link} to={loadBillPath}>
                Wczytywanie paragonów
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as='li'>
              <Nav.Link eventKey={defaultPicturesPath} as={Link} to={defaultPicturesPath}>
                Domyślne obrazki
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as='li'>
              <Nav.Link eventKey={newAccountsPath} as={Link} to={newAccountsPath}>
                Nowe konta
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as='li'>
              <Nav.Link eventKey={featureFlagsPath} as={Link} to={featureFlagsPath}>
                Flagi aplikacji
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as='li'>
              <Nav.Link eventKey={productsScrapersPath} as={Link} to={productsScrapersPath}>
                Zbieracze produktów
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        <Col md={9}>
          <Routes>
            <Route path='/' element={<UserRoles />} />
            <Route path='user-roles' element={<UserRoles />} />
            <Route path='manage-users' element={<ManageUsers />} />
            <Route path='manage-users/user/:id' element={<ManageUser />} />
            <Route path='manage-roles' element={<ManageRoles />} />
            <Route path='load-bill' element={<LoadBillSettings />} />
            <Route path='default-pictures' element={<DefaultPictures />} />
            <Route path='new-accounts' element={<NewAccounts />} />
            <Route path='feature-flags' element={<FeatureFlags />} />
            <Route path='products-scrapers' element={<ProductsScrapers />} />
          </Routes>
        </Col>
      </Row>
    </Container>
  );
};

export default ApplicationSettings;
