import { Container, Tabs, Tab } from 'react-bootstrap';
import LoadBillConfiguration from './LoadBillConfiguration';
import LoadBillConfigurationCompatibility from './LoadBillConfigurationCompatibility';

const LoadBillSettings = () => (
  <Container>
    <Tabs defaultActiveKey='configuration' id='load-bill-tab' className='mb-3'>
      <Tab eventKey='configuration' title='Konfiguracja'>
        <LoadBillConfiguration />
      </Tab>
      <Tab eventKey='compatibility' title='Zgodność'>
        <LoadBillConfigurationCompatibility />
      </Tab>
    </Tabs>
  </Container>
);

export default LoadBillSettings;
