import { Route, Routes } from 'react-router-dom';
import Login from './Login';
import Logout from './Logout';

const ApiAuthorizationRoutes = () => (
  <Routes>
    <Route path='login' element={<Login action='login' />} />
    <Route path='login-failed' element={<Login action='login-failed' />} />
    <Route path='login-callback' element={<Login action='login-callback' />} />
    <Route path='profile' element={<Login action='profile' />} />
    <Route path='register' element={<Login action='register' />} />
    <Route path='logout' element={<Logout action='logout' />} />
    <Route path='logout-callback' element={<Logout action='logout-callback' />} />
    <Route path='logged-out' element={<Logout action='logged-out' />} />
  </Routes>
);

export default ApiAuthorizationRoutes;
