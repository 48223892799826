import React, { useState, useCallback, useContext } from 'react';
import { Container } from 'react-bootstrap';
import Common from '../../shared/Common';
import Bill from '../bill/Bill';
import type { IBill } from '../../../interfaces/IBill';
import BillsTable from './BillsTable';
import { DictionaryContext } from '../../../App';
import type { IPaginationProps } from './BillsTable';
import type { ISummaryElement } from '../../../interfaces/ISummaryElement';

import './style.css';

export interface IBillsProps {
  summaryElementsSelection: boolean;
  selectedSummaryElements?: ISummaryElement[];
  setSelectedSummaryElements?: React.Dispatch<React.SetStateAction<ISummaryElement[]>>;
}

const Bills = (props: IBillsProps) => {
  const [bills, setBills] = useState<IBill[]>([]);
  const [showBillElements, setShowBillElements] = useState<boolean>(props.summaryElementsSelection);
  const [showGroups, setShowGroups] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const [pageCount, setPageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [modalShow, setModalShow] = useState(false);
  const [selectedBillId, setSelectedBillId] = useState(0);
  const [uploadedBillPictureBase64, setUploadedBillPictureBase64] = useState<string | null>(null);
  const [fetchTrigger, setFetchTrigger] = useState(0);
  const fetchIdRef = React.useRef(0);
  const { predictionModel } = useContext(DictionaryContext);

  const fetchData = useCallback(
    async ({ pageIndex, pageSize, sortBy, filters, showBillElements }: IPaginationProps) => {
      setLoading(true);
      const fetchId = ++fetchIdRef.current;
      const filterRequest = filters.map((f) => '&' + f.id + 'Filter=' + f.value).join('');
      var sortByRequest = '';
      if (sortBy.length > 0) {
        sortByRequest = sortBy.map((s) => '&sortBy=' + s.id + (s.desc ? ' DESC' : ' ASC')).join('');
      } else {
        sortByRequest = '&sortBy=billId DESC';
      }
      const requestUrl =
        'api/bills/getbills?showBillElements=' +
        showBillElements +
        '&pageIndex=' +
        pageIndex +
        '&pageSize=' +
        pageSize +
        filterRequest +
        sortByRequest;
      const response = await Common.authorizedFetch(requestUrl, undefined, true);
      const data = await response.json();
      if (data.success) {
        if (fetchId === fetchIdRef.current) {
          if ((data.result.bills?.length ?? 0) > 0) {
            setBills(data.result.bills);
            setPageCount(Math.ceil(data.result.bills[0].totalCount / pageSize));
            setTotalCount(data.result.bills[0].totalCount);
            setTotalCost(data.result.bills[0].allBillsCost);
          } else {
            setBills([]);
            setPageCount(0);
          }
          setLoading(false);
        }
      }
    },
    []
  );

  const showEditBillModal = (billId: number, uploadedBillPictureBase64: string | null) => {
    if (!props.summaryElementsSelection) {
      setSelectedBillId(billId);
      setUploadedBillPictureBase64(uploadedBillPictureBase64);
      setModalShow(true);
    }
  };

  return (
    <Container className='mt-2'>
      {!props.summaryElementsSelection &&
        (showBillElements ? (
          <h2 className='mb-3' id='tabelLabel'>
            Elementy rachunków
          </h2>
        ) : (
          <h2 className='mb-3' id='tabelLabel'>
            Rachunki
          </h2>
        ))}
      <BillsTable
        data={bills}
        loading={loading}
        totalCount={totalCount}
        totalPageCount={pageCount}
        totalCost={totalCost}
        fetchData={fetchData}
        fetchTrigger={fetchTrigger}
        showEditBillModal={showEditBillModal}
        showBillElements={showBillElements}
        setShowBillElements={setShowBillElements}
        showGroups={showGroups}
        setShowGroups={setShowGroups}
        summaryElementsSelection={props.summaryElementsSelection}
        selectedSummaryElements={props.selectedSummaryElements}
        setSelectedSummaryElements={props.setSelectedSummaryElements}
      />
      <Bill
        show={modalShow}
        showGroups={showGroups}
        setShowGroups={setShowGroups}
        onHide={() => {
          setModalShow(false);
          setSelectedBillId(0);
          setUploadedBillPictureBase64(null);
        }}
        onBillSave={() => {
          setFetchTrigger(fetchTrigger + 1);
        }}
        onBillAdd={() => {
          setFetchTrigger(fetchTrigger + 1);
        }}
        onBillRemove={() => {
          setFetchTrigger(fetchTrigger + 1);
        }}
        billId={selectedBillId}
        pictureToLoadBill={uploadedBillPictureBase64}
        predictionModel={predictionModel}
      />
    </Container>
  );
};

export default Bills;
