import React, { useCallback, useEffect, useState } from 'react';
import Common from '../../shared/Common';
import { Breadcrumb, Row } from 'react-bootstrap';
import CompanyProductsSummaryTable from './CompanyProductsSummaryTable';
import type { ICompanyProductSummary } from '../../../interfaces/ICompanyProductSummary';
import { Link, useParams } from 'react-router-dom';
import CompanyProductsTable from './CompanyProductsTable';
import type { IPricesView } from '../../../interfaces/IPricesView';
import { CurrentPriceView } from '../../../interfaces/IPricesView';
import ProductDetails from './ProductDetails';

import './style.css';

const Prices = () => {
  const [currentViewParams, setCurrentViewParams] = useState<IPricesView>({
    currentView: CurrentPriceView.CompaniesSummary,
    selectedCompany: undefined,
    selectedLocationProductId: undefined,
  });
  const [companySummaries, setCompanySummaries] = useState<ICompanyProductSummary[]>([]);
  const [selectedProductName, setSelectedProductName] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  const params = useParams();

  useEffect(() => {
    if (params.locationProductId && params.company) {
      setCurrentViewParams({
        currentView: CurrentPriceView.ProductsDetails,
        selectedLocationId: parseInt(params.locationId ?? '0'),
        selectedCompany: Common.Utils.camelCaseToTitleCase(params.company),
        selectedLocationProductId: parseInt(params.locationProductId!),
      });
    }
    // przypadek prawdopodobnie nigdy nie wystąpi, bo skoro mamy productId,
    // to w url powinien być company
    if (params.locationProductId && !params.company) {
      setCurrentViewParams({
        currentView: CurrentPriceView.ProductsDetails,
        selectedLocationId: undefined,
        selectedCompany: undefined,
        selectedLocationProductId: parseInt(params.locationProductId!),
      });
    }
    if (!params.locationProductId && params.company) {
      setCurrentViewParams({
        currentView: CurrentPriceView.CompanyProducts,
        selectedLocationId: parseInt(params.locationId ?? '0'),
        selectedCompany: Common.Utils.camelCaseToTitleCase(params.company),
        selectedLocationProductId: undefined,
      });
    }
    if (!params.locationProductId && !params.company) {
      setCurrentViewParams({
        currentView: CurrentPriceView.CompaniesSummary,
        selectedLocationId: undefined,
        selectedCompany: undefined,
        selectedLocationProductId: undefined,
      });
    }
  }, [params.company, params.locationId, params.locationProductId]);

  const fetchIdRef = React.useRef(0);

  const generatePriceBreadcrumb = () => {
    switch (currentViewParams.currentView) {
      case CurrentPriceView.CompaniesSummary:
        return (
          <Breadcrumb className='mt-2'>
            <Breadcrumb.Item key='level-0' active>
              Ceny
            </Breadcrumb.Item>
          </Breadcrumb>
        );
      case CurrentPriceView.CompanyProducts:
        return (
          <Breadcrumb className='mt-2'>
            <Breadcrumb.Item key='level-0' linkAs={Link} linkProps={{ to: '/prices' }}>
              Ceny
            </Breadcrumb.Item>
            <Breadcrumb.Item key='level-1' active>
              {currentViewParams.selectedCompany}
            </Breadcrumb.Item>
          </Breadcrumb>
        );
      case CurrentPriceView.ProductsDetails:
        return (
          <Breadcrumb className='mt-2'>
            <Breadcrumb.Item key='level-0' linkAs={Link} linkProps={{ to: '/prices' }}>
              Ceny
            </Breadcrumb.Item>
            <Breadcrumb.Item
              key='level-1'
              linkAs={Link}
              linkProps={{
                to:
                  '/prices/' +
                  Common.Utils.titleCaseToCamelCase(currentViewParams.selectedCompany ?? '') +
                  '/' +
                  currentViewParams.selectedLocationId,
              }}
            >
              {currentViewParams.selectedCompany}
            </Breadcrumb.Item>
            <Breadcrumb.Item key='level-2' active>
              {selectedProductName ? selectedProductName : 'Ładowanie...'}
            </Breadcrumb.Item>
          </Breadcrumb>
        );
    }
  };

  const fetchData = useCallback(async () => {
    // gdy mamy wybrany produkt lub sklep, to nie ładujemy podsumowań
    if (params.company || params.locationProductId) {
      return;
    }
    // gdy załadowaliśmy już podsumowania, to nie robimy tego ponownie
    if (companySummaries.length > 0) {
      return;
    }
    setLoading(true);
    const fetchId = ++fetchIdRef.current;
    const response = await Common.authorizedFetch('api/prices/getCompanyProductsSummary');
    const data = await response.json();
    if (data.success) {
      if (fetchId === fetchIdRef.current) {
        setCompanySummaries(data.result.companySummaries);
      }
    }
    setLoading(false);
  }, [params.locationProductId, params.company, companySummaries.length]);

  return (
    <>
      {generatePriceBreadcrumb()}
      <Row className='mb-3'>
        <div className={currentViewParams.currentView === CurrentPriceView.CompaniesSummary ? '' : 'd-none'}>
          <h5>Wybierz sklep</h5>
          <CompanyProductsSummaryTable data={companySummaries} loading={loading} fetchData={fetchData} />
        </div>
        <div className={currentViewParams.currentView === CurrentPriceView.CompanyProducts ? '' : 'd-none'}>
          <h5>Produkty {currentViewParams.selectedCompany}</h5>
          <CompanyProductsTable pricesViewParams={currentViewParams} />
        </div>
        <div className={currentViewParams.currentView === CurrentPriceView.ProductsDetails ? '' : 'd-none'}>
          <h5>Szczegóły produktu</h5>
          <ProductDetails currentViewParams={currentViewParams} setSelectedProductName={setSelectedProductName} />
        </div>
      </Row>
    </>
  );
};

export default Prices;
