import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import React, { useState, useEffect } from 'react';
import { Container, Form, Button, Navbar, Nav } from 'react-bootstrap';
import Common from '../../shared/Common';
import WarningModal from '../../shared/shared-modals/WarningModal';
import type { IQuantityType } from '../../../interfaces/IQuantityType';
import type { ColumnDef, SortingState } from '@tanstack/react-table';
import type { IQuantityTypeData } from '../../../interfaces/IQuantityTypeData';
import { SortUpIcon, SortDownIcon } from '../../shared/icons/Svgs';

import './style.css';

export interface ITableProps {
  columns: ColumnDef<IQuantityTypeData>[];
  data: IQuantityTypeData[];
  loading: boolean;
  fetchData: () => Promise<void>;
  fetchTrigger: number;
  setFetchTrigger: React.Dispatch<React.SetStateAction<number>>;
  setShowSpinner: React.Dispatch<React.SetStateAction<boolean>>;
  updateQuantityTypeName: (rowIndex: number, columnId: string, value: any) => void;
  updateQuantityTypeShortName: (rowIndex: number, columnId: string, value: any) => void;
  updatedQuantityTypes: IQuantityType[];
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  saveButtonDisabled: boolean;
  removeButtonDisabled: boolean;
  setSaveButtonDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  setRemoveButtonDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  selectedRowId: number;
  setSelectedRowId: React.Dispatch<React.SetStateAction<number>>;
  selectedQuantityType: IQuantityTypeData | null;
  setSelectedQuantityType: React.Dispatch<React.SetStateAction<IQuantityTypeData | null>>;
}

export interface IEditableCellProps {
  value: any;
  row: any;
  column: any;
  updateQuantityTypeName: (rowIndex: number, columnId: string, value: any) => void;
}

function QuantityTypesTable(props: ITableProps) {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [showWarningModal, setShowWarningModal] = useState(false);

  const defaultColumn: Partial<ColumnDef<IQuantityTypeData>> = {
    cell: function Cell({ getValue, row: { index }, column: { id }, table }) {
      const initialValue = getValue();
      const [value, setValue] = React.useState(initialValue);

      const onBlur = () => {
        table.options.meta?.updateData?.(index, id, value);
      };

      const onChange = (e: any) => {
        if (e.target.value === '') props.setSaveButtonDisabled(true);
        else props.setSaveButtonDisabled(false);

        setValue(e.target.value);
      };

      React.useEffect(() => {
        setValue(initialValue);
      }, [initialValue]);

      return <Form.Control type='text' onChange={onChange} onBlur={onBlur} value={value as string} />;
    },
  };

  const table = useReactTable({
    data: props.data,
    columns: props.columns,
    defaultColumn,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    meta: {
      updateData: (rowIndex, columnId, value) => {
        switch (columnId) {
          case 'name':
            props.updateQuantityTypeName(rowIndex, columnId, value);
            break;
          case 'shortName':
            props.updateQuantityTypeShortName(rowIndex, columnId, value);
            break;
        }
      },
    },
  });

  const { fetchData, fetchTrigger } = props;

  useEffect(() => {
    fetchData();
  }, [fetchData, fetchTrigger]);

  const saveQuantityTypes = async () => {
    props.setShowSpinner(true);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ quantityTypes: props.updatedQuantityTypes }),
    };
    props.setSaveButtonDisabled(true);
    const response = await Common.authorizedFetch('api/quantityTypes/saveQuantityTypes', requestOptions);
    const data = await response.json();
    if (!data.success) {
      alert(data.errors);
    } else {
      props.setFetchTrigger(props.fetchTrigger + 1);
    }
    props.setShowSpinner(false);
  };

  const onTableRowClick = (index: number, quantityType: IQuantityTypeData) => {
    props.setSelectedRowId(index);
    props.setSelectedQuantityType(quantityType);
    if (
      quantityType.count === 0 &&
      quantityType.quantityType.quantityTypeId !== 1 &&
      quantityType.quantityType.quantityTypeId !== 3
    ) {
      props.setRemoveButtonDisabled(false);
    } else {
      props.setRemoveButtonDisabled(true);
    }
  };

  const removeQuantityType = async () => {
    props.setShowSpinner(true);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        quantityTypeId: props.selectedQuantityType?.quantityType.quantityTypeId,
      }),
    };
    const response = await Common.authorizedFetch('api/quantityTypes/removeQuantityType', requestOptions);
    const data = await response.json();
    if (!data.success) {
      alert(data.errors);
    } else {
      props.setFetchTrigger(props.fetchTrigger + 1);
      setShowWarningModal(false);
    }
    props.setShowSpinner(false);
  };

  return (
    <>
      <Navbar style={{ backgroundColor: 'transparent' }} variant='light' fixed='bottom'>
        <Container>
          <Nav className='me-auto' />
          <Nav>
            <Button
              disabled={props.removeButtonDisabled}
              className='me-2'
              variant='danger'
              onClick={() => {
                setShowWarningModal(true);
              }}
            >
              Usuń
            </Button>
            <Button
              className='me-2'
              variant='success'
              onClick={() => {
                props.setShowModal(true);
              }}
            >
              Dodaj
            </Button>
            <Button
              disabled={props.saveButtonDisabled}
              className='me-2'
              variant='dark'
              onClick={() => {
                saveQuantityTypes();
              }}
            >
              Zapisz
            </Button>
          </Nav>
        </Container>
      </Navbar>
      <div className='mt-1 pb-5 table-responsive-sm'>
        <table id='quantity-types-table' className='table'>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          style: header.column.getCanSort()
                            ? {
                                cursor: 'pointer',
                              }
                            : {},
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: <SortUpIcon style={{ marginLeft: '5px' }} />,
                          desc: <SortDownIcon style={{ marginLeft: '5px' }} />,
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {props.loading
              ? Common.Ui.getSkeletonTableRows(6, 4, 35)
              : table.getRowModel().rows.map((row) => (
                  <tr
                    key={row.id}
                    className={props.selectedRowId === row.index ? 'selected-row' : undefined}
                    onClick={() => {
                      onTableRowClick(row.index, row.original);
                    }}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                    ))}
                  </tr>
                ))}
          </tbody>
        </table>
      </div>
      <WarningModal
        show={showWarningModal}
        modalBodyText={`Czy na pewno chcesz usunąć typ liczności '${props.selectedQuantityType?.quantityType.name}'?`}
        onHide={() => {
          setShowWarningModal(false);
        }}
        onConfirmation={() => removeQuantityType()}
      />
    </>
  );
}

export default QuantityTypesTable;
