import './style.css';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link, Navigate } from 'react-router-dom';
import Common from '../../../shared/Common';
import type { IUserSettingsChildProps } from '../UserSettings';

const GenerateRecoveryCodes = (props: IUserSettingsChildProps) => {
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [generatedCodes, setGeneratedCodes] = useState<string[]>([]);
  const { setStatus, fetchUserDataTrigger, setFetchUserDataTrigger } = props;

  const generateCodes = async () => {
    setFormLoading(true);
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify({}),
      headers: { 'Content-Type': 'application/json' },
    };
    const response = await Common.authorizedFetch('api/twoFactorAuthentication/GenerateRecoveryCodes', requestOptions);
    const data = await response.json();
    setFormLoading(false);
    if (!data.success) {
      setStatus({
        variant: 'error',
        status: data.errors,
        messageTime: Date.now(),
      });
    } else {
      var generatedCodes = data.result?.generatedCodes;
      if (generatedCodes?.length > 0) {
        setGeneratedCodes(generatedCodes);
      }
      setStatus({
        variant: 'success',
        status: data.informations,
        messageTime: Date.now(),
      });
      setFetchUserDataTrigger(fetchUserDataTrigger + 1);
    }
  };

  return (
    <>
      {generatedCodes?.length > 0 && (
        <Navigate to={{ pathname: '/settings/user/2fa/show-recovery-codes' }} state={{ generatedCodes }} replace />
      )}
      {Common.Ui.showLoadingSpinnerFixed(formLoading)}
      <h4>Wygeneruj kody odzyskiwania do uwierzytelnienia dwuskładnikowego (2FA)</h4>
      <div className='alert alert-warning' role='alert'>
        <p>
          <strong>Odłóż te kody w bezpieczne miejsce.</strong>
        </p>
        <p>
          Jeśli zgubisz swoje urządzenie i nie będziesz mieć dostępu do kodów odzyskiwania, stracisz dostęp do swojego
          konta.
        </p>
        <p>
          Wygenerowanie nowych kodów odzyskania nie zmieni klucza uwierzytelniającego w aplikacji 2FA. Jeśli chcesz
          zmienić klucz uwierzytelniający&nbsp;
          <Link to='/settings/user/2fa/reset-authenticator'>możesz to zrobić wchodząc w ten link</Link>.
        </p>
      </div>
      <Button variant='danger' onClick={generateCodes} disabled={formLoading}>
        Wygeneruj kody odzyskiwania
      </Button>
    </>
  );
};

export default GenerateRecoveryCodes;
