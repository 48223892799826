export enum CurrentPriceView {
  CompaniesSummary,
  CompanyProducts,
  ProductsDetails,
}

export interface IPricesView {
  currentView: CurrentPriceView;
  selectedLocationId?: number;
  selectedCompany?: string;
  selectedLocationProductId?: number;
}
