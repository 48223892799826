import { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import DashboardContent from './DashboardContent';
import DashboardNavigation from './DashboardNavigation';

import './style.css';

const Dashboard = () => {
  const [bookmarkActive, setBookmarkActive] = useState(false);
  const location = useLocation();
  const query = location.search;
  useEffect(() => {
    if (query && query.includes('show-menu=1')) {
      setBookmarkActive(true);
    } else {
      setBookmarkActive(false);
    }
  }, [query]);

  return (
    <>
      <Container className='dashboard'>
        <Row>
          <Col md={0} lg={4} xl={3} className={bookmarkActive ? '' : 'd-none d-lg-block '}>
            <DashboardNavigation />
          </Col>
          {!bookmarkActive && <DashboardContent />}
        </Row>
      </Container>
    </>
  );
};

export default Dashboard;
