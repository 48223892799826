import React, { useContext } from 'react';
import { NavDropdown } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { UserContext } from '../App';
import './NavMenu.css';
import { renderHomeIcon, renderReportsIcon, renderSettingsIcon, renderBookmarksIcon } from './shared/icons/SvgRenders';

const NavMenu = () => {
  const [homeActive, setHomeActive] = React.useState(false);
  const [reportsActive, setReportsActive] = React.useState(false);
  const [settingsActive, setSettingsActive] = React.useState(false);
  const [bookmarkActive, setBookmarkActive] = React.useState(false);
  const { user, authenticated } = useContext(UserContext);
  const location = useLocation();

  React.useEffect(() => {
    const url = location.pathname;
    const query = location.search;
    if (query && query.includes('show-menu=1')) {
      setBookmarkActive(true);
    } else {
      setBookmarkActive(false);
    }
    if (url) {
      if (url.includes('dictionaries') || url.includes('bills')) {
        setHomeActive(true);
        setReportsActive(false);
        setSettingsActive(false);
      } else if (url.includes('reports')) {
        setHomeActive(false);
        setReportsActive(true);
        setSettingsActive(false);
        return;
      } else if (url.includes('settings')) {
        setHomeActive(false);
        setReportsActive(false);
        setSettingsActive(true);
        return;
      } else {
        setHomeActive(true);
        setReportsActive(false);
        setSettingsActive(false);
      }
    }
  }, [location]);

  return (
    <>
      <div>
        <div
          style={{
            zIndex: 3,
            top: 0,
            left: 0,
            position: 'fixed',
            marginLeft: '16px',
            height: '56px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Link
            style={{
              textDecoration: 'none',
              WebkitUserSelect: 'none',
            }}
            to='/'
          >
            <img alt='logo' src='/Static/icons/logo.png' style={{ height: '36px', width: '36px' }} />
            <span
              className='header-hide-on-small-devices'
              style={{
                color: '#1C1E21',
                fontSize: 25,
                fontWeight: 500,
                paddingLeft: '10px',
                verticalAlign: 'middle',
              }}
            >
              Pan Rachunek
            </span>
          </Link>
        </div>
        <div
          style={{
            zIndex: 1,
            top: 0,
            left: 0,
            right: 0,
            position: 'fixed',
          }}
        >
          <div style={{ backgroundColor: '#FFFFFF' }}>
            <div>
              <div style={{ height: '56px' }}>
                <ul className='header-central-list header-transform-on-small-devices'>
                  <li className={(homeActive ? '' : 'header-action-link ') + 'header-central-list-element'}>
                    <div
                      id='home-button'
                      style={{
                        height: '56px',
                        position: 'relative',
                      }}
                    >
                      {homeActive && (
                        <div
                          style={{
                            backgroundColor: 'var(--app-main-color)',
                            borderTopRightRadius: '1px',
                            borderTopLeftRadius: '1px',
                            bottom: 0,
                            right: '2px',
                            height: '3px',
                            position: 'absolute',
                            left: '2px',
                          }}
                        />
                      )}
                      <Link
                        aria-label='Strona główna'
                        style={{
                          alignItems: 'center',
                          display: 'flex',
                          height: '100%',
                          justifyContent: 'center',
                        }}
                        to='/'
                      >
                        {renderHomeIcon(
                          homeActive,
                          {
                            color: 'var(--app-main-color)',
                            height: '32px',
                            width: '32px',
                          },
                          {
                            color: 'var(--app-secondary-color)',
                            height: '32px',
                            width: '32px',
                          }
                        )}
                      </Link>
                    </div>
                  </li>
                  <li
                    className={
                      (reportsActive ? '' : 'header-action-link ') +
                      'header-central-list-element header-central-list-element-margin-left'
                    }
                  >
                    <div
                      id='reports-button'
                      style={{
                        height: '56px',
                        position: 'relative',
                      }}
                    >
                      {reportsActive && (
                        <div
                          style={{
                            backgroundColor: 'var(--app-main-color)',
                            borderTopRightRadius: '1px',
                            borderTopLeftRadius: '1px',
                            bottom: 0,
                            right: '2px',
                            height: '3px',
                            position: 'absolute',
                            left: '2px',
                          }}
                        />
                      )}
                      <Link
                        aria-label='Raporty'
                        style={{
                          alignItems: 'center',
                          display: 'flex',
                          height: '100%',
                          justifyContent: 'center',
                        }}
                        to='/reports'
                      >
                        {renderReportsIcon(
                          reportsActive,
                          {
                            color: 'var(--app-main-color)',
                            height: '28px',
                            width: '28px',
                          },
                          {
                            color: 'var(--app-secondary-color)',
                            height: '28px',
                            width: '28px',
                          }
                        )}
                      </Link>
                    </div>
                  </li>
                  <li
                    className={
                      (settingsActive ? '' : 'header-action-link ') +
                      'header-central-list-element header-central-list-element-margin-left'
                    }
                  >
                    <div
                      id='settings-button'
                      style={{
                        height: '56px',
                        position: 'relative',
                      }}
                    >
                      {settingsActive && (
                        <div
                          style={{
                            backgroundColor: 'var(--app-main-color)',
                            borderTopRightRadius: '1px',
                            borderTopLeftRadius: '1px',
                            bottom: 0,
                            right: '2px',
                            height: '3px',
                            position: 'absolute',
                            left: '2px',
                          }}
                        />
                      )}
                      <Link
                        aria-label='Ustawienia'
                        style={{
                          alignItems: 'center',
                          display: 'flex',
                          height: '100%',
                          justifyContent: 'center',
                        }}
                        to={user?.isAdmin() ? '/settings/application/user-roles' : '/settings/user/profile'}
                      >
                        {renderSettingsIcon(
                          settingsActive,
                          {
                            color: 'var(--app-main-color)',
                            height: '37px',
                            width: '37px',
                          },
                          {
                            color: 'var(--app-secondary-color)',
                            height: '28px',
                            width: '28px',
                          }
                        )}
                      </Link>
                    </div>
                  </li>
                  <li
                    className={
                      (bookmarkActive ? '' : 'header-action-link ') +
                      'header-central-list-element header-central-list-element-margin-left header-hide-on-not-small-devices'
                    }
                  >
                    <div
                      id='bookmark-button'
                      style={{
                        height: '56px',
                        position: 'relative',
                      }}
                    >
                      {bookmarkActive && (
                        <div
                          style={{
                            backgroundColor: 'var(--app-main-color)',
                            borderTopRightRadius: '1px',
                            borderTopLeftRadius: '1px',
                            bottom: 0,
                            right: '2px',
                            height: '3px',
                            position: 'absolute',
                            left: '2px',
                          }}
                        />
                      )}
                      <Link
                        aria-label='Więcej'
                        style={{
                          alignItems: 'center',
                          display: 'flex',
                          height: '100%',
                          justifyContent: 'center',
                        }}
                        to={(() => {
                          if (reportsActive || homeActive) {
                            return bookmarkActive ? '?' : '?show-menu=1';
                          } else {
                            // w przypadku, gdy aktywna zakładka to nie reports lub home przechodzimy do dashboard
                            return bookmarkActive ? '/dashboard' : '/dashboard?show-menu=1';
                          }
                        })()}
                      >
                        {renderBookmarksIcon(
                          bookmarkActive,
                          {
                            color: 'var(--app-main-color)',
                            height: '28px',
                            width: '28px',
                          },
                          {
                            color: 'var(--app-secondary-color)',
                            height: '28px',
                            width: '28px',
                          }
                        )}
                      </Link>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            {!reportsActive && (
              <div
                style={{
                  backgroundImage:
                    'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAAOBAMAAAD3WtBsAAAAFVBMVEUAAAAAAAAAAAAAAAAAAAAAAAD29va1cB7UAAAAB3RSTlMCCwQHGBAaZf6MKAAAABpJREFUCNdjSGNIY3BhCGUQBEJjIFQCQigAACyJAjLNW4w5AAAAAElFTkSuQmCC)',
                  backgroundRepeat: 'repeat-x',
                  backgroundSize: '1px 7px',
                  bottom: '-6px',
                  right: 0,
                  height: '7px',
                  pointerEvents: 'none',
                  position: 'absolute',
                  left: 0,
                }}
              />
            )}
          </div>
        </div>
        <div
          style={{
            zIndex: 3,
            top: 0,
            position: 'fixed',
            paddingRight: '16px',
            right: 0,
          }}
        >
          <div
            id='profile-button'
            aria-label='Ustawienia konta'
            style={{
              height: '56px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {authenticated && (
              <NavDropdown
                className='ml-2 avatar-header-toggle'
                id='collasible-nav-dropdown'
                title={
                  <div
                    style={{
                      position: 'relative',
                      display: 'flex',
                    }}
                  >
                    <div role='button'>
                      <svg
                        aria-label='Twój profil'
                        style={{
                          height: '40px',
                          width: '40px',
                        }}
                      >
                        <mask id='jsc_c_c'>
                          <circle cx='20' cy='20' fill='white' r='20' />
                        </mask>
                        <g mask='url(#jsc_c_c)'>
                          <image
                            style={{
                              height: '40px',
                              width: '40px',
                            }}
                            x='0'
                            y='0'
                            height='100%'
                            preserveAspectRatio='xMidYMid slice'
                            width='100%'
                            xlinkHref={
                              user?.profilePicture === '' ||
                              user?.profilePicture === undefined ||
                              user?.profilePicture === null
                                ? '/Static/icons/avatar.png'
                                : user?.profilePicture
                            }
                          />
                          <circle
                            style={{
                              fill: 'none',
                              stroke: 'rgba(0, 0, 0, 0.1)',
                              strokeWidth: 2,
                            }}
                            cx='20'
                            cy='20'
                            r='20'
                          />
                        </g>
                      </svg>
                    </div>
                  </div>
                }
              >
                <span className='dropdown-item-text'>
                  Zalagowany jako <b>{user?.userName}</b>
                </span>
                <NavDropdown.Divider />
                <NavDropdown.Item as={Link} to={'/settings/user/profile'}>
                  Ustawienia konta
                </NavDropdown.Item>
                {user?.isAdmin() && (
                  <NavDropdown.Item as={Link} to={'/settings/application/user-roles'}>
                    Ustawienia aplikacji
                  </NavDropdown.Item>
                )}
                <NavDropdown.Item as={Link} to={{ pathname: '/authentication/logout' }} state={{ local: true }}>
                  Wyloguj
                </NavDropdown.Item>
              </NavDropdown>
            )}
          </div>
          <Tooltip
            anchorId='settings-button'
            content={user?.isAdmin() ? 'Ustawienia aplikacji' : 'Ustawienia użytkownika'}
            place='bottom'
          />
          <Tooltip anchorId='bookmark-button' content='Więcej' place='bottom' />
          <Tooltip anchorId='home-button' content='Strona główna' place='bottom' />
          <Tooltip anchorId='reports-button' content='Raporty' place='bottom' />
          <Tooltip anchorId='profile-button' content='Konto' place='bottom' />
        </div>
      </div>
    </>
  );
};

export default NavMenu;
