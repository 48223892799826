import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import type { ICategoryName } from '../../../interfaces/ICategoryName';
import type { ILocationData } from '../../../interfaces/ILocationData';
import LocationMappingsTable from './LocationMappingsTable';

import './style.css';

export interface ILocationMappingsModalProps {
  show: boolean;
  locationData: ILocationData | undefined;
  setSelectedLocationMappings: React.Dispatch<React.SetStateAction<ILocationData | undefined>>;
  categoriesNames: ICategoryName[];
  updateLocationMappings: (value: ILocationData) => void;
  onHide: () => void;
}

function LocationMappingsModal(props: ILocationMappingsModalProps) {
  return (
    <>
      <Modal show={props.show} onHide={props.onHide} aria-labelledby='contained-modal-title-vcenter' size='lg'>
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            Mapowania lokalizacji {props.locationData?.location?.locationName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LocationMappingsTable
            show={props.show}
            locationData={props.locationData}
            setSelectedLocationMappings={props.setSelectedLocationMappings}
            categoriesNames={props.categoriesNames}
            updateLocationMappings={props.updateLocationMappings}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant='dark' onClick={props.onHide}>
            Zamknij
          </Button>
          <Button
            variant='success'
            onClick={() => {
              if (props.locationData !== undefined) {
                props.updateLocationMappings(props.locationData);
              }
              props.onHide();
            }}
          >
            Zatwierdź
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default LocationMappingsModal;
