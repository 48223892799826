import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import React, { useEffect } from 'react';
import type { ColumnDef, SortingState } from '@tanstack/react-table';
import Common from '../../shared/Common';
import { SortUpIcon, SortDownIcon } from '../../shared/icons/Svgs';
import type { ICompanyProductSummary } from '../../../interfaces/ICompanyProductSummary';
import { useNavigate } from 'react-router-dom';

interface ICompanyProductsSummaryTableProps {
  data: ICompanyProductSummary[];
  loading: boolean;
  fetchData: () => Promise<void>;
}

function CompanyProductsSummaryTable(props: ICompanyProductsSummaryTableProps) {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const navigate = useNavigate();

  const columns = React.useMemo<ColumnDef<ICompanyProductSummary>[]>(
    () => [
      {
        accessorFn: (row) => row.companyName,
        id: 'name',
        header: () => <span>Sklep</span>,
        cell: (info) => (
          <div className='d-flex align-items-center'>
            <img src={info.row.original.logoPath} height={75} width={75} alt='logo' style={{ marginRight: '10px' }} />
            <h6>{info.row.original.companyName}</h6>
          </div>
        ),
      },
      {
        accessorFn: (row) => row.productCount,
        id: 'productCount',
        header: () => <span>Produkty</span>,
        cell: (info) => <span>{Common.Utils.getNumberWithSpaces(info.row.original.productCount)}</span>,
      },
      {
        accessorFn: (row) => row.categoryCount,
        id: 'categoryCount',
        header: () => <span>Kategorie</span>,
        cell: (info) => <span>{Common.Utils.getNumberWithSpaces(info.row.original.categoryCount)}</span>,
      },
      {
        accessorFn: (row) => row.lastCompletionDate,
        id: 'lastCompletionDate',
        header: () => <span>Ostatnie sprawdzenie produktów</span>,
        cell: (info) => (
          <span>{Common.Utils.getDateWithTimeWithoutZoneConverter(info.row.original.lastCompletionDate)}</span>
        ),
      },
    ],
    []
  );

  const table = useReactTable({
    data: props.data,
    columns: columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const { fetchData } = props;

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      {Common.Ui.showLoadingSpinnerFixed(props.loading)}
      <div className='table-responsive'>
        <table id='company-summary-table' className='table table-hover'>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          style: header.column.getCanSort() ? { cursor: 'pointer' } : {},
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: <SortUpIcon style={{ marginLeft: '5px' }} />,
                          desc: <SortDownIcon style={{ marginLeft: '5px' }} />,
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {props.loading
              ? Common.Ui.getSkeletonTableRows(8, 4, 35)
              : table.getRowModel().rows.map((row) => (
                  <tr
                    key={row.id}
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      navigate(
                        '/prices/' +
                          Common.Utils.titleCaseToCamelCase(row.original.companyName) +
                          '/' +
                          row.original.defaultLocationId
                      )
                    }
                  >
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                    ))}
                  </tr>
                ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default CompanyProductsSummaryTable;
