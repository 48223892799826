import { useState, useEffect, useCallback, useRef } from 'react';
import { Container, Button } from 'react-bootstrap';
import type { IDefaultLocationPicture } from '../../../../interfaces/IDefaultLocationPicture';
import type { IDefaultLocationPictureCategory } from '../../../../interfaces/IDefaultLocationPictureCategory';
import Common from '../../../shared/Common';
import PictureCategoriesTable from './CategoryPicturesTable';
import PictureLocationsPanel from './PictureLocationsPanel';

const DefaultPictures = () => {
  const [pictureCategories, setPictureCategories] = useState<IDefaultLocationPictureCategory[]>([]);
  const [locationPictures, setLocationPictures] = useState<IDefaultLocationPicture[]>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [dataChanged, setDataChanged] = useState<boolean>(false);
  const [fetchDataTrigger, setFetchDataTrigger] = useState(0);
  const fetchIdRef = useRef(0);

  const fetchData = useCallback(async () => {
    setLoading(true);
    const fetchId = ++fetchIdRef.current;
    const response = await Common.authorizedFetch('api/applicationLogicConfiguration/getDefaultLocationPictures');
    if (fetchId === fetchIdRef.current) {
      const data = await response.json();
      if (data.success) {
        setPictureCategories(data.result.pictureCategories);
        setLocationPictures(data.result.locationPictures);
      }
      setLoading(false);
    }
  }, []);

  const updateData = async () => {
    setLoading(true);
    setDataChanged(false);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        pictureCategories: pictureCategories,
        locationPictures: locationPictures,
      }),
    };
    const response = await Common.authorizedFetch(
      'api/applicationLogicConfiguration/updateDefaultLocationPictures',
      requestOptions
    );
    const data = await response.json();
    if (!data.success) {
      alert(data.errors);
      setLoading(false);
    } else {
      setFetchDataTrigger((prev) => prev + 1);
    }
  };

  useEffect(() => {
    fetchData();
  }, [fetchData, fetchDataTrigger]);

  return (
    <>
      <h4 className='mb-3'>Domyślne obrazki dla lokalizacji</h4>
      <Container>
        <h5>Kategorie obrazków</h5>
        <PictureCategoriesTable
          loading={loading}
          pictureCategories={pictureCategories}
          locationPictures={locationPictures}
          setPictureCategories={setPictureCategories}
          setDataChanged={setDataChanged}
        />
        <h5>Domyślne obrazki</h5>
        <PictureLocationsPanel
          loading={loading}
          pictureCategories={pictureCategories}
          locationPictures={locationPictures}
          setLocationPictures={setLocationPictures}
          setDataChanged={setDataChanged}
        />
        <Button className='mt-3' variant='primary' onClick={updateData} disabled={!dataChanged}>
          Zapisz
        </Button>
      </Container>
    </>
  );
};

export default DefaultPictures;
