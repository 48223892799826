export class User {
  constructor(userName: string, roles: string[], profilePicture?: string) {
    this.userName = userName;
    this.userRoles = roles;
    this.profilePicture = profilePicture;
  }

  userName: string;
  userRoles: string[];
  profilePicture: string | undefined;

  UserRoleName: string = 'user';
  ReportViewerRoleName: string = 'reportviewer';
  DictionariesManagerRoleName: string = 'dictionariesmanager';
  RolesManagerRoleName: string = 'rolesmanager';
  AdminRoleName: string = 'admin';

  isUser = () => this.userRoles.findIndex((r) => r === this.UserRoleName) > -1;
  isReportViewer = () => this.userRoles.findIndex((r) => r === this.ReportViewerRoleName) > -1;
  isDictionariesManager = () => this.userRoles.findIndex((r) => r === this.DictionariesManagerRoleName) > -1;
  isRolesManager = () => this.userRoles.findIndex((r) => r === this.RolesManagerRoleName) > -1;
  isAdmin = () => this.userRoles.findIndex((r) => r === this.AdminRoleName) > -1;
}
