import { useState } from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import Common from '../../shared/Common';

import './style.css';

export interface IAddQuantityTypeModalProps {
  show: boolean;
  onHide: () => void;
  onSave: () => void;
}

function AddQuantityTypeModal(props: IAddQuantityTypeModalProps) {
  const [quantityTypeName, setQuantityTypeName] = useState<string>('');
  const [quantityTypeShortName, setQuantityTypeShortName] = useState<string>('');

  const addQuantityType = async () => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ quantityTypeName, quantityTypeShortName }),
    };
    const response = await Common.authorizedFetch('api/quantityTypes/addQuantityType', requestOptions);
    const data = await response.json();
    if (!data.success) {
      alert(data.errors);
    } else {
      setQuantityTypeName('');
      setQuantityTypeShortName('');
      props.onHide();
      props.onSave();
    }
  };

  return (
    <Modal centered show={props.show} onHide={props.onHide} aria-labelledby='contained-modal-title-vcenter'>
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>Dodaj nowy typ liczności</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId='formBasicQuantityTypeName'>
            <Form.Label>Nazwa typu liczności</Form.Label>
            <Form.Control
              type='text'
              placeholder='Wpisz nazwę'
              onChange={(e: any) => {
                setQuantityTypeName(e.target.value);
              }}
            />
            <Form.Text className='text-muted'>
              Nazwa typu liczności określająca ilość zakupionych produktów (np. kilogram).
            </Form.Text>
          </Form.Group>
          <Form.Group controlId='formBasicQuantityTypeShortName'>
            <Form.Label>Skrócona nazwa typu liczności</Form.Label>
            <Form.Control
              type='text'
              placeholder='Wpisz skróconą nazwę'
              onChange={(e: any) => {
                setQuantityTypeShortName(e.target.value);
              }}
            />
            <Form.Text className='text-muted'>
              Skrócona nazwa typu liczności określająca ilość zakupionych produktów (np. kg).
            </Form.Text>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='dark' onClick={props.onHide}>
          Zamknij
        </Button>
        <Button variant='success' onClick={addQuantityType}>
          Zapisz
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddQuantityTypeModal;
