import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import React, { useEffect } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import Common from '../../shared/Common';
import type { IRecurringOperation } from '../../../interfaces/IRecurringOperation';
import type { ColumnDef, SortingState } from '@tanstack/react-table';
import { SortUpIcon, SortDownIcon } from '../../shared/icons/Svgs';

import './style.css';

export interface ITableProps {
  data: IRecurringOperation[];
  loading: boolean;
  fetchData: () => Promise<void>;
  fetchTrigger: number;
  setFetchTrigger: React.Dispatch<React.SetStateAction<number>>;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  selectedRowId: number;
  setSelectedRowId: React.Dispatch<React.SetStateAction<number>>;
  selectedRecurringOperation: IRecurringOperation | null;
  setSelectedRecurringOperation: React.Dispatch<React.SetStateAction<IRecurringOperation | null>>;
}

function RecurringOperationsTable(props: ITableProps) {
  const [sorting, setSorting] = React.useState<SortingState>([]);

  const columns: ColumnDef<IRecurringOperation>[] = React.useMemo<ColumnDef<IRecurringOperation>[]>(
    () => [
      {
        accessorFn: (row) => row.location?.locationName,
        id: 'locationName',
        header: () => <span>Lokalizacja</span>,
      },
      {
        accessorFn: (row) => row.description,
        id: 'description',
        header: () => <span>Nazwa</span>,
      },
      {
        accessorFn: (row) => row.category?.categoryFullName,
        id: 'category',
        header: () => <span>Kategoria</span>,
      },
      {
        accessorFn: (row) => Common.Utils.getCurrencyString(row.cost),
        enableSorting: false,
        id: 'cost',
        header: () => <span>Koszt</span>,
      },
      {
        accessorFn: (row) => row.interval,
        id: 'intervalTime',
        header: () => <span>Interwał</span>,
      },
      {
        accessorFn: (row) => new Date(row.lastUpdate),
        id: 'lastUpdateDate',
        header: () => <span>Poprzednia aktualizacja</span>,
        cell: (info) => (
          <span>{Common.Utils.getDatetimeWithoutZoneConverter(info.row.original.lastUpdate?.toString())}</span>
        ),
      },
      {
        accessorFn: (row) => new Date(row.nextUpdate),
        id: 'nextUpdateDate',
        header: () => <span>Następna aktualizacja</span>,
        cell: (info) => (
          <span>
            {info.row.original.active
              ? Common.Utils.getDatetimeWithoutZoneConverter(info.row.original.nextUpdate?.toString())
              : ''}
          </span>
        ),
      },
    ],
    []
  );

  const table = useReactTable({
    data: props.data,
    columns: columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const { fetchData, fetchTrigger } = props;

  useEffect(() => {
    fetchData();
  }, [fetchData, fetchTrigger]);

  const onTableRowClick = (index: number, recurringOperation: IRecurringOperation) => {
    props.setSelectedRowId(index);
    props.setSelectedRecurringOperation(recurringOperation);
    props.setShowModal(true);
  };

  return (
    <>
      <Row>
        <Col>
          <Button
            variant='success'
            onClick={() => {
              props.setSelectedRowId(-1);
              props.setSelectedRecurringOperation(null);
              props.setShowModal(true);
            }}
          >
            Dodaj
          </Button>
        </Col>
      </Row>
      <div className='mt-1 pb-1 table-responsive-sm'>
        <table id='recurring-operations-table' className='table table-hover'>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          style: header.column.getCanSort()
                            ? {
                                cursor: 'pointer',
                              }
                            : {},
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: <SortUpIcon style={{ marginLeft: '5px' }} />,
                          desc: <SortDownIcon style={{ marginLeft: '5px' }} />,
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {props.loading
              ? Common.Ui.getSkeletonTableRows(6, 7, 35)
              : table.getRowModel().rows.map((row) => (
                  <tr
                    key={row.id}
                    style={{ cursor: 'pointer' }}
                    className={props.selectedRowId === row.index ? 'selected-row' : undefined}
                    onClick={() => {
                      onTableRowClick(row.index, row.original);
                    }}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                    ))}
                  </tr>
                ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default RecurringOperationsTable;
