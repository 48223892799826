export declare type Variant = 'info' | 'success' | 'error';
export declare type MessageCode = 'AddExternalProviderSuccess' | 'AddExternalProviderFailure' | string;

export class AlertMessages {
  static getMessageByCode = (code: MessageCode): { message: string; variant: Variant } => {
    switch (code) {
      case 'AddExternalProviderSuccess':
        return {
          message: 'Login zewnętrzny został dodany.',
          variant: 'success',
        };
      case 'AddExternalProviderFailure':
        return {
          message: 'Login zewnętrzny nie został dodany. Login zewnętrzny może być powiązany tylko z jednym kontem.',
          variant: 'error',
        };
      case 'ExternalProviderSignInRemoteError':
        return {
          message: 'Wystąpił nieprzewidziany błąd od dostawcy zewnętrznego.',
          variant: 'error',
        };
      case 'ExternalProviderSignInLoadDataError':
        return {
          message: 'Błąd podczas ładowania danych logowania od dostawcy zewnętrznego.',
          variant: 'error',
        };
      default:
        return {
          message: 'Wystąpił nieprzewidziany błąd',
          variant: 'error',
        };
    }
  };
}

export default AlertMessages;
