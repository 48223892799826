import { useState, useEffect, useCallback, useRef } from 'react';
import Common from '../../../shared/Common';

const UserRoles = () => {
  const [userRoles, setUserRoles] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const fetchIdRef = useRef(0);

  const fetchRoleUsers = useCallback(async () => {
    setLoading(true);
    const fetchId = ++fetchIdRef.current;

    const response = await Common.authorizedFetch('api/users/getUserRoles');
    if (fetchId === fetchIdRef.current) {
      const data = await response.json();
      if (data.success) {
        setUserRoles(data.result.rolesNames);
      }
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchRoleUsers();
  }, [fetchRoleUsers]);

  return (
    <>
      <h4>Twoje role w aplikacji</h4>
      {loading ? (
        Common.Ui.getSkeletonListElements(3, 25)
      ) : userRoles.length === 0 ? (
        <p>Aktualnie nie posiadasz żadnych uprawnień.</p>
      ) : (
        <ul className='list-group mb-4'>
          {userRoles.map((r) => (
            <li key={r} className='list-group-item'>
              {r}
            </li>
          ))}
        </ul>
      )}
      <p>Aktualnie działające role w aplikacji:</p>
      <table className='table'>
        <thead className='thead-dark'>
          <tr>
            <th scope='col'>#</th>
            <th scope='col'>Uprawnienie</th>
            <th scope='col'>Dostęp</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope='row'>1</th>
            <td>User</td>
            <td>przeglądanie swoich paragonów, dodawanie paragonów, edycja swoich paragonów</td>
          </tr>
          <tr>
            <th scope='row'>2</th>
            <td>ReportViewer</td>
            <td>przeglądanie swoich raportów</td>
          </tr>
          <tr>
            <th scope='row'>3</th>
            <td>DictionariesManager</td>
            <td>
              dodawanie kategorii, edycja kategorii, dodawanie lokalizacji, edycja lokalizacji, dodawanie typów
              liczności, edycja typów liczności
            </td>
          </tr>
          <tr>
            <th scope='row'>4</th>
            <td>RolesManager</td>
            <td>zarządzanie rolami</td>
          </tr>
          <tr>
            <th scope='row'>5</th>
            <td>Admin</td>
            <td>
              przeglądanie swoich paragonów, dodawanie paragonów, edycja swoich paragonów, dodawanie kategorii, edycja
              kategorii, dodawanie lokalizacji, edycja lokalizacji, dodawanie typów liczności, edycja typów liczności,
              zarządzanie rolami, przeglądanie swoich raportów
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default UserRoles;
