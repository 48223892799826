import { useCallback, useState } from 'react';
import { Button, Container } from 'react-bootstrap';

import Common from '../../../shared/Common';
import ExternalLogins from '../../../shared/external-logins/ExternalLogins';
import type { ILoginProvider } from '../../../../interfaces/ILoginProvider';
import type { IUserSettingsChildProps } from '../UserSettings';

const ExternalLogin = (props: IUserSettingsChildProps) => {
  const [formLoading, setFormLoading] = useState<boolean>(false);

  const identityServerUrl = import.meta.env.VITE_IDENTITY_SERVER_URL ?? '';

  const { setFetchUserDataTrigger, fetchUserDataTrigger, loading, userData, setStatus } = props;

  const removeExternalProvider = useCallback(
    async (provider: ILoginProvider) => {
      setFormLoading(true);
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          loginProvider: provider.loginProvider,
          providerKey: provider.providerKey,
        }),
      };
      const response = await Common.authorizedFetch('idsrv/externalProviders/removeExternalProvider', requestOptions);
      const data = await response.json();
      setFormLoading(false);
      if (!data.success) {
        setStatus({
          variant: 'error',
          status: data.errors,
          messageTime: Date.now(),
        });
      } else {
        setStatus({
          variant: 'success',
          status: data.informations,
          messageTime: Date.now(),
        });
        setFetchUserDataTrigger(fetchUserDataTrigger + 1);
      }
    },
    [fetchUserDataTrigger, setFetchUserDataTrigger, setStatus]
  );

  const CurrentLoginsTable = useCallback(() => {
    const currentLoginsTable = [];
    const loginProvidersLength = userData?.currentLogins.length ?? 0;
    for (let ind = 0; ind < loginProvidersLength; ind++) {
      currentLoginsTable.push(
        <tr key={ind}>
          <td key={userData?.currentLogins[ind].loginProvider + '1'}>
            {userData?.currentLogins[ind].providerDisplayName}
          </td>
          {userData?.canRemoveExternalProviders && (
            <td key={userData?.currentLogins[ind].loginProvider}>
              <Button
                value={userData?.currentLogins[ind].loginProvider + '2'}
                onClick={async () => await removeExternalProvider(userData?.currentLogins[ind])}
                size='sm'
                variant='danger'
              >
                Usuń
              </Button>
            </td>
          )}
        </tr>
      );
    }
    return (
      <>
        {loading ? (
          <table className='table'>
            <tbody>{Common.Ui.getSkeletonTableRows(3, 1, 35)}</tbody>
          </table>
        ) : (
          loginProvidersLength > 0 && (
            <>
              <h5 className='mt-4'>Zapisane aplikacje</h5>
              <table className='table'>
                <tbody>{currentLoginsTable}</tbody>
              </table>
            </>
          )
        )}
      </>
    );
  }, [userData, loading, removeExternalProvider]);
  const AddExternalLoginsPanel = useCallback(
    () => (
      <>
        {loading ? (
          <table className='table'>
            <tbody>{Common.Ui.getSkeletonTableRows(3, 1, 35)}</tbody>
          </table>
        ) : (
          (userData?.otherLogins.length ?? 0) > 0 && (
            <>
              <h5>Dodaj inne metody logowania</h5>
              <hr />
              <ExternalLogins
                actionType='linkLogin'
                identityServerUrl={identityServerUrl}
                loginProviders={userData?.otherLogins ?? []}
              />
            </>
          )
        )}
      </>
    ),
    [userData, identityServerUrl, loading]
  );

  return (
    <>
      <h4>Logowanie za pomocą aplikacji</h4>
      <Container>
        <CurrentLoginsTable />
        <AddExternalLoginsPanel />
      </Container>
      {Common.Ui.showLoadingSpinnerFixed(formLoading)}
    </>
  );
};

export default ExternalLogin;
