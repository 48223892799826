import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import { Formik } from 'formik';
import React, { useCallback, useRef, useState, useEffect } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import * as yup from 'yup';
import type { ColumnDef, SortingState } from '@tanstack/react-table';
import type { IUser } from '../../../../interfaces/IUser';
import Common from '../../../shared/Common';
import { SortUpIcon, SortDownIcon } from '../../../shared/icons/Svgs';

export interface IRoleToManage {
  id: string;
  name: string;
  roleUsers: string[];
}

export interface ITableProps {
  columns: ColumnDef<IRoleToManage>[];
  data: IRoleToManage[];
  loading: boolean;
  fetchData: () => Promise<void>;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  setRoleId: React.Dispatch<React.SetStateAction<string | undefined>>;
  fetchTrigger: number;
  setFetchTrigger: React.Dispatch<React.SetStateAction<number>>;
}

export interface ICreateRoleFormProps {
  fetchTrigger: number;
  setFetchTrigger: React.Dispatch<React.SetStateAction<number>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface IUpdateRoleModalProps {
  show: boolean;
  roleId: string | undefined;
  onHide: () => void;
  onSave: () => void;
}

function Table(props: ITableProps) {
  const [sorting, setSorting] = React.useState<SortingState>([]);

  const table = useReactTable({
    data: props.data,
    columns: props.columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const { fetchData, fetchTrigger } = props;

  useEffect(() => {
    fetchData();
  }, [fetchData, fetchTrigger]);

  return (
    <>
      <h4>Zarządzaj rolami w aplikacji</h4>
      <div className='table-responsive'>
        <table id='roles-table' className='table table-sm table-bordered table-bordered'>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          style: header.column.getCanSort() ? { cursor: 'pointer' } : {},
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: <SortUpIcon style={{ marginLeft: '5px' }} />,
                          desc: <SortDownIcon style={{ marginLeft: '5px' }} />,
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {props.loading
              ? Common.Ui.getSkeletonTableRows(6, 5, 35)
              : table.getRowModel().rows.map((row) => (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                    ))}
                  </tr>
                ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

const ManageRoles = () => {
  const [roles, setRoles] = useState<IRoleToManage[]>([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [roleId, setRoleId] = useState<string | undefined>(undefined);
  const [fetchTrigger, setFetchTrigger] = useState(0);
  const fetchIdRef = React.useRef(0);

  const updateRole = (roleId: string) => {
    setRoleId(roleId);
    setShowModal(true);
  };

  const removeRole = useCallback(
    async (id: string) => {
      setLoading(true);
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ roleId: id }),
      };
      const response = await Common.authorizedFetch('api/roles/removeRole', requestOptions);
      const data = await response.json();
      if (!data.success) {
        alert(data.errors);
      } else {
        setFetchTrigger(fetchTrigger + 1);
      }
    },
    [fetchTrigger]
  );

  const fetchData = useCallback(async () => {
    setLoading(true);
    const fetchId = ++fetchIdRef.current;
    const response = await Common.authorizedFetch('api/roles/getAllRoles');
    const data = await response.json();
    if (data.success) {
      if (fetchId === fetchIdRef.current) {
        setRoles(data.result.roles);
      }
    }
    setLoading(false);
  }, []);

  const columns = React.useMemo<ColumnDef<IRoleToManage>[]>(
    () => [
      {
        accessorFn: (row) => row.id,
        id: 'id',
        header: () => <span>Id</span>,
      },
      {
        accessorFn: (row) => row.name,
        id: 'name',
        header: () => <span>Nazwa</span>,
      },
      {
        accessorFn: (row) => row.roleUsers.join(', '),
        id: 'users',
        header: () => <span>Użytkownicy</span>,
      },
      {
        id: 'update',
        header: 'Aktualizuj',
        cell: (info) => (
          <Button value={info.row.original.id} onClick={() => updateRole(info.row.original.id)} size='sm'>
            Aktualizuj
          </Button>
        ),
        enableSorting: false,
      },
      {
        id: 'delete',
        header: 'Usuń',
        cell: (info) => (
          <Button
            value={info.row.original.id}
            onClick={async () => await removeRole(info.row.original.id)}
            size='sm'
            variant='danger'
          >
            Usuń
          </Button>
        ),
        enableSorting: false,
      },
    ],
    [removeRole]
  );

  return (
    <>
      <CreateRoleForm fetchTrigger={fetchTrigger} setFetchTrigger={setFetchTrigger} setLoading={setLoading} />
      <Table
        columns={columns}
        data={roles}
        loading={loading}
        fetchData={fetchData}
        setShowModal={setShowModal}
        setRoleId={setRoleId}
        fetchTrigger={fetchTrigger}
        setFetchTrigger={setFetchTrigger}
      />
      <UpdateRoleModal
        show={showModal}
        roleId={roleId}
        onHide={() => {
          setShowModal(false);
          setRoleId(undefined);
        }}
        onSave={() => {
          setFetchTrigger(fetchTrigger + 1);
        }}
      />
    </>
  );
};

const CreateRoleForm = (props: ICreateRoleFormProps) => {
  const validationSchema = yup.object().shape({
    roleName: yup.string().required('Nazwa roli jest wymagana'),
  });

  return (
    <>
      <h4>Stwórz nową rolę</h4>
      <Formik
        initialValues={{ roleName: '' }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setFieldError, setSubmitting, resetForm }) => {
          setSubmitting(true);
          props.setLoading(true);
          const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ roleName: values.roleName }),
          };
          const response = await Common.authorizedFetch('api/roles/createRole', requestOptions);
          const data = await response.json();
          if (!data.success) {
            setFieldError('roleName', data.errors);
          } else {
            props.setFetchTrigger(props.fetchTrigger + 1);
            resetForm();
          }
          props.setLoading(false);
          setSubmitting(false);
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <Row className='mb-3'>
              <Col className='col-md-6'>
                <Form.Group>
                  <Form.Control
                    type='text'
                    name='roleName'
                    placeholder='Nazwa roli'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.roleName}
                    className={touched.roleName && errors.roleName ? 'error' : undefined}
                  />
                  {touched.roleName && errors.roleName ? <div className='error-message'>{errors.roleName}</div> : null}
                </Form.Group>
              </Col>
              <Col>
                <Button variant='primary' type='submit' disabled={isSubmitting}>
                  Dodaj
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
};

function UpdateRoleModal(props: IUpdateRoleModalProps) {
  const [modalInitialLoading, setModalInitialLoading] = useState<boolean>(false);
  const [modalLoading, setModalLoading] = useState<boolean>(false);
  const [members, setMembers] = useState<IUser[]>([]);
  const [nonMembers, setNonMembers] = useState<IUser[]>([]);
  const [addRoleUserIds, setAddRoleUserIds] = useState<string[]>([]);
  const [removeRoleUserIds, setRemoveRoleUserIds] = useState<string[]>([]);
  const [roleName, setRoleName] = useState<string | undefined>(undefined);
  const fetchIdRef = useRef(0);

  const fetchRoleUsers = useCallback(async (roleId: string | undefined) => {
    setModalLoading(false);
    if (roleId !== undefined) {
      setModalInitialLoading(true);
      const fetchId = ++fetchIdRef.current;

      const response = await Common.authorizedFetch('api/roles/getRoleUsers?roleId=' + roleId);
      if (fetchId === fetchIdRef.current) {
        const data = await response.json();
        if (data.success) {
          setMembers(data.result.members);
          setNonMembers(data.result.nonMembers);
          setRoleName(data.result.roleName);
        }
        setModalInitialLoading(false);
      }
    }
  }, []);

  useEffect(() => {
    fetchRoleUsers(props.roleId);
    return () => {
      setAddRoleUserIds([]);
      setRemoveRoleUserIds([]);
    };
  }, [fetchRoleUsers, props.roleId]);

  useEffect(() => {
    setAddRoleUserIds([]);
    setRemoveRoleUserIds([]);
  }, [props.show]);

  const generateRoleUsersTable = (forMembers: boolean) => {
    const handleChange = (e: any) => {
      var isChecked = e.target.checked;
      var userId = e.target.value as string;
      if (forMembers) {
        var index = removeRoleUserIds.findIndex((id) => id === userId);
        if (isChecked && index === -1) {
          setRemoveRoleUserIds((prevState) => {
            prevState.push(userId);
            return prevState;
          });
        }
        if (!isChecked && index !== -1) {
          setRemoveRoleUserIds((prevState) => {
            prevState.splice(index, 1);
            return prevState;
          });
        }
      } else {
        index = addRoleUserIds.findIndex((id) => id === userId);
        if (isChecked && index === -1) {
          setAddRoleUserIds((prevState) => {
            prevState.push(userId);
            return prevState;
          });
        }
        if (!isChecked && index !== -1) {
          setAddRoleUserIds((prevState) => {
            prevState.splice(index, 1);
            return prevState;
          });
        }
      }
    };

    var users: IUser[] = [];
    if (forMembers) {
      users = members;
    } else {
      users = nonMembers;
    }
    let table = [];
    for (let ind = 0; ind < users.length; ind++) {
      table.push(
        <tr key={ind}>
          <td>{users[ind].userName}</td>
          <td style={{ width: '2em' }}>
            <input type='checkbox' name='AddIds' value={users[ind].id} onInput={(e) => handleChange(e)} />
          </td>
        </tr>
      );
    }
    return table;
  };

  const updateRoleUsers = async () => {
    setModalLoading(true);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        membersIds: addRoleUserIds,
        nonMembersIds: removeRoleUserIds,
        roleName,
      }),
    };
    const response = await Common.authorizedFetch('api/roles/updateRoleUsers', requestOptions);
    const data = await response.json();
    if (!data.success) {
      alert(data.errors);
    } else {
      props.onSave();
      props.onHide();
    }
    setModalLoading(false);
  };

  return (
    <Modal centered show={props.show} onHide={props.onHide} size='lg' aria-labelledby='contained-modal-title-vcenter'>
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>Aktualizuj rolę {roleName}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {Common.Ui.showLoadingSpinnerAbsolute(modalLoading)}
        <h5>Dodaj użytkownika do roli</h5>
        <div className='table-responsive-sm'>
          <table className='table table-bordered table-sm'>
            <tbody>
              {modalInitialLoading ? (
                Common.Ui.getSkeletonTableRows(5, 2, 35)
              ) : nonMembers.length === 0 ? (
                <tr>
                  <td colSpan={2}>Wszyscy użytkownicy mają tę rolę</td>
                </tr>
              ) : (
                generateRoleUsersTable(false)
              )}
            </tbody>
          </table>
        </div>
        <h5>Usuń użytkownika z roli</h5>
        <div className='table-responsive-sm'>
          <table className='table table-bordered table-sm'>
            <tbody>
              {modalInitialLoading ? (
                Common.Ui.getSkeletonTableRows(5, 2, 35)
              ) : members.length === 0 ? (
                <tr>
                  <td colSpan={2}>Żaden z użytkowników nie ma tej roli</td>
                </tr>
              ) : (
                generateRoleUsersTable(true)
              )}
            </tbody>
          </table>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='dark' onClick={props.onHide}>
          Zamknij
        </Button>
        <Button variant='success' onClick={updateRoleUsers}>
          Zapisz
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ManageRoles;
