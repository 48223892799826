import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import type { Column } from '@tanstack/react-table';
import { useState, useEffect, useContext, useMemo, useRef } from 'react';
import { Row, Form, Button, Col, Pagination } from 'react-bootstrap';
import Common from '../../shared/Common';
import WarningModal from '../../shared/shared-modals/WarningModal';
import type { ILocation } from '../../../interfaces/ILocation';
import type { ColumnDef, SortingState, PaginationState, ColumnFiltersState } from '@tanstack/react-table';
import {
  GalleryAddIcon,
  GalleryExportIcon,
  GalleryRemoveIcon,
  MinusIcon,
  SortDownIcon,
  SortUpIcon,
  TaskSquareIcon,
} from '../../shared/icons/Svgs';
import type { ILocationData } from '../../../interfaces/ILocationData';
import { DictionaryContext } from '../../../App';
import type { ICategoryName } from '../../../interfaces/ICategoryName';
import DefaultLocationPictures from '../../shared/default-location-pictures/DefaultLocationPictures';
import type { IDefaultLocationPicture } from '../../../interfaces/IDefaultLocationPicture';
import debounce from 'lodash.debounce';
import type { IPaginationProps } from './Locations';

import './style.css';
import LocationMappingsModal from './LocationMappingsModal';

export interface ITableProps {
  data: ILocationData[];
  loading: boolean;
  fetchData: ({ pageIndex, pageSize, sortBy, filters }: IPaginationProps) => Promise<void>;
  fetchTrigger: number;
  setFetchTrigger: React.Dispatch<React.SetStateAction<number>>;
  setShowSpinner: React.Dispatch<React.SetStateAction<boolean>>;
  updateLocation: (rowIndex: number, columnId: string, value: any) => void;
  updateLocationMappings: (value: ILocationData) => void;
  updatedLocations: ILocation[];
  setShowAddLocationModal: React.Dispatch<React.SetStateAction<boolean>>;
  selectedRowId: number;
  setSelectedRowId: React.Dispatch<React.SetStateAction<number>>;
  locationPictures: IDefaultLocationPicture[];
  totalPageCount: number;
}

export interface IEditableCellProps {
  value: any;
  row: any;
  column: any;
  updateLocation: (rowIndex: number, columnId: string, value: any) => void;
  categoriesNames: ICategoryName[];
}

function LocationsTable(props: ITableProps) {
  const [locationToRemove, setLocationToRemove] = useState<ILocation | undefined>();
  const [showDefaultPictures, setShowDefaultPictures] = useState<boolean>(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState<number>(-1);
  const { reloadLocationNames, categoriesNames } = useContext(DictionaryContext);
  const avatarInputRef = useRef<HTMLInputElement>(null);
  const { fetchData, locationPictures, fetchTrigger, updateLocationMappings } = props;
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const [selectedLocationMappings, setSelectedLocationMappings] = useState<ILocationData | undefined>(undefined);
  const [showConfigureMappingsModal, setShowConfigureMappingsModal] = useState(false);

  const [sorting, setSorting] = useState<SortingState>([]);
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const { updateLocation } = props;

  const columns = useMemo<ColumnDef<ILocationData>[]>(
    () => [
      {
        header: () => <span>Nazwa lokalizacji</span>,
        accessorFn: (row) => row.location?.locationName,
        id: 'locationName',
      },
      {
        header: () => <span>Rachunki</span>,
        accessorFn: (row) => row.count,
        enableColumnFilter: false,
        id: 'count',
      },
      {
        header: () => <span>Mapowania</span>,
        accessorFn: (row) => row.location?.mappings?.length ?? 0,
        enableSorting: false,
        enableColumnFilter: false,
        cell: (info) =>
          !info.row.original.location ? <></> : <span>{info.row.original.location?.mappings?.length ?? 0}</span>,
        id: 'mappings',
      },
      {
        header: () => <span>Wydano</span>,
        enableColumnFilter: false,
        accessorFn: (row) => row.totalCost,
        cell: (info) => <div>{Common.Utils.getCurrencyString(info.row.original.totalCost)}</div>,
        id: 'totalCost',
      },
      {
        header: () => <span>Obraz</span>,
        enableColumnFilter: false,
        accessorFn: (row) => row.location?.picture,
        id: 'picture',
      },
      {
        id: 'actions',
        enableColumnFilter: false,
        header: () => <div className='text-center'>Akcje</div>,
        cell: (info) =>
          !info.row.original.location ? (
            <></>
          ) : (
            <Row className='justify-content-center'>
              <Col xs='auto' className='p-0'>
                <div
                  style={{ cursor: 'pointer' }}
                  title='Edytuj mapowania'
                  onClick={() => {
                    var mappings = Common.Utils.cloneObject(info.row.original);
                    setSelectedLocationMappings(mappings);
                    setShowConfigureMappingsModal(true);
                  }}
                >
                  <TaskSquareIcon
                    style={{
                      color: 'var(--app-main-color)',
                      width: '38px',
                      height: '38px',
                    }}
                  />
                </div>
              </Col>
              <Col xs='auto' className='p-0'>
                <div
                  style={{ cursor: 'pointer' }}
                  title='Wybierz obraz'
                  onClick={() => {
                    setShowDefaultPictures(true);
                    setSelectedRowIndex(info.row.index);
                  }}
                >
                  <GalleryExportIcon
                    style={{
                      color: 'black',
                      width: '38px',
                      height: '38px',
                    }}
                  />
                </div>
              </Col>
              <Col xs='auto' className='p-0'>
                <div
                  style={{ cursor: 'pointer' }}
                  title='Wgraj zdjęcie'
                  onClick={() => {
                    avatarInputRef.current?.click();
                    setSelectedRowIndex(info.row.index);
                  }}
                >
                  <GalleryAddIcon
                    style={{
                      color: 'black',
                      width: '38px',
                      height: '38px',
                    }}
                  />
                </div>
              </Col>
              {!!info.row.original.location?.picture && (
                <Col xs='auto' className='p-0'>
                  <div
                    style={{ cursor: 'pointer' }}
                    title='Usuń obraz'
                    onClick={() => {
                      updateLocation(info.row.index, 'picture', {
                        type: 'picture',
                        value: '',
                      });
                      setSaveButtonDisabled(false);
                    }}
                  >
                    <GalleryRemoveIcon
                      style={{
                        color: 'black',
                        width: '38px',
                        height: '38px',
                      }}
                    />
                  </div>
                </Col>
              )}
              {!!info.row.original.location &&
                info.row.original.count === 0 &&
                (info.row.original.location.mappings?.length ?? 0) === 0 && (
                  <Col xs='auto' className='p-0'>
                    <div
                      title='Usuń lokalizację'
                      onClick={() => {
                        setLocationToRemove(info.row.original.location);
                      }}
                    >
                      <MinusIcon style={{ cursor: 'pointer', width: '38px', height: '38px', fill: '#dc3545' }} />
                    </div>
                  </Col>
                )}
            </Row>
          ),
        enableSorting: false,
      },
    ],
    [setSelectedLocationMappings, setShowConfigureMappingsModal, updateLocation, setSaveButtonDisabled]
  );

  const defaultColumn: Partial<ColumnDef<ILocationData>> = {
    cell: function Cell({ getValue, row, column: { id }, table }) {
      const initialValue = getValue();
      const [value, setValue] = useState(initialValue);
      const [valueChanged, setValueChanged] = useState(false);

      const onBlur = () => {
        if (valueChanged) {
          table.options.meta?.updateData?.(row.index, id, value);
        }
      };

      const onChange = (e: any) => {
        setValue(e.target.value);
        setValueChanged(true);
        if (e.target.value === '') {
          setSaveButtonDisabled(true);
        } else {
          setSaveButtonDisabled(false);
        }
      };

      useEffect(() => {
        setValue(initialValue);
      }, [initialValue]);

      if (!row.original.location) {
        if (id === 'picture') {
          return <></>;
        }
        if (id === 'locationName') {
          return <i>Nieprzypisane</i>;
        }
      }

      if (id === 'locationName') {
        return <Form.Control type='text' onChange={onChange} onBlur={onBlur} value={value as string} />;
      } else if (id === 'picture') {
        return (
          <img
            src={value === '' || value === undefined || value === null ? '/Static/icons/avatar.png' : (value as string)}
            height={75}
            width={75}
            alt='avatar'
          />
        );
      } else if (id === 'totalCost') {
        return <>{Common.Utils.getNumberWithSpaces(value as string)}</>;
      } else {
        return <>{value}</>;
      }
    },
  };

  const table = useReactTable({
    data: props.data,
    columns: columns,
    pageCount: props.totalPageCount,
    defaultColumn,
    state: {
      columnFilters,
      sorting,
      pagination,
    },
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    manualSorting: true,
    manualFiltering: true,
    meta: {
      updateData: (rowIndex, columnId, value) => {
        switch (columnId) {
          case 'locationName':
            props.updateLocation(rowIndex, columnId, {
              type: 'name',
              value: value,
            });
            break;
        }
      },
    },
  });

  useEffect(() => {
    fetchData({
      pageIndex,
      pageSize,
      sortBy: sorting,
      filters: columnFilters,
    });
  }, [fetchData, fetchTrigger, pageIndex, pageSize, sorting, columnFilters]);

  useEffect(() => {
    setSaveButtonDisabled(true);
  }, [fetchTrigger]);

  const saveLocations = async () => {
    setSaveButtonDisabled(true);
    props.setShowSpinner(true);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ locations: props.updatedLocations }),
    };
    const response = await Common.authorizedFetch('api/locations/saveLocations', requestOptions);
    const data = await response.json();
    if (!data.success) {
      alert(data.errors);
    } else {
      if (reloadLocationNames) {
        reloadLocationNames();
      }
      props.setFetchTrigger(props.fetchTrigger + 1);
    }
    props.setShowSpinner(false);
  };

  const removeLocation = async (locationId: number) => {
    props.setShowSpinner(true);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        locationId: locationId,
      }),
    };
    const response = await Common.authorizedFetch('api/locations/removeLocation', requestOptions);
    const data = await response.json();
    if (!data.success) {
      alert(data.errors);
    } else {
      if (reloadLocationNames) {
        reloadLocationNames();
      }
      props.setFetchTrigger(props.fetchTrigger + 1);
      setLocationToRemove(undefined);
    }
    props.setShowSpinner(false);
  };

  const onPictureSelected = (selectedPicture: IDefaultLocationPicture | undefined) => {
    setSaveButtonDisabled(false);
    props.updateLocation(selectedRowIndex, 'picture', {
      type: 'defaultPicture',
      value: {
        picture: selectedPicture?.pictureBase64String,
        defaultPictureId: selectedPicture?.defaultPictureId,
      },
    });
  };

  const debouncedFilterSearchChanged = useMemo(
    () =>
      debounce((e: React.ChangeEvent<HTMLInputElement>, column: Column<ILocationData, unknown>) => {
        column.setFilterValue(e.target.value);
        table.setPageIndex(0);
      }, 500),
    [table]
  );

  const onPictureUploaded = async (event: any) => {
    if (!!event.target.files[0]) {
      const billPictureBase64 = (await Common.Images.toBase64(event.target.files[0])) as string;
      setSaveButtonDisabled(false);
      props.updateLocation(selectedRowIndex, 'picture', {
        type: 'picture',
        value: billPictureBase64,
      });
    }
  };

  let items = [];
  for (
    let ind = 0,
      number = pageIndex < 2 ? 0 : pageIndex + 2 >= table.getPageCount() ? table.getPageCount() - 5 : pageIndex - 2;
    ind < 5;
    number++
  ) {
    if (number + 1 > table.getPageCount()) {
      break;
    }
    if (number + 1 <= 0) {
      continue;
    }
    items.push(
      <Pagination.Item key={number} active={number === pageIndex} onClick={() => table.setPageIndex(number)}>
        {number + 1}
      </Pagination.Item>
    );
    ind++;
  }

  return (
    <>
      <>
        <LocationMappingsModal
          show={showConfigureMappingsModal}
          locationData={selectedLocationMappings}
          categoriesNames={categoriesNames}
          setSelectedLocationMappings={setSelectedLocationMappings}
          updateLocationMappings={(value: ILocationData) => {
            updateLocationMappings(value);
            setSaveButtonDisabled(false);
          }}
          onHide={() => {
            setShowConfigureMappingsModal(false);
          }}
        />
        <DefaultLocationPictures
          show={showDefaultPictures}
          setShow={setShowDefaultPictures}
          locationPictures={locationPictures}
          onPictureSelected={onPictureSelected}
        />
        <input
          className='d-none'
          name='avatar'
          ref={avatarInputRef}
          onChange={(e) => {
            onPictureUploaded(e);
          }}
          type='file'
          accept='image/*'
        />
        <Row>
          <Col>
            <Pagination>
              <Pagination.First onClick={() => table.setPageIndex(0)} disabled={!table.getCanPreviousPage()} />
              <Pagination.Prev onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()} />
              {items}
              <Pagination.Next onClick={() => table.nextPage()} disabled={!table.getCanNextPage()} />
              <Pagination.Last
                onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                disabled={!table.getCanNextPage()}
              />
            </Pagination>
          </Col>
          <Col>
            <Col className='float-end'>
              <Button
                className='me-2 mb-1'
                variant='success'
                onClick={() => {
                  props.setShowAddLocationModal(true);
                }}
              >
                Dodaj
              </Button>
              <Button
                disabled={saveButtonDisabled}
                className='me-2 mb-1'
                variant='dark'
                onClick={() => {
                  saveLocations();
                }}
              >
                Zapisz
              </Button>
            </Col>
          </Col>
        </Row>
        <div className='mt-1 pb-5 table-responsive-sm'>
          <table id='locations-table' className='table'>
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th key={header.id}>
                      {header.isPlaceholder ? null : (
                        <>
                          {header.column.getCanFilter() ? (
                            <div>
                              <input
                                onChange={(e) => {
                                  debouncedFilterSearchChanged(e, header.column);
                                }}
                                placeholder={'Szukaj...'}
                                className='no-border-input'
                              />
                            </div>
                          ) : null}
                          <div
                            {...{
                              style: header.column.getCanSort()
                                ? {
                                    cursor: 'pointer',
                                  }
                                : {},
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            {flexRender(header.column.columnDef.header, header.getContext())}
                            {{
                              asc: <SortUpIcon style={{ marginLeft: '5px' }} />,
                              desc: <SortDownIcon style={{ marginLeft: '5px' }} />,
                            }[header.column.getIsSorted() as string] ?? null}
                          </div>
                        </>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {props.loading
                ? Common.Ui.getSkeletonTableRows(10, 6, 35)
                : table.getRowModel().rows.map((row) => (
                    <tr key={row.id}>
                      {row.getVisibleCells().map((cell) => (
                        <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                      ))}
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>
      </>
      <WarningModal
        show={!!locationToRemove}
        modalBodyText={`Czy na pewno chcesz usunąć lokalizację '${locationToRemove?.locationName}'?`}
        onHide={() => {
          setLocationToRemove(undefined);
        }}
        onConfirmation={() => removeLocation(locationToRemove?.locationId ?? 0)}
      />
    </>
  );
}

export default LocationsTable;
