import { useEffect, useState } from 'react';
import { PropagateLoader } from 'react-spinners';
import { LoginActions, QueryParameterNames, ApplicationPaths } from './ApiAuthorizationConstants';
import authService, { AuthenticationResultStatus } from './AuthorizeService';

// The main responsibility of this component is to handle the user's login process.
// This is the starting point for the login process. Any component that needs to authenticate
// a user can simply perform a redirect to this component with a returnUrl query parameter and
// let the component perform the login and return back to the return url.
const Login = (props: any) => {
  const [message, setMessage] = useState<string | undefined>(undefined);

  useEffect(() => {
    const login = async (returnUrl: string, returnPath: string) => {
      const result = await authService.signIn(returnPath);
      switch (result.status) {
        case AuthenticationResultStatus.Redirect:
          break;
        case AuthenticationResultStatus.Success:
          await navigateToReturnUrl(returnUrl);
          break;
        case AuthenticationResultStatus.Fail:
          setMessage(result.message);
          break;
        default:
          throw new Error(`Invalid status result ${result.status}.`);
      }
    };

    const processLoginCallback = async () => {
      const url = window.location.href;
      const result = await authService.completeSignIn(url);
      switch (result.status) {
        case AuthenticationResultStatus.Redirect:
          // There should not be any redirects as the only time completeSignIn finishes
          // is when we are doing a redirect sign in flow.
          throw new Error('Should not redirect.');
        case AuthenticationResultStatus.Success:
          await navigateToReturnUrl(getReturnUrl(result.state));
          break;
        case AuthenticationResultStatus.Fail:
          setMessage(result.message);
          break;
        default:
          throw new Error(`Invalid authentication result status '${result.status}'.`);
      }
    };

    switch (props.action) {
      case LoginActions.Login:
        login(getReturnUrl(undefined), getReturnPath(undefined));
        break;
      case LoginActions.LoginCallback:
        processLoginCallback();
        break;
      case LoginActions.LoginFailed:
        const params = new URLSearchParams(window.location.search);
        const error = params.get(QueryParameterNames.Message);
        setMessage(error ?? undefined);
        break;
      case LoginActions.Profile:
        redirectToProfile();
        break;
      case LoginActions.Register:
        redirectToRegister();
        break;
      default:
        throw new Error(`Invalid action '${props.action}'`);
    }
  }, [props.action]);

  const renderContent = () => {
    if (!!message) {
      return <h2 style={{ textAlign: 'center' }}>{message}</h2>;
    } else {
      switch (props.action) {
        case LoginActions.Login:
          return <h2 style={{ textAlign: 'center' }}>Sprawdzanie dostępów</h2>;
        case LoginActions.LoginCallback:
          return <h2 style={{ textAlign: 'center' }}>Logowanie</h2>;
        case LoginActions.Profile:
        case LoginActions.Register:
          return <div />;
        default:
          throw new Error(`Invalid action '${props.action}'`);
      }
    }
  };

  return (
    <div
      style={{
        position: 'absolute',
        left: '50%',
        top: '40%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      {renderContent()}
      <PropagateLoader
        color='#343A40'
        loading={true}
        cssOverride={{
          top: '10px',
          left: '45%',
        }}
        size={25}
      />
    </div>
  );
};

const getReturnUrl = (state: any) => {
  const params = new URLSearchParams(window.location.search);
  const fromQuery = params.get(QueryParameterNames.ReturnUrl);
  if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
    // This is an extra check to prevent open redirects.
    throw new Error('Invalid return url. The return url needs to have the same origin as the current page.');
  }
  return (state && state.returnUrl) || fromQuery || `${window.location.origin}/`;
};

const getReturnPath = (state: any) => {
  const params = new URLSearchParams(window.location.search);
  const fromQuery = params.get('returnPath');
  return (state && state.returnPath) || fromQuery || `${window.location.origin}/`;
};

const redirectToRegister = () => {
  redirectToApiAuthorizationPath(
    `${ApplicationPaths.IdentityRegisterPath}?${QueryParameterNames.ReturnUrl}=${encodeURI(ApplicationPaths.Login)}`
  );
};

const redirectToProfile = () => {
  redirectToApiAuthorizationPath(ApplicationPaths.IdentityManagePath);
};

const redirectToApiAuthorizationPath = (apiAuthorizationPath: string) => {
  const redirectUrl = `${window.location.origin}/${apiAuthorizationPath}`;
  // It's important that we do a replace here so that when the user hits the back arrow on the
  // browser they get sent back to where it was on the app instead of to an endpoint on this
  // component.
  window.location.replace(redirectUrl);
};

const navigateToReturnUrl = (returnUrl: string) => {
  // It's important that we do a replace here so that we remove the callback uri with the
  // fragment containing the tokens from the browser history.
  window.location.replace(returnUrl);
};

export default Login;
