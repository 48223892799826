import { Container } from 'react-bootstrap';
import { Route, Routes } from 'react-router-dom';
import AccessDenied from './access-denied/AccessDenied';
import ConfirmEmailChange from './confirm-email-change/ConfirmEmailChange';
import ConfirmEmail from './confirm-email/ConfirmEmail';
import CreateExternalLogin from './create-external-login/CreateExternalLogin';
import Lockout from './lockout/Lockout';
import Login from './login/Login';
import Register from './register/Register';
import ResendActivationEmail from './resend-activation-email/ResendActivationEmail';
import ResetPassword from './reset-password/ResetPassword';
import SetPassword from './set-password/SetPassword';

const Account = () => (
  <Container className='mt-2'>
    <Routes>
      <Route path='login' element={<Login />} />
      <Route path='register' element={<Register />} />
      <Route path='create-external-login' element={<CreateExternalLogin />} />
      <Route path='reset-password' element={<ResetPassword />} />
      <Route path='set-password' element={<SetPassword />} />
      <Route path='confirm-email' element={<ConfirmEmail />} />
      <Route path='confirm-email-change' element={<ConfirmEmailChange />} />
      <Route path='resend-activation-email' element={<ResendActivationEmail />} />
      <Route path='lockout' element={<Lockout />} />
      <Route path='access-denied' element={<AccessDenied />} />
    </Routes>
  </Container>
);

export default Account;
