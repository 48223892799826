import { useContext, useState } from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import Common from '../../shared/Common';
import CategoryAutosuggest from '../../shared/category-autosuggest/CategoryAutosuggest';
import { DictionaryContext } from '../../../App';
import type { ICategoryName } from '../../../interfaces/ICategoryName';

import './style.css';

export interface IAddCategoryModalProps {
  show: boolean;
  onHide: () => void;
  onSave: () => void;
  categoriesNames: ICategoryName[];
}

function AddCategoryModal(props: IAddCategoryModalProps) {
  const [categoryName, setCategoryName] = useState<string>('');
  const [parentCategoryId, setParentCategoryId] = useState<number | undefined>(undefined);
  const { reloadCategoriesNames } = useContext(DictionaryContext);

  const addCategory = async () => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ categoryName, parentCategoryId }),
    };
    const response = await Common.authorizedFetch('api/categories/addCategory', requestOptions);
    const data = await response.json();
    if (!data.success) {
      alert(data.errors);
    } else {
      if (reloadCategoriesNames) {
        reloadCategoriesNames();
      }
      setCategoryName('');
      setParentCategoryId(undefined);
      props.onHide();
      props.onSave();
    }
  };

  return (
    <Modal centered show={props.show} onHide={props.onHide} aria-labelledby='contained-modal-title-vcenter'>
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>Dodaj nową kategorię</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId='formBasicCategoryName'>
            <Form.Label>Nazwa kategorii</Form.Label>
            <Form.Control
              type='text'
              placeholder='Wpisz nazwę'
              onChange={(e: any) => {
                setCategoryName(e.target.value);
              }}
            />
            <Form.Text className='text-muted'>Nazwa kategorii określająca kategorię produktu.</Form.Text>
          </Form.Group>
          <Form.Group controlId='formBasicParentCategoryId'>
            <Form.Label>Kategoria rodzica</Form.Label>
            <CategoryAutosuggest
              onChange={(categoryJson) => {
                setParentCategoryId(JSON.parse(categoryJson).categoryId);
              }}
              categories={props.categoriesNames}
            />
            <Form.Text className='text-muted'>Nazwa kategorii będącej agregatem dodawanej kategorii.</Form.Text>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='dark' onClick={props.onHide}>
          Zamknij
        </Button>
        <Button variant='success' onClick={addCategory}>
          Zapisz
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddCategoryModal;
