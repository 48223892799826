import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import React, { useCallback, useState, useEffect } from 'react';
import { Button, Container, Form, Modal, Row } from 'react-bootstrap';
import Common from '../../../shared/Common';
import { SortUpIcon, SortDownIcon, MinusIcon, PlusIcon } from '../../../shared/icons/Svgs';
import type { ColumnDef, SortingState } from '@tanstack/react-table';

import './style.css';

export interface ILoadBillConfiguration {
  setting: string;
  code: string;
  value: ILoadBillConfigurationValue[];
}

export interface ILoadBillConfigurationValue {
  id?: number;
  value: string;
}

export interface ITableProps {
  columns: ColumnDef<ILoadBillConfiguration, string>[];
  data: ILoadBillConfiguration[];
  loading: boolean;
  fetchData: () => Promise<void>;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  fetchTrigger: number;
  setFetchTrigger: React.Dispatch<React.SetStateAction<number>>;
  updateConfiguration: (config: ILoadBillConfiguration) => void;
}

export interface ISettingValueTableProps {
  show: boolean;
  loadBillConfiguration: ILoadBillConfiguration;
  setLoadBillConfiguration: React.Dispatch<React.SetStateAction<ILoadBillConfiguration>>;
  onHide: () => void;
  onSave: () => void;
}

export interface ISettingValueModalProps {
  show: boolean;
  loadBillConfiguration: ILoadBillConfiguration;
  setLoadBillConfiguration: React.Dispatch<React.SetStateAction<ILoadBillConfiguration>>;
  onHide: () => void;
  onSave: () => void;
}

export interface IEditableCellProps {
  value: any;
  row: any;
  column: any;
}

function SettingsTable(props: ITableProps) {
  const [sorting, setSorting] = React.useState<SortingState>([]);

  const table = useReactTable({
    data: props.data,
    columns: props.columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const { fetchData, fetchTrigger } = props;

  useEffect(() => {
    fetchData();
  }, [fetchData, fetchTrigger]);

  return (
    <Container>
      <Row>
        <h4>Zarządzaj konfiguracjami wczytywania paragonu</h4>
      </Row>
      <Row className='mt-1 table-responsive-sm'>
        <table id='all-configurations-table' className='table table-sm table-bordered table-striped table-hover'>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          style: header.column.getCanSort() ? { cursor: 'pointer' } : {},
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: <SortUpIcon style={{ marginLeft: '5px' }} />,
                          desc: <SortDownIcon style={{ marginLeft: '5px' }} />,
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {props.loading
              ? Common.Ui.getSkeletonTableRows(6, 2, 35)
              : table.getRowModel().rows.map((row) => (
                  <tr
                    key={row.id}
                    onClick={() =>
                      props.updateConfiguration({
                        setting: row.original.setting,
                        code: row.original.code,
                        value: row.original.value,
                      })
                    }
                  >
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                    ))}
                  </tr>
                ))}
          </tbody>
        </table>
      </Row>
    </Container>
  );
}

function SettingValueTable(props: ISettingValueTableProps) {
  const { setLoadBillConfiguration } = props;

  const columns = React.useMemo<ColumnDef<ILoadBillConfigurationValue>[]>(
    () => [
      {
        accessorFn: (row) => row.id,
        id: 'actionButton',
        header: () => (
          <div
            onClick={() => {
              setLoadBillConfiguration((prevState) => ({
                ...prevState,
                value: prevState.value.concat([
                  {
                    id: undefined,
                    value: '',
                  },
                ]),
              }));
            }}
          >
            <PlusIcon style={{ cursor: 'pointer', width: '30px', height: '30px', fill: 'var(--app-main-color)' }} />
          </div>
        ),
        cell: (info) => (
          <div
            onClick={() => {
              setLoadBillConfiguration((prevState) => {
                const selectedValues = [info.row.original];
                return {
                  ...prevState,
                  value: prevState.value.filter((item) => !selectedValues.includes(item)),
                };
              });
            }}
          >
            <MinusIcon style={{ cursor: 'pointer', width: '30px', height: '30px', fill: '#dc3545' }} />
          </div>
        ),
      },
      {
        accessorFn: (row) => row.value,
        id: 'value',
        header: () => <span>Wartość</span>,
      },
    ],
    [setLoadBillConfiguration]
  );

  const defaultColumn: Partial<ColumnDef<ILoadBillConfigurationValue>> = {
    cell: function Cell({ getValue, row: { index }, column: { id }, table }) {
      const initialValue = getValue();
      const [value, setValue] = React.useState(initialValue);

      const onBlur = () => {
        table.options.meta?.updateData?.(index, id, value);
      };

      React.useEffect(() => {
        setValue(initialValue);
      }, [initialValue]);

      return (
        <Form.Control
          size='sm'
          type='text'
          onChange={(e) => setValue(e.target.value)}
          onBlur={onBlur}
          value={value as string}
        />
      );
    },
  };

  var items = props.loadBillConfiguration?.value ?? [];

  const table = useReactTable({
    data: items,
    columns,
    defaultColumn,
    getCoreRowModel: getCoreRowModel(),
    meta: {
      updateData: (rowIndex, columnId, value) => {
        props.setLoadBillConfiguration((prevState) => {
          const updatedValue = prevState.value;
          updatedValue[rowIndex] = {
            ...prevState.value[rowIndex],
            [columnId]: value,
          };

          const obj = {
            ...prevState,
            value: updatedValue,
          };

          return obj;
        });
      },
    },
  });

  return (
    <>
      <table
        id={'edited-configuration-table'}
        className='table table-striped table-sm mt-2'
        aria-labelledby='tabelLabel'
      >
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder ? null : (
                    <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}

function SettingValueModal(props: ISettingValueModalProps) {
  const [loading, setLoading] = useState(false);

  const saveSettingValue = async () => {
    setLoading(true);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        configuration: props.loadBillConfiguration,
      }),
    };
    const response = await Common.authorizedFetch(
      'api/applicationLogicConfiguration/saveLoadBillConfiguration',
      requestOptions
    );
    const data = await response.json();
    if (!data.success) {
      alert(data.errors);
    }
    setLoading(false);
    props.onSave();
    props.onHide();
  };

  return (
    <>
      <Modal show={props.show} onHide={props.onHide} aria-labelledby='contained-modal-title-vcenter'>
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            Konfiguracja {props.loadBillConfiguration.setting}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {Common.Ui.showLoadingSpinnerAbsolute(loading)}
          <SettingValueTable
            show={props.show}
            loadBillConfiguration={props.loadBillConfiguration}
            setLoadBillConfiguration={props.setLoadBillConfiguration}
            onHide={() => {
              props.onHide();
            }}
            onSave={() => {
              props.onSave();
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant='dark' onClick={props.onHide}>
            Zamknij
          </Button>
          <Button variant='success' disabled={loading} onClick={saveSettingValue}>
            Zapisz
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

const LoadBillConfiguration = () => {
  const [configurations, setConfigurations] = useState<ILoadBillConfiguration[]>([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [loadBillConfiguration, setLoadBillConfiguration] = useState<ILoadBillConfiguration>({
    setting: '',
    code: '',
    value: [],
  });
  const [fetchTrigger, setFetchTrigger] = useState(0);
  const fetchIdRef = React.useRef(0);

  const updateConfiguration = (loadBillConfiguration: ILoadBillConfiguration) => {
    setLoadBillConfiguration(loadBillConfiguration);
    setShowModal(true);
  };

  const fetchData = useCallback(async () => {
    setLoading(true);
    const fetchId = ++fetchIdRef.current;
    const response = await Common.authorizedFetch('api/applicationLogicConfiguration/getLoadBillConfiguration');
    const data = await response.json();
    if (data.success) {
      if (fetchId === fetchIdRef.current) {
        setConfigurations(data.result.configurations);
      }
    }
    setLoading(false);
  }, []);

  const columnHelper = createColumnHelper<ILoadBillConfiguration>();

  const columns = React.useMemo<ColumnDef<ILoadBillConfiguration, string>[]>(
    () => [
      columnHelper.accessor((row) => row.setting, {
        id: 'setting',
        cell: (info) => info.getValue(),
        header: () => <span>Ustawienie</span>,
      }),
      columnHelper.accessor((row) => row.value.map((item) => item.value).join('\r\n'), {
        id: 'value',
        cell: (info) => info.getValue(),
        header: () => <span>Wartość</span>,
      }),
    ],
    [columnHelper]
  );

  return (
    <Container className='mt-2'>
      <SettingsTable
        columns={columns}
        data={configurations}
        loading={loading}
        fetchData={fetchData}
        setShowModal={setShowModal}
        fetchTrigger={fetchTrigger}
        setFetchTrigger={setFetchTrigger}
        updateConfiguration={updateConfiguration}
      />
      <SettingValueModal
        show={showModal}
        loadBillConfiguration={loadBillConfiguration}
        setLoadBillConfiguration={setLoadBillConfiguration}
        onHide={() => {
          setShowModal(false);
          setLoadBillConfiguration({
            setting: '',
            code: '',
            value: [],
          });
        }}
        onSave={() => {
          setFetchTrigger(fetchTrigger + 1);
        }}
      />
    </Container>
  );
};

export default LoadBillConfiguration;
